<template>
  <div class="body">
    <div class="container">
      <div class="row">
        <div class="mx-auto w-100">
          <div class="border-0 rounded-3 my-5">
            <div class="card-body p-4 p-sm-5">
              <!-- <h3 class="card-title text-center mb-5 fw-light fs-5">
               {{updateDetails ? "Update": "Enter"}} Card Details
              </h3> -->
              <form @submit.prevent="onSubmit">
                <div class="form-floating mb-3">
                  <label for="floatingInput">First Name:</label>
                  <input
                    type="text"
                    class="form-control"
                    id="floatingInput"
                    placeholder="First name"
                    v-model="first_name"
                    required
                  />
                </div>
                <div class="form-floating mb-3">
                  <label for="floatingInput">Last Name:</label>
                  <input
                    type="text"
                    class="form-control"
                    id="floatingInput"
                    placeholder="Last name"
                    v-model="last_name"
                    required
                  />
                </div>
                <div
                  class="d-grid text-center justify-content-between"
                  :class="cancel ? 'd-flex' : ''"
                >
                  <button
                    class="btn btn-danger text-uppercase fw-bold"
                    type="button"
                    v-if="cancel"
                    @click="$emit('closeModal')"
                  >
                    Cancel
                  </button>
                  <button
                    class="btn btn-login text-uppercase fw-bold"
                    type="submit"
                    :disabled="isLoading"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ChangeName",
  props: {
    updateDetails: {
      type: Boolean,
      default: false,
    },
    cancel: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      first_name: "",
      last_name: "",
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["updateUserName","getUser"]),
    async onSubmit() {
      this.isLoading = true;
      const response = await this.updateUserName({
        // username: `abccccdcdd`,
        first_name: this.first_name,
        last_name: this.last_name,
      });
      console.log(response);
      const upd_user = await this.getUser();
        localStorage.setItem(
          "instant_username",
          `${upd_user.first_name} ${upd_user.last_name}`
        );
      this.isLoading = false;
      this.$toastr.Add({
        name: "Success", // this is give you ability to use removeByName method
        msg: "Your Name has been changed Successfully!", // Toast Message
        type: "success", // Toast type,
      });
      this.$emit('closeModal')
      // const data = {
      //   name: this.first_name,
      // };
    },
  },
  async mounted() {
    this.first_name = this.user.first_name;
    this.last_name = this.user.last_name;
  },
};
</script>
