<template>
  <div id="sidebar-print">
    <div id="sidebar-wrapper" class="sidebar">
      <div class="logo-details">
        <router-link to="/"> </router-link>
        <div
          class="
            logo_name
            d-flex
            justify-content-between
            align-items-center
            w-100
          "
        >
          <router-link to="/"> </router-link>
        </div>
      </div>
      <ul class="nav-list">
        <li>
          <router-link to="/htma-results-input" active-class="active" exact>
            <i class="fa fa-user"></i>
            <span class="links_name">HTMA Results Input</span>
          </router-link>
        </li>
        <li>
          <router-link to="/naq-symptoms" active-class="active" exact>
            <i class="fa fa-sliders"></i>
            <span class="links_name">NAQ Symptoms </span>
          </router-link>
        </li>

        <li>
          <router-link to="/conditions" active-class="active" exact>
            <i class="fa fa-heart"></i>
            <span class="links_name">Conditions</span>
          </router-link>
        </li>
        <li>
          <router-link to="/htma-naq-conditions" active-class="active" exact>
            <i class="fa fa-database" aria-hidden="true"></i>
            <span class="links_name">HTMA+NAQ+Conditions</span>
          </router-link>
        </li>
        <li>
          <router-link to="/metabolic-blueprint" active-class="active" exact>
            <i class="fa fa-list"></i>
            <span class="links_name">Metabolic Blueprint</span>
          </router-link>
        </li>
         <li>
          <router-link to="/comparison-review" active-class="active" exact>
            <i class="fa fa-exchange"></i>
            <span class="links_name">Comparison Review</span>
          </router-link>
        </li>

        <li>
          <router-link to="/client-report" active-class="active" exact>
            <i class="fa fa-book"></i>
            <span class="links_name">Client Report Generator</span>
          </router-link>
        </li>
        <li>
          <router-link to="/iron-blood-markers" active-class="active" exact>
            <!-- <i class="fa fa-circle-o"></i> -->
            <!-- <i class="fa fa-circle" aria-hidden="true"></i> -->
            <!-- <svg height="100" width="100">
  <circle cx="50" cy="50" r="40" stroke="black" stroke-width="3" fill="white" />
</svg> -->
            <!-- <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
              <circle
                cx="50"
                cy="50"
                r="40"
                stroke="white"
                stroke-width="15"
                fill="transparent"
              />
            </svg> -->
            <i class="fa fa-tint"></i>

            <span class="links_name">Iron Blood Markers</span>
          </router-link>
        </li>

        <li>
          <router-link to="/iodine-blood-markers" active-class="active" exact>
            <i class="fa fa-tint"></i>
            <span class="links_name">Iodine Blood Markers</span>
          </router-link>
        </li>

        <li>
          <router-link to="/copper-blood-markers" active-class="active" exact>
            <i class="fa fa-tint"></i>
            <span class="links_name">Copper Blood Markers</span>
          </router-link>
        </li>

        
       

        <li>
          <router-link to="/client-list" active-class="active" exact>
            <i class="fa fa-th-list"></i>
            <span class="links_name">Client List</span>
          </router-link>
        </li>
        <li>
          <router-link to="/account-settings" active-class="active" exact>
            <i class="fa fa-gear"></i>
            <span class="links_name">Account Settings</span>
          </router-link>
        </li>
      </ul>
    </div>
    <button
      class="navbar-toggler"
      type="button"
      id="menu-toggle"
      data-bs-target="#navbar-collapse-x"
    >
      <div class="hamburger-toggle active d-none d-sm-block">
        <div class="hamburger">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </button>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
};
</script>

<style>
#wrapper {
  padding-left: 240px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled {
  padding-left: 250px;
}

#sidebar-wrapper {
  z-index: 1;
  position: fixed;
  left: 250px;
  top: 0;
  width: 0;
  height: 100%;
  background-color: #884c42;
  margin-left: -250px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.sidebar-brand a img {
  width: 130px;
}

#wrapper.toggled #sidebar-wrapper {
  width: 250px;
}

#page-content-wrapper {
  width: 100%;
  position: absolute;
  padding: 15px;
}

#page-content-wrapper .container-fluid .border {
  border-bottom: none !important;
}

#wrapper.toggled #page-content-wrapper {
  position: absolute;
  margin-right: -250px;
}

/* TOGGLED */
.navbar-toggler {
  border: none;
  padding: 0;
  outline: none;
  left: 0;
  top: -100px;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.navbar-toggler .hamburger-toggle {
  position: absolute;
  display: inline-block;
  width: 50px;
  height: 50px;
  z-index: 11;
  top: 20px;
  right: -70px;
}

.navbar-toggler .hamburger-toggle .hamburger {
  position: absolute;
  transform: translate(0, 0) rotate(45deg);
  right: 42px;
  top: 0px;
  width: 0%;
  height: 50%;
  pointer-events: none;
  z-index: 1000;
}

.navbar-toggler .hamburger-toggle .hamburger span {
  width: 25px;
  height: 3px;
  position: absolute;
  background: #5e72e4;
  border-radius: 2px;
  z-index: 1;
  transition: transform 0.2s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.2s cubic-bezier(0.77, 0.2, 0.05, 1), all 0.2s ease-in-out;
}

.navbar-toggler .hamburger-toggle .hamburger span:first-child {
  top: 1%;
  left: 0.1rem;
  transform: rotate(-45deg);
}

.navbar-toggler .hamburger-toggle .hamburger span:nth-child(2) {
  top: 5px;
  left: 7px;
  transform: rotate(-45deg);
}

.navbar-toggler .hamburger-toggle .hamburger span:last-child {
  left: 12px;
  bottom: 50%;
  transform: rotate(-45deg);
}

/* Sidebar Styles */
.sidebar-nav {
  margin: 0;
  padding: 0;
  list-style: none;
}

#wrapper.toggled {
  padding-left: 62px;
}

#sidebar-wrapper {
  width: 240px;
  padding: 0;
}

#wrapper.toggled #sidebar-wrapper {
  width: 63px;
}

#wrapper.toggled #sidebar-wrapper .sidebar {
  opacity: 0;
}

#page-content-wrapper {
  padding: 0;
  position: relative;
}

#wrapper.toggled #page-content-wrapper {
  position: relative;
  margin-right: 0;
}

.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 77px;
  background: #11101d;
  z-index: 99;
  transition: all 0.5s ease;
}

.sidebar.open {
  width: inherit;
}

.sidebar .logo-details {
  height: 60px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 40px 17px;
}

.sidebar .logo-details .icon {
  opacity: 0;
  transition: all 0.5s ease;
}

.sidebar .logo-details .logo_name {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  opacity: 1;
  transition: all 0.5s ease;
}

#wrapper.toggled .logo-details .logo_name {
  opacity: 0;
}

.sidebar .logo-details .logo_name .close {
  color: rgb(255 255 255 / 40%);
  cursor: pointer;
}

.sidebar .logo-details .logo_name .close :not(svg) {
  color: #fff !important;
}

.sidebar.open .logo-details .icon,
.sidebar.open .logo-details .logo_name {
  opacity: 1;
}

.sidebar .logo-details #btn {
  position: fixed;
  top: 20px;
  left: 30px;
  transform: translate(60px, 0px);
  font-size: 22px;
  transition: all 0.4s ease;
  font-size: 23px;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s ease;
  color: #fff;
  border-radius: 5px;
}

.sidebar.open .logo-details #btn {
  visibility: hidden;
  opacity: 0;
}

.sidebar i {
  color: #fff;
  height: 30px;
  min-width: 42px;
}

.sidebar .nav-list {
  margin-top: 10px;
  height: 100%;
  padding: 0;
}

.sidebar li {
  position: relative;
  margin: 5px 0;
  list-style: none;
}

.sidebar.open input {
  padding: 0 20px 0 50px;
  width: 100%;
}

.sidebar .bx-search {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 22px;
  color: #fff;
}

.sidebar li a {
  display: flex;
  height: 40px;
  width: 100%;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
}

.sidebar li a.active,
.sidebar li a:active {
  background: rgb(255 255 255 / 10%);
  border-left: 3px solid #fff;
}

.sidebar li a.active svg,
.sidebar li a:active svg {
  margin-left: 17px;
}

.sidebar li a .links_name {
  color: #fff;
  font-size: 15px;
  margin-left: 12px;
  font-weight: 400;
  white-space: nowrap;
  opacity: 1;
  pointer-events: none;
  transition: 0.4s;
  padding: 20px 0px;
}

#wrapper.toggled .links_name {
  opacity: 0;
}

.sidebar.open li a .links_name {
  opacity: 1;
  pointer-events: auto;
}

#wrapper .logo-details .small-logo {
  display: none;
}

#wrapper #menu-toggle {
  display: none;
}

#wrapper.toggled #menu-toggle {
  display: none;
  position: absolute;
  left: 35px;
  top: 0px;
}

#wrapper #menu-toggle {
  display: none;
}

#wrapper.toggled #menu-toggle {
  display: block;
  position: absolute;
  left: 35px;
  top: 0px;
}

#wrapper.toggled .logo-details .small-logo {
  display: block;
}

.sidebar li a:hover .links_name,
.sidebar li a:hover i {
  transition: all 0.5s ease;
  color: #fff;
}

.sidebar li i {
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  border-radius: 12px;
}

.sidebar li.profile {
  position: absolute;
  height: 60px;
  left: 0;
  right: 0;
  bottom: -8px;
  padding: 10px 14px;
  transition: all 0.5s ease;
  overflow: hidden;
  cursor: pointer;
}

.sidebar.open li.profile {
  width: 100%;
}

.sidebar li svg {
  height: 20px;
  width: 20px;
  font-size: 20px;
  object-fit: contain;
  margin-right: 10px;
  margin-left: 20px;
  color: white;
  overflow: visible;
}

.sidebar .bx-log-out img {
  margin-left: 0px;
  margin-right: 15px;
  cursor: pointer;
}

.sidebar li.profile .name,
.sidebar li.profile .job {
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  white-space: nowrap;
}

.sidebar li.profile .job {
  font-size: 12px;
}

.sidebar .profile #log_out {
  position: absolute;
  top: 50%;
  left: 0;
  padding-left: 17px;
  text-align: left;
  transform: translateY(-50%);
  width: 100%;
  height: 60px;
  line-height: 60px;
  border-radius: 0px;
  transition: all 0.5s ease;
}

.sidebar .profile .links_name {
  font-style: normal;
  font-size: 14px;
}

.sidebar.open .profile #log_out {
  width: 50px;
  background: none;
}

.home-section {
  position: relative;
  background: #e4e9f7;
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
}

.sidebar.open ~ .home-section {
  left: 250px;
  width: calc(100% - 250px);
}

.home-section .text {
  display: inline-block;
  color: #11101d;
  font-size: 25px;
  font-weight: 500;
  margin: 18px;
}

@media (max-width: 991px) {
  .sidebar .logo-details {
    padding: 40px 18px;
  }

  .sidebar.open .logo-details .logo_name img {
    width: 130px !important;
  }
}

@media (max-width: 767px) {
  #wrapper.toggle #menu-toggle {
    display: none;
  }

  #wrapper #sidebar-wrapper {
    width: 77px;
    overflow: hidden;
  }

  #wrapper {
    padding-left: 0px;
  }

  #wrapper .links_name,
  #wrapper .logo_name {
    opacity: 0;
  }

  #wrapper .small-logo {
    display: block !important;
    margin-left: 5px;
  }

  .sidebar li img {
    margin-left: 28px;
  }

  .sidebar li a.active img,
  .sidebar li a:active img {
    margin-left: 26px;
  }

  .sidebar .bx-log-out img {
    margin-left: 10px;
  }
}

@media (max-width: 575px) {
  .sidebar .logo-details #btn {
    top: 40px;
    left: 25px;
  }
}
@media print {
  #sidebar-wrapper * {
    visibility: hidden;
  }
  /* body * {
  visibility:hidden;
  } */
  /* #print, #print * {
    visibility:visible;
  }
  #print {
    position:absolute;
    left:0;
    top:0;
  } */
}
</style>
