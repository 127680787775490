import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/auth/Login.vue";
import Register from "../views/auth/Register.vue";
import ForgotPassword from "../views/auth/ForgotPassword.vue";
import ResetPassword from "../views/auth/ResetPassword.vue";
import PrivacyPolicy from "../views/auth/PrivacyPolicy.vue";
import Home from "../views/auth/Home.vue";

import Stripe from "../views/auth/Stripe.vue";
import PaymentPricing from "../views/website/PaymentPricing.vue"
import Website from "../views/Website.vue";

import AllEntries from "../views/website/AllEntries.vue";
import AllReports from "../views/website/AllReports.vue";
import HTMAResultsInput from "../views/website/HTMAResultsInput.vue";
import HTMAAnalysis from "../views/website/HTMAAnalysis.vue";
import HTMANaqConditions from "../views/website/HTMANaqConditions.vue";
import SingleTestAnalysis from "../views/website/SingleTestAnalysis.vue";
import MetabolicBlueprint from "../views/website/MetabolicBlueprint.vue";
import MetabolicBlueprintResult from "../views/website/MetabolicBlueprintResult.vue";
import BloodMarkersIron from "../views/website/BloodMarkersIron.vue";
import BloodMarkersIodine from "../views/website/BloodMarkersIodine.vue";
import BloodMarkersCopper from "../views/website/BloodMarkersCopper.vue";
import NAQSymptoms from "../views/website/NAQSymptoms.vue";
import NAQSymptomsOutput from "../views/website/NAQSymptomsOutput.vue";
import Conditions from "../views/website/Conditions.vue";
import ConditionOutput from "../views/website/ConditionOutput.vue";
import ComparisonReview from "../views/website/ComparisonReview.vue";
import ComparisonTestAnalysis from "../views/website/ComparisonTestAnalysis.vue";
import ClientReportGenerator from "../views/website/ClientReportGenerator.vue";
import ClientReport from "../views/website/ClientReport.vue";
import ClientList from "../views/website/ClientList.vue";
import AccountSettings from "../views/website/AccountSettings.vue"


import HTMAInput from "../components/HTMAInput/HTMAInput.vue"
import NAQ from "../components/NAQ/NAQ.vue"
import NaqHtmaForm from "../components/NaqHtmaForm/NaqHtmaForm.vue"
import ConditionForm from "../components/ConditionForm/ConditionForm.vue"
import BloodMarkerIron from "../components/BloodMarkers/BloodMarkerIron.vue"
import BloodMarkerIodine from "../components/BloodMarkers/BloodMarkerIodine.vue"
import BloodMarkerCopper from "../components/BloodMarkers/BloodMarkerCopper.vue"

import ComparisonReviewNew from "../views/website/ComparisonReviewNew.vue";
import ComparisonList from "../views/website/ComparisonList.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "",
    component: Website,
    name: "Website",
    
    children: [
      { path: "", redirect: { name: "HTMAResultsInput" } },
      // { path: "", redirect: { name: "HTMANaqConditions" } },
      
      {
        path: "/all-entries",
        name: "AllEntries",
        component: AllEntries,
        meta:{
          requiresSubscription: true
        },
      },
      {
        path: "/all-reports",
        name: "AllReports",
        component: AllReports,
        meta:{
          requiresSubscription: true
        },
      },
      {
        path: "/client-list",
        name: "ClientList",
        component: ClientList,
        meta:{
          requiresSubscription: true
        },
      },
      {
        path: "/single-test-analysis",
        name: "SingleTestAnalysis",
        component: SingleTestAnalysis,
        props: (route) => ({
          test: route.query.test,
          naq: route.query.naq,
          naqhtma: route.query.naqhtma,
          condition: route.query.condition,
        }),
        meta:{
          requiresSubscription: true
        }
      },
      {
        path: "/htma-naq-conditions",
        name: "HTMANaqConditions",
        component: HTMANaqConditions,
        meta:{
          requiresSubscription: true
        },
      },
      {
        path: "/htma-results-input",
        name: "HTMAResultsInput",
        component: HTMAResultsInput,
        meta:{
          requiresSubscription: true
        },
      },
      {
        path: "/htma-analysis",
        name: "HTMAAnalysis",
        component: HTMAAnalysis,
        meta:{
          requiresSubscription: true
        },
      },
      {
        path: "/metabolic-blueprint",
        name: "MetabolicBlueprint",
        component: MetabolicBlueprint,
        meta:{
          requiresSubscription: true
        },
      },
      {
        path: "/metabolic-blueprint/:id",
        name: "MetabolicBlueprintResult",
        component: MetabolicBlueprintResult,
        meta:{
          requiresSubscription: true
        },
      },
      {
        path: "/iron-blood-markers",
        name: "BloodMarkersIron",
        component: BloodMarkersIron,
        meta:{
          requiresSubscription: true
        },
      },
      {
        path: "/iron-blood-markers/:result",
        name: "BloodMarkersIron",
        component: BloodMarkersIron,
        meta:{
          requiresSubscription: true
        },
      },
      {
        path: "/iodine-blood-markers",
        name: "BloodMarkersIodine",
        component: BloodMarkersIodine,
        meta:{
          requiresSubscription: true
        },
      },
      {
        path: "/iodine-blood-markers/:result",
        name: "BloodMarkersIodine",
        component: BloodMarkersIodine,meta:{
          requiresSubscription: true
        },
      },
      {
        path: "/copper-blood-markers",
        name: "BloodMarkersCopper",
        component: BloodMarkersCopper,
        meta:{
          requiresSubscription: true
        },
      },
      {
        path: "/copper-blood-markers/:result",
        name: "BloodMarkersCopper",
        component: BloodMarkersCopper,meta:{
          requiresSubscription: true
        },
      },
      {
        path: "/naq-symptoms",
        name: "NAQSymptoms",
        component: NAQSymptoms,
        meta:{
          requiresSubscription: true
        },
      },
      {
        path: "/naq-symptoms-output",
        name: "NAQSymptomsOutput",
        component: NAQSymptomsOutput,
        meta:{
          requiresSubscription: true
        },
      },
      {
        path: "/conditions",
        name: "Conditions",
        component: Conditions,
        meta:{
          requiresSubscription: true
        },
      },
      {
        path: "/conditions/:id",
        name: "ConditionOutput",
        component: ConditionOutput,
        meta:{
          requiresSubscription: true
        },
      },
      {
        path: "/client-report",
        name: "ClientReportGenerator",
        component: ClientReportGenerator,
        meta:{
          requiresSubscription: true
        },
      },
      {
        path: "/client-report/:id",
        name: "ClientReport",
        component: ClientReport,
        meta:{
          requiresSubscription: true
        },
      },


      {
        path: "/input-htma/:id",
        name: "HTMAInput",
        component: HTMAInput,
        meta:{
          requiresSubscription: true
        },
      },
      {
        path: "/naq/:id",
        name: "NAQ",
        component: NAQ,
        meta:{
          requiresSubscription: true
        },
      },
      {
        path: "/naq-htma/:id",
        name: "NaqHtmaForm",
        component: NaqHtmaForm,
        meta:{
          requiresSubscription: true
        },
      },
      {
        path: "/condition/:id",
        name: "ConditionForm",
        component: ConditionForm,
        meta:{
          requiresSubscription: true
        },
      },
      {
        path: "/account-settings/",
        name: "AccountSettings",
        component: AccountSettings,
        meta:{
          requiresSubscription: true
        },
      },
      {
        path: "/payment",
        name: "Stripe",
        component: Stripe,
        meta:{
          requiresSubscription: false
        },
      },
      {
        path: "/pricing",
        name: "PaymentPricing",
        component: PaymentPricing,
        meta:{
          requiresSubscription: false
        },
      },
      {
        path: "/pricing/:id",
        name: "PaymentPricing",
        component: PaymentPricing,
        meta:{
          requiresSubscription: true
        }
      },
       { path: "/iron-blood-marker/:id",
        name: "BloodMarkerIron",
        component: BloodMarkerIron,
        meta:{
          requiresSubscription: true
        },
      },
      {
        path: "/iodine-blood-marker/:id",
        name: "BloodMarkerIodine",
        component: BloodMarkerIodine,
        meta:{
          requiresSubscription: true
        },
      },
      {
        path: "/copper-blood-marker/:id",
        name: "BloodMarkerCopper",
        component: BloodMarkerCopper,
        meta:{
          requiresSubscription: true
        },
      },
      {
        path: "/comparison-review-old",
        name: "ComparisonReview",
        component: ComparisonReview,
        meta:{
          requiresSubscription: true
        },
      },
      {
        path: "/comparison-test-analysis-old/:id/:id2",
        name: "ComparisonTestAnalysis",
        component: ComparisonTestAnalysis,
        meta:{
          requiresSubscription: true
        },
      },
      {
        path: "/comparison-review",
        name: "ComparisonReviewNew",
        component: ComparisonReviewNew,
        meta:{
          requiresSubscription: true
        },
      },
      {
        path: "/comparison-test-analysis/:client_name",
        name: "ComparisonList",
        component: ComparisonList,
        meta:{
          requiresSubscription: true
        },
      },
    ],
  },
 
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/password/reset/confirm/:uid/:token",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
  if (
    to.name === "Login" ||
    to.name === "Register" ||
    to.name === "ForgotPassword" ||
    to.name === "ResetPassword" ||
    to.name === "Home" 
  ) {
    if (localStorage.getItem("instant_token")) {
      next({ name: "Website" });
    } else next();
  } else {
    if(to.meta.requiresSubscription && localStorage.getItem("instant_token") && (localStorage.getItem("has_subscribed") && localStorage.getItem("has_subscribed") === 'false') && (localStorage.getItem("lifetime_subscribed") && localStorage.getItem("lifetime_subscribed") === 'false')){
      next({name:"PaymentPricing"})
    }
     else if (!localStorage.getItem("instant_token") && to.name !== "PrivacyPolicy") {
      next({ name: "Home" });
      next();
    } else next();
  }
});
export default router;
