<template>
  <div id="app">
    <div class="container-fluid" v-if="this.$router.currentRoute.path !=='/payment' && !this.$router.currentRoute.path.includes('pricing')">
      <Header/>
      <div class="row" id="wrapper" >
        <Sidebar />
        <div id="page-content-wrapper">
          <router-view />
        </div>
    </div>
    </div>
    <div v-else>
      <router-view/>
      </div>
  </div>
</template>

<script>
import Header from "../components/header/Header.vue";
import Sidebar from "../components/sidebar/Sidebar.vue";
import "jquery"
import $ from "jquery"
import {
  mapActions,
  mapGetters
} from "vuex";
export default {
  name: "Website",
  components: {
    Header,
    Sidebar,
  },

  data() {
    return {};
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
        ...mapActions([ "getUser","getUserSubscriptionStatus"]),
  },
  async mounted() {
    $('#preloader').show()
    const user = await this.getUser();
    const {has_subscribed,lifetime_subscribed} = await this.getUserSubscriptionStatus(this.user.pk)
        localStorage.setItem(
          "instant_username",
          `${user.first_name} ${user.last_name}`
        );
        $('#preloader').delay(500).fadeOut(500);
      if(has_subscribed === 'true' || lifetime_subscribed === 'true') this.$router.push('/')
      else this.$router.push('/pricing')
  },
};
</script>
<style scoped>
@media (max-width: 767px) {
  #page-content-wrapper {
    padding-left: 76px;
  }
}
@media print {
  #sidebar-wrapper *, #header-print *, #sidebar-print *, #wrapper * {
  visibility:hidden;
  }
  #page-content-wrapper, #page-content-wrapper * {
    visibility:visible;
  }
  #page-content-wrapper {
    position:absolute;
    left:0;
    top:0;
  }
}
</style>