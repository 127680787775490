<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col cols="12">
          <div class="data-table team-data-table bg-white p-4 rounded">
            <div class="table_row team_table_row">
              <div class="text-start">
                <h4>Copper Blood Markers</h4>
              </div>
              <br />
              <b-table
                responsive
                :fields="fields"
                :items="CopperBloodMarkerTableData"
                striped
                hover
                :per-page="perPage"
                :current-page="currentPage"
              >
                <template #cell(created)="data">
                  {{ getMomentDate(data.item.created) }}
                </template>
                <template #cell(action)="data">
                  <a @click="editData(data.item.id)" style="cursor: pointer"
                    ><i class="mr-4 fa fa-pencil text-success"></i
                  ></a>
                  <a @click="deleteData(data.item.id)" style="cursor: pointer"
                    ><i class="fa fa-trash text-danger"></i
                  ></a>
                </template>
              </b-table>
            </div>
          </div>
        </b-col>
      </b-row>
      <div class="entries-pagination mt-4">
        <div
          class="d-flex align-items-center justify-content-between flex-wrap"
        >
          <div class="text-left show-entries d-flex">
            <label for="" class="ms-0">Show</label>
            <b-form-select v-model="perPage" :options="pageOptions" />
            <label for="" class="me-0">entries</label>
          </div>
          <div class="pagination">
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </div>
        <p class="mt-2">{{ positionText }}</p>
      </div>
    </b-col>
    <DeleteModal v-if="showModal" @confirm="deleteConfirm($event)" />
  </b-row>
  <!-- Modal -->

  <!-- Modal -->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

import DeleteModal from "../DeleteModal/DeleteModal.vue";

export default {
  name: "CopperBloodMarkerTable",
  components: {
    DeleteModal,
  },
  props: ["CopperBloodMarkerTableData"],
  data() {
    return {
      statusOptions: [
        {
          value: null,
          text: "",
        },
        {
          value: "Active",
          text: "Active",
        },
        {
          value: "Inactive",
          text: "Inactive",
        },
      ],
      perPage: 10,
      currentPage: 1,
      errors: {},
      fields: [
        {
          key: "client_name",
          label: "Client Name",
          sortable: true,
          tdClass: "align-middle",
        },
        {
          key: "created",
          label: "Date",
          sortable: true,
          tdClass: "align-middle",
        },
        {
          key: "action",
          label: "Action",
          sortable: true,
          tdClass: "align-middle",
        },
      ],
      pageOptions: [
        {
          value: 10,
          text: "10",
        },
        {
          value: 25,
          text: "25",
        },
        {
          value: 50,
          text: "50",
        },
        {
          value: 100,
          text: "100",
        },
      ],
      showModal: false,
      dataID: null,
    };
  },
  computed: {
    ...mapGetters(["copperBloodMarkers"]),
    rows() {
      return this.CopperBloodMarkerTableData.length;
      // return this.items.length;
    },
    positionText() {
      let endIndex = this.currentPage * this.perPage,
        startIndex =
          this.rows > 0 ? (this.currentPage - 1) * this.perPage + 1 : 0;

      return (
        "Showing " +
        startIndex +
        " to " +
        (endIndex > this.rows ? this.rows : endIndex) +
        " of " +
        this.rows +
        " entries"
      );
    },
  },
  methods: {
    ...mapActions(["deleteCopperBloodMarkers", "getCopperBloodMarkers"]),
    getMomentDate: function (date) {
      return moment(date).format("MM/DD/YYYY");
    },

    editData(data) {
      this.$router.push(`/copper-blood-marker/${data}`);
      console.log(data);
    },
    deleteData(data) {
      this.showModal = true;
      console.log(data);
      this.dataID = data;
    },
    async deleteConfirm(item) {
      this.showModal = false;
      console.log(item);
      if (this.dataID) {
        try {
          const deletedData = await this.deleteCopperBloodMarkers(this.dataID);
          console.log(deletedData);
          await this.getCopperBloodMarkers();
          this.$emit("setCopperBloodMarkerData", await this.copperBloodMarkers);
          this.$toastr.Add({
            name: "Success", // this is give you ability to use removeByName method
            msg: "Blood Marker has been deleted Successfully!", // Toast Message
            type: "success", // Toast type,
          });
        } catch (error) {
          console.log(error);
          this.$toastr.Add({
            name: "Error", // this is give you ability to use removeByName method
            msg: "Error has occurred", // Toast Message
            type: "error", // Toast type,
          });
          // await this.setNotification({
          //     msg: error,
          //     type: 'error',
          //     color: 'red',
          // })
        }
      }
    },
  },
  async mounted() {},
};
</script>

<style>
.label {
  text-transform: uppercase;
  color: var(--black) !important;
  font-size: var(--extrasmall);
}

.page-item.active .page-link {
  color: white !important;
  background-color: #884c42 !important;
  border-color: #884c42 !important;
}

.show-entries label {
  font-weight: 500;
  margin: auto 10px;
}
</style>
