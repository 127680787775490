<template>
  <div>
    <br />
    <div class="accoridian_bg mt_35 graph_box">
      <h2 class="mt-5 mx-2 pt-2 mb-2">Comparison Review</h2>
      <div class="">
        <div class="row mb-5">
          <h2 class="text-center mx-auto w-100"></h2>
          <div class="col-lg-6 mx-auto w-100">
            <div class="pl-1 pb-2 pt-3 font-weight-bold">Find Client Name</div>
            <model-list-select
              :list="clientNameData"
              v-model="clientName"
              option-value="id"
              option-text="client_name"
              placeholder="Search Client Name"
            >
            </model-list-select>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 mx-auto w-100 submit-box" style="display:flex;justify-content:flex-end!important">
            <button @click="comparionReport()" class="menu_item submit-button">
              Generate Comparison Report
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import moment from "moment";
import { ModelListSelect } from "vue-search-select";
export default {
  components: {
    ModelListSelect,
  },
  name: "ComparisonReview",
  data() {
    return {
      clientName: "",
      clientNameData: [],
      selectedClientName:"",
    };
  },
  computed: {
    ...mapGetters([
      "allInputData",
    ]),
  },
  watch: {
    clientName(value) {
      console.log("value", value);
      this.selectedClientName = this.inputData.find((x) => x.id === value).client_name
      console.log('this.selected client name', this.selectedClientName)
      // this.htmaDatesData = this.inputData.filter(
      //   (data) =>
      //     data.client_name ===
      //     this.inputData.find((x) => x.id === value).client_name
      // );
      // console.log("data.htma_test_date", this.htmaDatesData);
    },
  },
  async mounted() {
    await this.getInputData();
    this.inputData = await this.allInputData;

    this.clientNameData = this.inputData.filter(
      (a, i) =>
        this.inputData.findIndex((s) => a.client_name === s.client_name) === i
    );
    console.log("all inputs are ", this.inputData);
    console.log("all client names are ", this.clientNameData);

  },
  methods: {
    ...mapActions([
      "getInputData",
    ]),

    comparionReport() {
      // console.log("submit");
      // this.inputId = this.htmaDate;
      // this.inputId2 = this.htmaDate2;

      console.log("submit");
      console.log("submit", this.clientName);
      console.log('this.selected client name', this.selectedClientName)
      // console.log("htmadata id ", this.htmaDate);
      // this.inputId = this.allInputHtmaData.find((data) => {
      //   return data.input == this.htmaDate;
      // });
      // this.inputId2 = this.allInputHtmaData.find((data) => {
      //   return data.input == this.htmaDate2;
      // });
      // console.log("input id searched is ", this.inputId.id);

      this.$router.push(
        `/comparison-test-analysis/${this.selectedClientName}`
      );
    },

  },
};
</script>

<style>
.submit-box {
  display: flex;
  justify-content: flex-end !important;
  padding-right: 20px;
}

.submit-button {
  width: 250px;
}

.no-button {
  color: transparent !important;
  background-color: transparent !important;
  border: transparent !important;
  cursor: auto !important;
}
</style>