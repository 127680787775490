import { getReq, postReq, patchReq, deleteReq, putReq } from "../../../services/api";

const state = {
  allInputHtma:[],
  allInput:[],
  allInputData:[],
  allHeavyMetals:[],
  singleTestAnalysis:[],
  singleInput:[],
  singleInputHtma:[],
  fileData:[],
};

const getters = {
  allInputHtma: (state) => state.allInputHtma,
  allInput: (state) => state.allInput,
  allInputData: (state) => state.allInputData,
  allHeavyMetals: (state) => state.allHeavyMetals,
  singleTestAnalysis: (state) => state.singleTestAnalysis,
  singleInput: (state) => state.singleInput,
  singleInputHtma: (state) => state.singleInputHtma,
  fileData: (state) => state.fileData,

};


const actions = {
  // Input and HTMA

    async getAllInputHtma({ commit },) {
    try {
      let allInputHtma = await getReq(`input-htma/`);
      commit("setAllInputHtma", allInputHtma);
      
    } catch (error) {
      console.log(error)
        // if(error.response.status === 403){
        //     throw "You are not allowed to perform this action."
        // }
      console.log(error.response);
      throw error.response;
    }
  },

 
  
   async addInputHtma(contex,payload) {
    try {
       const newTest = await postReq(`input-htma/`,payload );
       return newTest 
     
    } catch (error) {
      console.log(error.response.data);
      throw error.response.data;
    }
  },
  
  // async updateInputHtma({ commit }, { id, payload }) {
  //   try {
  //     console.log('id for update',id)
  //     const updatedData = await patchReq("input-htma/", id, payload);
  //     console.log(commit)
  //     console.log(updatedData)
  //     return updatedData
  //   } catch (e) {
  //       console.log(e)
  //       if(!e.response){
  //           throw "No network connection"
  //       }
  //       const errors = e.response.data;
  //       console.log(errors)
  //       throw errors;
  //   }
  // },

  async updateInputHtma({ commit }, { id, payload }) {
    try {
      console.log('id for update',id)
      const updatedData = await putReq("input-htma/", id, payload);
      console.log(commit)
      console.log(updatedData)
      return updatedData
    } catch (e) {
        console.log(e)
        if(!e.response){
            throw "No network connection"
        }
        const errors = e.response.data;
        console.log(errors)
        throw errors;
    }
  },
  
  async deleteInputHtma(_, id) {
    console.log('delete id ',id)
    try {
        return await deleteReq("input-htma", id);
    } catch (e) {
        if(!e.response){
            throw "No network connection"
        }
        const errors = e.response.data;
        console.log(errors)
        throw errors;
    }
  },

  async getSingleInputHtma({ commit },id) {
    try {
      let singleInputHtma = await getReq(`input-htma/${id}/`);
      commit("setSingleInputHtma", singleInputHtma);
      
    } catch (error) {
      console.log(error)
      console.log(error.response);
      throw error.response;
    }
  },

  // Input

  // Not sync with user
  async getAllInput({ commit },) {
    try {
      let allInput = await getReq(`input/`);
      commit("setAllInput", allInput);
      
    } catch (error) {
      console.log(error)
      console.log(error.response);
      throw error.response;
    }
  },

  // sync with user
  async getInputData({ commit },) {
    try {
      let allInput = await getReq(`input-view/`);
      commit("setInputData", allInput);
      
    } catch (error) {
      console.log(error)
      console.log(error.response);
      throw error.response;
    }
  },

  async getSingleInput({ commit },id) {
    try {
      let singleInput = await getReq(`input/${id}/`);
      commit("setSingleInput", singleInput);
      
    } catch (error) {
      console.log(error)
      console.log(error.response);
      throw error.response;
    }
  },
  
   async addInput(_,payload) {
     
    try {
       const newInput = await postReq(`input/`,payload );
       return newInput
     
    } catch (error) {
      console.log(error.response.data);
      throw error.response.data;
    }
  },
  
  async updateInput({ commit }, { id, payload }) {
    console.log(commit)
    try {
      const updatedData = await patchReq("input/", id, payload);
      if (updatedData) {
          console.log(updatedData);
      }
    } catch (e) {
        console.log(e)
        if(!e.response){
            throw "No network connection"
        }
        const errors = e.response.data;
        console.log(errors)
        throw errors;
    }
  },
  
  async deleteInput(_, id) {
    try {
        return await deleteReq("input/", id);
    } catch (e) {
        if(!e.response){
            throw "No network connection"
        }
        const errors = e.response.data;
        console.log(errors)
        throw errors;
    }
  },

  // allHeavyMetals

  async getAllHeavyMetals({ commit },) {
    try {
      let allHeavyMetals = await getReq(`heavy-metal`);
      commit("setAllHeavyMetals", allHeavyMetals);
      
    } catch (error) {
      console.log(error)
      console.log(error.response);
      throw error.response;
    }
  },

  // Single Test analysis

  async getSingleTestAnalysis({ commit },id) {
    try {
      let singleTestAnalysis = await getReq(`single-test-analysis/${id}/`);
      commit("setSingleTestAnalysis", singleTestAnalysis);
      
    } catch (error) {
      console.log(error)
      console.log(error.response);
      throw error.response;
    }
  },

  // Unsave Api

  async getSingleTestAnalysisUnsaved({commit},payload) {
    try {
       const singleTestAnalysis = await postReq(`input-htma-unsaved/`,payload );
      commit("setSingleTestAnalysis", singleTestAnalysis);
       return singleTestAnalysis 
     
    } catch (error) {
      console.log(error.response.data);
      throw error.response.data;
    }
  },

  // Upload File and export data

  async uploadFile({ commit },payload) {
    try {
      console.log('payload file ',payload)
       const fileData = await postReq(`upload/`,payload );
      commit("setFileData", fileData);

       return fileData 
     
    } catch (error) {
      console.log(error.response.data);
      throw error.response.data;
    }
  },

};

const mutations = {
  setAllInputHtma: (state, allInputHtma) => (state.allInputHtma = allInputHtma),
  setAllInput: (state, allInput) => (state.allInput = allInput),
  setInputData: (state, allInputData) => (state.allInputData = allInputData),
  setAllHeavyMetals: (state, allHeavyMetals) => (state.allHeavyMetals = allHeavyMetals),
  setSingleTestAnalysis: (state, singleTestAnalysis) => (state.singleTestAnalysis = singleTestAnalysis),
  setSingleInput: (state, singleInput) => (state.singleInput = singleInput),
  setSingleInputHtma: (state, singleInputHtma) => (state.singleInputHtma = singleInputHtma),
  setFileData: (state, fileData) => (state.fileData = fileData),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
