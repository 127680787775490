<template>
  <div class="body">
    <div class="container">
      <div class="row">
        <div class="mx-auto w-100">
          <div class="border-0 rounded-3 my-5">
            <div class="card-body p-4 p-sm-5">
              <!-- <h3 class="card-title text-center mb-5 fw-light fs-5">
               {{updateDetails ? "Update": "Enter"}} Card Details
              </h3> -->
              <form @submit.prevent="onSubmit">
                <div class="form-floating mb-3">
                  <label for="floatingInput">Old Password:</label>
                  <input
                    type="text"
                    class="form-control"
                    id="floatingInput"
                    placeholder="Old password"
                    v-model="old_password"
                    required
                  />
                </div>
                <div class="form-floating mb-3">
                  <label for="floatingInput">New Password:</label>
                  <input
                    type="text"
                    class="form-control"
                    id="floatingInput"
                    placeholder="New password"
                    v-model="new_password1"
                    required
                  />
                </div>
                <div class="form-floating mb-3">
                  <label for="floatingInput">Confirm Password:</label>
                  <input
                    type="text"
                    class="form-control"
                    id="floatingInput"
                    placeholder="Confirm password"
                    v-model="new_password2"
                    required
                  />
                </div>
                <div
                  class="d-grid text-center justify-content-between"
                  :class="cancel ? 'd-flex' : ''"
                >
                  <button
                    class="btn btn-danger text-uppercase fw-bold"
                    type="button"
                    v-if="cancel"
                    @click="$emit('closeModal')"
                  >
                    Cancel
                  </button>
                  <button
                    class="btn btn-login text-uppercase fw-bold"
                    type="submit"
                    :disabled="isLoading"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ChangeName",
  props: {
    updateDetails: {
      type: Boolean,
      default: false,
    },
    cancel: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      old_password: "",
      new_password1: "",
      new_password2: "",
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["changePassword", "logoutUser"]),
    async onSubmit() {
      this.isLoading = true;
      if (this.new_password1 !== this.new_password2) {
        this.$toastr.Add({
          name: "Error", // this is give you ability to use removeByName method
          msg: "Passwords do not match!", // Toast Message
          type: "error", // Toast type,
        });
        this.isLoading = false;
      }
      const response = await this.changePassword({
        old_password: this.old_password,
        new_password1: this.new_password1,
        new_password2: this.new_password2,
      });
      console.log(response);
      this.isLoading = false;
      this.$toastr.Add({
        name: "Success", // this is give you ability to use removeByName method
        msg: "Your Password has been changed Successfully!<br>Please Login again", // Toast Message
        type: "success", // Toast type,
      });
      this.logoutUser();
      this.$router.push({ name: "Login" });
    },
  },
  async mounted() {},
};
</script>
