<template>
  <div id="">
    <main>
      <div class="main_content_wrapper h-100">
        <div class="container">
          <div class="row no-print">
            <div
              class="mt-5 mx-auto w-100 submit-box"
              style="display: flex; justify-content: flex-end !important"
            >
              <button @click="submit()" class="menu_item next-button">
                Submit
              </button>
            </div>
          </div>
          <br /><br />

          <div class="row no-print">
            <h2 class="mx-2 mt-2">Blood Markers Iron Input</h2>
            <div class="col-lg-12 pt_55 col_index4">
              <!-- search -->
              <div class="row mb-5">
                <div class="col-lg-5 mx-auto w-100">
                  <model-list-select
                    :list="clientNameData"
                    v-model="clientName"
                    option-value="id"
                    option-text="client_name"
                    placeholder="Search Client Name"
                  >
                  </model-list-select>
                </div>
                <div class="col-lg-5">
                  <model-list-select
                    :list="datesData"
                    v-model="bloodMarkerDate"
                    option-value="id"
                    option-text="created"
                    placeholder="Search Date"
                  >
                  </model-list-select>
                </div>
                <div class="col-lg-2 submit-box">
                  <button
                    v-if="formId"
                    @click="onReset()"
                    class="menu_item submit-button"
                  >
                    Reset
                  </button>
                  <button
                    v-else
                    @click="search()"
                    class="menu_item submit-button"
                  >
                    Search
                  </button>
                </div>
              </div>

              <!-- search -->
              <div class="accorodion_wrapper" id="accordian">
                <div>
                  <BloodMarkerIron
                    @nextStep="getNextStep()"
                    @backStep="getBackStep()"
                    :data="bloodMarkersData"
                  />
                </div>
                <!-- <br />
                <div>
                  <BloodMarkersIodine
                    @nextStep="getNextStep()"
                    @backStep="getBackStep()"
                    :data="bloodMarkersData"
                  />
                </div> -->
              </div>
            </div>
          </div>

          <!--  -->

          <div v-if="this.result" class="main_content_wrapper">
            <br />
            <h2 class="mx-2 pt-2 mb-2 text-center">
              Iron Blood Markers Results for
              {{ bloodMarkersReportData.client_name }}
            </h2>
            <h3 class="mx-2 pt-2 mb-2 text-center">
              Prepared on
              {{ dateValue }}
            </h3>
            <hr />

            <div class="container">
              <div class="row">
                <div
                  v-if="
                    bloodMarkersReportData.serum_iron ||
                    bloodMarkersReportData.tibc ||
                    bloodMarkersReportData.iron_saturation ||
                    bloodMarkersReportData.ferritin_female ||
                    bloodMarkersReportData.ferritin_male
                  "
                  class="col-lg-12 col-md-12 col-sm-12 pt_20"
                >
                  <div
                    class="middle_content_box"
                    style="height: 100% !important"
                  >
                    <table>
                      <tr>
                        <td>
                          <h3 class="title_md" id="SO_D2">
                            Iron Blood Markers:
                          </h3>
                        </td>
                        <td>
                          <h3 class="title_md" style="text-align: right"></h3>
                        </td>
                      </tr>
                    </table>
                    <ul
                     
                      class="middle_content_list"
                    >
                      <li
                        v-if="bloodMarkersReportData.serum_iron"
                      >
                        Serum Iron: {{ bloodMarkersReportData.serum_iron }}
                      </li>
                      <li
                        v-if="bloodMarkersReportData.tibc"
                      >
                        TIBC: {{ bloodMarkersReportData.tibc }}
                      </li>
                      <li
                        v-if="bloodMarkersReportData.iron_saturation"
                      >
                       Iron Saturation:  {{ bloodMarkersReportData.iron_saturation }}
                      </li>
                      <li
                        v-if="bloodMarkersReportData.ferritin_female"
                      >
                        Ferritin (Female): {{ bloodMarkersReportData.ferritin_female }}
                      </li>
                      <li
                        v-if="bloodMarkersReportData.ferritin_male"
                      >
                         Ferritin (Male): {{ bloodMarkersReportData.ferritin_male }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  v-if="
                    bloodMarkersReportData.iron_serum"
                  class="col-lg-12 col-md-12 col-sm-12 pt_20"
                >
                  <div
                    class="middle_content_box"
                    style="height: 100% !important"
                  >
                    <table>
                      <tr>
                        <td>
                          <h3 class="title_md" id="SO_D2">Serum Iron:</h3>
                        </td>
                      </tr>
                    </table>
                    <ul v-if="
                    bloodMarkersReportData.iron_serum"
                      class="middle_content_list"
                      
                    >
                      <li id="SO_E2">
                        {{ bloodMarkersReportData.iron_serum }}
                      </li>
                    </ul>
                    <ul v-else>
                      <li id="SO_E2">No Information for Serum Iron</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="container">
              <div class="row">
                <div
                  v-if="
                    bloodMarkersReportData.e40_e44 &&
                    bloodMarkersReportData.e40_e44.length
                  "
                  class="col-lg-12 col-md-12 col-sm-12 pt_20"
                >
                  <div
                    class="middle_content_box"
                    style="height: 100% !important"
                  >
                    <table>
                      <tr>
                        <td>
                          <h3 class="title_md" id="SO_D2">
                            Iron Blood Markers:
                          </h3>
                        </td>
                        <td>
                          <h3 class="title_md" style="text-align: right"></h3>
                        </td>
                      </tr>
                    </table>
                    <ul
                      v-if="bloodMarkersReportData.e40_e44"
                      class="middle_content_list"
                    >
                      <li
                        id="SO_F7"
                        v-for="value in bloodMarkersReportData.e40_e44.filter(
                          (test) => test !== ''
                        )"
                        :key="value.index"
                      >
                        {{ value }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  v-if="
                    bloodMarkersReportData.iron_serum &&
                    (bloodMarkersReportData.iron_serum.c43 ||
                      bloodMarkersReportData.iron_serum.c44 ||
                      bloodMarkersReportData.iron_serum.c45 ||
                      bloodMarkersReportData.iron_serum.c46 ||
                      bloodMarkersReportData.iron_serum.c47)
                  "
                  class="col-lg-12 col-md-12 col-sm-12 pt_20"
                >
                  <div
                    class="middle_content_box"
                    style="height: 100% !important"
                  >
                    <table>
                      <tr>
                        <td>
                          <h3 class="title_md" id="SO_D2">Serum Iron:</h3>
                        </td>
                      </tr>
                    </table>
                    <ul
                      class="middle_content_list"
                      v-if="
                        bloodMarkersReportData.iron_serum &&
                        (bloodMarkersReportData.iron_serum.c43 ||
                          bloodMarkersReportData.iron_serum.c44 ||
                          bloodMarkersReportData.iron_serum.c45 ||
                          bloodMarkersReportData.iron_serum.c46 ||
                          bloodMarkersReportData.iron_serum.c47)
                      "
                    >
                      <li id="SO_E2">
                        {{ bloodMarkersReportData.iron_serum.c43 }}
                      </li>
                      <li id="SO_E2">
                        {{ bloodMarkersReportData.iron_serum.c44 }}
                      </li>
                      <li id="SO_E2">
                        {{ bloodMarkersReportData.iron_serum.c45 }}
                      </li>
                      <li id="SO_E2">
                        {{ bloodMarkersReportData.iron_serum.c46 }}
                      </li>
                      <li id="SO_E2">
                        {{ bloodMarkersReportData.iron_serum.c47 }}
                      </li>
                    </ul>
                    <ul v-else>
                      <li id="SO_E2">No Information for Serum Iron</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div> -->
          </div>

          <!--  -->
        </div>
      </div>
    </main>
    <!-- Modal -->
    <IronsModal
      v-if="showModal"
      :save="save"
      @confirm="confirmSubmit($event)"
    />

    <!-- Modal -->
  </div>
</template>

<script>
import BloodMarkerIron from "../../components/BloodMarkers/BloodMarkerIron.vue";
// import BloodMarkersIodine from "../../components/BloodMarkers/BloodMarkersIodine.vue";
import IronsModal from "../../components/SaveModal/IronsModal.vue";

import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import { ModelListSelect } from "vue-search-select";
function getInitBloodMarkersData() {
  return {
    client_name: "",
    serum_iron: 0,
    tibc: 0,
    iron_saturation: 0,
    ferritin_female: 0,
    ferritin_male: 0,
    htma_iron: 0,
    // iron: {
    //   serum_iron: 0,
    //   tibc: 0,
    //   iron_saturation: 0,
    //   ferritin_female: 0,
    //   ferritin_male: 0,
    //   htma_iron: 0,
    // },
    // iodine: {
    //   total_t3: 0,
    //   reverse_t3: 0,
    // },
  };
}
export default {
  name: "BloodMarkersIron",
  components: {
    BloodMarkerIron,
    // BloodMarkersIodine,
    ModelListSelect,
    IronsModal,
  },
  data() {
    return {
      formId: null,

      allBloodMarkerData: [],
      clientName: "",
      bloodMarkerDate: "",
      clientNameData: [],
      datesData: [],

      bloodMarkersData: getInitBloodMarkersData(),

      resultId: null,
      bloodMarkersReportData: {},
      result: false,

      save: {
        data: false,
      },
      showModal: false,

      // blood_markers_iron: {
      //   client_name: "",
      //   serum_iron: 0,
      //   tibc: 0,
      //   iron_saturation: 0,
      //   ferritin_female: 0,
      //   ferritin_male: 0,
      //   htma_iron: 0,
      // },
      // blood_markers_iodine: {
      //   total_t3: 0,
      //   reverse_t3: 0,
      // },
    };
  },
  watch: {
    clientName(value) {
      if (value) {
        console.log("value", value);
        this.datesData = this.allBloodMarkerData.filter(
          (data) =>
            data.client_name ===
            this.allBloodMarkerData.find((x) => x.id === value).client_name
        );
        console.log("data.created");
        this.datesData.map((data) => {
          data.created = moment(data.created).format("MM/DD/YYYY");
        });
        console.log("bloodmarkerDatesData", this.datesData);
      }
    },
  },
  computed: {
    ...mapGetters([
      "ironBloodMarkers",
      "singleIronBloodMarker",
      "ironBloodMarkersReport",
    ]),
    dateValue() {
      var date = new Date(this.bloodMarkersReportData.date);
      return (
        (date.getMonth() > 8
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1)) +
        "/" +
        (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
        "/" +
        date.getFullYear()
      );
    },
  },
  methods: {
    ...mapActions([
      "addIronBloodMarkers",

      "getIronBloodMarkers",
      "getSingleIronBloodMarker",

      "getIronBloodMarkersReport",
      "getIronBloodMarkersReportUnsaved",
    ]),

    async search() {
      console.log("search");
      console.log("bloodMarkers data id ", this.bloodMarkersData);
      console.log("bloodMarkers id searched is ", this.bloodMarkerDate);
      this.formId = this.bloodMarkerDate;
      await this.getSingleIronBloodMarker(this.formId);
      console.log(
        "select getSingleBloodMarker is ",
        await this.singleIronBloodMarker
      );
      this.bloodMarkersData = await this.singleIronBloodMarker;
      // this.$emit("setConditionId", this.conditionId);
    },
    onReset() {
      // this.conditionId = null;
      this.formId = null;
      this.clientName = null;
      this.bloodMarkerDate = null;
      console.log("condition id is", this.formId);
      // this.$emit("reset");
      Object.assign(this.bloodMarkersData, getInitBloodMarkersData());
    },
    async confirmSubmit(toSave) {
      if (toSave) {
        if (!this.bloodMarkersData.client_name) {
          this.$toastr.Add({
            name: "Error", // this is give you ability to use removeByName method
            msg: "Please Enter Client Name!", // Toast Message
            type: "error", // Toast type,
          });
        } else {
          this.$toastr.Add({
            name: "Success", // this is give you ability to use removeByName method
            msg: "Blood Markers has been submitted Successfully!", // Toast Message
            type: "success", // Toast type,
          });
          //  Blood Markers
          if (this.formId) {
            console.log("selected blood markers id ", this.formId);
          } else {
            if (this.save.data) {
              try {
                // const response = await this.addBloodMarkers({
                //   iron: this.blood_markers_iron,
                //   iodine: this.blood_markers_iodine,
                // });
                const response = await this.addIronBloodMarkers(
                  this.bloodMarkersData
                );
                console.log("blood markers response ", response);
                this.formId = response.id;
              } catch (error) {
                console.log(error);
              }
            } else {
              // not saving data
              this.showModal = false;
              console.log("confirm submit toSave ", toSave, this.save);
              this.bloodMarkersData["created"] = new Date().toISOString();
              sessionStorage.setItem(
                "blood_marker",
                JSON.stringify(this.bloodMarkersData)
              );
              this.formId = 0;
            }
          }

          this.$router.push(`/iron-blood-markers/${this.formId}`);
        }
        this.resultId = this.formId;
        console.log("calling report");
        if (this.formId != 0) {
          await this.getIronBloodMarkersReport(this.resultId);
        } else {
          const blood_marker = JSON.parse(
            sessionStorage.getItem("blood_marker")
          );
          document.getElementById("print-pdf").style.display = 'inline-block'
          console.log("blood_marker raw data", blood_marker);
          await this.getIronBloodMarkersReportUnsaved(blood_marker);
        }
        this.bloodMarkersReportData = await this.ironBloodMarkersReport;
        console.log("this.bloodMarkersReportData", this.bloodMarkersReportData);
        localStorage.setItem(
          "instant_clientname",
          `${this.bloodMarkersReportData.client_name}`
        );
        this.result = true;
      } else {
        this.showModal = false;
        console.log("confirm submit data ", toSave);
      }
    },

    async submit() {
      console.log("submitttting...");

      this.showModal = true;
      // if (!this.bloodMarkersData.client_name) {
      //   this.$toastr.Add({
      //     name: "Error", // this is give you ability to use removeByName method
      //     msg: "Please Enter Client Name!", // Toast Message
      //     type: "error", // Toast type,
      //   });
      // } else {
      //   this.$toastr.Add({
      //     name: "Success", // this is give you ability to use removeByName method
      //     msg: "Blood Markers has been submitted Successfully!", // Toast Message
      //     type: "success", // Toast type,
      //   });
      //   //  Blood Markers
      //   if (this.formId) {
      //     console.log("selected blood markers id ", this.formId);
      //   } else {
      //     try {
      //       // const response = await this.addBloodMarkers({
      //       //   iron: this.blood_markers_iron,
      //       //   iodine: this.blood_markers_iodine,
      //       // });
      //       const response = await this.addIronBloodMarkers(
      //         this.bloodMarkersData
      //       );
      //       console.log("blood markers response ", response);
      //       this.formId = response.id;
      //     } catch (error) {
      //       console.log(error);
      //     }
      //   }

      //   this.$router.push(`/iron-blood-markers/${this.formId}`);
      // }
      // this.resultId = this.formId;
      // console.log("calling report");
      // await this.getIronBloodMarkersReport(this.resultId);
      // this.bloodMarkersReportData = await this.ironBloodMarkersReport;
      // console.log("this.bloodMarkersReportData", this.bloodMarkersReportData);
      // localStorage.setItem(
      //   "instant_clientname",
      //   `${this.bloodMarkersReportData.client_name}`
      // );
      // this.result = true;
    },
  },
  async mounted() {
    this.$nextTick(async() => {
    this.resultId = this.$route.params.result;
    console.log(this.resultId)
    if (this.resultId) {
      document.getElementById("print-pdf").style.display = 'inline-block'
      await this.getIronBloodMarkersReport(this.resultId);
      this.bloodMarkersReportData = await this.ironBloodMarkersReport;
      console.log("this.bloodMarkersReportData", this.bloodMarkersReportData);
      localStorage.setItem(
        "instant_clientname",
        `${this.bloodMarkersReportData.client_name}`
      );
      this.result = true;
    }
    else document.getElementById("print-pdf").style.display = 'none'
    await this.getIronBloodMarkers();
    this.allBloodMarkerData = await this.ironBloodMarkers;
    console.log("bloodmarker data ", this.allBloodMarkerData);

    this.clientNameData = this.allBloodMarkerData.filter(
      (a, i) =>
        this.allBloodMarkerData.findIndex(
          (s) => a.client_name === s.client_name
        ) === i
    );
    console.log("allBloodMarkerData are ", this.allBloodMarkerData);
    // console.log("all client names are ", this.clientNameData);
  })
}

};
</script>
<style>
.b-toaster {
  position: absolute;
  max-width: 350px;
  width: 100%;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
}
.submit-box {
  display: flex;
  justify-content: flex-end !important;
  padding-right: 20px;
}

.next-button {
  width: 100px;
}

.no-button {
  color: transparent !important;
  background-color: transparent !important;
  border: transparent !important;
  cursor: auto !important;
}
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
</style>
