<template>
  <div>
    <div class="box" v-if="!editId">
      <button class="menu_item back-button" @click="onBack">Back</button>
      <button class="menu_item next-button" @click="onNext">
        {{ buttonNext }}
      </button>
    </div>
    <div class="accoridian_bg mt_35 graph_box">
      <h2
        v-if="editId"
        class="mx-2 pt-2 mt-4 mb-2"
        style="margin-top: 80px !important"
      >
        Edit NAQ + HTMA
      </h2>
      <h2 v-else class="mx-2 pt-2 mb-2">NAQ + HTMA</h2>
      <div
        class="box"
        style="display: flex; justify-content: flex-end !important"
        v-if="editId"
      >
        <button class="menu_item next-button" @click="onUpdate">Update</button>
      </div>
      <br /><br />
      <div>
        <!-- search -->
        <div class="row mb-5" v-if="!editId">
          <div class="col-lg-5 mx-auto w-100">
            <model-list-select
              :list="clientNameData"
              v-model="clientName"
              option-value="id"
              option-text="client_name"
              placeholder="Search Client Name"
            >
            </model-list-select>
          </div>
          <div class="col-lg-5">
            <model-list-select
              :list="datesData"
              v-model="naqHtmaDate"
              option-value="id"
              option-text="created"
              placeholder="Search NAQ HTMA Date"
            >
            </model-list-select>
          </div>
          <div class="col-lg-2 submit-box">
            <button
              v-if="naqHtmaId"
              @click="onReset()"
              class="menu_item submit-button"
            >
              Reset
            </button>
            <button v-else @click="search()" class="menu_item submit-button">
              Search
            </button>
          </div>
        </div>
        <!-- search -->

        <div class="card">
          <div class="card__item">
            <div style="margin-left: 5%" v-if="naqOnly || editId">
              <label for="">Client Name *</label>
              <input
                style="margin-left: 5%"
                type="text"
                min="0"
                class="w-75"
                v-model="compVal.client_name"
              />
            </div>
            <table id="tbl_naq_htma">
              <!-- <tr v-if="naqOnly || editId">
                <td>Client Name *</td>
                <td class="right_col">
                  <input
                    type="text"
                    v-model="compVal.client_name"
                  />
                </td>
              </tr> -->
              <tr>
                <td class="table_section" rowspan="20">Diet</td>
                <td>
                  <input type="checkbox" v-model="compVal.diet.drink_alcohol" />
                </td>
                <td>Drink Alcohol</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.diet.artificial_sweeteners"
                  />
                </td>
                <td>Artificial Sweeteners</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.diet.candy_desserts_refined_sugar"
                  />
                </td>
                <td>Candy, desserts, refined sugar</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.diet.carbonate_beverages_water"
                  />
                </td>
                <td>Carbonated beverages, including sparkling water</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.diet.chewing_tobacco"
                  />
                </td>
                <td>Chewing tobacco</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" v-model="compVal.diet.cigarettes" />
                </td>
                <td>Cigarettes</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" v-model="compVal.diet.cigars_pipes" />
                </td>
                <td>Cigars/Pipes</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.diet.caffeinated_beverages"
                  />
                </td>
                <td>Caffeinated Beverages</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" v-model="compVal.diet.fast_foods" />
                </td>
                <td>Fast Foods</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" v-model="compVal.diet.fried_foods" />
                </td>
                <td>Fried Foods</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.diet.luncheon_meats"
                  />
                </td>
                <td>Luncheon Meats</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" v-model="compVal.diet.margarine" />
                </td>
                <td>Margarine</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.diet.dairy_products"
                  />
                </td>
                <td>Dairy products</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.diet.radiation_exposure"
                  />
                </td>
                <td>Radiation exposure</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" v-model="compVal.diet.refined_flour" />
                </td>
                <td>Refined flour/Baked goods</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.diet.vitamin_mineral_supplements"
                  />
                </td>
                <td>Vitamins and Mineral Supplements</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.diet.distilled_water"
                  />
                </td>
                <td>Distilled Water</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" v-model="compVal.diet.tap_water" />
                </td>
                <td>Tap Water</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" v-model="compVal.diet.well_water" />
                </td>
                <td>Well Water</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.diet.diet_weight_control"
                  />
                </td>
                <td>Diet for weight control</td>
              </tr>
              <tr>
                <td class="table_section" rowspan="19">Upper GI</td>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.upper_gi.belching_gas"
                  />
                </td>
                <td>Belching or gas within one hour after eating</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.upper_gi.heartburn_acid_reflux"
                  />
                </td>
                <td>Heartburn or acid reflux</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.upper_gi.bloating_after_eating"
                  />
                </td>
                <td>Bloating within one hour after eating</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.upper_gi.vegan_diet"
                  />
                </td>
                <td>Vegan diet (No=Never, Yes=Monthly)</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.upper_gi.bad_breath"
                  />
                </td>
                <td>Bad Breath</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.upper_gi.lost_taste_for_meat"
                  />
                </td>
                <td>Loss of taste for meat</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.upper_gi.sweat_has_strong_odor"
                  />
                </td>
                <td>Sweat has a strong odor</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.upper_gi.stomach_upset_by_vitamins"
                  />
                </td>
                <td>Stomach gets upset from taking vitamins</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.upper_gi.excess_fullness_after_meal"
                  />
                </td>
                <td>Sense of excess fullness after meals</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.upper_gi.skipping_breakfast"
                  />
                </td>
                <td>Feel like skipping breakfast</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.upper_gi.feel_better_while_not_eating"
                  />
                </td>
                <td>Feel better if you don&#39;t eat</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.upper_gi.sleepy_after_meal"
                  />
                </td>
                <td>Sleepy after meals</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.upper_gi.fingernails_chip"
                  />
                </td>
                <td>Fingernails chip, peel, or break easily</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" v-model="compVal.upper_gi.anemia" />
                </td>
                <td>Anemia unresponsive to iron</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.upper_gi.stomach_pain"
                  />
                </td>
                <td>Stomach pains or cramps</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" v-model="compVal.upper_gi.diarrhea" />
                </td>
                <td>Diarrhea, chronic</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.upper_gi.diarrhea_shortly"
                  />
                </td>
                <td>Diarrhea shortly after meals</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" v-model="compVal.upper_gi.stool" />
                </td>
                <td>Black or tarry colored stools</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.upper_gi.undigested_food_in_stool"
                  />
                </td>
                <td>Undigested food in stool</td>
              </tr>
              <tr>
                <td class="table_section" rowspan="27">Liver / Gallbladder</td>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.liver.pain_shoulder_blade"
                  />
                </td>
                <td>Pain between shoulder blades</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.liver.stomach_upset_by_food"
                  />
                </td>
                <td>Stomach upset by greasy foods</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" v-model="compVal.liver.greasy" />
                </td>
                <td>Greasy or shiny stools</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" v-model="compVal.liver.nausea" />
                </td>
                <td>Nausea</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.liver.airplane_motion_sickness"
                  />
                </td>
                <td>Sea, car, or airplane motion sickness</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.liver.light_or_clay"
                  />
                </td>
                <td>Light or clay colored stools</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" v-model="compVal.liver.itchy_feet" />
                </td>
                <td>Dry skin, itchy feet, or skin peels on feet</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" v-model="compVal.liver.headache" />
                </td>
                <td>Headache over eyes</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.liver.gallbladder_attack"
                  />
                </td>
                <td>Gallbladder attacks</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.liver.morning_sickness"
                  />
                </td>
                <td>History of morning sickness</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" v-model="compVal.liver.bitter_taste" />
                </td>
                <td>Bitter taste in mouth, especially after meals</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.liver.alcoholic_drink"
                  />
                </td>
                <td>Alcoholic drinks per week (none, 1-3, 4-7, or 7&#43;)</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" v-model="compVal.liver.diesel_fumes" />
                </td>
                <td>Exposure to diesel fumes</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.liver.pain_under_rib_cage"
                  />
                </td>
                <td>Pain under right side of rib cage</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.liver.consumption_of_diet_product"
                  />
                </td>
                <td>
                  Nutrasweet or Aspartame consumption (typically in diet drinks,
                  diet products)
                </td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" v-model="compVal.liver.fibromyalgia" />
                </td>
                <td>Chronic fatigue or Fibromyalgia</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" v-model="compVal.liver.gallbladder" />
                </td>
                <td>Gallbladder removed</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.liver.sick_after_drink_wine"
                  />
                </td>
                <td>Become sick if you were to drink wine</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.liver.easily_intoxicated"
                  />
                </td>
                <td>Easily intoxicated if you were to drink wine</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.liver.hungover_when_drink_wine"
                  />
                </td>
                <td>Easily hungover if you were to drink wine</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.liver.recovering_alcoholic"
                  />
                </td>
                <td>Recovering Alcoholic</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.liver.alcohol_abuse"
                  />
                </td>
                <td>History or drug or alcohol abuse</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.liver.history_of_hepatitis"
                  />
                </td>
                <td>History of hepatitis</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.liver.recreational_drug"
                  />
                </td>
                <td>Long term use of prescription or recreational drugs</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.liver.sensitive_to_chemical"
                  />
                </td>
                <td>Sensitive to chemicals (perfume, cleaning agents, etc)</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.liver.sensitive_to_tobacco"
                  />
                </td>
                <td>Sensitive to tobacco smoke</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.liver.varicose_vein"
                  />
                </td>
                <td>Hemorrhoids or varicose veins</td>
              </tr>
              <tr>
                <td class="table_section" rowspan="17">Small Intestine</td>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.small_intestine.food_allergy"
                  />
                </td>
                <td>Food allergies</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.small_intestine.abdominal_bloating"
                  />
                </td>
                <td>Abdominal bloating 1-2 hours after eating</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.small_intestine.foods_make_you_tired"
                  />
                </td>
                <td>Specific foods make you tired or bloated</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.small_intestine.pulse_speed"
                  />
                </td>
                <td>Pulse speeds after eating</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.small_intestine.airborne_allergy"
                  />
                </td>
                <td>Airborne allergies</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.small_intestine.experience_hive"
                  />
                </td>
                <td>Experience hives</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.small_intestine.sinus_congestion"
                  />
                </td>
                <td>Sinus congestion/stuffy head</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.small_intestine.noodle"
                  />
                </td>
                <td>Crave bread or noodles</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.small_intestine.alternating_constipation"
                  />
                </td>
                <td>Alternating constipation and diarrhea</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.small_intestine.crohn_disease"
                  />
                </td>
                <td>Crohn&#39;s disease (No,Yes)</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.small_intestine.grain_sensitivity"
                  />
                </td>
                <td>Wheat or grain sensitivity</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.small_intestine.dairy_sensitivity"
                  />
                </td>
                <td>Dairy sensitivity</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.small_intestine.foods_you_cant_give_up"
                  />
                </td>
                <td>Are there foods you could not give up? (No,Yes)</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.small_intestine.asthma"
                  />
                </td>
                <td>Asthma, sinus infections, stuffy nose</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.small_intestine.vivid_dream"
                  />
                </td>
                <td>Bizarre, vivid dreams, nightmares</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.small_intestine.counter_pain_medication"
                  />
                </td>
                <td>Use over-the-counter pain medications</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.small_intestine.feel_spacey"
                  />
                </td>
                <td>Feel spacey or unreal</td>
              </tr>
              <tr>
                <td class="table_section" rowspan="20">Large Intestine</td>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.large_intestine.anus_itch"
                  />
                </td>
                <td>Anus itches</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.large_intestine.coated_tongue"
                  />
                </td>
                <td>Coated tongue</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.large_intestine.feel_worse"
                  />
                </td>
                <td>Feel worse in moldy or musty place</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.large_intestine.antibiotics"
                  />
                </td>
                <td>
                  Taken antibiotics for a total accumulated time of (Never taken
                  antibiotics, Less than 1 month, 1-2 months, 3&#43; months)
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.large_intestine.yeast_infection"
                  />
                </td>
                <td>Fungus or yeast infections</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.large_intestine.ring_worm"
                  />
                </td>
                <td>
                  Ring worm, &#34;jock itch&#34;, &#34;athletes foot&#34;, nail
                  fungus
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.large_intestine.swelling_in_joint"
                  />
                </td>
                <td>
                  Yeast symptoms increase with sugar, starch or alcohol (Yeast
                  symptoms include: fatigue, poor memory, feeling
                  &#39;spacey&#39; or &#39;unreal&#39;, muscle aches or
                  weakness, pain or swelling in joints, constipation, diarrhea
                  or bloating.
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.large_intestine.difficult_to_pass"
                  />
                </td>
                <td>Stools hard or difficult to pass</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.large_intestine.parasite"
                  />
                </td>
                <td>History of parasites</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.large_intestine.bowel_movement"
                  />
                </td>
                <td>Less than one bowel movement per day</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.large_intestine.curve_flat_shape"
                  />
                </td>
                <td>
                  Stools have corners or edges, are flat, or ribbon shapped
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.large_intestine.loose"
                  />
                </td>
                <td>Stools are not well formed (loose)</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.large_intestine.mucus_colitis"
                  />
                </td>
                <td>Irritable bowel or mucus colitis</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.large_intestine.blood"
                  />
                </td>
                <td>Blood in stool</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.large_intestine.mucus"
                  />
                </td>
                <td>Mucus in stool</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.large_intestine.foul_smelling"
                  />
                </td>
                <td>Excessive foul smelling lower bowel gas</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.large_intestine.strong_body_odor"
                  />
                </td>
                <td>Bad breath or strong body odors</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.large_intestine.painful_tigh"
                  />
                </td>
                <td>Painful to press along the outer sides of the thighs</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.large_intestine.abdominal_region"
                  />
                </td>
                <td>Cramping in lower abdominal region</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.large_intestine.dark_circle"
                  />
                </td>
                <td>Dark circles under eyes</td>
              </tr>
              <tr>
                <td class="table_section" rowspan="29">Mineral Needs</td>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.mineral_needs.carpal_tunnel_syndrome"
                  />
                </td>
                <td>History of carpal tunnel syndrome</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.mineral_needs.abdominal_pain"
                  />
                </td>
                <td>
                  History of lower right abdominal pains or ileocecal valve
                  problems
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.mineral_needs.stress_fracture"
                  />
                </td>
                <td>History of stress fracture</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.mineral_needs.bone_loss"
                  />
                </td>
                <td>Bone loss (reduced density on bone scan)</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.mineral_needs.shorter_than_usual"
                  />
                </td>
                <td>Are you shorter than you used to be?</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.mineral_needs.cramps_at_rest"
                  />
                </td>
                <td>Calf, foot, or toe cramps at rest</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.mineral_needs.cold_sore"
                  />
                </td>
                <td>Cold sores, fever blisters, or herpes lesions</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.mineral_needs.frequent_fever"
                  />
                </td>
                <td>Frequent fevers</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.mineral_needs.hives"
                  />
                </td>
                <td>Frequent skin rashes and/or hives</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.mineral_needs.herniated_disc"
                  />
                </td>
                <td>Herniated disc</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.mineral_needs.flexible_joint"
                  />
                </td>
                <td>Excessively flexible joints, &#34;double jointed&#34;</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.mineral_needs.joints_pop"
                  />
                </td>
                <td>Joints pop or click</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.mineral_needs.swelling_in_joint"
                  />
                </td>
                <td>Pain or swelling in joints</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.mineral_needs.bursitis"
                  />
                </td>
                <td>Bursitis or tendonitis</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.mineral_needs.bone_spur"
                  />
                </td>
                <td>History of bone spurs</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.mineral_needs.morning_stiffness"
                  />
                </td>
                <td>Morning stiffness</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.mineral_needs.vomiting"
                  />
                </td>
                <td>Nausea with vomiting</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.mineral_needs.crave_chocolate"
                  />
                </td>
                <td>Crave chocolate</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.mineral_needs.feet_have_strong_odor"
                  />
                </td>
                <td>Feet have a strong odor</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.mineral_needs.anemia"
                  />
                </td>
                <td>History of anemia</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.mineral_needs.eyes_are_blue_tinted"
                  />
                </td>
                <td>Whites of eyes are blue tinted</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.mineral_needs.hoarseness"
                  />
                </td>
                <td>Hoarseness in voice</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.mineral_needs.swallowing"
                  />
                </td>
                <td>Difficulty swallowing</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.mineral_needs.lump_in_throat"
                  />
                </td>
                <td>Lump in throat</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.mineral_needs.dry_mouth"
                  />
                </td>
                <td>Dry mouth, eyes, and/or nose</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.mineral_needs.gag_easily"
                  />
                </td>
                <td>Gag easily</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.mineral_needs.fingernail"
                  />
                </td>
                <td>White spots on fingernails</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.mineral_needs.cuts_heal"
                  />
                </td>
                <td>Cuts heal slowly and/or scar easily</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.mineral_needs.decreased_sense"
                  />
                </td>
                <td>Decreased sense of taste or smell</td>
              </tr>
              <tr>
                <td class="table_section" rowspan="8">Essential Fatty Acids</td>
                <td>
                  <input
                    type="checkbox"
                    v-model="
                      compVal.essential_fatty_acid.pain_relief_with_aspirin
                    "
                  />
                </td>
                <td>Experience pain relief with aspirin</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.essential_fatty_acid.greasy_food"
                  />
                </td>
                <td>Crave fatty or greasy foods</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.essential_fatty_acid.reduced_fat_diet"
                  />
                </td>
                <td>Low or reduced-fat diet</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.essential_fatty_acid.headache"
                  />
                </td>
                <td>Tension headaches at base of skull</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="
                      compVal.essential_fatty_acid.headaches_when_out_in_sun
                    "
                  />
                </td>
                <td>Headaches when out in the hot sun</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.essential_fatty_acid.sunburn_easily"
                  />
                </td>
                <td>Sunburn easily or suffer sun poisoning</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.essential_fatty_acid.muscles_fatigued"
                  />
                </td>
                <td>Muscles easily fatigued</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.essential_fatty_acid.dandruff"
                  />
                </td>
                <td>Dry flaky skin or dandruff</td>
              </tr>
              <tr>
                <td class="table_section" rowspan="13">Sugar Handling</td>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.sugar_handling.awaken_after_falling_asleep"
                  />
                </td>
                <td>
                  Awaken a few hours after falling asleep, hard to get back to
                  sleep
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.sugar_handling.crave_sweet"
                  />
                </td>
                <td>Crave sweets</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.sugar_handling.uncontrolled_eating"
                  />
                </td>
                <td>Binge or uncontrolled eating</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.sugar_handling.excessive_appetite"
                  />
                </td>
                <td>Excessive appetite</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.sugar_handling.crave_coffee"
                  />
                </td>
                <td>Crave coffee or sugar in the afternoon</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.sugar_handling.sleepy"
                  />
                </td>
                <td>Sleepy in the afternoon</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.sugar_handling.fatigue"
                  />
                </td>
                <td>Fatigue that is relieved by eating</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.sugar_handling.headache"
                  />
                </td>
                <td>Headache if meals are skipped or delayed</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.sugar_handling.irritable_before_meals"
                  />
                </td>
                <td>Irritable before meals</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.sugar_handling.shaky"
                  />
                </td>
                <td>Shaky if meals delayed</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.sugar_handling.family_members_with_diabete"
                  />
                </td>
                <td>Family members with diabetes</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.sugar_handling.frequent_thirst"
                  />
                </td>
                <td>Frequent thirst</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.sugar_handling.frequent_urination"
                  />
                </td>
                <td>Frequent urination</td>
              </tr>
              <tr>
                <td class="table_section" rowspan="27">Vitamin Need</td>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.vitamin_need.muscles_fatigued"
                  />
                </td>
                <td>Muscles easily become fatigued</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.vitamin_need.feel_exhausted"
                  />
                </td>
                <td>Feel exhausted or sore after moderate exercise</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.vitamin_need.insect_bites"
                  />
                </td>
                <td>Vulnerable to insect bites</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.vitamin_need.loss_of_muscle"
                  />
                </td>
                <td>Loss of muscle tone, heaviness in arms/legs</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.vitamin_need.enlarged_heart"
                  />
                </td>
                <td>Enlarged heart or congestive heart failure</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.vitamin_need.pulse_below_65"
                  />
                </td>
                <td>Pulse below 65 per minute</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.vitamin_need.ringing_in_ear"
                  />
                </td>
                <td>Ringing in the ears</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.vitamin_need.numbness"
                  />
                </td>
                <td>Numbness, tingling, or itching in hands and feet</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.vitamin_need.depressed"
                  />
                </td>
                <td>Depressed</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.vitamin_need.impending_doom"
                  />
                </td>
                <td>Fear of impending doom</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.vitamin_need.worrier"
                  />
                </td>
                <td>Worrier, apprehensive, anxious</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.vitamin_need.nervous"
                  />
                </td>
                <td>Nervous or agitated</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.vitamin_need.feelings_of_insecurity"
                  />
                </td>
                <td>Feelings of insecurity</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.vitamin_need.heart_race"
                  />
                </td>
                <td>Heart races</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.vitamin_need.hear_heart_beat_on_pillow"
                  />
                </td>
                <td>Can hear heart beat on pillow at night</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.vitamin_need.body_jerk_as_falling_asleep"
                  />
                </td>
                <td>Whole body or limb jerk as falling asleep</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.vitamin_need.night_sweat"
                  />
                </td>
                <td>Night sweats</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.vitamin_need.restless_leg_syndrome"
                  />
                </td>
                <td>Restless leg syndrome</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.vitamin_need.cracks"
                  />
                </td>
                <td>Cracks at corner of mouth</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.vitamin_need.fragile_skin"
                  />
                </td>
                <td>Fragile skin, easily chaffed, as in shaving</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" v-model="compVal.vitamin_need.wart" />
                </td>
                <td>Polyps or warts</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.vitamin_need.msg_sensitivity"
                  />
                </td>
                <td>MSG sensitivity</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.vitamin_need.wakeup_without_remember_dream"
                  />
                </td>
                <td>Wake up without remembering dreams</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.vitamin_need.small_bumps_on_back_arms"
                  />
                </td>
                <td>Small bumps on back of arms</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.vitamin_need.irritates_eye"
                  />
                </td>
                <td>Strong light at night irritates eyes</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.vitamin_need.nose_bleed"
                  />
                </td>
                <td>Nose bleeds and/or tend to bruise easily</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.vitamin_need.bleeding_gum"
                  />
                </td>
                <td>Bleeding gums especially when brushing teeth</td>
              </tr>
              <tr>
                <td class="table_section" rowspan="26">Adrenal</td>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.ardenal.night_person"
                  />
                </td>
                <td>Tend to be a &#34;night&#34; person</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.ardenal.difficulty_in_sleep"
                  />
                </td>
                <td>Difficulty falling asleep</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.ardenal.slow_starter"
                  />
                </td>
                <td>Slow starter in the morning</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.ardenal.trouble_calming_down"
                  />
                </td>
                <td>Tend to be keyed up, trouble calming down</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.ardenal.blood_pressure"
                  />
                </td>
                <td>Blood pressure above 120/80</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" v-model="compVal.ardenal.headache" />
                </td>
                <td>Headache after exercising</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.ardenal.feeling_wired"
                  />
                </td>
                <td>Feeling wired or jittery after drinking coffee</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" v-model="compVal.ardenal.clench" />
                </td>
                <td>Clench or grind teeth</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.ardenal.calm_outside_trouble_inside"
                  />
                </td>
                <td>Calm on the outside, troubled on the inside</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.ardenal.worse_fatigue"
                  />
                </td>
                <td>Chronic low back pain, worse with fatigue</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.ardenal.become_dizzy"
                  />
                </td>
                <td>Become dizzy when standing up suddenly</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.ardenal.difficult_manipulative_correction"
                  />
                </td>
                <td>Difficulty maintaining manipulative correction</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.ardenal.pain_after_manipulative_correction"
                  />
                </td>
                <td>Pain after manipulative correction</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.ardenal.arthritic_tendency"
                  />
                </td>
                <td>Arthritic tendencies</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.ardenal.crave_salty_food"
                  />
                </td>
                <td>Crave salty food</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" v-model="compVal.ardenal.salt_food" />
                </td>
                <td>Salt foods before tasting</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.ardenal.perspire_easily"
                  />
                </td>
                <td>Perspire easily</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.ardenal.chronic_fatigue"
                  />
                </td>
                <td>Chronic fatigue, or get drowsy often</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.ardenal.afternoon_yawning"
                  />
                </td>
                <td>Afternoon yawning</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.ardenal.afternoon_headache"
                  />
                </td>
                <td>Afternoon headache</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" v-model="compVal.ardenal.asthma" />
                </td>
                <td>Asthma, wheezing, or difficulty breathing</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.ardenal.pain_in_innerside_of_knee"
                  />
                </td>
                <td>Pain on the medial or inner side of the knee</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.ardenal.sprain_ankles"
                  />
                </td>
                <td>Tendency to sprain ankles or &#34;shin splints&#34;</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.ardenal.need_sunglass"
                  />
                </td>
                <td>Tendency to need sunglasses</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" v-model="compVal.ardenal.allergy" />
                </td>
                <td>Allergies and/or hives</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" v-model="compVal.ardenal.dizziness" />
                </td>
                <td>Weakness, dizziness</td>
              </tr>
              <tr>
                <td class="table_section" rowspan="13">Pituitary</td>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.pituitary.height_over_6_6"
                  />
                </td>
                <td>Height over 6&#39;6&#34;</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.pituitary.early_sexual_development"
                  />
                </td>
                <td>Early sexual development, before age 10</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.pituitary.increased_libido"
                  />
                </td>
                <td>Increased libido</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.pituitary.splitting_type_headache"
                  />
                </td>
                <td>Splitting type headaches</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.pituitary.memory_failing"
                  />
                </td>
                <td>Memory failing</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.pituitary.tolerate_sugar"
                  />
                </td>
                <td>Tolerate sugar, feel fine when eating sugar</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.pituitary.height_under_4_10"
                  />
                </td>
                <td>Height under 4&#39;10&#34;</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.pituitary.decreased_libido"
                  />
                </td>
                <td>Decreased libido</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.pituitary.excessive_thirst"
                  />
                </td>
                <td>Excessive thirst</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.pituitary.weight_gain_around_waist"
                  />
                </td>
                <td>Weight gain around hips or waist</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.pituitary.menstrual_disorder"
                  />
                </td>
                <td>Menstrual disorders</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.pituitary.delayed_sexual_development"
                  />
                </td>
                <td>Delayed sexual development (after age 13)</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" v-model="compVal.pituitary.colitis" />
                </td>
                <td>Tendency to ulcers or colitis</td>
              </tr>
              <tr>
                <td class="table_section" rowspan="16">Thyroid</td>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.thyroid.allergic_to_iodine"
                  />
                </td>
                <td>Sensitive/allergic to iodine</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.thyroid.difficulty_gaining_weightfic"
                  />
                </td>
                <td>Difficulty gaining weight, even with large appetite</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" v-model="compVal.thyroid.nervous" />
                </td>
                <td>Nervous, emotional, can&#39;t work under pressure</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.thyroid.inward_trembling"
                  />
                </td>
                <td>Inward trembling</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.thyroid.flush_easily"
                  />
                </td>
                <td>Flush easily</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" v-model="compVal.thyroid.fast_pulse" />
                </td>
                <td>Fast pulse at rest</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.thyroid.intolerance"
                  />
                </td>
                <td>Intolerance to high temperatures</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.thyroid.difficulty_losing_weight"
                  />
                </td>
                <td>Difficulty losing weight</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.thyroid.mentally_sluggish"
                  />
                </td>
                <td>Mentally sluggish, reduced initiative</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.thyroid.easily_fatigued"
                  />
                </td>
                <td>Easily fatigued, sleeping during the day</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.thyroid.sensitive_to_cold"
                  />
                </td>
                <td>
                  Sensitive to cold, poor circulation (cold hands and feet)
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.thyroid.constipation"
                  />
                </td>
                <td>Constipation, chronic</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.thyroid.excessive_hair_loss"
                  />
                </td>
                <td>Excessive hair loss and/or coarse hair</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.thyroid.morning_headache"
                  />
                </td>
                <td>Morning headaches, wear off during the day</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.thyroid.Loss_of_lateral_of_eyebrow"
                  />
                </td>
                <td>Loss of lateral 1/3 of eyebrow</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.thyroid.seasonal_sadness"
                  />
                </td>
                <td>Seasonal sadness</td>
              </tr>
              <tr>
                <td class="table_section" rowspan="10">Cardio</td>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.cardio.irregular_breathing"
                  />
                </td>
                <td>Aware of heavy and/or irregular breathing</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" v-model="compVal.cardio.discomfort" />
                </td>
                <td>Discomfort at high altitudes</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.cardio.sigh_frequently"
                  />
                </td>
                <td>Air hunger or sigh frequently</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.cardio.in_closed_room"
                  />
                </td>
                <td>Compelled to open windows in a closed room</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.cardio.shortness_of_breath"
                  />
                </td>
                <td>Shortness of breath with moderate exertion</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.cardio.ankles_swell"
                  />
                </td>
                <td>Ankles swell, especially at end of day</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.cardio.cough_at_night"
                  />
                </td>
                <td>Cough at night</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.cardio.face_turns_red"
                  />
                </td>
                <td>Blush or face turns red for no reason</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.cardio.tightness_in_chest"
                  />
                </td>
                <td>
                  Dull pain or tightness in chest and/or radiate into right arm,
                  worse with exertion
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.cardio.muscle_cramp"
                  />
                </td>
                <td>Muscle cramps with exertion</td>
              </tr>
              <tr>
                <td class="table_section" rowspan="5">
                  Kidney <br />/ Bladder
                </td>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.kidney_bladder.pain_in_back"
                  />
                </td>
                <td>Pain in mid-back region</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.kidney_bladder.dark_circle"
                  />
                </td>
                <td>Puffy around the eyes, dark circles under eyes</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.kidney_bladder.kidney_stone"
                  />
                </td>
                <td>History of kidney stones</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.kidney_bladder.darked_urine"
                  />
                </td>
                <td>Cloudy, bloody, or darkened urine</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.kidney_bladder.urine_has_strong_odor"
                  />
                </td>
                <td>Urine has a strong odor</td>
              </tr>
              <tr>
                <td class="table_section" rowspan="10">Immune System</td>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.immune_system.drippy_nose"
                  />
                </td>
                <td>Runny or drippy nose</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.immune_system.catch_colds"
                  />
                </td>
                <td>Catch colds at the beginning of winter</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.immune_system.mucus_producing_cough"
                  />
                </td>
                <td>Mucus producing cough</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.immune_system.frequent_flu"
                  />
                </td>
                <td>Frequent colds or flu</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.immune_system.infections"
                  />
                </td>
                <td>
                  Other infections (sinus, ear, lung, skin, bladder, kidney,
                  etc)
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.immune_system.never_get_sick"
                  />
                </td>
                <td>Never get sick</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" v-model="compVal.immune_system.acne" />
                </td>
                <td>Acne (adult)</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.immune_system.dermatitis"
                  />
                </td>
                <td>Itchy skin (dermatitis)</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.immune_system.cysts"
                  />
                </td>
                <td>Cysts, boils, rashes</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.immune_system.chronic_viral_condition"
                  />
                </td>
                <td>
                  History of Epstein-barr, Mono, Herpes, Shingles, Chronic
                  Fatigue Syndrome, Hepatitis, or other chronic viral condition
                </td>
              </tr>
              <tr>
                <td class="table_section" rowspan="20">Women Only</td>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.women_only.depression_during_period"
                  />
                </td>
                <td>Depression during periods</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.women_only.mood_swings_with_period"
                  />
                </td>
                <td>Mood swings associated with periods (PMS)</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.women_only.crave_chocolate"
                  />
                </td>
                <td>Crave chocolate around periods</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.women_only.breast_tenderness"
                  />
                </td>
                <td>Breast tenderness associated with cycle</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.women_only.menstrual_flow"
                  />
                </td>
                <td>Excessive menstrual flow</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.women_only.scanty_blood"
                  />
                </td>
                <td>Scanty blood flow during periods</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.women_only.occasional_skipped_period"
                  />
                </td>
                <td>Occasional skipped periods</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.women_only.variations_in_menstrual_cycle"
                  />
                </td>
                <td>Variations in menstrual cycle length</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.women_only.endometriosis"
                  />
                </td>
                <td>Endometriosis</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.women_only.uterine_fibroid"
                  />
                </td>
                <td>Uterine fibroids</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.women_only.breast_fibroid"
                  />
                </td>
                <td>Breast fibroids, benign masses</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.women_only.painful_intercourse"
                  />
                </td>
                <td>Painful intercourse</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.women_only.vaginal_discharge"
                  />
                </td>
                <td>Vaginal discharge</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.women_only.vaginal_dryness"
                  />
                </td>
                <td>Vaginal dryness</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.women_only.vaginal_itchiness"
                  />
                </td>
                <td>Vaginal itchiness</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.women_only.tendency_to_gain_weight"
                  />
                </td>
                <td>
                  Tendency to gain weight around hips, thighs, and buttocks as
                  opposed to other areas such as the mid-section
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.women_only.excess_facial"
                  />
                </td>
                <td>Excess facial or body hair</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.women_only.hot_flashes"
                  />
                </td>
                <td>Hot flashes</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.women_only.night_sweats"
                  />
                </td>
                <td>Night sweats (in menopausal females)</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.women_only.thinning_skin"
                  />
                </td>
                <td>Thinning skin</td>
              </tr>
              <tr>
                <td class="table_section" rowspan="9">Men Only</td>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.men_only.prostate_problem"
                  />
                </td>
                <td>Prostate problems</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" v-model="compVal.men_only.dribbling" />
                </td>
                <td>Difficulty with urination, dribbling</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.men_only.stop_urine_stream"
                  />
                </td>
                <td>Difficult to start and stop urine stream</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.men_only.pain_with_urination"
                  />
                </td>
                <td>Pain or burning with urination</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.men_only.walk_to_urine_at_night"
                  />
                </td>
                <td>Waking to urinate at night</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.men_only.interruption_of_stream"
                  />
                </td>
                <td>Interruption of stream during urination</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.men_only.pain_inside_leg"
                  />
                </td>
                <td>Pain on inside of legs or heels</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.men_only.incomplete_bowel_evacuation"
                  />
                </td>
                <td>Feeling of incomplete bowel evacuation</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.men_only.decreased_sexual_function"
                  />
                </td>
                <td>Decreased sexual function</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <br /><br />

      <div
        class="box"
        style="display: flex; justify-content: flex-end !important"
        v-if="editId"
      >
        <button class="menu_item next-button" @click="onUpdate">Update</button>
      </div>
      <div class="box" v-else>
        <button class="menu_item back-button" @click="onBack">Back</button>
        <button class="menu_item next-button" @click="onNext">
          {{ buttonNext }}
        </button>
      </div>
      <br />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import { ModelListSelect } from "vue-search-select";
export default {
  name: "NaqHtmaForm",
  components: {
    ModelListSelect,
  },
  props: ["naqHtma", "naqOnly"],
  data() {
    return {
      buttonNext: "Next",

      clientName: "",
      naqHtmaDate: "",
      naqHtmaId: null,
      allNaqHtmaData: [],
      clientNameData: [],
      datesData: [],

      editId: null,
      editData: [],
    };
  },
  watch: {
    clientName(value) {
      if (value) {
        console.log("value", value);
        this.datesData = this.allNaqHtmaData.filter(
          (data) =>
            data.client_name ===
            this.allNaqHtmaData.find((x) => x.id === value).client_name
        );
        console.log("data.created");
        this.datesData.map((data) => {
          data.created = moment(data.created).format("MM/DD/YYYY");
        });
        console.log("naqHtmaDatesData", this.datesData);
      }
    },
  },
  computed: {
    ...mapGetters(["allNaqHtma", "singleNaqHtma"]),
    box: function () {
      return this.naqOnly ? "submit-box" : "box";
    },
    compVal: {
      get() {
        if (this.editId) {
          return this.editData;
        } else {
          return this.naqHtma;
        }
      },
      // set (val) {
      //   if (this.editId) {
      //     this.editData = val
      //   } else {
      //     this.naqHtma = val
      //   }
      // }
    },
  },
  methods: {
    ...mapActions(["getAllNaqHtma", "getSingleNaqHtma", "updateNaqHtma"]),

    async search() {
      console.log("submit");
      console.log("naqHtma data id ", this.naqHtmaDate);
      console.log("naqHtma id searched is ", this.naqHtmaDate);
      this.naqHtmaId = this.naqHtmaDate;
      this.$emit("setNaqHtmaId", this.naqHtmaId);
    },
    onReset() {
      this.naqHtmaId = null;
      this.clientName = null;
      this.naqHtmaDate = null;
      console.log("naqHtma id is", this.naqHtmaId);
      this.$emit("reset");
    },
    onNext() {
      if(this.naqOnly){
        console.log('this.naqhtma',this.naqHtma.client_name)
        if(!this.naqHtma.client_name){
          this.$toastr.Add({
            name: "Error", // this is give you ability to use removeByName method
            msg: "Please Enter Client Name!", // Toast Message
            type: "error", // Toast type,
          });
        }
        else{
          this.$emit("nextStep", {});
        window.scrollTo(0, 0);
        }
      }
      else{
      this.$emit("nextStep", {});
      window.scrollTo(0, 0);
      }
    },
    onBack() {
      this.$emit("backStep");
      window.scrollTo(0, 0);
    },
    // Update

    async onUpdate() {
      if(!this.editData.client_name){
          this.$toastr.Add({
            name: "Error", // this is give you ability to use removeByName method
            msg: "Please Enter Client Name!", // Toast Message
            type: "error", // Toast type,
          });
        }
        else{
      this.$toastr.Add({
        name: "Success", // this is give you ability to use removeByName method
        msg: "NAQ+HTMA has been updated Successfully!", // Toast Message
        type: "success", // Toast type,
      });
      console.log("updating");
      console.log("naqhtma", this.naqHtma);
      console.log("edit naqhtma", this.editData);
      const dataUpdate = await this.updateNaqHtma({
        id: this.editId,
        payload: this.editData,
      });
      console.log("data has been edited ", dataUpdate);
      this.$router.push(`/client-list`);
    }
    }
  },
  async mounted() {
    if (this.naqOnly) {
      this.buttonNext = "Submit";
    }

    this.editId = this.$route.params.id;
    if (this.editId) {
      console.log(this.editId);
      console.log("id getting");
      //  console.log(this.input)
      await this.getSingleNaqHtma(this.editId);
      this.editData = await this.singleNaqHtma;
      console.log(this.editData);
    } else {
      await this.getAllNaqHtma();
      this.allNaqHtmaData = await this.allNaqHtma;
      console.log("naqHtma data ", this.allNaqHtmaData);

      this.clientNameData = this.allNaqHtmaData.filter(
        (a, i) =>
          this.allNaqHtmaData.findIndex(
            (s) => a.client_name === s.client_name
          ) === i
      );
    }
    // console.log("all naqHtma are ", this.allNaqHtmaData);
    // console.log("all client names are ", this.clientNameData);
  },
};
</script>
<style>
.box {
  display: flex;
  justify-content: space-between !important;
  padding-right: 20px;
}
.next-button {
  width: 100px;
}
</style>
