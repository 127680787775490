<template>
  <div class="card">
    <div class="card__item">
      <table id="single_tbl_input">
        <tr>
          <th colspan="2">Input</th>
        </tr>
        <tr>
          <td>Practitioner Name: *</td>
          <td class="right_col">
            <!-- <input
              type="text"
              v-model="input.practitioner_name"
            /> -->
            <input
              :readonly="true"
              type="text"
              v-model="input.practitioner_name"
            />
          </td>
        </tr>
        <tr>
          <td>Client Name: *</td>
          <td class="right_col">
            <input
              :required="true"
              :readonly="inputId"
              type="text"
              v-model="input.client_name"
            />
            <p
              v-if="!input.client_name && this.clientNameError"
              style="color: red"
            >
              Required*
            </p>
          </td>
        </tr>
        <tr>
          <td>Metabolic Type: *</td>
          <td class="right_col">
            <select v-model="input.metabolic_type">
              <option value="" selected disabled></option>
              <option
                :disabled="inputId"
                v-for="(value, index) in metabolic_type_choices"
                :value="value.value"
                :key="index"
              >
                {{ value.text }}
              </option>
            </select>
            <p
              v-if="!input.metabolic_type && this.metTypeError"
              style="color: red"
            >
              Required*
            </p>
          </td>
        </tr>
        <tr>
          <td>HTMA Test Date: *</td>
          <td class="right_col">
            <datepicker
              class="datepicker w-100"
              v-model="input.htma_test_date"
              :disabled="!!inputId"
              :readonly="inputId"
              format="MM/dd/yyyy"
              placeholder="mm/dd/yyyy"
              :typeable="true"
              :opened="true"
            ></datepicker>
            <p
              v-if="!input.htma_test_date && this.htmaDateError"
              style="color: red"
            >
              Required*
            </p>
          </td>
        </tr>
        <tr>
          <td>Specific Heavy Metals:</td>
          <td class="right_col">
            <select v-model="input.specific_heavy_metals[0]">
              <option value="" selected disabled></option>
              <option
                :disabled="inputId"
                v-for="(value, index) in heavyMetals"
                :value="value.id"
                :key="index"
              >
                {{ value.heavy_metal.toUpperCase() }}
              </option>
            </select>
          </td>
        </tr>
        <tr>
          <td></td>
          <td class="right_col">
            <select v-model="input.specific_heavy_metals[1]">
              <option
                :disabled="inputId"
                v-for="(value, index) in heavyMetals"
                :value="value.id"
                :key="index"
              >
                {{ value.heavy_metal.toUpperCase() }}
              </option>
            </select>
          </td>
        </tr>
        <tr>
          <td></td>
          <td class="right_col">
            <select v-model="input.specific_heavy_metals[2]">
              <option
                :disabled="inputId"
                v-for="(value, index) in heavyMetals"
                :value="value.id"
                :key="index"
              >
                {{ value.heavy_metal.toUpperCase() }}
              </option>
            </select>
          </td>
        </tr>
        <tr>
          <td></td>
          <td class="right_col">
            <select v-model="input.specific_heavy_metals[3]">
              <option
                :disabled="inputId"
                v-for="(value, index) in heavyMetals"
                :value="value.id"
                :key="index"
              >
                {{ value.heavy_metal.toUpperCase() }}
              </option>
            </select>
          </td>
        </tr>
        <tr>
          <td></td>
          <td class="right_col">
            <select v-model="input.specific_heavy_metals[4]">
              <option
                :disabled="inputId"
                v-for="(value, index) in heavyMetals"
                :value="value.id"
                :key="index"
              >
                {{ value.heavy_metal.toUpperCase() }}
              </option>
            </select>
          </td>
        </tr>
        <tr>
          <td></td>
          <td class="right_col">
            <select v-model="input.specific_heavy_metals[5]">
              <option
                :disabled="inputId"
                v-for="(value, index) in heavyMetals"
                :value="value.id"
                :key="index"
              >
                {{ value.heavy_metal.toUpperCase() }}
              </option>
            </select>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Datepicker from "vuejs-datepicker";

export default {
  name: "InputForm",
  props: [
    "input",
    "inputId",
    "clientNameError",
    "metTypeError",
    "htmaDateError",
  ],
  components: {
    Datepicker,
  },
  data() {
    return {
      query: "",
      heavyMetals: {},
      singleTests: [],
      metabolic_type_choices: [
        { value: "f1", text: "Fast 1" },
        { value: "f2", text: "Fast 2" },
        { value: "f3", text: "Fast 3" },
        { value: "f4", text: "Fast 4" },
        { value: "s1", text: "Slow 1" },
        { value: "s2", text: "Slow 2" },
        { value: "s3", text: "Slow 3" },
        { value: "s4", text: "Slow 4" },
      ],
    };
  },
  computed: {
    ...mapGetters(["allSingleTest", "allHeavyMetals"]),
  },
  watch: {
    query(value) {
      console.log(value);
    },
  },
  async mounted() {
    console.log("input id is on input page ", this.inputId);
    await this.getAllHeavyMetals();
    this.heavyMetals = await this.allHeavyMetals;
  },
  methods: {
    ...mapActions(["getAllHeavyMetals"]),

    onNext() {
      this.$emit("nextStep");
      window.scrollTo(0, 0);
    },
    onBack() {
      this.$emit("backStep");
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style>
.box {
  display: flex;
  justify-content: space-between !important;
  padding-right: 20px;
}

.next-button {
  width: 150px;
}

.no-button {
  color: transparent !important;
  background-color: transparent !important;
  border: transparent !important;
  cursor: auto !important;
}
.vdp-datepicker input[type="text"] {
  width: 100% !important;
  /* position: static !important */
  /* cursor: pointer; */
}
.vdp-datepicker input[type="text"]:read-only {
  width: 100% !important;
  cursor: pointer;
}
.vdp-datepicker__calendar > header {
  position: static !important;
  padding: 0 !important;
  margin-bottom: 0px !important;
  height: 40px;
}
</style>