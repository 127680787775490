<template>
  <div id="">
    <main>
      <div class="main_content_wrapper h-100">
        <div class="container">
          <div class="row no-print">
            <div
              class="mt-5 mx-auto w-100 submit-box"
              style="display: flex; justify-content: flex-end !important"
            >
              <button @click="submit()" class="menu_item next-button">
                Submit
              </button>
            </div>
          </div>
          <br /><br />

          <div class="row no-print">
            <h2 class="mx-2 mt-2">Blood Markers Iodine Input</h2>
            <div class="col-lg-12 pt_55 col_index4">
              <!-- search -->
              <div class="row mb-5">
                <div class="col-lg-5 mx-auto w-100">
                  <model-list-select
                    :list="clientNameData"
                    v-model="clientName"
                    option-value="id"
                    option-text="client_name"
                    placeholder="Search Client Name"
                  >
                  </model-list-select>
                </div>
                <div class="col-lg-5">
                  <model-list-select
                    :list="datesData"
                    v-model="bloodMarkerDate"
                    option-value="id"
                    option-text="created"
                    placeholder="Search Date"
                  >
                  </model-list-select>
                </div>
                <div class="col-lg-2 submit-box">
                  <button
                    v-if="formId"
                    @click="onReset()"
                    class="menu_item submit-button"
                  >
                    Reset
                  </button>
                  <button
                    v-else
                    @click="search()"
                    class="menu_item submit-button"
                  >
                    Search
                  </button>
                </div>
              </div>

              <!-- search -->
              <div class="accorodion_wrapper" id="accordian">
                <!-- <div>
                  <BloodMarkersIron
                    @nextStep="getNextStep()"
                    @backStep="getBackStep()"
                    :data="bloodMarkersData"
                  />
                </div>
                <br /> -->
                <div>
                  <BloodMarkerIodine
                    @nextStep="getNextStep()"
                    @backStep="getBackStep()"
                    :data="bloodMarkersData"
                  />
                </div>
              </div>
            </div>
          </div>

          <!--  -->

          <div v-if="result" class="">
            <br />
            <h2 class="pt-2 mb-2 text-center">
              Iodine Blood Markers Results for
              {{ bloodMarkersReportData.client_name }}
            </h2>
            <h3 class="pt-2 mb-2 text-center">
              Prepared on
              {{ dateValue }}
            </h3>
            <hr />
            <div class="container">
              <div class="row">
                <div
                  v-if="bloodMarkersReportData.iodine_blood_marker"
                  class="col-lg-12 col-md-12 col-sm-12 pt_20"
                >
                  <div
                    class="middle_content_box"
                    style="height: 100% !important"
                  >
                    <table>
                      <tr>
                        <td>
                          <h3 class="title_md" id="SO_D2">
                            Iodine Blood Markers:
                          </h3>
                        </td>
                        <td>
                          <h3 class="title_md" style="text-align: right">
                            <span id="SO_E2"></span>
                          </h3>
                        </td>
                      </tr>
                    </table>
                    <ul class="middle_content_list">
                      <li>{{ bloodMarkersReportData.iodine_blood_marker }}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--  -->
        </div>
      </div>
    </main>
    <!-- Modal -->
    <IodinesModal
      v-if="showModal"
      :save="save"
      @confirm="confirmSubmit($event)"
    />

    <!-- Modal -->
  </div>
</template>

<script>
// import BloodMarkersIron from "../../components/BloodMarkers/BloodMarkersIron.vue";
import BloodMarkerIodine from "../../components/BloodMarkers/BloodMarkerIodine.vue";
import IodinesModal from "../../components/SaveModal/IodinesModal.vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import { ModelListSelect } from "vue-search-select";
function getInitBloodMarkersData() {
  console.log("getting init");
  return {
    client_name: "",
    total_t3: 0,
    reverse_t3: 0,
  };
}
export default {
  name: "BloodMarkersIodine",
  components: {
    BloodMarkerIodine,
    ModelListSelect,
    IodinesModal,
  },
  data() {
    return {
      formId: null,

      allBloodMarkerData: [],
      clientName: "",
      bloodMarkerDate: "",
      clientNameData: [],
      datesData: [],

      bloodMarkersData: getInitBloodMarkersData(),

      resultId: null,
      bloodMarkersReportData: {},
      result: false,

      save: {
        data: false,
      },
      showModal: false,
    };
  },
  watch: {
    clientName(value) {
      if (value) {
        console.log("value", value);
        this.datesData = this.allBloodMarkerData.filter(
          (data) =>
            data.client_name ===
            this.allBloodMarkerData.find((x) => x.id === value).client_name
        );
        console.log("data.created");
        this.datesData.map((data) => {
          data.created = moment(data.created).format("MM/DD/YYYY");
        });
        console.log("bloodmarkerDatesData", this.datesData);
      }
    },
  },
  computed: {
    ...mapGetters([
      "iodineBloodMarkers",
      "singleIodineBloodMarker",
      "iodineBloodMarkersReport",
    ]),
    dateValue() {
      var date = new Date(this.bloodMarkersReportData.created);
      return (
        (date.getMonth() > 8
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1)) +
        "/" +
        (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
        "/" +
        date.getFullYear()
      );
    },
  },
  methods: {
    ...mapActions([
      "addIodineBloodMarkers",

      "getIodineBloodMarkers",
      "getSingleIodineBloodMarker",

      "getIodineBloodMarkersReport",
      "getIodineBloodMarkersReportUnsaved",
    ]),

    async search() {
      console.log("search");
      console.log("bloodMarkers data id ", this.bloodMarkersData);
      console.log("bloodMarkers id searched is ", this.bloodMarkerDate);
      this.formId = this.bloodMarkerDate;
      await this.getSingleIodineBloodMarker(this.formId);
      console.log(
        "select getSingleBloodMarker is ",
        await this.singleIodineBloodMarker
      );
      this.bloodMarkersData = await this.singleIodineBloodMarker;
    },
    onReset() {
      this.formId = null;
      this.clientName = null;
      this.bloodMarkerDate = null;
      console.log("condition id is", this.formId);
      Object.assign(this.bloodMarkersData, getInitBloodMarkersData());
    },
    async confirmSubmit(toSave) {
      if (toSave) {
        if (!this.bloodMarkersData.client_name) {
          this.$toastr.Add({
            name: "Error", // this is give you ability to use removeByName method
            msg: "Please Enter Client Name!", // Toast Message
            type: "error", // Toast type,
          });
        } else {
          this.$toastr.Add({
            name: "Success", // this is give you ability to use removeByName method
            msg: "Blood Markers has been submitted Successfully!", // Toast Message
            type: "success", // Toast type,
          });
          //  Blood Markers
          if (this.formId) {
            console.log("selected blood markers id ", this.formId);
          } else {
            if (this.save.data) {
              try {
                const response = await this.addIodineBloodMarkers(
                  this.bloodMarkersData
                );
                console.log("blood markers response ", response);
                this.formId = response.id;
              } catch (error) {
                console.log(error);
              }
            } else {
              // not saving data
              this.showModal = false;
              console.log("confirm submit toSave ", toSave, this.save);
              this.bloodMarkersData["created"] = new Date().toISOString();
              sessionStorage.setItem(
                "blood_marker",
                JSON.stringify(this.bloodMarkersData)
              );
              this.formId = 0;
            }
          }

          this.$router.push(`/iodine-blood-markers/${this.formId}`);
        }
        this.resultId = this.formId;
        console.log("calling report");
        if (this.formId != 0) {
          await this.getIodineBloodMarkersReport(this.resultId);
        } else {
          const blood_marker = JSON.parse(
            sessionStorage.getItem("blood_marker")
          );
          console.log("blood_marker raw data", blood_marker);
          await this.getIodineBloodMarkersReportUnsaved(blood_marker);
        }
        this.bloodMarkersReportData = await this.iodineBloodMarkersReport;
        console.log("this.bloodMarkersReportData", this.bloodMarkersReportData);
        document.getElementById("print-pdf").style.display = 'inline-block'
        localStorage.setItem(
          "instant_clientname",
          `${this.bloodMarkersReportData.client_name}`
        );
        this.result = true;
      } else {
        this.showModal = false;
        console.log("confirm submit data ", toSave);
      }
    },

    async submit() {
      console.log("submitttting...");

      this.showModal = true;
      // if (!this.bloodMarkersData.client_name) {
      //   this.$toastr.Add({
      //     name: "Error", // this is give you ability to use removeByName method
      //     msg: "Please Enter Client Name!", // Toast Message
      //     type: "error", // Toast type,
      //   });
      // } else {
      //   this.$toastr.Add({
      //     name: "Success", // this is give you ability to use removeByName method
      //     msg: "Blood Markers has been submitted Successfully!", // Toast Message
      //     type: "success", // Toast type,
      //   });
      //   //  Blood Markers
      //   if (this.formId) {
      //     console.log("selected blood markers id ", this.formId);
      //   } else {
      //     try {
      //       const response = await this.addIodineBloodMarkers(
      //         this.bloodMarkersData
      //       );
      //       console.log("blood markers response ", response);
      //       this.formId = response.id;
      //     } catch (error) {
      //       console.log(error);
      //     }
      //   }

      //   this.$router.push(`/iodine-blood-markers/${this.formId}`);
      // }
      // this.resultId = this.formId;
      // console.log("calling report");
      // await this.getIodineBloodMarkersReport(this.resultId);
      // this.bloodMarkersReportData = await this.iodineBloodMarkersReport;
      // console.log("this.bloodMarkersReportData", this.bloodMarkersReportData);
      // localStorage.setItem(
      //   "instant_clientname",
      //   `${this.bloodMarkersReportData.client_name}`
      // );
      // this.result = true;
    },
  },
  async mounted() {
    this.$nextTick(async() => {
    this.resultId = this.$route.params.result;
    if (this.resultId) {
      console.log("calling report");
      document.getElementById("print-pdf").style.display = 'inline-block'
      await this.getIodineBloodMarkersReport(this.resultId);
      this.bloodMarkersReportData = await this.iodineBloodMarkersReport;
      console.log("this.bloodMarkersReportData", this.bloodMarkersReportData);
      localStorage.setItem(
        "instant_clientname",
        `${this.bloodMarkersReportData.client_name}`
      );
      this.result = true;
    }
    else document.getElementById("print-pdf").style.display = 'none'

    await this.getIodineBloodMarkers();
    this.allBloodMarkerData = await this.iodineBloodMarkers;
    console.log("bloodmarker iodine data ", this.allBloodMarkerData);

    this.clientNameData = this.allBloodMarkerData.filter(
      (a, i) =>
        this.allBloodMarkerData.findIndex(
          (s) => a.client_name === s.client_name
        ) === i
    );
    console.log("allBloodMarkerData are ", this.allBloodMarkerData);
    // Object.assign(this.bloodMarkersData, getInitBloodMarkersData());
  })
  },
};
</script>
<style>
.b-toaster {
  position: absolute;
  max-width: 350px;
  width: 100%;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
}
.submit-box {
  display: flex;
  justify-content: flex-end !important;
  padding-right: 20px;
}

.next-button {
  width: 100px;
}

.no-button {
  color: transparent !important;
  background-color: transparent !important;
  border: transparent !important;
  cursor: auto !important;
}
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
</style>
