import { getReq, postReq, deleteReq, putReq } from "../../../services/api";

const state = {
  ironBloodMarkers: [],
  ironBloodMarkersReport: [],
  singleIronBloodMarker:[],
};

const getters = {
  ironBloodMarkers: (state) => state.ironBloodMarkers,
  ironBloodMarkersReport: (state) => state.ironBloodMarkersReport,
  singleIronBloodMarker: (state) => state.singleIronBloodMarker,
};


const actions = {
  // Iron ironBloodMarkers
  async getIronBloodMarkers({ commit },) {
    try {
      let ironBloodMarkers = await getReq(`iron-blood/`);
      commit("setIronBloodMarkers", ironBloodMarkers);

    } catch (error) {
      console.log(error)
      console.log(error.response);
      throw error.response;
    }
  },

  async getSingleIronBloodMarker({ commit },id) {
    try {
      let singleBloodMarker = await getReq(`iron-blood/${id}/`);
      commit("setSingleIronBloodMarker", singleBloodMarker);
      
    } catch (error) {
      console.log(error)
      console.log(error.response);
      throw error.response;
    }
  },

  async addIronBloodMarkers(_, payload) {
    try {
      const newIronBloodMarkers = await postReq(`iron-blood/`, payload);
      return newIronBloodMarkers

    } catch (error) {
      console.log(error.response.data);
      throw error.response.data;
    }
  },

  // async updateironBloodMarkers({ commit }, { id, payload }) {
  //   try {
  //     console.log(commit)
  //     console.log(payload)
  //     const updatedData = await patchReq("iron-blood/", id, payload);
  //     if (updatedData) {
  //       console.log(updatedData);
  //     }
  //   } catch (e) {
  //     console.log(e)
  //     if (!e.response) {
  //       throw "No network connection"
  //     }
  //     const errors = e.response.data;
  //     console.log(errors)
  //     throw errors;
  //   }
  // },

  async updateIronBloodMarkers({ commit }, { id, payload }) {
    try {
      console.log(commit)
      console.log(payload)
      const updatedData = await putReq("iron-blood/", id, payload);
      if (updatedData) {
        console.log(updatedData);
      }
      return updatedData
    } catch (e) {
      console.log(e)
      if (!e.response) {
        throw "No network connection"
      }
      const errors = e.response.data;
      console.log(errors)
      throw errors;
    }
  },


  async deleteIronBloodMarkers(_, id) {
    try {
      return await deleteReq("iron-blood", id);
    } catch (e) {
      if (!e.response) {
        throw "No network connection"
      }
      const errors = e.response.data;
      console.log(errors)
      throw errors;
    }
  },

  // ironBloodMarkers Unsaved Api

  async getIronBloodMarkersReportUnsaved({commit},payload) {
    try {
       const ironBloodMarkersReport = await postReq(`iron-blood-unsaved/`,payload );
      commit("setIronBloodMarkersReport", ironBloodMarkersReport);
       return ironBloodMarkersReport 
     
    } catch (error) {
      console.log(error.response.data);
      throw error.response.data;
    }
  },

  // ironBloodMarkers Report

  async getIronBloodMarkersReport({ commit }, id) {
    try {
      let ironBloodMarkersReport = await getReq(`iron-blood-marker-report/${id}/`);
      commit("setIronBloodMarkersReport", ironBloodMarkersReport);

    } catch (error) {
      console.log(error)
      console.log(error.response);
      throw error.response;
    }
  },

};

const mutations = {
  setIronBloodMarkers: (state, ironBloodMarkers) => (state.ironBloodMarkers = ironBloodMarkers),
  setIronBloodMarkersReport: (state, ironBloodMarkersReport) => (state.ironBloodMarkersReport = ironBloodMarkersReport),
  setSingleIronBloodMarker: (state, singleIronBloodMarker) => (state.singleIronBloodMarker = singleIronBloodMarker),

};

export default {
  state,
  getters,
  actions,
  mutations,
};
