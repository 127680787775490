<template>
  <div id="">
    <main>
      <div class="main_content_wrapper">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 pt_55 col_index4">
              <div class="accorodion_wrapper" id="accordian">
                <div>
                  <HTMAInput
                  :upload="true"
                    label="HTMA Results Input"
                    @nextStep="submit()"
                    @reset="resetSearchInput()"
                    @setInputId="setInputId($event)"
                    @setInput="setInput($event)"
                    @setHTMA="setHTMA($event)"
                    :input="input"
                    :htma="htma"
                    :inputId="inputId"
                  />
                </div>

                <!-- <div v-if="step == 2">
                  <NAQ
                    :naqOnly="false"
                    @nextStep="getNextStep()"
                    @backStep="getBackStep()"
                    @setNaqId="setNaqId($event)"
                    @reset="resetSearchNaq()"
                    :naq="naq"
                  />
                </div>

                <div v-if="step == 3">
                  <NaqHtmaForm
                    @nextStep="getNextStep()"
                    @backStep="getBackStep()"
                    :naqHtma="naqHtma"
                    @setNaqHtmaId="setNaqHtmaId($event)"
                    @reset="resetSearchNaqHtma()"
                  />
                </div>

                <div v-if="step == 4">
                  <ConditionForm
                    :conditionsOnly="false"
                    @nextStep="getNextStep()"
                    @backStep="getBackStep()"
                    :condition="condition"
                    :submit="submit"
                    @setConditionId="setConditionId($event)"
                    @reset="resetSearchCondition()"
                  />
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <!-- Modal -->
    <InputHTMAModal
      v-if="showModal"
      :save="save"
      @confirm="confirmSubmit($event)"
    />

    <!-- Modal -->
  </div>
</template>

<script>
import HTMAInput from "../../components/HTMAInput/HTMAInput.vue";
// import NAQ from "../../components/NAQ/NAQ.vue";
// import NaqHtmaForm from "../../components/NaqHtmaForm/NaqHtmaForm.vue";
// import ConditionForm from "../../components/ConditionForm/ConditionForm.vue";
import InputHTMAModal from "../../components/SaveModal/InputHTMAModal.vue"; 
import moment from "moment";

import { mapActions, mapGetters } from "vuex";

function getInitInputData() {
  return {
    practitioner_name: "",
    client_name: "",
    metabolic_type: "",
    specific_heavy_metals: [null, null, null, null, null, null],
    htma_test_date: null,
  };
}
function getInitHtmaData() {
  return {
    ca_p_automonic_balance: 0,
    na_k_vitality: 0,
    ca_k_thyroid: 0,
    zn_cu_hormonal_stress: 0,
    na_mg_adrenal: 0,
    ca_mg_blood_sugar: 0,
    fe_cu_infection: 0,
    cu_mo_copper_imbalance: 0,
    calcium: 0,
    magnesium: 0,
    sodium: 0,
    potassium: 0,
    copper: 0,
    zinc: 0,
    phosphorus: 0,
    iron: 0,
    manganese: 0,
    chromium: 0,
    selenium: 0,
    boron: 0,
    cobalt: 0,
    molybdenum: 0,
    sulfur: 0,
    uranium: 0,
    arsenic: 0,
    beryllium: 0,
    mercury: 0,
    cadmium: 0,
    lead: 0,
    aluminum: 0,
    germanium: 0,
    barium: 0,
    bismuth: 0,
    rubidium: 0,
    lithium: 0,
    nickel: 0,
    platinum: 0,
    thallium: 0,
    vanadium: 0,
    strontium: 0,
    tin: 0,
    titanium: 0,
    tungsten: 0,
    zirconium: 0,
  };
}
// function getInitNaqData() {
//   return {
//     client_name: "",
//     upper_gi: 0,
//     liver_and_gb: 0,
//     small_intestine: 0,
//     large_intestine: 0,
//     mineral_need: 0,
//     fatty_acid: 0,
//     sugar_handling: 0,
//     vitamin_need: 0,
//     adrenal: 0,
//     pituitary: 0,
//     thyroid: 0,
//     cardiovascular: 0,
//     kidney_or_bladder: 0,
//     immune: 0,
//     female_only: 0,
//     male_only: 0,
//     // total: 0,
//   };
// }
// function getInitNaqHtmaData() {
//   return {
//     client_name: "",
//     diet: {
//       drink_alcohol: false,
//       artificial_sweeteners: false,
//       candy_desserts_refined_sugar: false,
//       carbonate_beverages_water: false,
//       chewing_tobacco: false,
//       cigarettes: false,
//       cigars_pipes: false,
//       caffeinated_beverages: false,
//       fast_foods: false,
//       fried_foods: false,
//       luncheon_meats: false,
//       margarine: false,
//       dairy_products: false,
//       radiation_exposure: false,
//       refined_flour: false,
//       vitamin_mineral_supplements: false,
//       distilled_water: false,
//       tap_water: false,
//       well_water: false,
//       diet_weight_control: false,
//     },
//     upper_gi: {
//       belching_gas: false,
//       heartburn_acid_reflux: false,
//       bloating_after_eating: false,
//       vegan_diet: false,
//       bad_breath: false,
//       lost_taste_for_meat: false,
//       sweat_has_strong_odor: false,
//       stomach_upset_by_vitamins: false,
//       excess_fullness_after_meal: false,
//       skipping_breakfast: false,
//       feel_better_while_not_eating: false,
//       sleepy_after_meal: false,
//       fingernails_chip: false,
//       anemia: false,
//       stomach_pain: false,
//       diarrhea: false,
//       diarrhea_shortly: false,
//       stool: false,
//       undigested_food_in_stool: false,
//     },
//     liver: {
//       pain_shoulder_blade: false,
//       stomach_upset_by_food: false,
//       greasy: false,
//       nausea: false,
//       airplane_motion_sickness: false,
//       light_or_clay: false,
//       itchy_feet: false,
//       headache: false,
//       gallbladder_attack: false,
//       morning_sickness: false,
//       bitter_taste: false,
//       alcoholic_drink: false,
//       diesel_fumes: false,
//       pain_under_rib_cage: false,
//       consumption_of_diet_product: false,
//       fibromyalgia: false,
//       gallbladder: false,
//       sick_after_drink_wine: false,
//       easily_intoxicated: false,
//       hungover_when_drink_wine: false,
//       recovering_alcoholic: false,
//       alcohol_abuse: false,
//       history_of_hepatitis: false, // new added
//       // stomach_pains: false, // no need ?
//       // hepatitis: false,
//       recreational_drug: false,
//       sensitive_to_chemical: false,
//       sensitive_to_tobacco: false,
//       varicose_vein: false,
//     },
//     small_intestine: {
//       food_allergy: false,
//       abdominal_bloating: false,
//       foods_make_you_tired: false,
//       pulse_speed: false,
//       airborne_allergy: false,
//       experience_hive: false,
//       sinus_congestion: false,
//       noodle: false,
//       alternating_constipation: false,
//       crohn_disease: false,
//       grain_sensitivity: false,
//       dairy_sensitivity: false,
//       foods_you_cant_give_up: false,
//       asthma: false,
//       vivid_dream: false,
//       counter_pain_medication: false,
//       feel_spacey: false,
//     },
//     large_intestine: {
//       anus_itch: false,
//       coated_tongue: false,
//       feel_worse: false,
//       antibiotics: false,
//       yeast_infection: false,
//       ring_worm: false,
//       swelling_in_joint: false,
//       difficult_to_pass: false,
//       parasite: false,
//       bowel_movement: false,
//       curve_flat_shape: false,
//       loose: false,
//       mucus_colitis: false,
//       blood: false,
//       mucus: false,
//       foul_smelling: false,
//       strong_body_odor: false,
//       painful_tigh: false,
//       abdominal_region: false,
//       dark_circle: false,
//     },
//     mineral_needs: {
//       carpal_tunnel_syndrome: false,
//       abdominal_pain: false,
//       stress_fracture: false,
//       bone_loss: false,
//       shorter_than_usual: false,
//       cramps_at_rest: false,
//       cold_sore: false,
//       frequent_fever: false,
//       hives: false,
//       herniated_disc: false,
//       flexible_joint: false,
//       joints_pop: false,
//       swelling_in_joint: false,
//       bursitis: false,
//       bone_spur: false,
//       morning_stiffness: false,
//       vomiting: false,
//       crave_chocolate: false,
//       feet_have_strong_odor: false,
//       anemia: false,
//       eyes_are_blue_tinted: false,
//       hoarseness: false,
//       swallowing: false,
//       lump_in_throat: false,
//       dry_mouth: false,
//       gag_easily: false,
//       fingernail: false,
//       cuts_heal: false,
//       decreased_sense: false,
//     },
//     essential_fatty_acid: {
//       pain_relief_with_aspirin: false,
//       greasy_food: false,
//       reduced_fat_diet: false,
//       headache: false,
//       headaches_when_out_in_sun: false,
//       sunburn_easily: false,
//       muscles_fatigued: false,
//       dandruff: false,
//     },
//     sugar_handling: {
//       awaken_after_falling_asleep: false,
//       crave_sweet: false,
//       uncontrolled_eating: false,
//       excessive_appetite: false,
//       crave_coffee: false,
//       sleepy: false,
//       fatigue: false,
//       headache: false,
//       irritable_before_meals: false,
//       shaky: false,
//       family_members_with_diabete: false,
//       frequent_thirst: false,
//       frequent_urination: false,
//     },
//     vitamin_need: {
//       muscles_fatigued: false,
//       feel_exhausted: false,
//       insect_bites: false,
//       loss_of_muscle: false,
//       enlarged_heart: false,
//       pulse_below_65: false,
//       ringing_in_ear: false,
//       numbness: false,
//       depressed: false,
//       impending_doom: false,
//       worrier: false,
//       nervous: false,
//       feelings_of_insecurity: false,
//       heart_race: false,
//       hear_heart_beat_on_pillow: false,
//       body_jerk_as_falling_asleep: false,
//       night_sweat: false,
//       restless_leg_syndrome: false,
//       cracks: false,
//       fragile_skin: false,
//       wart: false,
//       msg_sensitivity: false,
//       wakeup_without_remember_dream: false,
//       small_bumps_on_back_arms: false,
//       irritates_eye: false,
//       nose_bleed: false,
//       bleeding_gum: false,
//     },
//     ardenal: {
//       night_person: false,
//       difficulty_in_sleep: false,
//       slow_starter: false,
//       trouble_calming_down: false,
//       blood_pressure: false,
//       headache: false,
//       feeling_wired: false,
//       clench: false,
//       calm_outside_trouble_inside: false,
//       worse_fatigue: false,
//       become_dizzy: false,
//       difficult_manipulative_correction: false,
//       pain_after_manipulative_correction: false,
//       arthritic_tendency: false,
//       crave_salty_food: false,
//       salt_food: false,
//       perspire_easily: false,
//       chronic_fatigue: false,
//       afternoon_yawning: false,
//       afternoon_headache: false,
//       asthma: false,
//       pain_in_innerside_of_knee: false,
//       sprain_ankles: false,
//       need_sunglass: false,
//       allergy: false,
//       // nose_bleeds: false, // ?
//       dizziness: false,
//     },
//     pituitary: {
//       height_over_6_6: false,
//       early_sexual_development: false,
//       increased_libido: false,
//       splitting_type_headache: false,
//       memory_failing: false,
//       tolerate_sugar: false,
//       height_under_4_10: false,
//       decreased_libido: false,
//       excessive_thirst: false,
//       weight_gain_around_waist: false,
//       menstrual_disorder: false,
//       delayed_sexual_development: false,
//       colitis: false,
//     },
//     thyroid: {
//       allergic_to_iodine: false,
//       difficulty_gaining_weight: false,
//       nervous: false,
//       inward_trembling: false,
//       flush_easily: false,
//       fast_pulse: false,
//       intolerance: false,
//       difficulty_losing_weight: false,
//       mentally_sluggish: false,
//       easily_fatigued: false,
//       sensitive_to_cold: false,
//       constipation: false,
//       excessive_hair_loss: false,
//       morning_headache: false,
//       Loss_of_lateral_of_eyebrow: false,
//       seasonal_sadness: false,
//     },
//     cardio: {
//       irregular_breathing: false,
//       discomfort: false,
//       sigh_frequently: false,
//       in_closed_room: false,
//       shortness_of_breath: false,
//       ankles_swell: false,
//       cough_at_night: false,
//       face_turns_red: false,
//       tightness_in_chest: false,
//       muscle_cramp: false,
//     },
//     kidney_bladder: {
//       pain_in_back: false,
//       dark_circle: false,
//       kidney_stone: false,
//       darked_urine: false,
//       urine_has_strong_odor: false,
//     },
//     immune_system: {
//       drippy_nose: false,
//       catch_colds: false,
//       mucus_producing_cough: false,
//       frequent_flu: false,
//       infections: false,
//       never_get_sick: false,
//       acne: false,
//       dermatitis: false,
//       cysts: false,
//       chronic_viral_condition: false,
//     },
//     women_only: {
//       depression_during_period: false,
//       mood_swings_with_period: false,
//       crave_chocolate: false,
//       breast_tenderness: false,
//       menstrual_flow: false,
//       scanty_blood: false,
//       occasional_skipped_period: false,
//       variations_in_menstrual_cycle: false,
//       endometriosis: false,
//       uterine_fibroid: false,
//       breast_fibroid: false,
//       painful_intercourse: false,
//       vaginal_discharge: false,
//       vaginal_dryness: false,
//       vaginal_itchiness: false,
//       tendency_to_gain_weight: false,
//       excess_facial: false,
//       hot_flashes: false,
//       night_sweats: false,
//       thinning_skin: false,
//     },
//     men_only: {
//       prostate_problem: false,
//       dribbling: false,
//       stop_urine_stream: false,
//       pain_with_urination: false,
//       walk_to_urine_at_night: false,
//       interruption_of_stream: false,
//       pain_inside_leg: false,
//       incomplete_bowel_evacuation: false,
//       decreased_sexual_function: false,
//     },
//   };
// }

// function getInitConditionData() {
//   return {
//     client_name: "",
//     add_adhd: false,
//     allergy: false,
//     aneurysms: false,
//     anxiety: false,
//     arrhythmias: false,
//     arteriosclerosis: false,
//     arthritis: false,
//     bloating: false,
//     cancer: false,
//     candida: false,
//     constipation: false,
//     depression: false,
//     diabetes: false,
//     diarrhea: false,
//     edema: false,
//     epilepsy: false,
//     fractures_not_healing: false,
//     gastritis: false,
//     headache: false,
//     heartattack: false,
//     high_blood_pressure: false,
//     hypoglycemia: false,
//     low_blood_pressure: false,
//     mood_swing: false,
//     spasms: false,
//     neuromuscular_disease: false,
//     osteoporosis: false,
//     pms_estrogen_dominant: false,
//     pms_low_estrogen: false,
//     poor_circulation: false,
//     kidney_impairment: false,
//     scoliosis: false,
//     ligament_problem: false,
//     plaque: false,
//     ulcer: false,
//   };
// }
export default {
  name: "HTMAResultsInput",
  components: {
    HTMAInput,
    // NAQ,
    // NaqHtmaForm,
    // ConditionForm,
    InputHTMAModal,
  },
  data() {
    return {
      step: 1,
      inputId: null,
      testId: null,
      naqId: null,
      naqHtmaId: null,
      conditionId: null,
      input: getInitInputData(),
      htma: getInitHtmaData(),
      // naq: getInitNaqData(),
      // naqHtma: getInitNaqHtmaData(),
      // condition: getInitConditionData(),

      save: {
        input: false,
        // naq: false,
        // condition: false,
      },
      showModal: false,
    };
  },
  computed: {
    ...mapGetters([
      "allInputHtma",
      "allNaq",
      "allNaqHtma",
      "allConditions",

      "singleInputHtma",
      "singleNaq",
      "singleNaqHtma",
      "singleCondition",
    ]),
  },
  methods: {
    ...mapActions([
      "addInputHtma",
      "addNaq",
      "addNaqHtma",
      "addCondition",

      "getSingleInputHtma",
      "getSingleNaq",
      "getSingleNaqHtma",
      "getSingleCondition",
    ]),
    async confirmSubmit(data) { 
      if (data) {
        this.showModal = false;
        console.log("confirm submit data ", data, this.save);

        console.log("input", this.input);
        // this.naq.client_name = this.input.client_name;
        // this.naqHtma.client_name = this.input.client_name;
        // this.condition.client_name = this.input.client_name;

        // console.log("naqhtma", this.naqHtma);
        // console.log("condition", this.condition);

        // saving input htma

        if (this.inputId) {
          console.log("this.inputId", this.inputId);
          console.log("selected test");
          this.testId = this.inputId;
        } else {
          console.log("input", this.input);
          console.log("htma", this.htma);
          this.input.htma_test_date = new Date(
            this.input.htma_test_date
          ).toISOString();

          let heavyMetals = [];
          for (var i = 0; i < 6; i++) {
            if (this.input.specific_heavy_metals[i] === null) {
              break;
            } else {
              heavyMetals.push(this.input.specific_heavy_metals[i]);
            }
          }
          this.input.specific_heavy_metals = heavyMetals;
          console.log(
            "specific_heavy_metals are",
            this.input.specific_heavy_metals
          );

          console.log("input htma data ", {
            input: this.input,
            htma: this.htma,
          });
          if (this.save.input) {
            // Input htma
            try {
              const inputHtmaResponse = await this.addInputHtma({
                input: this.input,
                htma: this.htma,
              });
              console.log(
                "InputHtmaResponse from add single test is ",
                inputHtmaResponse
              );
              this.testId = inputHtmaResponse.id;
              console.log("testid, ", this.testId);
            } catch (error) {
              console.log(error);
            }
            // }
          } else {
            // not saving input htma
            // sessionStorage.setItem(
            sessionStorage.setItem("input", JSON.stringify(this.input));
            sessionStorage.setItem("htma", JSON.stringify(this.htma));
            this.testId = 0;
          }
        }

        // Naq
        // if (this.naqId) {
        //   console.log("selected naqid will be", this.naqId);
        // } else {
        //   // saving naq and naq htma
        //   console.log("not selected previous naq");
        //   // this.naq.client_name = this.input.client_name;
        //   console.log("naq", this.naq);
        //   if (this.save.naq) {
        //     try {
        //       const naqResponse = await this.addNaq(this.naq);
        //       console.log("naq response ", naqResponse);
        //       this.naqId = naqResponse.naqs.id;
        //     } catch (error) {
        //       console.log(error);
        //     }
        //     console.log("naqid will be", this.naqId);
        //   } else {
        //     // not saving naq and naq+htma
        //     this.naq["created"] = new Date(
        //     this.input.htma_test_date
        //   ).toISOString();
        //     //  = this.input.htma_test_date;
        //     sessionStorage.setItem("naq", JSON.stringify(this.naq));
        //     this.naqId = 0;
        //   }
        // }
        // // Naq+Htma
        // if (this.naqHtmaId) {
        //   console.log("selected naqhtmaid will be", this.naqHtmaId);
        // } else {
        //   if (this.save.naq) {
        //     try {
        //       const naqHtmaResponse = await this.addNaqHtma(this.naqHtma);
        //       console.log("naq htma response ", naqHtmaResponse);
        //       this.naqHtmaId = naqHtmaResponse.diet.id;
        //     } catch (error) {
        //       console.log(error);
        //     }
        //     // }
        //   } else {
        //     // not saving naq and naq+htma
        //     this.naqHtma["created"] = new Date(
        //     this.input.htma_test_date
        //   ).toISOString();
        //     sessionStorage.setItem("naqHtma", JSON.stringify(this.naqHtma));
        //     this.naqHtmaId = 0;
        //   }
        // }

        // // Condition
        // if (this.conditionId) {
        //   console.log("selected condition id will be", this.conditionId);
        // } else {
        //   if (this.save.condition) {
        //     try {
        //       const conditionResponse = await this.addCondition(this.condition);
        //       console.log("condition response ", conditionResponse);
        //       this.conditionId = conditionResponse.id;
        //     } catch (error) {
        //       console.log(error);
        //     }
        //     // }
        //   } else {
        //     // not saving conditions
        //     this.condition["created"] = new Date(
        //     this.input.htma_test_date
        //   ).toISOString();
        //     sessionStorage.setItem("condition", JSON.stringify(this.condition));
        //     this.conditionId = 0;
        //   }
        // }

        this.$toastr.Add({
          name: "Success", // this is give you ability to use removeByName method
          msg: "Single Test has been submitted Successfully!", // Toast Message
          type: "success", // Toast type,
        });
        this.$router.push(
          `/htma-analysis?test=${this.testId}`
        );
      } else {
        this.showModal = false;
        console.log("confirm submit data ", data);
      }
    },
    // setShowModal(data){
    //   this.showModal = data
    // },
    setInput(data) {
      console.log("data comming", data);
      this.input = data;
      this.input.specific_heavy_metals = [null, null, null, null, null, null];
      this.input.practitioner_name = localStorage.getItem("instant_username")
        ? localStorage.getItem("instant_username")
        : data.practitioner_name;
    },
    setHTMA(data) {
      console.log("data comming", data);
      this.htma = data;
    },

    // getNextStep() {
    //   if (this.step < 4) {
    //     this.step++;
    //   } else {
    //     this.step = 1;
    //   }
    // },
    // getBackStep() {
    //   if (this.step > 1) {
    //     this.step--;
    //   }
    // },
    resetSearchInput() {
      console.log("reset");
      const practitioner_name = localStorage.getItem("instant_username")
        ? localStorage.getItem("instant_username")
        : "";
      this.inputId = null;
      Object.assign(this.input, getInitInputData());
      Object.assign(this.htma, getInitHtmaData());
      this.input.practitioner_name = practitioner_name;
    },
    // resetSearchNaq() {
    //   console.log("resetting naq ....");
    //   this.naqId = null;
    //   Object.assign(this.naq, getInitNaqData());
    // },
    // resetSearchNaqHtma() {
    //   console.log("resetting naqhtma ....");
    //   this.naqHtmaId = null;
    //   Object.assign(this.naqHtma, getInitNaqHtmaData());
    // },
    // resetSearchCondition() {
    //   console.log("resetting condition ....");
    //   this.conditionId = null;
    //   Object.assign(this.condition, getInitConditionData());
    // },
    async setInputId(value) {
      console.log("setInputId", value);
      this.inputId = value;

      await this.getSingleInputHtma(this.inputId);
      console.log("selected input htma", await this.singleInputHtma);
      this.singleInputHtmaData = await this.singleInputHtma;
      this.input = this.singleInputHtmaData[0];
      this.htma = this.singleInputHtmaData[1];
      this.input.htma_test_date = moment(
        this.singleInputHtmaData[0].htma_test_date
      ).format("YYYY-MM-DD");

      console.log(this.input.htma_test_date);
    },
    // async setNaqId(value) {
    //   this.naqId = value;
    //   await this.getSingleNaq(this.naqId);
    //   console.log("select naq is ", await this.singleNaq);
    //   this.naq = await this.singleNaq;
    // },
    // async setNaqHtmaId(value) {
    //   this.naqHtmaId = value;
    //   console.log("naq htma id ", this.naqHtmaId);
    //   await this.getSingleNaqHtma(this.naqHtmaId);
    //   // console.log("select naq htma is ", await this.singleNaqHtma);
    //   this.naqHtma = await this.singleNaqHtma;
    // },
    // async setConditionId(value) {
    //   this.conditionId = value;
    //   await this.getSingleCondition(this.conditionId);
    //   console.log("select Condition is ", await this.singleCondition);
    //   this.condition = await this.singleCondition;
    // },

    // submit data

    async submit() {
      console.log("submitttting...");

      this.showModal = true;

      // console.log("input", this.input);
      // this.naq.client_name = this.input.client_name;
      // this.naqHtma.client_name = this.input.client_name;
      // this.condition.client_name = this.input.client_name;

      // console.log("naqhtma", this.naqHtma);
      // console.log("condition", this.condition);

      // if (this.inputId) {
      //   console.log("this.inputId", this.inputId);
      //   console.log("selected test");
      //   this.testId = this.inputId;
      // } else {
      //   console.log("input", this.input);
      //   console.log("htma", this.htma);
      //   this.input.htma_test_date = new Date(
      //     this.input.htma_test_date
      //   ).toISOString();

      //   let heavyMetals = [];
      //   for (var i = 0; i < 6; i++) {
      //     if (this.input.specific_heavy_metals[i] === null) {
      //       break;
      //     } else {
      //       heavyMetals.push(this.input.specific_heavy_metals[i]);
      //     }
      //   }
      //   this.input.specific_heavy_metals = heavyMetals;
      //   console.log(
      //     "specific_heavy_metals are",
      //     this.input.specific_heavy_metals
      //   );

      //   console.log("input htma data ", {
      //     input: this.input,
      //     htma: this.htma,
      //   });
      //   // Input htma
      //   try {
      //     const inputHtmaResponse = await this.addInputHtma({
      //       input: this.input,
      //       htma: this.htma,
      //     });
      //     console.log(
      //       "InputHtmaResponse from add single test is ",
      //       inputHtmaResponse
      //     );
      //     this.testId = inputHtmaResponse.id;
      //     console.log("testid, ", this.testId);
      //   } catch (error) {
      //     console.log(error);
      //   }
      // }
      // // Naq
      // if (this.naqId) {
      //   console.log("selected naqid will be", this.naqId);
      // } else {
      //   console.log("not selected previous naq");
      //   this.naq.client_name = this.input.client_name;
      //   console.log("naq", this.naq);
      //   try {
      //     const naqResponse = await this.addNaq(this.naq);
      //     console.log("naq response ", naqResponse);
      //     this.naqId = naqResponse.naqs.id;
      //   } catch (error) {
      //     console.log(error);
      //   }
      //   console.log("naqid will be", this.naqId);
      // }
      // // Naq+Htma
      // if (this.naqHtmaId) {
      //   console.log("selected naqhtmaid will be", this.naqHtmaId);
      // } else {
      //   try {
      //     const naqHtmaResponse = await this.addNaqHtma(this.naqHtma);
      //     console.log("naq htma response ", naqHtmaResponse);
      //     this.naqHtmaId = naqHtmaResponse.diet.id;
      //   } catch (error) {
      //     console.log(error);
      //   }
      // }
      // // Condition
      // if (this.conditionId) {
      //   console.log("selected condition id will be", this.conditionId);
      // } else {
      //   try {
      //     const conditionResponse = await this.addCondition(this.condition);
      //     console.log("condition response ", conditionResponse);
      //     this.conditionId = conditionResponse.id;
      //   } catch (error) {
      //     console.log(error);
      //   }
      // }
      // this.$toastr.Add({
      //   name: "Success", // this is give you ability to use removeByName method
      //   msg: "Single Test has been submitted Successfully!", // Toast Message
      //   type: "success", // Toast type,
      // });
      // this.$router.push(
      //   `/single-test-analysis?test=${this.testId}&naq=${this.naqId}&naqhtma=${this.naqHtmaId}&condition=${this.conditionId}`
      // );
    },
  },
  async mounted() {
    this.input.practitioner_name = localStorage.getItem("instant_username")
      ? localStorage.getItem("instant_username")
      : "";
    // sessionStorage.setItem('data','abcc')
    // this.input.htma_test_date = moment(
    //   Date.now()
    // ).format("YYYY-MM-DD");
  },
};
</script>
<style>
.b-toaster {
  position: absolute;
  max-width: 350px;
  width: 100%;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
}
</style>
