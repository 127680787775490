import { getReq, postReq, deleteReq, putReq } from "../../../services/api";

const state = {
  allConditions:[],
  conditionReport:[],
  singleCondition:[],

};

const getters = {
  allConditions: (state) => state.allConditions,
  conditionReport: (state) => state.conditionReport,
  singleCondition: (state) => state.singleCondition,

};


const actions = {
    // Conditions

  async getAllCondition({ commit },) {
    try {
      let allCondition = await getReq(`condition/`);
      commit("setAllConditions", allCondition);
      
    } catch (error) {
      console.log(error)
      console.log(error.response);
      throw error.response;
    }
  },

  async getSingleCondition({ commit },id) {
    try {
      let singleCondition = await getReq(`condition/${id}/`);
      commit("setSingleCondition", singleCondition);
      
    } catch (error) {
      console.log(error)
      console.log(error.response);
      throw error.response;
    }
  },
  
   async addCondition(_,payload) {
    try {
       const newCondition = await postReq(`condition/`,payload );
       return newCondition
     
    } catch (error) {
      console.log(error.response.data);
      throw error.response.data;
    }
  },
  
  // async updateCondition({ commit }, { id, payload }) {
  //   try {
  //     console.log(commit)
  //     console.log(payload)
  //     const updatedData = await patchReq("condition/", id, payload);
  //     if (updatedData) {
  //         console.log(updatedData);
  //     }
  //   } catch (e) {
  //       console.log(e)
  //       if(!e.response){
  //           throw "No network connection"
  //       }
  //       const errors = e.response.data;
  //       console.log(errors)
  //       throw errors;
  //   }
  // },

  async updateCondition({ commit }, { id, payload }) {
    try {
      console.log(commit)
      console.log(payload)
      const updatedData = await putReq("condition/", id, payload);
      if (updatedData) {
          console.log(updatedData);
      }
      return updatedData
    } catch (e) {
        console.log(e)
        if(!e.response){
            throw "No network connection"
        }
        const errors = e.response.data;
        console.log(errors)
        throw errors;
    }
  },
  
  async deleteCondition(_, id) {
    try {
        return await deleteReq("condition", id);
    } catch (e) {
        if(!e.response){
            throw "No network connection"
        }
        const errors = e.response.data;
        console.log(errors)
        throw errors;
    }
  },

  
  // Condition Unsaved Api

  async getConditionReportUnsaved({commit},payload) {
    try {
       const conditionReport = await postReq(`condition-unsaved/`,payload );
      commit("setConditionReport", conditionReport);
       return conditionReport 
     
    } catch (error) {
      console.log(error.response.data);
      throw error.response.data;
    }
  },

  // Condition Report

  async getConditionReport({ commit },id) {
    try {
      let conditionReport = await getReq(`condition-report/${id}/`);
      commit("setConditionReport", conditionReport);
      
    } catch (error) {
      console.log(error)
      console.log(error.response);
      throw error.response;
    }
  },

};

const mutations = {
  setAllConditions: (state, allConditions) => (state.allConditions = allConditions),
  setConditionReport: (state, conditionReport) => (state.conditionReport = conditionReport),

  setSingleCondition: (state, singleCondition) => (state.singleCondition = singleCondition),

};

export default {
  state,
  getters,
  actions,
  mutations,
};
