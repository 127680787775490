<template>
  <div id="">
    <div v-if="loading" id="preloader">
      <div class="loader3">
        <span></span>
        <span></span>
      </div>
    </div>
    <main>
      <div class="main_content_wrapper">
        <br />
        <h2 class="mx-2 pt-2 mb-2 text-center">
          Hair Tissue Mineral Analysis Summary for
          {{ clientReportData.client_name }}
        </h2>
        <h3 class="mx-2 pt-2 mb-2 text-center">
          Prepared by {{ clientReportData.practitioner_name }} on
          {{ dateValue }}
        </h3>
        <hr />
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 pt_20">
              <div
                class="middle_content_box"
                style="height: 100% !important;  width:auto; w-100"
              >
                <ul class="middle_content_list">
                  <li>
                    Hair is used for mineral testing because of its very nature.
                    Hair is formed from clusters of specialized cells that make
                    up the hair follicle. During the growth phase, the hair is
                    exposed to the internal environment such as blood, lymph,
                    and extracellular fluids. As the hair continues to grow and
                    reach the surface of the skin, the outer layers harden,
                    locking in the metabolic activity that has occurred during
                    this time.
                  </li>
                  <li>
                    The precise analytical method of determining the levels of
                    minerals in the hair is a highly sophisticated process using
                    spectroscopy. This method was developed ~75 years ago and
                    continues to be the standard way to test for minerals in
                    geology, agriculture, plant, animal, and human tissue
                    studies. When performed to the exact standards and
                    interpreted correctly, it may be used as a screening and aid
                    for determining mineral deficiencies, excesses, and/or
                    imbalances. HTMA provides you and your health care
                    professional with an economical and sensitive indicator of
                    the long-term effects of diet, stress, toxic metal exposure
                    and their effects on your mineral balance that is difficult
                    to obtain through other clinical tests.
                  </li>
                  <li>
                    It is important to determine your mineral status because
                    minerals are absolutely critical for life and abundant
                    health. They are involved in and are necessary for cellular
                    metabolism, structural support, nerve conduction, muscular
                    activity, immune functions, antioxidant and endocrine
                    activity, enzyme functions, water and acid/alkaline balance
                    and even DNA function.
                  </li>
                  <li>
                    Many factors can affect mineral nutrition, such as; food
                    preparation, dietary habits, genetic and metabolic
                    disorders, disease, medications, stress, environmental
                    factors, as well as exposure to heavy metals. Rarely does a
                    single nutrient deficiency exist in a person today. Multiple
                    nutritional imbalances however are quite common,
                    contributing to an increased incidence of adverse health
                    conditions. In fact, it is estimated that mild and
                    sub-clinical nutritional imbalances are up to ten times more
                    common than nutritional deficiency alone. ~ Trace Elements
                    Inc.
                  </li>
                  <li style="font-weight: bold">
                    The laboratory test results and the comprehensive report
                    that follows should not be construed as diagnostic medical
                    advice.
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12 pt_20">
              <div
                class="middle_content_box"
                style="height: 100% !important;  width:auto; w-100"
              >
                <table>
                  <tr>
                    <td>
                      <h3 class="title_md" id="SO_D2">Metabolic Type</h3>
                    </td>
                  </tr>
                </table>
                <ul class="middle_content_list"> 
                  <li>
                    The interpretation of your hair analysis depends upon
                    developing a "metabolic blueprint" of how the body is
                    responding to stress. The ability to determine the stage of
                    stress and the oxidation rate from a hair analysis makes it
                    possible to assess the likelihood of many conditions and
                    guide correction based upon metabolic imbalances. A thorough
                    interpretation of the test results also requires
                    identification of mineral levels, ratios, and metabolic
                    patterns.
                  </li>
                  <li>
                    The adrenal and thyroid glands are the main energy-producing
                    organs in the body and they determine the rate of metabolism
                    or oxidation rate. They work together to release simple
                    sugars from the liver and then process them into energy.
                    These glands need to be functioning at optimal levels to
                    have the maximum amount of energy possible. The adrenal
                    glands are also responsible for providing extra energy when
                    needed. In an emergency situation, it is the adrenal glands
                    that release the hormone adrenaline, which generates a
                    sudden increase in energy. They are also required for
                    maintaining normal blood pressure and blood sugar, combating
                    inflammation, carbohydrate metabolism, and to activate the
                    body's response to stress.
                  </li>
                  <li>
                    The goal with the oxidation rate is always balance. Someone
                    who is excessively Fast will have just as many health issues
                    as someone who is excessively Slow. When you're too Fast,
                    you're basically burning through your minerals too quickly.
                    When you're Slow, everything can get clogged up because your
                    system is moving too slowly. We are looking to be somewhere
                    in the middle. We want to achieve balance.
                  </li>
                  <br />
                  <li style="color: #884c42; font-weight: bold">
                    Your metabolic type is
                    {{ clientReportData.metabolic_type }}:
                  </li>
                  <li
                    style="color: #884c42"
                    v-for="value in clientReportData.metabolic_1.filter(
                      (test) => test !== ''
                    )"
                    :key="value.index"
                  >
                    {{ value }}
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12 pt_20">
              <div
                class="middle_content_box"
                style="height: 100% !important;  width:auto; w-100"
              >
                <table>
                  <tr>
                    <td>
                      <h3 class="title_md" id="SO_D2">Significant Ratios</h3>
                      <p>
                        Mineral Ratios provide the most useful information in
                        determining nutritional deficiencies and excesses.
                        Analyzing the ratios provides significantly more
                        information about the body than looking at each mineral
                        level alone. The ratios represent homeostatic balances
                        and can be indicative of disease trends (not diagnostic,
                        but associated based on research). Ratios can also be
                        predictive of future metabolic dysfunctions or hidden
                        metabolic dysfunctions. They are also used to chart
                        progress on re-test reports.
                      </p>
                    </td>
                  </tr>
                </table>
                <br /><br />
                <ul class="middle_content_list">
                  <li>
                    <span style="font-weight: bold"
                      >Calcium to Phosphorus (Ca/P)
                    </span>
                    <br />
                    <span>“Autonomic Balance Ratio” </span><br />
                    <span>Range: 1.6 - 3.6 </span><br />
                    <span>Optimal: 2.6 </span><br />
                  </li>
                  <!-- <br> -->
                  <li>
                    The calcium to phosphorus ratio indicates the autonomic
                    state (sympathetic or parasympathetic) and the metabolic
                    state of the body. Calcium is controlled by the
                    parasympathetic branch and phosphorus is controlled by the
                    sympathetic branch.
                  </li>
                  <li>Sympathetic: “Fight or Flight”</li>
                  <li>
                    This side of the nervous system activates the brain and
                    muscles and is the "speed-up" system. It causes expenditure
                    of energy and is catabolic. The sympathetic nervous system
                    becomes activated when you're having a stress response,
                    which can be anything from being in a heated meeting to
                    seeing a bear!
                  </li>
                  <li>Parasympathetic: “Rest and Digest”</li>
                  <li>
                    This side of the nervous system is more relaxed and calm. It
                    is conserving, nourishing, restful, and nurturing. It is
                    anabolic and regenerates the body. Ideally, we want to be
                    spending most of our time in this state, unless the body has
                    defaulted to being in an unhealthy parasympathetic state
                    after being exposed to prolonged chronic stress and has
                    trouble “speeding up” when needed.
                  </li>
                  <li>
                    The body balances these two systems and the minerals
                    associated with them. Animals are quite good at this.
                    Healthy cats or dogs run fast and fight hard but are equally
                    comfortable spending the afternoon sleeping. Humans,
                    however, have more difficulty balancing the autonomic
                    system. Many people compulsively fight or run all the time,
                    others are in a give-up mode where they are unable to keep
                    fighting. They are often depressed and feel like victims.
                  </li>
                  <li>
                    The autonomic state is important as it is closely related to
                    the activity of the adrenal and thyroid glands. We fluctuate
                    between being in a sympathetic or parasympathetic state, but
                    genetically we tend to be more dominant on one side or the
                    other. Causes of autonomic imbalance include chemical
                    imbalances one is born with, poor diet, use of stimulants,
                    negative thinking, structural imbalances, physical or
                    emotional traumas, and exposure to toxic metals and
                    chemicals that affect the hypothalamus and pituitary gland.
                  </li>
                  <li style="color: #884c42; font-weight: bold">
                    {{ clientReportData.client_name }} -
                    {{ clientReportData.ca_p }}
                  </li>
                  <li
                    style="color: #884c42"
                    v-for="value in clientReportData.cap_result.filter(
                      (test) => test !== ''
                    )"
                    :key="value.index"
                  >
                    {{ value }}
                  </li>
                </ul>
                <br /><br />

                <ul class="middle_content_list">
                  <li>
                    <span style="font-weight: bold"
                      >Sodium to Potassium (Na/K)
                    </span>
                    <br />
                    <span>“Stress Ratio” </span><br />
                    <span>Range: 1.4 - 3.4 </span><br />
                    <span>Optimal: 2.6 </span><br />
                  </li>
                  <li>
                    The sodium to potassium ratio is one of the most important
                    ratios to evaluate because there is a sodium/potassium pump
                    that controls movement in and out of the cell on every
                    single cell membrane in the body. Nothing exits or gets into
                    the cell without the use of the sodium/ potassium pump.
                  </li>
                  <li>
                    This ratio tells us about the electrical potential of the
                    cells, kidney, liver, and adrenal function. Sodium is linked
                    to aldosterone and potassium is linked to cortisone.
                  </li>
                  <li style="color: #884c42; font-weight: bold">
                    {{ clientReportData.client_name }} -
                    {{ clientReportData.na_k }}
                  </li>
                  <li
                    style="color: #884c42"
                    v-for="value in clientReportData.nak_result.filter(
                      (test) => test !== ''
                    )"
                    :key="value.index"
                  >
                    {{ value }}
                  </li>
                </ul>
                <br /><br />

                <ul class="middle_content_list">
                  <li>
                    <span style="font-weight: bold"
                      >Calcium to Potassium (Ca/K)
                    </span>
                    <br />
                    <span>“Thyroid Ratio” </span><br />
                    <span>Range: 2.2 - 6.2 </span><br />
                    <span>Optimal: 4.2 </span><br />
                  </li>
                  <li>
                    The thyroid gland is one of the major glands that regulates
                    metabolic rate in the body. Thyroid hormone controls calcium
                    in the body via the production of calcitonin. The higher the
                    calcium, the lower the thyroid gland activity. Potassium is
                    associated with sensitivity of the tissues to thyroid
                    hormone. When potassium is low, thyroid hormone is poorly
                    utilized.
                  </li>
                  <li>
                    When the thyroid ratios are abnormal, the efficiency of
                    energy production in the body decreases. It is like an
                    engine that is turning too slow or too fast - power output
                    declines.
                  </li>
                  <li>
                    HTMA results do not always correlate with blood thyroid
                    tests because HTMA is a tissue test. Often blood tests will
                    be normal, but hair analysis will indicate impaired thyroid
                    function. For nutritional correction, it is prudent to
                    follow the hair analysis indication.
                  </li>
                  <li style="color: #884c42; font-weight: bold">
                    {{ clientReportData.client_name }} -
                    {{ clientReportData.cak }}
                  </li>
                  <li
                    style="color: #884c42"
                    v-for="value in clientReportData.cak_result.filter(
                      (test) => test !== ''
                    )"
                    :key="value.index"
                  >
                    {{ value }}
                  </li>
                </ul>
                <br /><br />
                <ul class="middle_content_list">
                  <li>
                    <span style="font-weight: bold"
                      >Zinc to Copper (Zn/Cu)
                    </span>
                    <br />
                    <span>“Hormone and Energy Regulation Ratio” </span><br />
                    <span>Range: 4.0 - 12.0 </span><br />
                    <span>Optimal: 8 </span><br />
                  </li>
                  <li>
                    The zinc to copper ratio is a much more effective method of
                    evaluating zinc and copper levels than considering either
                    copper or zinc levels alone.
                  </li>
                  <li>
                    This ratio can be indicative of copper deficiency or copper
                    unavailability.
                  </li>
                  <li>
                    Zinc roughly correlates with progesterone in women and
                    testosterone in men, while copper roughly correlates with
                    estrogen in men and women.
                  </li>
                  <li style="color: #884c42; font-weight: bold">
                    {{ clientReportData.client_name }} -
                    {{ clientReportData.zn_cu }}
                  </li>
                  <li
                    style="color: #884c42"
                    v-for="value in clientReportData.zn_cu_result.filter(
                      (test) => test !== ''
                    )"
                    :key="value.index"
                  >
                    {{ value }}
                  </li>
                </ul>
                <br /><br />
                <ul class="middle_content_list">
                  <li>
                    <span style="font-weight: bold"
                      >Sodium to Magnesium (Na/Mg)
                    </span>
                    <br />
                    <span>“Adrenal Health Ratio” </span><br />
                    <span>Range: 2.0 - 6.0 </span><br />
                    <span>Optimal: 4.0 </span><br />
                  </li>
                  <li>
                    Aldosterone, a mineralocorticoid adrenal hormone, regulates
                    retention of sodium in the body. In general, the higher the
                    sodium level, the higher the aldosterone level.
                  </li>
                  <li>
                    This ratio is also a measure of energy output, because the
                    adrenal glands are a major regulator (along with the thyroid
                    gland) of the rate of metabolism.
                  </li>
                  <li>
                    This ratio is a tissue reading and will often not match
                    blood tests for adrenal hormones. Usually the blood tests
                    will be normal, but the tissue mineral test will show
                    abnormal adrenal function. Symptoms, however, tend to
                    correlate well with the hair analysis.
                  </li>
                  <li style="color: #884c42; font-weight: bold">
                    {{ clientReportData.client_name }} -
                    {{ clientReportData.na_mg }}
                  </li>
                  <li
                    style="color: #884c42"
                    v-for="value in clientReportData.na_mg_result.filter(
                      (test) => test !== ''
                    )"
                    :key="value.index"
                  >
                    {{ value }}
                  </li>
                </ul>
                <br /><br />
                <ul class="middle_content_list">
                  <li>
                    <span style="font-weight: bold"
                      >Calcium to Magnesium (Ca/Mg)
                    </span>
                    <br />
                    <span>“Blood Sugar Ratio” </span><br />
                    <span>Range: 3.0 - 11.0 </span><br />
                    <span>Optimal: 7.0 </span><br />
                  </li>
                  <li>
                    Calcium is required for the release of insulin from the
                    pancreas. Magnesium is necessary for both the action of
                    insulin and the manufacture of insulin.
                  </li>

                  <li style="color: #884c42; font-weight: bold">
                    {{ clientReportData.client_name }} -
                    {{ clientReportData.ca_mg }}
                  </li>
                  <li
                    style="color: #884c42"
                    v-for="value in clientReportData.ca_mg_result.filter(
                      (test) => test !== ''
                    )"
                    :key="value.index"
                  >
                    {{ value }}
                  </li>

                  <li>
                    <span style="font-weight: bold"
                      >Iron to Copper (Fe/Cu)
                    </span>
                    <br />
                    <span>“Infection Indication Ratio” </span><br />
                    <span>Range: .2 - 1.6 </span><br />
                    <span>Optimal: .9 </span><br />
                  </li>
                  <li>
                    The iron to copper ratio indicates if your body tends toward
                    viral infections or bacterial infections.
                  </li>

                  <li style="color: #884c42; font-weight: bold">
                    {{ clientReportData.client_name }} -
                    {{ clientReportData.fe_cu }}
                  </li>
                  <li
                    style="color: #884c42"
                    v-for="value in clientReportData.fe_cu_result.filter(
                      (test) => test !== ''
                    )"
                    :key="value.index"
                  >
                    {{ value }}
                  </li>
                </ul>
                <br /><br />
                <ul class="middle_content_list">
                  <li>
                    <span style="font-weight: bold"
                      >Copper to Molybdenum (Cu/Mo)
                    </span>
                    <br />
                    <span>“Copper Imbalance Ratio” </span><br />
                    <span>Range: 400 - 850 </span><br />
                    <span>Optimal: 625 </span><br />
                  </li>
                  <li>
                    The copper to molybdenum ratio is an important marker for
                    copper imbalance.
                  </li>

                  <li style="color: #884c42; font-weight: bold">
                    {{ clientReportData.client_name }} -
                    {{ clientReportData.cu_mo }}
                  </li>
                  <li
                    style="color: #884c42"
                    v-for="value in clientReportData.cu_mo_result.filter(
                      (test) => test !== ''
                    )"
                    :key="value.index"
                  >
                    {{ value }}
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12 pt_20">
              <div
                class="middle_content_box"
                style="height: 100% !important;  width:auto; w-100"
              >
                <table>
                  <tr>
                    <td>
                      <h3 class="title_md" id="SO_D2">Heavy Metals</h3>
                      <p>
                        Heavy metals are a group of minerals with no known
                        physiological or biological function in the human body
                        and are known to be harmful. They are naturally
                        occurring, but the industrial revolution has exposed us
                        to increasing amounts of heavy metals. They are a major
                        cause of illness, aging, and genetic defects.
                      </p>
                      <p>
                        The body uses what it can when it needs minerals. Heavy
                        metals can be used in place of mineral deficiencies and
                        displace the trace elements. This can potentially skew
                        the readings of the minerals until the toxic metals are
                        removed, which can take months or even years in some
                        cases.
                      </p>
                      <p>
                        Low levels of metals on HTMA only tells us that the
                        amount of toxic metal deposited in the hair was low. Low
                        levels do not indicate that the body doesn’t have any
                        metals in their body. It should be assumed that toxic
                        metals are present - however, most are hidden deep in
                        the body organs and tissues.
                      </p>
                      <p>
                        This report only focuses on the heavy metals that are
                        present in your hair currently. Please review the
                        potential sources for each heavy metal exposure and try
                        to limit them.
                      </p>
                    </td>
                  </tr>
                </table>
                <br />
                <ul class="middle_content_list">
                  <span
                    v-for="value in clientReportData.heavy_metallist.filter(
                      (test) => test !== ''
                    )"
                    :key="value.index"
                  >
                    <li style="color: #884c42; font-weight: bold">
                      {{ value.metal_name }}
                    </li>
                    <li
                      style="color: #884c42"
                      v-for="desc in value.metal_desc.filter(
                        (test) => test !== ''
                      )"
                      :key="desc.index"
                    >
                      {{ desc }}
                    </li>
                    <br />
                  </span>
                </ul>
              </div>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12 pt_20">
              <div
                class="middle_content_box"
                style="height: 100% !important;  width:auto; w-100"
              >
                <table>
                  <tr>
                    <td>
                      <h3 class="title_md" id="SO_D2">Single Minerals</h3>
                      <p>
                        A very high or a very low hair level may indicate
                        bio-unavailability of the mineral.
                      </p>
                      <p>
                        This simply refers to the ability of the body to utilize
                        a mineral or incorporate it into an enzyme or other
                        binding site. Bio-unavailability is important to
                        understand so you don't simply take each mineral reading
                        at face value.
                      </p>
                      <p>
                        Additionally, it is important to understand that a hair
                        mineral level may be elevated because it is being
                        displaced by another mineral or heavy metal. For
                        example, arsenic can displace magnesium, calcium, and
                        other minerals. Often, arsenic will also raise the
                        sodium level which in turn, affects the sodium/potassium
                        ratio.
                      </p>
                    </td>
                  </tr>
                </table>
                <br /><br />
                <ul class="middle_content_list">
                  <li>
                    <span style="font-weight: bold">Calcium </span>
                    <br />
                    <span>Range: 22 - 97 </span><br />
                    <span>Optimal: 42 </span><br />
                  </li>
                  <li>
                    <span>Functions of Calcium: </span><br />
                    <span>-The primary structural element</span><br />
                    <span
                      >-90%+ of calcium is in the bone and teeth, which act as
                      reservoirs that calcium can be withdrawn as required for
                      extra-skeletal functions
                    </span>
                    <br />
                    <span
                      >-Regulated in tissues and serum at the expense of
                      skeletal structures</span
                    ><br />
                    <span>-Controls the nervous system</span><br />
                    <span>-Responsible for muscle contractions</span><br />
                    <span>-Maintains the pH balance of the body </span><br />
                    <span>-Reduces lactic acid build up</span><br />
                    <span>-Regulated by the parathyroid and kidneys</span><br />
                    <span>-Inhibits thyroid hormone</span><br />
                    <span
                      >-Insulin release is dependent on calcium being
                      available</span
                    ><br />
                  </li>
                  <li style="color: #884c42; font-weight: bold">
                    {{ clientReportData.client_name }} -
                    {{ clientReportData.calcium }}
                  </li>
                  <li
                    style="color: #884c42"
                    v-for="value in clientReportData.calcium_result.filter(
                      (test) => test !== ''
                    )"
                    :key="value.index"
                  >
                    {{ value }}
                  </li>
                </ul>
                <br /><br />
                <ul class="middle_content_list">
                  <li>
                    <span style="font-weight: bold">Magnesium </span>
                    <br />
                    <span>Range: 2.0 - 11.0 </span><br />
                    <span>Optimal: 6 </span><br />
                  </li>
                  <li>
                    <span>Functions of Magnesium: </span><br />
                    <span
                      >-Intracellular mineral & key element in cellular
                      metabolism </span
                    ><br />
                    <span
                      >-Needed for both Glycolysis & the Citric Acid Cycle
                    </span>
                    <br />
                    <span
                      >-Needed for 500 different critical enzyme reactions</span
                    ><br />
                    <span
                      >-Regulates sugar metabolism & involved in energy
                      production</span
                    ><br />
                    <span
                      >-Highest concentration are found in muscles, liver, heart
                      and pancreas</span
                    ><br />
                    <span>-Cell membrane permeability</span><br />
                    <span>-Relaxation of the muscle & reduces inflammation</span
                    ><br />
                    <span>-The "heart mineral"</span><br />
                  </li>
                  <li style="color: #884c42; font-weight: bold">
                    {{ clientReportData.client_name }} -
                    {{ clientReportData.magnesium }}
                  </li>
                  <li
                    style="color: #884c42"
                    v-for="value in clientReportData.magnesium_result.filter(
                      (test) => test !== ''
                    )"
                    :key="value.index"
                  >
                    {{ value }}
                  </li>
                </ul>
                <br /><br />
                <ul class="middle_content_list">
                  <li>
                    <span style="font-weight: bold">Sodium </span>
                    <br />
                    <span>Range: 4 - 36 </span><br />
                    <span>Optimal: 25 </span><br />
                  </li>
                  <li>
                    <span>Functions of sodium: </span><br />
                    <span
                      >-Major regulator of the fluid balance, pH and other
                      fluid-related aspects of the body; the body's great
                      solvent. </span
                    ><br />
                    <span>-Regulated by the adrenal glands (aldosterone) </span>
                    <br />
                    <span
                      >-Regulates the blood pressure & increased heart rate </span
                    ><br />
                    <span>-Influences stomach acid levels </span><br />
                    <span>-Influences cell membrane permeability</span><br />
                  </li>
                  <li style="color: #884c42; font-weight: bold">
                    {{ clientReportData.client_name }} -
                    {{ clientReportData.sodium }}
                  </li>
                  <li
                    style="color: #884c42"
                    v-for="value in clientReportData.sodium_result.filter(
                      (test) => test !== ''
                    )"
                    :key="value.index"
                  >
                    {{ value }}
                  </li>
                </ul>
                <br /><br />
                <ul class="middle_content_list">
                  <li>
                    <span style="font-weight: bold">Potassium </span>
                    <br />
                    <span>Range: 2 - 24 </span><br />
                    <span>Optimal: 10 </span><br />
                  </li>
                  <li>
                    <span>Functions of potassium: </span><br />
                    <span>-A primary solvent mineral </span><br />
                    <span>-Intracellular mineral</span>
                    <br />
                    <span>-Regulates the blood pressure with sodium</span><br />
                    <span>-Maintains fluid balance & pH level of the body</span
                    ><br />
                    <span>-Influences cell membrane permeability</span><br />
                    <span>-Sensitizes the cell to thyroid hormone</span><br />
                    <span>-Nerve conduction of the heart </span><br />
                    <span>-Lowers heart rate & dilates arteries</span><br />
                    <span
                      >-Regulates the cell membrane potential, cell
                      permeability, sodium pump action, muscle contraction and
                      relaxation, and nerve impulse conduction</span
                    ><br />
                  </li>
                  <li style="color: #884c42; font-weight: bold">
                    {{ clientReportData.client_name }} -
                    {{ clientReportData.potassium }}
                  </li>
                  <li
                    style="color: #884c42"
                    v-for="value in clientReportData.potassium_result.filter(
                      (test) => test !== ''
                    )"
                    :key="value.index"
                  >
                    {{ value }}
                  </li>
                </ul>
                <br /><br />
                <ul class="middle_content_list">
                  <li>
                    <span style="font-weight: bold">Copper </span>
                    <br />
                    <span>Range: .9 - 3.9 </span><br />
                    <span>Optimal: 2.2 </span><br />
                  </li>
                  <li>
                    <span>Functions of Copper: </span><br />
                    <span>-Electron transport chain </span><br />
                    <span>-Hemoglobin synthesis</span>
                    <br />
                    <span>-Neurotransmitter activity</span><br />
                    <span>-Immune system health</span><br />
                    <span>-Cardiovascular health</span><br />
                    <span>-Synthesis of collagen and elastin</span><br />
                    <span>-Iron availability</span><br />
                    <span>-Anti-fungal, anti-mold, anti-bacterial</span><br />
                  </li>
                  <li>
                    The highest level of copper is found in the liver and brain.
                    Copper levels must always be assessed with other mineral
                    relationships and history of exposure. It is a very
                    problematic mineral today. Copper transport requires strong
                    adrenal function and removal from the body requires strong
                    bile function.
                  </li>
                  <li style="color: #884c42; font-weight: bold">
                    {{ clientReportData.client_name }} -
                    {{ clientReportData.copper }}
                  </li>
                  <li
                    style="color: #884c42"
                    v-for="value in clientReportData.copper_result.filter(
                      (test) => test !== ''
                    )"
                    :key="value.index"
                  >
                    {{ value }}
                  </li>
                </ul>
                <br /><br />
                <ul class="middle_content_list">
                  <li>
                    <span style="font-weight: bold">Zinc </span>
                    <br />
                    <span>Range: 10 - 21</span><br />
                    <span>Optimal: 18 </span><br />
                  </li>
                  <li>
                    <span>Functions of zinc: </span><br />
                    <span>-Protein synthesis</span><br />
                    <span>-Male reproductive health</span>
                    <br />
                    <span>-HCL, digestive enzyme, & bile production</span><br />
                    <span>-Insulin production & secretion</span><br />
                    <span>-Immune system health</span><br />
                    <span>-Brain development</span><br />
                    <span>-Hair, skin, nail, & eye health</span><br />
                  </li>
                  <li>
                    Zinc is the balancing stabilizing mineral and it is
                    anti-viral and immune supportive.
                  </li>

                  <li style="color: #884c42; font-weight: bold">
                    {{ clientReportData.client_name }} -
                    {{ clientReportData.zinc }}
                  </li>
                  <li
                    style="color: #884c42"
                    v-for="value in clientReportData.zinc_result.filter(
                      (test) => test !== ''
                    )"
                    :key="value.index"
                  >
                    {{ value }}
                  </li>
                </ul>
                <br /><br />
                <ul class="middle_content_list">
                  <li>
                    <span style="font-weight: bold">Phosphorus </span>
                    <br />
                    <span>Range: 11 - 20 </span><br />
                    <span>Optimal: 16 </span><br />
                  </li>
                  <li>
                    <span>Functions of phosphorus: </span><br />
                    <span>-Stimulatory mineral that makes up ATP</span><br />
                    <span
                      >-Important for growth & development, sympathetic nervous
                      system -Cell membrane function, protein synthesis, bone
                      formation</span
                    >
                    <br />
                  </li>
                  <li>
                    This is the 'power' mineral or digestive fire mineral.
                  </li>
                  <li>
                    Phosphorus is an essential mineral that is involved in
                    protein synthesis and energy production within the cells.
                    All proteins contain phosphorus and thus are a significant
                    source of organic phosphorus. The hair tissue mineral level
                    of phosphorus is often associated with the adequacy of
                    protein synthesis in the body. This depends on the diet,
                    lifestyle, condition of the intestinal tract and liver and
                    the levels of other nutritional minerals such as zinc and
                    copper.
                  </li>

                  <li style="color: #884c42; font-weight: bold">
                    {{ clientReportData.client_name }} -
                    {{ clientReportData.phosphorus }}
                  </li>
                  <li
                    style="color: #884c42"
                    v-for="value in clientReportData.phosphorus_result.filter(
                      (test) => test !== ''
                    )"
                    :key="value.index"
                  >
                    {{ value }}
                  </li>
                </ul>
                <br /><br />
                <ul class="middle_content_list">
                  <li>
                    <span style="font-weight: bold">Iron </span>
                    <br />
                    <span>Range: .5 - 1.6 </span><br />
                    <span>Optimal: 1.1 </span><br />
                  </li>
                  <li>
                    <span>Functions of Iron: </span><br />
                    <span>- Required for production of hemoglobin</span><br />
                    <span>- Energy production</span>
                    <span>- Immune system efficiency</span>
                    <br />
                  </li>
                  <li>
                    We do not interpret hair iron levels alone; it is best to
                    correlate with blood chemistry markers.
                  </li>
                  <li style="color: #884c42; font-weight: bold">
                    {{ clientReportData.client_name }} -
                    {{ clientReportData.iron }}
                  </li>
                  <li
                    style="color: #884c42"
                    v-for="value in clientReportData.iron_result.filter(
                      (test) => test !== ''
                    )"
                    :key="value.index"
                  >
                    {{ value }}
                  </li>
                </ul>
                <br /><br />
                <ul class="middle_content_list">
                  <li>
                    <span style="font-weight: bold">Manganese </span>
                    <br />
                    <span>Range: .010 - .13</span><br />
                    <span>Optimal: .07</span><br />
                  </li>
                  <li>
                    <span>Functions of Manganese: </span><br />
                    <span
                      >- Helps the mitochondria of the cells metabolize
                      glucose.</span
                    ><br />
                    <span
                      >- Required for tendon and ligament function, energy
                      production, and thyroid activity.</span
                    >
                    <span
                      >- Necessary for the bacteria lactobacillus to thrive in
                      the microbiome.</span
                    >
                    <span
                      >- Involved in the formation of T4 thyroid hormone and
                      necessary for proper thyroid function</span
                    >
                    <br />
                  </li>
                  <li>
                    Manganese is an alkaline-forming, regulatory mineral. It is
                    located in the mitochondria of the cells, so the structure
                    and function of mitochondria are particularly affected by
                    manganese status. It is found in the liver, thyroid,
                    pituitary, pancreas, kidneys, and bones.
                  </li>
                  <li style="color: #884c42; font-weight: bold">
                    {{ clientReportData.client_name }} -
                    {{ clientReportData.manganese }}
                  </li>
                  <li
                    style="color: #884c42"
                    v-for="value in clientReportData.manganese_result.filter(
                      (test) => test !== ''
                    )"
                    :key="value.index"
                  >
                    {{ value }}
                  </li>
                </ul>
                <br /><br />
                <ul class="middle_content_list">
                  <li>
                    <span style="font-weight: bold">Chromium </span>
                    <br />
                    <span>Range: .02 - .08</span><br />
                    <span>Optimal: .05</span><br />
                  </li>
                  <li>
                    <span>Functions of Chromium: </span><br />
                    <span
                      >- Along with zinc, manganese, copper, and a few other
                      trace minerals, chromium is needed for blood sugar
                      regulation, processing carbohydrates and fats, and helping
                      cells respond properly to insulin.</span
                    ><br />
                    <span
                      >- Helps transport insulin and attach it to cell
                      walls.</span
                    >
                    <span
                      >- Important for the structure and metabolism of nucleic
                      acids.</span
                    >
                  </li>

                  <li style="color: #884c42; font-weight: bold">
                    {{ clientReportData.client_name }} -
                    {{ clientReportData.chromium }}
                  </li>
                  <li
                    style="color: #884c42"
                    v-for="value in clientReportData.chromium_result.filter(
                      (test) => test !== ''
                    )"
                    :key="value.index"
                  >
                    {{ value }}
                  </li>
                </ul>
                <br /><br />
                <ul class="middle_content_list">
                  <li>
                    <span style="font-weight: bold">Selenium </span>
                    <br />
                    <span>Range: .03 - .18</span><br />
                    <span>Optimal: .12</span><br />
                  </li>
                  <li>
                    <span>Functions of Selenium: </span><br />
                    <span
                      >- Aids in liver detoxification processes, as it is a
                      precursor to glutathione</span
                    ><br />
                    <span
                      >- Needed for thyroid hormone conversion (T4 to T3)</span
                    >
                    <span>- Works with vitamin E as an antioxidant</span>
                    <span
                      >- May help prevent heart disease and cancer through its
                      roles as an antioxidant and in DNA repair.</span
                    >
                  </li>

                  <li style="color: #884c42; font-weight: bold">
                    {{ clientReportData.client_name }} -
                    {{ clientReportData.selenium }}
                  </li>
                  <li
                    style="color: #884c42"
                    v-for="value in clientReportData.selenium_result.filter(
                      (test) => test !== ''
                    )"
                    :key="value.index"
                  >
                    {{ value }}
                  </li>
                </ul>
                <br /><br />
                <ul class="middle_content_list">
                  <li>
                    <span style="font-weight: bold">Boron </span>
                    <br />
                    <span>Range: .02 - .91</span><br />
                    <span>Optimal: .46</span><br />
                  </li>
                  <li>
                    <span>Functions of Boron: </span><br />
                    <span>- Supports adrenal gland activity</span><br />
                    <span>- Helps the body respond to hormone activity.</span>
                    <span
                      >- Increases absorption of and regulates calcium,
                      magnesium, copper and phosphorus.</span
                    >
                    <span
                      >- Cofactor in enzymatic reactions throughout the
                      body.</span
                    >
                  </li>

                  <li style="color: #884c42; font-weight: bold">
                    {{ clientReportData.client_name }} -
                    {{ clientReportData.boron }}
                  </li>
                  <li
                    style="color: #884c42"
                    v-for="value in clientReportData.boron_result.filter(
                      (test) => test !== ''
                    )"
                    :key="value.index"
                  >
                    {{ value }}
                  </li>
                </ul>
                <br /><br />
                <ul class="middle_content_list">
                  <li>
                    <span style="font-weight: bold">Cobalt </span>
                    <br />
                    <span>Range: .001 - .003</span><br />
                    <span>Optimal: .002</span><br />
                  </li>
                  <li>
                    <span>Functions of Cobalt: </span><br />
                    <span>- Required for normal function of the pancreas</span
                    ><br />
                    <span>- Helps take in and process Vitamin B12</span>
                    <span>- Helps with iron absorption</span>
                  </li>

                  <li style="color: #884c42; font-weight: bold">
                    {{ clientReportData.client_name }} -
                    {{ clientReportData.cobalt }}
                  </li>
                  <li
                    style="color: #884c42"
                    v-for="value in clientReportData.cobalt_result.filter(
                      (test) => test !== ''
                    )"
                    :key="value.index"
                  >
                    {{ value }}
                  </li>
                </ul>
                <br /><br />
                <ul class="middle_content_list">
                  <li>
                    <span style="font-weight: bold">Molybdenum </span>
                    <br />
                    <span>Range: .003 - .008</span><br />
                    <span>Optimal: .005</span><br />
                  </li>
                  <li>
                    <span>Functions of Molybdenum: </span><br />
                    <span>- Helps the body utilize stored iron</span><br />
                    <span>- Prevents copper levels from getting too high</span>
                    <span>- Assists in alcohol detoxification</span>
                  </li>

                  <li style="color: #884c42; font-weight: bold">
                    {{ clientReportData.client_name }} -
                    {{ clientReportData.molybdenum }}
                  </li>
                  <li
                    style="color: #884c42"
                    v-for="value in clientReportData.molybdenum_result.filter(
                      (test) => test !== ''
                    )"
                    :key="value.index"
                  >
                    {{ value }}
                  </li>
                </ul>
                <br /><br />
                <ul class="middle_content_list">
                  <li>
                    <span style="font-weight: bold">Sulfur </span>
                    <br />
                    <span>Range: 3546 - 5336</span><br />
                    <span>Optimal: 4441</span><br />
                  </li>
                  <li>
                    <span>Functions of Sulfur: </span><br />
                    <span
                      >- Aids in liver detoxification and the metabolism of fats
                      and carbohydrates.</span
                    ><br />
                    <span
                      >- Promotes formation of collagen, which helps strengthen
                      hair and nails.</span
                    >
                    <span>- Precursor for utilization of amino acids</span>
                  </li>

                  <li style="color: #884c42; font-weight: bold">
                    {{ clientReportData.client_name }} -
                    {{ clientReportData.sulfur }}
                  </li>
                  <li
                    style="color: #884c42"
                    v-for="value in clientReportData.sulfur_result.filter(
                      (test) => test !== ''
                    )"
                    :key="value.index"
                  >
                    {{ value }}
                  </li>
                </ul>
                <br /><br />
                <ul class="middle_content_list">
                  <li>
                    <span style="font-weight: bold">Lithium </span>
                    <br />
                    <span>Range: 0-.007</span><br />
                    <span>Optimal: .035</span><br />
                  </li>
                  <li>
                    <span>Functions of Lithium: </span><br />
                    <span
                      >- Important for detoxification and mental health.</span
                    ><br />
                    <span>- May support serotonin activity.</span>
                  </li>

                  <li style="color: #884c42; font-weight: bold">
                    {{ clientReportData.client_name }} -
                    {{ clientReportData.lithium }}
                  </li>
                  <li
                    style="color: #884c42"
                    v-for="value in clientReportData.lithium_result.filter(
                      (test) => test !== ''
                    )"
                    :key="value.index"
                  >
                    {{ value }}
                  </li>
                </ul>
              </div>
            </div>

            <!-- <div class="col-lg-12 col-md-12 col-sm-12 pt_20">
              <div
                class="middle_content_box"
                style="height: 100% !important;  width:auto; w-100"
              >
                <ul class="middle_content_list">
                  <li style="font-weight: bold">
                    Recommendation Templates: Open, make a copy, and type in
                    your recommendations! Do not request to access!
                  </li>
                  <li>
                    Slow Dietary Recommendations Template -
                    <a
                      href="https://docs.google.com/document/d/1UHiE1-_MnU6GaOUBcw_-BFUBIusn4fDCiGKV-Sy2irA/edit?usp=sharing"
                      >https://docs.google.com/document/d/1UHiE1-_MnU6GaOUBcw_-BFUBIusn4fDCiGKV-Sy2irA/edit?usp=sharing</a
                    > 
                  </li>
                  <li>
                    Fast Dietary Recommendations Template -
                    <a
                      href="https://docs.google.com/document/d/1wurmKM3CGWuZUCeC5iAhn9NY8s4XG7uSfY_k96F7_Rk/edit?usp=sharing"
                      >https://docs.google.com/document/d/1wurmKM3CGWuZUCeC5iAhn9NY8s4XG7uSfY_k96F7_Rk/edit?usp=sharing</a
                    >
                  </li>
                  <li>
                    Four Lows Dietary Recommendations Template -
                    <a
                      href="https://docs.google.com/document/d/1ShHJ-hqmdnyFOqMeDaO78UxAu06XLG_ClZPH1digaaU/edit?usp=sharing"
                      >https://docs.google.com/document/d/1ShHJ-hqmdnyFOqMeDaO78UxAu06XLG_ClZPH1digaaU/edit?usp=sharing</a
                    >
                  </li>
                </ul>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ClientReport",
  components: {},
  data() {
    return {
      formId: null,
      clientReportData: {},
      loading: true,
    };
  },
  watch: {},

  computed: {
    ...mapGetters(["clientReport"]),
    dateValue() {
      var date = new Date(this.clientReportData.date);
      return (
        (date.getMonth() > 8
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1)) +
        "/" +
        (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
        "/" +
        date.getFullYear()
      );
    },
  },
  methods: {
    ...mapActions(["getClientReport"]),
    showLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
  },
  async mounted() {
    this.formId = this.$route.params.id;
    await this.getClientReport(this.formId);
    this.clientReportData = await this.clientReport;
    console.log("this.clientReportData", this.clientReportData);
    localStorage.setItem(
      "instant_clientname",
      `${this.clientReportData.client_name}`
    );
    this.showLoading();
  },
};
</script>

<style scoped>
.box {
  display: flex;
  justify-content: flex-end !important;
}
.menu_item_new {
  background-color: #884c42;
  padding: -19px 10px !important;
  color: #ffffff !important;
  border-radius: 10px;
  transition: 0.4s;
  margin: 5px;
}
.middle_content_box {
  height: 100%;
}
li {
  padding-bottom: 2px !important;
}
li,
p {
  line-height: 30px !important;
}
ul {
  padding: 1px !important;
}
</style>
