<template>
  <div>
    <br />
    <div class="accoridian_bg mt_35 graph_box">
      <h2 class="mt-5 mx-2 pt-2 mb-2">Metabolic Blueprint</h2>
      <div class="">
        <div class="row mb-5">
          <div class="col-lg-6 mx-auto w-100">
            <div class="pl-1 pb-2 pt-3 font-weight-bold">Find Client Name</div>
            <model-list-select
              :list="clientNameData"
              v-model="clientName"
              option-value="id"
              option-text="client_name"
              placeholder="Search Client Name"
            >
            </model-list-select>
            <div class="pl-1 pb-2 pt-3 font-weight-bold">Find Date</div>
            <model-list-select
              :list="htmaDatesData"
              v-model="htmaDate"
              option-value="id"
              option-text="htma_test_date"
              placeholder="Search HTMA Date"
            >
            </model-list-select>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 mx-auto w-100 submit-box" style="display:flex;justify-content:flex-end!important">
            <button @click="clientReport()" class="menu_item submit-button">
              Generate Metabolic Blueprint
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import { ModelListSelect } from "vue-search-select";

export default {
  components: {
    ModelListSelect,
  },
  name: "MetabolicBlueprint",
  data() {
    return {
      clientName: "",
      htmaDate: "",
      inputId: null,
      inputData: [],
      clientNameData: [],
      htmaDatesData: [],
      searchData: [],
      allInputHtmaData: [],
    };
  },
  computed: {
    ...mapGetters([
      // "allInput",
      "allInputData",
      "allInputHtma",
    ]),
  },
  watch: {
    clientName(value) {
      console.log("value", value);
      this.htmaDatesData = this.inputData.filter(
        (data) =>
          data.client_name ===
          this.inputData.find((x) => x.id === value).client_name
      );
      console.log("data.htma_test_date");
      this.htmaDatesData.map((data) => {
        data.htma_test_date = moment(data.htma_test_date).format("MM/DD/YYYY");
      });
      console.log("htmaDatesData", this.htmaDatesData);
    },
  },
  async mounted() {
    // await this.getAllInput();
    // this.inputData = await this.allInput;

    await this.getInputData();
    this.inputData = await this.allInputData;

    this.clientNameData = this.inputData.filter(
      (a, i) =>
        this.inputData.findIndex((s) => a.client_name === s.client_name) === i
    );
    console.log("all inputs are ", this.inputData);
    console.log("all client names are ", this.clientNameData);

    await this.getAllInputHtma();
    this.allInputHtmaData = await this.allInputHtma;
    console.log("input-htma", this.allInputHtmaData);
  },
  methods: {
    ...mapActions([
      // "getAllInput",
      "getInputData",
      "getAllInputHtma",
    ]),
    reset() {
      this.item = {};
    },

    clientReport() {
      // console.log("submit");
      // this.inputId = this.htmaDate;
      console.log("submit");
      console.log("htmadata id ", this.htmaDate);
      this.inputId = this.allInputHtmaData.find((data) => {
        return data.input == this.htmaDate;
      });
      console.log("input id searched is ", this.inputId.id);
      this.$router.push(`/metabolic-blueprint/${this.inputId.id}`);
    },

    onNext() {
      this.$emit("nextStep");
      window.scrollTo(0, 0);
    },
    onBack() {
      this.$emit("backStep");
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style>
.submit-box {
  display: flex;
  justify-content: flex-end !important;
  padding-right: 20px;
}

.submit-button {
  width: 250px;
}

.no-button {
  color: transparent !important;
  background-color: transparent !important;
  border: transparent !important;
  cursor: auto !important;
}
</style>