import { getReq } from "../../../services/api";

const state = {
  comparisonTestAnalysis:[],
};

const getters = {
  comparisonTestAnalysis: (state) => state.comparisonTestAnalysis,
};

const actions = {
  
  // comparisonTestAnalysis Report

  // async getComparisonTestAnalysis({ commit },ids) {
  //   try {
  //     let comparisonTestAnalysis = await getReq(`comparison-test-analysis/${ids.id1}/${ids.id2}/`);
  //     commit("setComparisonTestAnalysis", comparisonTestAnalysis);
      
  //   } catch (error) {
  //     console.log(error)
  //     console.log(error.response);
  //     throw error.response;
  //   }
  // },
  async getComparisonTestAnalysis({ commit },client_name) {
    try {
      let comparisonTestAnalysis = await getReq(`comparison-test-analysis/${client_name}`);
      console.log('result of comparison',comparisonTestAnalysis)
      commit("setComparisonTestAnalysis", comparisonTestAnalysis);
      
    } catch (error) {
      console.log(error)
      console.log(error.response);
      throw error.response;
    }
  },

};

const mutations = {
  setComparisonTestAnalysis: (state, comparisonTestAnalysis) => (state.comparisonTestAnalysis = comparisonTestAnalysis),

};

export default {
  state,
  getters,
  actions,
  mutations,
};
