<template>
  <main>
    <div class="main_content_wrapper">
      <div class="container mx-2">
        <div class="row">
          <div class="col-lg-12 mt_35 col_index4">
            <h2 class="mt-5 pt-2 mb-2">Account Settings</h2>
            <div class="row mt-5">
              <!-- <div
                class="col-12 d-flex align-items-center justify-content-center"
                v-if="!lifeTimeSubscribed"
              >
                <h5 class="font-weight-bold mb-0">Current Plan:</h5>
                <p class="ml-2 mb-0" v-if="currentSubscription[0]">
                  {{ currentSubscription[0].name }}
                </p>
              </div> -->
              <div
                class="col-12 align-items-center mt-5 justify-content-center"
              >
                <div class="row" v-if="!lifeTimeSubscribed">
                  <!-- <div class="col-md-4 text-center mb-3">
                    <router-link
                      class="btn btn-login text-uppercase fw-bold"
                      :to="`/pricing/${currentSubscription[0].id}`"
                      v-if="currentSubscription[0]"
                    >
                      Change Plan
                    </router-link>
                  </div> -->
                  <div class="col-md-4 text-center mb-3 mx-auto">
                    <a
                      class="btn btn-login text-uppercase fw-bold"
                      :href="'https://api.instanthtma.com/subscription/stripe-customer-portal?user='+user.pk"
                    >
                      Manage Subscription
                  </a>
                  </div>
                </div>
                <div
                  v-if="
                    allSubscriptions.filter(
                      (subscription) => subscription.original_amount != '0.00'
                    ).length > 0 && !lifeTimeSubscribed
                  "
                  class="mt-5"
                >
                  <h2>Payment History</h2>
                  <b-table
                    responsive
                    :fields="fields"
                    :items="
                      allSubscriptions.filter(
                        (subscription) => subscription.original_amount != '0.00'
                      )
                    "
                    class="mt-3"
                  >
                    <template #cell(invoice_no)="data">
                      {{ data.index + 1 }}
                    </template>
                    <template #cell(plan)="data">
                      {{
                        data.item.original_amount === "29.00"
                          ? "Monthly"
                          : "Yearly"
                      }}
                    </template>
                    <template #cell(original_amount)="data">
                      ${{ data.item.original_amount }}
                    </template>
                    <template #cell(created_at)="data">
                      {{ getMomentDate(data.item.created) }}
                    </template>
                    <template #cell(valid_till)="data">
                      {{ getMomentDate(data.item.valid_till) }}
                    </template>
                  </b-table>
                </div>
                <div class="row justify-content-between d-flex" :class="!lifeTimeSubscribed ? 'mt-5' : ''">
                  <!-- <div class="col-md-4 text-center mb-3">
                    <router-link
                      class="btn btn-login text-uppercase fw-bold"
                      @click="$bvModal.show('modal-change-name')"
                    >
                      Change Name
                    </router-link>
                  </div> -->
                  <div class="col-md-4 text-center mb-3">
                    <button
                      class="btn btn-login text-uppercase fw-bold"
                      @click="$bvModal.show('modal-change-name')"
                    >
                      Change Name
                    </button>
                  </div>
                  <div class="col-md-4 text-center mb-3">
                    <button
                      class="btn btn-login text-uppercase fw-bold"
                      @click="$bvModal.show('modal-change-password')"
                    >
                      Change Password
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-modal id="modal-stripe" hide-footer hide-header>
        <Stripe
          :updateDetails="true"
          :cancel="true"
          @closeModal="$bvModal.hide('modal-stripe')"
        />
      </b-modal>
      <b-modal id="modal-change-password" hide-footer hide-header>
        <ChangePassword
          :updateDetails="true"
          :cancel="true"
          @closeModal="$bvModal.hide('modal-change-password')"
        />
      </b-modal>
      <b-modal id="modal-change-name" hide-footer hide-header>
        <ChangeName
          :updateDetails="true"
          :cancel="true"
          @closeModal="$bvModal.hide('modal-change-name')"
        />
      </b-modal>
      <!-- <b-modal id="modal-manage-subscription" hide-footer hide-header>
        <h5 class="mb-3 text-center">
          Are you sure you want to Cancel your Subscription?
        </h5>
        <div
          class="text-center align-items-center d-flex justify-content-center"
        >
          <button
            class="btn btn-danger text-uppercase fw-bold"
            @click="$bvModal.hide('modal-manage-subscription')"
          >
            No
          </button>
          <button
            class="btn btn-login text-uppercase fw-bold ml-3"
            @click="manageSubscription"
          >
            Yes
          </button>
        </div>
      </b-modal> -->
    </div>
  </main>
</template>

<script>
import Stripe from "../../components/Stripe/Stripe.vue";
import ChangePassword from "../../components/Account/ChangePassword.vue";
import ChangeName from "../../components/Account/ChangeName.vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "AccountSettings.vue",
  components: { Stripe, ChangePassword, ChangeName },
  data() {
    return {
      currentSubscription: [],
      allSubscriptions: [],
      fields: [
        {
          key: "invoice_no",
          label: "Invoice No.",
          sortable: true,
          tdClass: "align-middle",
        },
        {
          key: "plan",
          label: "Plan",
          sortable: true,
          tdClass: "align-middle",
        },
        {
          key: "original_amount",
          label: "Amount",
          sortable: true,
          tdClass: "align-middle",
        },
        {
          key: "created_at",
          label: "Started At",
          sortable: true,
          tdClass: "align-middle",
        },
        {
          key: "valid_till",
          label: "Valid till",
          sortable: true,
          tdClass: "align-middle",
        },
      ],
    };
  },
  computed:{
    ...mapGetters(["lifeTimeSubscribed","user"])
  },
  methods: {
    ...mapActions([
      "logoutUser",
    ]),
    getMomentDate: function (date) {
      return moment(date).format("MM/DD/YYYY");
    },
    
  },

};
</script>
<style>
.btn-danger {
  font-size: 0.9rem;
  letter-spacing: 0.05rem;
  padding: 0.75rem 1rem;
  color: white !important;
  border-radius: 10px;
}
</style>