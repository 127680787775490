<template>
  <div id="">
    <main>
      <div class="main_content_wrapper">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 pt_55 col_index4">
              <div class="accorodion_wrapper" id="accordian">
                <div v-if="step == 1">
                  <NAQ
                    :naqOnly="true"
                    @nextStep="getNextStep()"
                    :naq="naq"
                    @setNaqId="setNaqId($event)"
                    @reset="resetSearchNaq()"
                  />
                </div>

                <div v-if="step == 2">
                  <NaqHtmaForm
                    :naqOnly="true"
                    @nextStep="submit()"
                    @backStep="getBackStep()"
                    :naqHtma="naqHtma"
                    @setNaqHtmaId="setNaqHtmaId($event)"
                    @reset="resetSearchNaqHtma()"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <!-- Modal -->
    <NaqsModal v-if="showModal" :save="save" @confirm="confirmSubmit($event)" />
  </div>
</template>

<script>
import NAQ from "../../components/NAQ/NAQ.vue";
import NaqHtmaForm from "../../components/NaqHtmaForm/NaqHtmaForm.vue";
import NaqsModal from "../../components/SaveModal/NaqsModal.vue";

import { mapActions, mapGetters } from "vuex";
function getInitNaqData() {
  return {
    client_name: "",
    upper_gi: 0,
    liver_and_gb: 0,
    small_intestine: 0,
    large_intestine: 0,
    mineral_need: 0,
    fatty_acid: 0,
    sugar_handling: 0,
    vitamin_need: 0,
    adrenal: 0,
    pituitary: 0,
    thyroid: 0,
    cardiovascular: 0,
    kidney_or_bladder: 0,
    immune: 0,
    female_only: 0,
    male_only: 0,
    // total: 0,
  };
}
function getInitNaqHtmaData() {
  return {
    client_name: "",
    diet: {
      drink_alcohol: false,
      artificial_sweeteners: false,
      candy_desserts_refined_sugar: false,
      carbonate_beverages_water: false,
      chewing_tobacco: false,
      cigarettes: false,
      cigars_pipes: false,
      caffeinated_beverages: false,
      fast_foods: false,
      fried_foods: false,
      luncheon_meats: false,
      margarine: false,
      dairy_products: false,
      radiation_exposure: false,
      refined_flour: false,
      vitamin_mineral_supplements: false,
      distilled_water: false,
      tap_water: false,
      well_water: false,
      diet_weight_control: false,
    },
    upper_gi: {
      belching_gas: false,
      heartburn_acid_reflux: false,
      bloating_after_eating: false,
      vegan_diet: false,
      bad_breath: false,
      lost_taste_for_meat: false,
      sweat_has_strong_odor: false,
      stomach_upset_by_vitamins: false,
      excess_fullness_after_meal: false,
      skipping_breakfast: false,
      feel_better_while_not_eating: false,
      sleepy_after_meal: false,
      fingernails_chip: false,
      anemia: false,
      stomach_pain: false,
      diarrhea: false,
      diarrhea_shortly: false,
      stool: false,
      undigested_food_in_stool: false,
    },
    liver: {
      pain_shoulder_blade: false,
      stomach_upset_by_food: false,
      greasy: false,
      nausea: false,
      airplane_motion_sickness: false,
      light_or_clay: false,
      itchy_feet: false,
      headache: false,
      gallbladder_attack: false,
      morning_sickness: false,
      bitter_taste: false,
      alcoholic_drink: false,
      diesel_fumes: false,
      pain_under_rib_cage: false,
      consumption_of_diet_product: false,
      fibromyalgia: false,
      gallbladder: false,
      sick_after_drink_wine: false,
      easily_intoxicated: false,
      hungover_when_drink_wine: false,
      recovering_alcoholic: false,
      alcohol_abuse: false,
      history_of_hepatitis: false, // new added
      // stomach_pains: false, // no need ?
      // hepatitis: false,
      recreational_drug: false,
      sensitive_to_chemical: false,
      sensitive_to_tobacco: false,
      varicose_vein: false,
    },
    small_intestine: {
      food_allergy: false,
      abdominal_bloating: false,
      foods_make_you_tired: false,
      pulse_speed: false,
      airborne_allergy: false,
      experience_hive: false,
      sinus_congestion: false,
      noodle: false,
      alternating_constipation: false,
      crohn_disease: false,
      grain_sensitivity: false,
      dairy_sensitivity: false,
      foods_you_cant_give_up: false,
      asthma: false,
      vivid_dream: false,
      counter_pain_medication: false,
      feel_spacey: false,
    },
    large_intestine: {
      anus_itch: false,
      coated_tongue: false,
      feel_worse: false,
      antibiotics: false,
      yeast_infection: false,
      ring_worm: false,
      swelling_in_joint: false,
      difficult_to_pass: false,
      parasite: false,
      bowel_movement: false,
      curve_flat_shape: false,
      loose: false,
      mucus_colitis: false,
      blood: false,
      mucus: false,
      foul_smelling: false,
      strong_body_odor: false,
      painful_tigh: false,
      abdominal_region: false,
      dark_circle: false,
    },
    mineral_needs: {
      carpal_tunnel_syndrome: false,
      abdominal_pain: false,
      stress_fracture: false,
      bone_loss: false,
      shorter_than_usual: false,
      cramps_at_rest: false,
      cold_sore: false,
      frequent_fever: false,
      hives: false,
      herniated_disc: false,
      flexible_joint: false,
      joints_pop: false,
      swelling_in_joint: false,
      bursitis: false,
      bone_spur: false,
      morning_stiffness: false,
      vomiting: false,
      crave_chocolate: false,
      feet_have_strong_odor: false,
      anemia: false,
      eyes_are_blue_tinted: false,
      hoarseness: false,
      swallowing: false,
      lump_in_throat: false,
      dry_mouth: false,
      gag_easily: false,
      fingernail: false,
      cuts_heal: false,
      decreased_sense: false,
    },
    essential_fatty_acid: {
      pain_relief_with_aspirin: false,
      greasy_food: false,
      reduced_fat_diet: false,
      headache: false,
      headaches_when_out_in_sun: false,
      sunburn_easily: false,
      muscles_fatigued: false,
      dandruff: false,
    },
    sugar_handling: {
      awaken_after_falling_asleep: false,
      crave_sweet: false,
      uncontrolled_eating: false,
      excessive_appetite: false,
      crave_coffee: false,
      sleepy: false,
      fatigue: false,
      headache: false,
      irritable_before_meals: false,
      shaky: false,
      family_members_with_diabete: false,
      frequent_thirst: false,
      frequent_urination: false,
    },
    vitamin_need: {
      muscles_fatigued: false,
      feel_exhausted: false,
      insect_bites: false,
      loss_of_muscle: false,
      enlarged_heart: false,
      pulse_below_65: false,
      ringing_in_ear: false,
      numbness: false,
      depressed: false,
      impending_doom: false,
      worrier: false,
      nervous: false,
      feelings_of_insecurity: false,
      heart_race: false,
      hear_heart_beat_on_pillow: false,
      body_jerk_as_falling_asleep: false,
      night_sweat: false,
      restless_leg_syndrome: false,
      cracks: false,
      fragile_skin: false,
      wart: false,
      msg_sensitivity: false,
      wakeup_without_remember_dream: false,
      small_bumps_on_back_arms: false,
      irritates_eye: false,
      nose_bleed: false,
      bleeding_gum: false,
    },
    ardenal: {
      night_person: false,
      difficulty_in_sleep: false,
      slow_starter: false,
      trouble_calming_down: false,
      blood_pressure: false,
      headache: false,
      feeling_wired: false,
      clench: false,
      calm_outside_trouble_inside: false,
      worse_fatigue: false,
      become_dizzy: false,
      difficult_manipulative_correction: false,
      pain_after_manipulative_correction: false,
      arthritic_tendency: false,
      crave_salty_food: false,
      salt_food: false,
      perspire_easily: false,
      chronic_fatigue: false,
      afternoon_yawning: false,
      afternoon_headache: false,
      asthma: false,
      pain_in_innerside_of_knee: false,
      sprain_ankles: false,
      need_sunglass: false,
      allergy: false,
      // nose_bleeds: false, // ?
      dizziness: false,
    },
    pituitary: {
      height_over_6_6: false,
      early_sexual_development: false,
      increased_libido: false,
      splitting_type_headache: false,
      memory_failing: false,
      tolerate_sugar: false,
      height_under_4_10: false,
      decreased_libido: false,
      excessive_thirst: false,
      weight_gain_around_waist: false,
      menstrual_disorder: false,
      delayed_sexual_development: false,
      colitis: false,
    },
    thyroid: {
      allergic_to_iodine: false,
      difficulty_gaining_weight: false,
      nervous: false,
      inward_trembling: false,
      flush_easily: false,
      fast_pulse: false,
      intolerance: false,
      difficulty_losing_weight: false,
      mentally_sluggish: false,
      easily_fatigued: false,
      sensitive_to_cold: false,
      constipation: false,
      excessive_hair_loss: false,
      morning_headache: false,
      Loss_of_lateral_of_eyebrow: false,
      seasonal_sadness: false,
    },
    cardio: {
      irregular_breathing: false,
      discomfort: false,
      sigh_frequently: false,
      in_closed_room: false,
      shortness_of_breath: false,
      ankles_swell: false,
      cough_at_night: false,
      face_turns_red: false,
      tightness_in_chest: false,
      muscle_cramp: false,
    },
    kidney_bladder: {
      pain_in_back: false,
      dark_circle: false,
      kidney_stone: false,
      darked_urine: false,
      urine_has_strong_odor: false,
    },
    immune_system: {
      drippy_nose: false,
      catch_colds: false,
      mucus_producing_cough: false,
      frequent_flu: false,
      infections: false,
      never_get_sick: false,
      acne: false,
      dermatitis: false,
      cysts: false,
      chronic_viral_condition: false,
    },
    women_only: {
      depression_during_period: false,
      mood_swings_with_period: false,
      crave_chocolate: false,
      breast_tenderness: false,
      menstrual_flow: false,
      scanty_blood: false,
      occasional_skipped_period: false,
      variations_in_menstrual_cycle: false,
      endometriosis: false,
      uterine_fibroid: false,
      breast_fibroid: false,
      painful_intercourse: false,
      vaginal_discharge: false,
      vaginal_dryness: false,
      vaginal_itchiness: false,
      tendency_to_gain_weight: false,
      excess_facial: false,
      hot_flashes: false,
      night_sweats: false,
      thinning_skin: false,
    },
    men_only: {
      prostate_problem: false,
      dribbling: false,
      stop_urine_stream: false,
      pain_with_urination: false,
      walk_to_urine_at_night: false,
      interruption_of_stream: false,
      pain_inside_leg: false,
      incomplete_bowel_evacuation: false,
      decreased_sexual_function: false,
    },
  };
}
export default {
  name: "NAQSymptoms",
  components: {
    NAQ,
    NaqHtmaForm,
    NaqsModal,
  },
  data() {
    return {
      step: 1,
      naqId: null,
      naqHtmaId: null,
      client_name: "",
      naq: getInitNaqData(),
      naqHtma: getInitNaqHtmaData(),

      save: {
        data:false
      },
      showModal: false,
    };
  },
  computed: {
    ...mapGetters(["singleNaq", "singleNaqHtma"]),
  },
  methods: {
    ...mapActions(["addNaq", "addNaqHtma", "getSingleNaq", "getSingleNaqHtma"]),
    getNextStep() {
      if (this.step < 4) {
        this.step++;
      } else {
        this.step = 1;
      }
    },
    getBackStep() {
      if (this.step > 1) {
        this.step--;
      }
    },
    async setNaqId(value) {
      console.log(value);
      this.naqId = value;
      await this.getSingleNaq(this.naqId);
      console.log("select naq is ", await this.singleNaq);
      this.naq = await this.singleNaq;
    },
    async setNaqHtmaId(value) {
      console.log(value);
      this.naqHtmaId = value;
      console.log("naq htma id ", this.naqHtmaId);
      await this.getSingleNaqHtma(this.naqHtmaId);
      // console.log("select naq htma is ", await this.singleNaqHtma);
      this.naqHtma = await this.singleNaqHtma;
    },
    resetSearchNaq() {
      console.log("resetting naq ....");
      this.naqId = null;
      Object.assign(this.naq, getInitNaqData());
    },
    resetSearchNaqHtma() {
      console.log("resetting naqhtma ....");
      this.naqHtmaId = null;
      Object.assign(this.naqHtma, getInitNaqHtmaData());
    },
    async confirmSubmit(toSave) {
      if(toSave){

      
      this.naqHtma.client_name = this.naq.client_name;
      console.log(this.naq);
      console.log(this.naqHtma);
      console.log("naq symtom page");

      this.$toastr.Add({
        name: "Success", // this is give you ability to use removeByName method
        msg: "NAQ Symptoms has been submitted Successfully!", // Toast Message
        type: "success", // Toast type,
      });
      if (this.naqId) {
        console.log("selected naqid will be", this.naqId);
      } else {
        // Naq
        if (this.save.data) {
          try {
            const naq = await this.addNaq(this.naq);
            console.log("naq response ", naq);
            this.naqId = naq.naqs.id;
          } catch (error) {
            console.log(error);
          }
          console.log("naqid", this.naqId);
        } else {
          // not saving naq and naq+htma
          this.naq["created"] = new Date().toISOString();
          sessionStorage.setItem("naq", JSON.stringify(this.naq));
          this.naqId = 0;
        }
      }
      if (this.naqHtmaId) {
        console.log("selected naqhtmaid will be", this.naqHtmaId);
      } else {
        // Naq+Htma
        if (this.save.data) {
          try {
            const naqHtmaResponse = await this.addNaqHtma(this.naqHtma);
            console.log("naq htma response ", naqHtmaResponse);
            this.naqHtmaId = naqHtmaResponse.diet.id;
          } catch (error) {
            console.log(error);
          }
        } else {
          // not saving naq and naq+htma
          this.naqHtma["created"] = new Date().toISOString();
          sessionStorage.setItem("naqHtma", JSON.stringify(this.naqHtma));
          this.naqHtmaId = 0;
        }
      }
      this.$router.push(
        // `/naq-symptoms/1`
        `/naq-symptoms-output?naq=${this.naqId}&naqhtma=${this.naqHtmaId}`
      );
      } else {
        this.showModal = false;
        console.log("confirm submit data ", toSave);
      }
    },
    async submit() {
      console.log("submitttting...");

      this.showModal = true;
      // console.log(this.client_name)
      // this.naq.client_name = this.client_name
      // this.naqHtma.client_name = this.naq.client_name;
      // console.log(this.naq);
      // console.log(this.naqHtma);
      // console.log("naq symtom page");

      // this.$toastr.Add({
      //   name: "Success", // this is give you ability to use removeByName method
      //   msg: "NAQ Symptoms has been submitted Successfully!", // Toast Message
      //   type: "success", // Toast type,
      // });
      // if (this.naqId) {
      //   console.log("selected naqid will be", this.naqId);
      // } else {
      //   // Naq
      //   try {
      //     const naq = await this.addNaq(this.naq);
      //     console.log("naq response ", naq);
      //     this.naqId = naq.naqs.id;
      //   } catch (error) {
      //     console.log(error);
      //   }

      //   console.log("naqid", this.naqId);
      // }
      // if (this.naqHtmaId) {
      //   console.log("selected naqhtmaid will be", this.naqHtmaId);
      // } else {
      //   // Naq+Htma
      //   try {
      //     const naqHtmaResponse = await this.addNaqHtma(this.naqHtma);
      //     console.log("naq htma response ", naqHtmaResponse);
      //     this.naqHtmaId = naqHtmaResponse.diet.id;
      //   } catch (error) {
      //     console.log(error);
      //   }
      // }
      // this.$router.push(
      //   // `/naq-symptoms/1`
      //   `/naq-symptoms-output?naq=${this.naqId}&naqhtma=${this.naqHtmaId}`
      // );
    },
  },
  async mounted() {
    console.log("naq symtom page");
  },
};
</script>
<style>
.b-toaster {
  position: absolute;
  max-width: 350px;
  width: 100%;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
}
</style>
