<template>
  <div id="">
    <div v-if="loading" id="preloader">
      <div class="loader3">
        <span></span>
        <span></span>
      </div>
    </div>
    <main>
      <div class="main_content_wrapper">
        <br />
        <h2 class="mx-2 pt-2 mb-2 text-center">
          Symptom Burden Graph
          <!-- {{ naqHtmaReportData.client_name }} -->
        </h2>
        <h3 class="mx-2 pt-2 mb-2 text-center">
          <!-- Prepared on
          {{ dateValue }} -->
        </h3>
        <hr />
        <div class="container">
          <div class="row">
            <div class="col-lg-12 pt_30">
              <div class="graph_box">
                <!-- <h2>NAQ - Symptom Burden Graph</h2> -->
                <div class="graph_img">
                  <BarChart
                    v-if="chartIsLoaded"
                    :chartdata="chartdata"
                    :options="chartOptions"
                  />
                </div>
              </div>
              <!-- </div>
          </div>
          <div class="row">
            <div class="col-lg-12 pt_30"> -->
              <!-- <div class="graph_box"> -->
              <!-- <h2>NAQ + HTMA</h2> -->
              <div class="accorodian_content accorodian_content2" id="SO_Q2_R">
                <naq-htma
                  v-if="naqHtmaReportData"
                  :singleTestAnalysisData="naqHtmaReportData"
                />
              </div>
              <!-- </div> -->
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import BarChart from "../../components/Chart/BarChart";
import NaqHtma from "../../components/SingleTestView/NaqHtma.vue";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "NAQSymptomsOutput",
  components: {
    BarChart,
    NaqHtma,
  },

  data() {
    return {
      chartIsLoaded: false,
      naqGraphLabel: [],
      naqGraphData: [],
      chartdata: {
        labels: [],
        datasets: [
          {
            backgroundColor: "#884C42",
            borderWidth: 2,
            borderRadius: 5,
            data: [],
          },
        ],
      },

      sysBurdenLabels: [
        // "",
        "High priority",
        "Moderate Priority",
        "Low Priority",
        "",
      ],
      chartOptions: {
        scales: {
          yAxes: [
            {
              ticks: {
                callback: (value, index) => {
                  return this.sysBurdenLabels[index];
                },
                min: 0,
                max: 0.4,
                // stepSize: 0.083,
                stepSize: 0.167,
                // stepSize: 0.166667,
                // fixedStepSize:0.083,
              },
              // ticks: {
              //   beginAtZero: true,
              // },
              gridLines: {
                display: true,
              },
            },
          ],
          // yAxes: [
          //   {
          //     ticks: {
          //       callback: (value, index) => {
          //         return this.sysBurdenLabels[index];
          //       },
          //       beginAtZero: true,
          //     },
          //     gridLines: {
          //       display: true,
          //     },
          //   },
          // ],
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: false,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
        tooltips: {
          enabled: true,
          mode: "single",
          callbacks: {
            label: function (tooltipItems) {
              return tooltipItems.yLabel;
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        height: 200,
      },
      singleTestAnalysisData: {},
      naqHtmaReportData: {},
      naqId: null,
      naqHtmaId: null,
      loading: true,
    };
  },
  watch: {},

  computed: {
    ...mapGetters(["naqGraph", "naqHtmaReport"]),
    dateValue() {
      var date = new Date(this.naqHtmaReportData.date);
      return (
        (date.getMonth() > 8
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1)) +
        "/" +
        (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
        "/" +
        date.getFullYear()
      );
    },
  },
  methods: {
    ...mapActions(["getNaqGraph", "getNaqHtmaReport","getNaqGraphUnsaved","getNaqHtmaReportUnsaved"]),
    showLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
  },
  async mounted() {
    this.naqId = this.$route.query.naq;
    this.naqHtmaId = this.$route.query.naqhtma;
    if (this.naqId != 0) {
      await this.getNaqGraph(this.naqId);
    } else {
      const naq = JSON.parse(sessionStorage.getItem("naq"));
      console.log("naq raw data", naq);
      await this.getNaqGraphUnsaved(naq);
    }
    // await this.getNaqGraph(this.naqId);
    if (this.naqHtmaId != 0) {
      await this.getNaqHtmaReport(this.naqHtmaId);
    } else {
      const naqHtma = JSON.parse(sessionStorage.getItem("naqHtma"));
      console.log("naqhtma raw data", naqHtma);
      await this.getNaqHtmaReportUnsaved(naqHtma);
    }
    // await this.getNaqHtmaReport(this.naqHtmaId);
    this.naqHtmaReportData = await this.naqHtmaReport;
    this.chartdata.labels = this.naqGraph.naq_graph_labels;
    this.chartdata.datasets[0].data = this.naqGraph.naq_graph_values;
    console.log("chart", this.chartdata);
    localStorage.setItem(
      "instant_clientname",
      `${this.naqHtmaReportData.client_name}`
    );
    this.chartIsLoaded = true;
    this.showLoading();
  },
};
</script>

<style scoped>
.menu_item_new {
  background-color: #884c42;
  padding: -19px 10px !important;
  color: #ffffff !important;
  border-radius: 10px;
  transition: 0.4s;
  margin: 5px;
}
.middle_content_box {
  height: 100%;
}
</style>
