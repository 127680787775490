import { getReq, postReq, deleteReq, putReq } from "../../../services/api";

const state = {
  iodineBloodMarkers: [],
  iodineBloodMarkersReport: [],
  singleIodineBloodMarker:[],
};

const getters = {
  iodineBloodMarkers: (state) => state.iodineBloodMarkers,
  iodineBloodMarkersReport: (state) => state.iodineBloodMarkersReport,
  singleIodineBloodMarker: (state) => state.singleIodineBloodMarker,
};


const actions = {
  // Iron iodineBloodMarkers
  async getIodineBloodMarkers({ commit },) {
    try {
      console.log('getting iodine data')
      let iodineBloodMarkers = await getReq(`iodine-blood/`);
      commit("setIodineBloodMarkers", iodineBloodMarkers);

    } catch (error) {
      console.log(error)
      console.log(error.response);
      throw error.response;
    }
  },

  async getSingleIodineBloodMarker({ commit },id) {
    try {
      let singleBloodMarker = await getReq(`iodine-blood/${id}/`);
      commit("setSingleIodineBloodMarker", singleBloodMarker);
      
    } catch (error) {
      console.log(error)
      console.log(error.response);
      throw error.response;
    }
  },

  async addIodineBloodMarkers(_, payload) {
    try {
      const newIodineBloodMarkers = await postReq(`iodine-blood/`, payload);
      return newIodineBloodMarkers

    } catch (error) {
      console.log(error.response.data);
      throw error.response.data;
    }
  },

  // async updateiodineBloodMarkers({ commit }, { id, payload }) {
  //   try {
  //     console.log(commit)
  //     console.log(payload)
  //     const updatedData = await patchReq("iodine-blood/", id, payload);
  //     if (updatedData) {
  //       console.log(updatedData);
  //     }
  //   } catch (e) {
  //     console.log(e)
  //     if (!e.response) {
  //       throw "No network connection"
  //     }
  //     const errors = e.response.data;
  //     console.log(errors)
  //     throw errors;
  //   }
  // },

  async updateIodineBloodMarkers({ commit }, { id, payload }) {
    try {
      console.log(commit)
      console.log(payload)
      const updatedData = await putReq("iodine-blood/", id, payload);
      if (updatedData) {
        console.log(updatedData);
      }
      return updatedData
    } catch (e) {
      console.log(e)
      if (!e.response) {
        throw "No network connection"
      }
      const errors = e.response.data;
      console.log(errors)
      throw errors;
    }
  },


  async deleteIodineBloodMarkers(_, id) {
    try {
      return await deleteReq("iodine-blood", id);
    } catch (e) {
      if (!e.response) {
        throw "No network connection"
      }
      const errors = e.response.data;
      console.log(errors)
      throw errors;
    }
  },

  // iodineBloodMarkers Unsaved Api

  async getIodineBloodMarkersReportUnsaved({commit},payload) {
    try {
       const iodineBloodMarkersReport = await postReq(`iodine-blood-unsaved/`,payload );
      commit("setIodineBloodMarkersReport", iodineBloodMarkersReport);
       return iodineBloodMarkersReport 
     
    } catch (error) {
      console.log(error.response.data);
      throw error.response.data;
    }
  },

  // iodineBloodMarkers Report

  async getIodineBloodMarkersReport({ commit }, id) {
    try {
      let iodineBloodMarkersReport = await getReq(`iodine-blood-marker-report/${id}/`);
      commit("setIodineBloodMarkersReport", iodineBloodMarkersReport);

    } catch (error) {
      console.log(error)
      console.log(error.response);
      throw error.response;
    }
  },

};

const mutations = {
  setIodineBloodMarkers: (state, iodineBloodMarkers) => (state.iodineBloodMarkers = iodineBloodMarkers),
  setIodineBloodMarkersReport: (state, iodineBloodMarkersReport) => (state.iodineBloodMarkersReport = iodineBloodMarkersReport),
  setSingleIodineBloodMarker: (state, singleIodineBloodMarker) => (state.singleIodineBloodMarker = singleIodineBloodMarker),

};

export default {
  state,
  getters,
  actions,
  mutations,
};
