<template>
  <div class="body">
    <div class="container">
      <div class="row">
        <div class="mx-auto" :class="!updateDetails ? 'col-sm-9 col-md-7 col-lg-':'col-12'">
          <div class="border-0 rounded-3 my-5" :class="!updateDetails ? 'card shadow':''">
            <div class="card-body p-4 p-sm-5">
              <h3 class="card-title text-center mb-5 fw-light fs-5">
               {{updateDetails ? "Update": "Enter"}} Card Details
              </h3>
              <form @submit.prevent="onSubmit">
                <div class="form-floating mb-3">
                  <label for="floatingInput">Name on Card:</label>
                  <input
                    type="text"
                    class="form-control"
                    id="floatingInput"
                    placeholder="First name"
                    v-model="cardHoldername"
                    required
                  />
                </div>
                <div class="form-floating mb-3">
                  <label for="floatingInput">Card Number:</label>
                  <div id="card-number-element" class="form-control p-2">
                    <!-- A Stripe Element will be inserted here. -->
                  </div>
                </div>
                <div class="form-floating mb-3">
                  <label for="floatingInput">Expiration Date:</label>
                  <div id="card-expiry-element" class="form-control p-2">
                    <!-- A Stripe Element will be inserted here. -->
                  </div>
                </div>
                <div class="form-floating mb-3">
                  <label for="floatingPassword">CVC:</label>
                  <div id="card-cvc-element" class="form-control p-2">
                    <!-- A Stripe Element will be inserted here. -->
                  </div>
                </div>
                <div class="d-grid text-center justify-content-between" :class="cancel ? 'd-flex':''">
                    <button
                    class="btn btn-danger text-uppercase fw-bold"
                    type="button"
                    v-if="cancel"
                    @click="$emit('closeModal')"
                  >
                    Cancel
                  </button>
                  <button
                    class="btn btn-login text-uppercase fw-bold"
                    type="submit"
                    :disabled="isLoading"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapGetters,mapActions
} from "vuex";

export default {
  name: "Stripe",
  props:{
    updateDetails:{
        type:Boolean,
        default:false
    },
    cancel:{
        type:Boolean,
        default:false
    }
  },

  data() {
    return {
      stripe: null,
      elements: null,
      cardHoldername: "",
      cardNumberElement: undefined,
      cardExpiryElement: undefined,
      cardCVCElement: undefined,
      stripeValidationError: null,
      isLoading:false
    };
  },
  computed: {
    ...mapGetters(["user","currentUserSubscription"]),
  },
  methods: {
      ...mapActions(["setUserSubscription","setCardDetails","updateCardDetails"]),
    async onSubmit() {
        this.isLoading = true
      const data = {
        name: this.cardHoldername,
      };
      await this.stripe
        .createToken(this.cardNumberElement, data)
        .then(async (result) => {
          if (result.error) {
            this.$toastr.Add({
          name: "Error", // this is give you ability to use removeByName method
          msg: result.error.message, // Toast Message
          type: "error", // Toast type,
        });
        } else {
            if(this.updateDetails){
              const stripeObject = {
              token: result.token.id,
              card_number:result.token.card.last4
              }
              try{
                console.log(stripeObject)
                const status = await this.updateCardDetails( 
                {id: this.currentUserSubscription,
              payload: stripeObject
              })
                if(status){
                  this.$toastr.Add({
                    name: "Success", // this is give you ability to use removeByName method
                    msg: "You have Changed Card Details Successfully!", // Toast Message
                    type: "success", // Toast type,
                  });
                  this.$emit('closeModal')
                }
              }
              catch(e){
                console.log(e)
              }
            }
            else {
            const stripeObject = {
              email: this.user.email,
              name: this.cardHoldername,
              user: this.user.pk,
              token: result.token.id,
              card_number:result.token.card.last4
            };
            try{
              const status = await this.setCardDetails(stripeObject)
               await this.setUserSubscription(this.$route.query.payment_id) 
               if(status){
                this.$toastr.Add({
                    name: "Success", // this is give you ability to use removeByName method
                    msg: "You have Subscribed Successfully!", // Toast Message
                    type: "success", // Toast type,
                  });
                this.$router.push("/")
               }
            } 
            catch(e){
              console.log(e)
            }     
            }      
            this.cardHoldername = "";
            this.cardNumberElement.clear();
            this.cardExpiryElement.clear();
            this.cardCVCElement.clear();
          }
        }).finally(()=>{
          this.isLoading = false
        })
    },
  },
  async mounted() {
    this.stripe = window.Stripe(
      `pk_live_cBjwOTeuxv2tRJ9RzWd3OzkV00RavIp8iQ`
    );
    var style = {
      base: {
        // Add your base input styles here. For example:
        fontSize: "16px",
        color: "black",
      },
    };
    (this.elements = this.stripe.elements()),
      (this.cardNumberElement = this.elements.create("cardNumber", {
        showIcon: true,
        style: style,
      }));
    this.cardNumberElement.mount("#card-number-element");
    this.cardExpiryElement = this.elements.create("cardExpiry", {
      style: style,
    });
    this.cardExpiryElement.mount("#card-expiry-element");
    this.cardCVCElement = this.elements.create("cardCvc", { style: style });
    this.cardCVCElement.mount("#card-cvc-element");
  },
};

</script>
