<template>
  <header id="header-print" class="d-flex align-items-center">
    <div class="container-fluid">
      <div class="header_main">
        <router-link to="/" class="logo_text logo_text4"
          >Instant HTMA Professional</router-link
        >
        <ul id="menu">
          <li
            id="print-pdf"
            v-if="
              $route.name === 'SingleTestAnalysis' ||
              $route.name === 'HTMAAnalysis' ||
              $route.name === 'ComparisonTestAnalysis' ||
              $route.name === 'MetabolicBlueprintResult' ||
              $route.name === 'BloodMarkersIron' ||
              $route.name === 'BloodMarkersIodine' ||
              $route.name === 'BloodMarkersCopper' ||
              $route.name === 'NAQSymptomsOutput' ||
              $route.name === 'ConditionOutput' ||
              $route.name === 'ComparisonReport' ||
              $route.name === 'ClientReport' ||
              $route.name === 'ComparisonList'
            "
          >
            <a class="menu_item" @click="print()">Print to PDF</a>
          </li>
          <li
          v-if="$route.name !== 'Login' && $route.name !== 'Register' && $route.name !== 'ForgotPassword' && $route.name !== 'PrivacyPolicy' && $route.name !== 'Home' && $route.name !== 'ResetPassword'"

          >
            <a class="menu_item" target="_blank" href="https://www.learnhtma.com/apptroubleshooting/">Help</a>
          </li>
          <li
          v-if="$route.name !== 'Login' && $route.name !== 'Register' && $route.name !== 'ForgotPassword' && $route.name !== 'PrivacyPolicy' && $route.name !== 'Home' && $route.name !== 'ResetPassword'"
          >
            <a class="menu_item" v-on:click="onLogout()">Logout</a>
          </li>
          <li
          v-if="($route.name === 'PrivacyPolicy' && !userLogin) || $route.name === 'Home'">
            <router-link tag="a" class="menu_item" to="/login">Login</router-link>
          </li>
          <li
          v-if="$route.name === 'PrivacyPolicy' && userLogin">
            <router-link tag="a" class="menu_item" to="/">Go to App</router-link>
          </li>
          <li
          v-if="$route.name === 'Login' || $route.name === 'Register' || $route.name === 'ForgotPassword' || $route.name === 'PrivacyPolicy' || $route.name === 'Home' || $route.name === 'ResetPassword'"          >
          <router-link tag="a" class="menu_item" to="/privacy-policy">Privacy Policy</router-link>
          </li>
        </ul>
        <div class="hamburger-menu right_clear">
          <span class="line-top"></span>
          <span class="line-center"></span>
          <span class="line-bottom"></span>
        </div>
        <div class="ofcavas-menu">
          <ul>
            <li
              v-if="
               $route.name === 'SingleTestAnalysis' ||
              $route.name === 'HTMAAnalysis' ||
              $route.name === 'ComparisonTestAnalysis' ||
              $route.name === 'MetabolicBlueprintResult' ||
              $route.name === 'BloodMarkersIron' ||
              $route.name === 'BloodMarkersIodine' ||
              $route.name === 'BloodMarkersCopper' ||
              $route.name === 'NAQSymptomsOutput' ||
              $route.name === 'ConditionOutput' ||
              $route.name === 'ComparisonReport' ||
              $route.name === 'ClientReport' ||
              $route.name === 'ComparisonList'
              "
            >
              <a class="menu_item" @click="print()">Print to PDF</a>
            </li>
            <li>
            <a class="menu_item" target="_blank" href="https://www.learnhtma.com/apptroubleshooting/">Help</a>
          </li>
            <!-- <li>
              <a class="menu_item" v-on:click="onLogout()">Logout</a>
            </li> -->
             <li
          v-if="$route.name !== 'Login' && $route.name !== 'Register' && $route.name !== 'ForgotPassword' && $route.name !== 'PrivacyPolicy' && $route.name !== 'Home' && $route.name !== 'ResetPassword'"
          >
            <a class="menu_item" v-on:click="onLogout()">Logout</a>
          </li>
          <li
          v-if="$route.name === 'PrivacyPolicy' || $route.name === 'Home'"          >
          <router-link tag="a" class="menu_item" to="/login">Login</router-link>
          </li>
          <li
          v-if="$route.name === 'Login' || $route.name === 'Register' || $route.name === 'ForgotPassword' || $route.name === 'PrivacyPolicy' || $route.name === 'Home' || $route.name === 'ResetPassword'"          >
            <router-link tag="a" class="menu_item" to="/privacy-policy">Privacy Policy</router-link>
          </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapActions } from "vuex";
import generatePDF from "../../services/generatePDF";
import generateDOC from "../../services/generateDOC";

export default {
  name: "Header",
  components: {},
  data() {
    return {
      clientName: "",
      userLogin: localStorage.getItem("instant_token") ? localStorage.getItem("instant_token") : null
    };
  },
  mounted() {
    console.log(this.$route)
  },
  computed: {
    dateValue() {
      var date = new Date();
      return (
        (date.getMonth() > 8
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1)) +
        "/" +
        (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
        "/" +
        date.getFullYear()
      );
    },
  },
  methods: {
    ...mapActions(["logoutUser"]),

    onLogout() {
      this.logoutUser();
      this.$toastr.Add({
        name: "Success", // this is give you ability to use removeByName method
        msg: "You have Logged Out Successfully!", // Toast Message
        type: "success", // Toast type,
      });
      this.$router.push({ name: "Login" });
    },
    print() {
      this.clientName = localStorage.getItem("instant_clientname")
        ? localStorage.getItem("instant_clientname")
        : "";
        let originalTitle = document.title;
        if(this.$route.name === 'HTMAAnalysis'){
          document.title =
          "HTMA Analysis - " + this.clientName
        }
      if (this.$route.name === "SingleTestAnalysis") {
        document.title =
          "HTMA Results - " + this.clientName + " - " + this.dateValue;
      }
      if (this.$route.name === "ComparisonTestAnalysis") {
        document.title =
          "Comparison Test Analysis - " +
          this.clientName +
          " - " +
          this.dateValue;
      }
      if (this.$route.name === "ComparisonList") {
        document.title =
           "Comparison Analysis - "+this.clientName
      }
      if (this.$route.name === "MetabolicBlueprintResult") {
        document.title =
          "Metabolic Blueprint - " + this.clientName + " - " + this.dateValue;
      }
      if (this.$route.name === "BloodMarkersOutput") {
        document.title =
          "Blood Markers Output - " + this.clientName + " - " + this.dateValue;
      }
if (this.$route.name === "BloodMarkersIronOutput") {
        document.title =
          "Blood Markers Iron Output - " + this.clientName + " - " + this.dateValue;
      }
      if (this.$route.name === "BloodMarkersIodineOutput") {
        document.title =
          "Blood Markers Iodine Output - " + this.clientName + " - " + this.dateValue;
      }

      if (this.$route.name === "NAQSymptomsOutput") {
        document.title =
          "NAQ Symptoms Output - " + this.clientName + " - " + this.dateValue;
      }
      if (this.$route.name === "ConditionOutput") {
        document.title =
          "Conditions Output - " + this.clientName + " - " + this.dateValue;
      }
      if (this.$route.name === "ClientReport") {
        document.title =
          "Client Report - " + this.clientName + " - " + this.dateValue;
      }

      window.print();
      document.title = originalTitle;  
    },
    generateMetabolicBlueprint() {
      console.log("generateMetabolicBlueprint");
      generatePDF("hello world");
    },
    generateClientReport() {
      console.log("generateClientReport");
      generateDOC("Hello World");
    },
  },
};
</script>
<style>
.menu_item {
  cursor: pointer;
  text-decoration: none;
}
/* #menu li {
    display: inline-block;
    padding: 0 7px;
} */
@media print {
  .table-hidden{
      display: table !important;
      /* text-align: center; */
      /* table-layout: fixed; */
    }
    .table-hidden th{
      text-align: end !important;
    }

    .table-shown{
      display: none;
    }
   .no-print, .no-print *
    {
        display: none !important;
    }
    
  #sidebar-wrapper *,
  #header-print *,
  #sidebar-print *,
  #wrapper * {
    visibility: hidden;
  }
  /* page-content-wrapper */
  /* body * {
  visibility:hidden;
  } */
  #page-content-wrapper,
  #page-content-wrapper * {
    visibility: visible;
  }
  /* #page-content-wrapper {
    position: absolute;
    left: 0;
    top: 0;
  } */
  @page {
    size: letter;
    margin: 0mm;
  }
  .accoridian_bg, .middle_content_box-2{
    break-inside: avoid;
  }
  #header-print {
    display: none !important;
  }
  /* margin: 0;
    size: A3;
  } */
  body {
    overflow: visible !important;
    margin: 1.6cm;
    /* display:flex !important; */
  }
 /* .middle_content_list > li, .middle_content_list > span > li, .middle_content_list > li > div, .middle_content_list > li > div, .middle_content_list > li > div{
    break-inside: avoid;
    white-space: pre-wrap;
  } */
  
  .pg_break {
    clear: both;

    display: block !important;
    page-break-after: always;
    /* page-break-inside: avoid;
    -webkit-region-break-inside: avoid; */
    /* page-break-before: always !important;
    page-break-after: always !important; */
  }
}
</style>
