import { getReq, postReq, deleteReq, putReq } from "../../../services/api";

const state = {
  copperBloodMarkers: [],
  copperBloodMarkersReport: [],
  singleCopperBloodMarker:[],
};

const getters = {
  copperBloodMarkers: (state) => state.copperBloodMarkers,
  copperBloodMarkersReport: (state) => state.copperBloodMarkersReport,
  singleCopperBloodMarker: (state) => state.singleCopperBloodMarker,
};


const actions = {
  // Copper copperBloodMarkers
  async getCopperBloodMarkers({ commit },) {
    try {
      let copperBloodMarkers = await getReq(`copper-blood/`);
      commit("setCopperBloodMarkers", copperBloodMarkers);

    } catch (error) {
      console.log(error)
      console.log(error.response);
      throw error.response;
    }
  },

  async getSingleCopperBloodMarker({ commit },id) {
    try {
      let singleBloodMarker = await getReq(`copper-blood/${id}/`);
      commit("setSingleCopperBloodMarker", singleBloodMarker);
      
    } catch (error) {
      console.log(error)
      console.log(error.response);
      throw error.response;
    }
  },

  async addCopperBloodMarkers(_, payload) {
    try {
      const newCopperBloodMarkers = await postReq(`copper-blood/`, payload);
      return newCopperBloodMarkers

    } catch (error) {
      console.log(error.response.data);
      throw error.response.data;
    }
  },

  async updateCopperBloodMarkers({ commit }, { id, payload }) {
    try {
      console.log(commit)
      console.log(payload)
      const updatedData = await putReq("copper-blood/", id, payload);
      if (updatedData) {
        console.log(updatedData);
      }
      return updatedData
    } catch (e) {
      console.log(e)
      if (!e.response) {
        throw "No network connection"
      }
      const errors = e.response.data;
      console.log(errors)
      throw errors;
    }
  },


  async deleteCopperBloodMarkers(_, id) {
    try {
      return await deleteReq("copper-blood", id);
    } catch (e) {
      if (!e.response) {
        throw "No network connection"
      }
      const errors = e.response.data;
      console.log(errors)
      throw errors;
    }
  },

  // CopperBloodMarkers Unsaved Api

  async getCopperBloodMarkersReportUnsaved({commit},payload) {
    try {
       const copperBloodMarkersReport = await postReq(`copper-blood-unsaved/`,payload );
      commit("setCopperBloodMarkersReport", copperBloodMarkersReport);
       return copperBloodMarkersReport 
     
    } catch (error) {
      console.log(error.response.data);
      throw error.response.data;
    }
  },

  // CopperBloodMarkers Report

  async getCopperBloodMarkersReport({ commit }, id) {
    try {
      let copperBloodMarkersReport = await getReq(`copper-blood-marker-report/${id}/`);
      commit("setCopperBloodMarkersReport", copperBloodMarkersReport);

    } catch (error) {
      console.log(error)
      console.log(error.response);
      throw error.response;
    }
  },

};

const mutations = {
  setCopperBloodMarkers: (state, copperBloodMarkers) => (state.copperBloodMarkers = copperBloodMarkers),
  setCopperBloodMarkersReport: (state, copperBloodMarkersReport) => (state.copperBloodMarkersReport = copperBloodMarkersReport),
  setSingleCopperBloodMarker: (state, singleCopperBloodMarker) => (state.singleCopperBloodMarker = singleCopperBloodMarker),

};

export default {
  state,
  getters,
  actions,
  mutations,
};
