<template>
  <div>
    <div
      v-if="naqOnly"
      class="mx-auto w-100 submit-box"
      style="display: flex; justify-content: flex-end !important"
    >
      <button class="menu_item next-button" @click="onNext">Next</button>
    </div>
    <div :class="box" v-if="!naqOnly && !editNaqId">
      <button class="menu_item back-button" @click="onBack">Back</button>
      <button class="menu_item next-button" @click="onNext">Next</button>
    </div>
    <br />
    <div class="accoridian_bg mt_5 graph_box">
      <h2
        v-if="editNaqId"
        class="mx-2 mb-2 pt-2 mt-4"
        style="margin-top: 80px !important"
      >
        Edit NAQ
      </h2>
      <h2 v-else class="mx-2 mb-2">NAQ</h2>
      <div>
        <!-- search -->
        <div class="row mb-5" v-if="!editNaqId">
          <div class="col-lg-5 mx-auto w-100">
            <model-list-select
              :list="clientNameData"
              v-model="clientName"
              option-value="id"
              option-text="client_name"
              placeholder="Search Client Name"
            >
            </model-list-select>
          </div>
          <div class="col-lg-5">
            <model-list-select
              :list="datesData"
              v-model="naqDate"
              option-value="id"
              option-text="created"
              placeholder="Search NAQ Date"
            >
            </model-list-select>
          </div>
          <div class="col-lg-2 submit-box">
            <button
              v-if="naqId"
              @click="onReset()"
              class="menu_item submit-button"
            >
              Reset
            </button>
            <button v-else @click="search()" class="menu_item submit-button">
              Search
            </button>
          </div>
        </div>

        <!-- search -->

        <div class="card">
          <div class="card__item">
            <table id="comparison_tbl_naq">
              <tr></tr>
              <tr v-if="naqOnly || editNaqId">
                <td>Client Name *</td>
                <td class="right_col">
                  <input
                    type="text"
                    v-model="compVal.client_name"
                  />
                </td>
              </tr>
              <tr>
                <td>Upper GI</td>
                <td class="right_col">
                  <input
                    type="number"
                    min="0"
                    onkeypress="return event.charCode >= 48 || event.charCode == 46"
                    v-model="compVal.upper_gi"
                  />
                </td>
              </tr>
              <tr>
                <td>Liver and Gb</td>
                <td class="right_col">
                  <input
                    type="number"
                    min="0"
                    onkeypress="return event.charCode >= 48 || event.charCode == 46"
                    v-model="compVal.liver_and_gb"
                  />
                </td>
              </tr>
              <tr>
                <td>Small Intestine</td>
                <td class="right_col">
                  <input
                    type="number"
                    min="0"
                    onkeypress="return event.charCode >= 48 || event.charCode == 46"
                    v-model="compVal.small_intestine"
                  />
                </td>
              </tr>
              <tr>
                <td>Large Intestine</td>
                <td class="right_col">
                  <input
                    type="number"
                    min="0"
                    onkeypress="return event.charCode >= 48 || event.charCode == 46"
                    v-model="compVal.large_intestine"
                  />
                </td>
              </tr>
              <tr>
                <td>Mineral Needs</td>
                <td class="right_col">
                  <input
                    type="number"
                    min="0"
                    onkeypress="return event.charCode >= 48 || event.charCode == 46"
                    v-model="compVal.mineral_need"
                  />
                </td>
              </tr>
              <tr>
                <td>Fatty Acids</td>
                <td class="right_col">
                  <input
                    type="number"
                    min="0"
                    onkeypress="return event.charCode >= 48 || event.charCode == 46"
                    v-model="compVal.fatty_acid"
                  />
                </td>
              </tr>
              <tr>
                <td>Sugar Handling</td>
                <td class="right_col">
                  <input
                    type="number"
                    min="0"
                    onkeypress="return event.charCode >= 48 || event.charCode == 46"
                    v-model="compVal.sugar_handling"
                  />
                </td>
              </tr>
              <tr>
                <td>Vitamin Need</td>
                <td class="right_col">
                  <input
                    type="number"
                    min="0"
                    onkeypress="return event.charCode >= 48 || event.charCode == 46"
                    v-model="compVal.vitamin_need"
                  />
                </td>
              </tr>
              <tr>
                <td>Adrenals</td>
                <td class="right_col">
                  <input
                    type="number"
                    min="0"
                    onkeypress="return event.charCode >= 48 || event.charCode == 46"
                    v-model="compVal.adrenal"
                  />
                </td>
              </tr>
              <tr>
                <td>Pituitary</td>
                <td class="right_col">
                  <input
                    type="number"
                    min="0"
                    onkeypress="return event.charCode >= 48 || event.charCode == 46"
                    v-model="compVal.pituitary"
                  />
                </td>
              </tr>
              <tr>
                <td>Thyroid</td>
                <td class="right_col">
                  <input
                    type="number"
                    min="0"
                    onkeypress="return event.charCode >= 48 || event.charCode == 46"
                    v-model="compVal.thyroid"
                  />
                </td>
              </tr>
              <tr>
                <td>Cardiovascular</td>
                <td class="right_col">
                  <input
                    type="number"
                    min="0"
                    onkeypress="return event.charCode >= 48 || event.charCode == 46"
                    v-model="compVal.cardiovascular"
                  />
                </td>
              </tr>
              <tr>
                <td>Kidney/Bladder</td>
                <td class="right_col">
                  <input
                    type="number"
                    min="0"
                    onkeypress="return event.charCode >= 48 || event.charCode == 46"
                    v-model="compVal.kidney_or_bladder"
                  />
                </td>
              </tr>
              <tr>
                <td>Immune</td>
                <td class="right_col">
                  <input
                    type="number"
                    min="0"
                    onkeypress="return event.charCode >= 48 || event.charCode == 46"
                    v-model="compVal.immune"
                  />
                </td>
              </tr>
              <tr>
                <td>Female Only</td>
                <td class="right_col">
                  <input
                    type="number"
                    min="0"
                    onkeypress="return event.charCode >= 48 || event.charCode == 46"
                    v-model="compVal.female_only"
                  />
                </td>
              </tr>
              <tr>
                <td>Male Only</td>
                <td class="right_col">
                  <input
                    type="number"
                    min="0"
                    onkeypress="return event.charCode >= 48 || event.charCode == 46"
                    v-model="compVal.male_only"
                  />
                </td>
              </tr>
              <!-- <tr>
                <td class="header">Total:</td>
                <td class="right_col">
                  <input type="number" min="0" onkeypress="return event.charCode >= 48 || event.charCode == 46" readonly v-model="naq.total" />
                </td>
              </tr> -->
            </table>
          </div>
        </div>
      </div>
      <br />
      <div
        v-if="naqOnly"
        class="mx-auto w-100 submit-box"
        style="display: flex; justify-content: flex-end !important"
      >
        <button class="menu_item next-button" @click="onNext">Next</button>
      </div>
      <div
        class="submit-box"
        style="display: flex; justify-content: flex-end !important"
        v-if="editNaqId"
      >
        <!-- <button
        class="menu_item"
        @click="onBack"
      >
        Back
      </button> -->
        <button class="menu_item next-button" @click="onUpdate">Update</button>
      </div>
    </div>
    <div :class="box" v-if="!naqOnly && !editNaqId">
      <button class="menu_item back-button" @click="onBack">
        Back
      </button>
      <button class="menu_item next-button" @click="onNext">Next</button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import { ModelListSelect } from "vue-search-select";

export default {
  name: "NAQ",
  props: ["naq", "naqOnly"],
  components: {
    ModelListSelect,
  },

  data() {
    return {
      buttonNext: "Next",

      clientName: "",
      naqDate: "",
      naqId: null,
      allNaqData: [],
      clientNameData: [],
      datesData: [],

      editNaqId: null,
      editNaqData: [],
    };
  },
  watch: {
    clientName(value) {
      if (value) {
        console.log("value", value);
        this.datesData = this.allNaqData.filter(
          (data) =>
            data.client_name ===
            this.allNaqData.find((x) => x.id === value).client_name
        );
        console.log("data.created");
        this.datesData.map((data) => {
          data.created = moment(data.created).format("MM/DD/YYYY");
        });
        console.log("naqDatesData", this.datesData);
      }
    },
  },
  computed: {
    ...mapGetters(["allNaq", "singleNaq"]),
    box: function () {
      return this.naqOnly ? "submit-box" : "box";
    },
    compVal: {
      get() {
        if (this.editNaqId) {
          return this.editNaqData;
        } else {
          return this.naq;
        }
      },
      // set (val) {
      //   if (this.editNaqId) {
      //     this.editNaqData = val
      //   } else {
      //     this.naq = val
      //   }
      // }
    },
  },
  methods: {
    ...mapActions(["getAllNaq", "getSingleNaq", "updateNaq"]),

    async search() {
      console.log("submit");
      console.log("naq data id ", this.naqDate);
      console.log("input id searched is ", this.naqDate);
      this.naqId = this.naqDate;
      this.$emit("setNaqId", this.naqId);
    },
    onReset() {
      this.naqId = null;
      this.clientName = null;
      this.naqDate = null;
      console.log("naq id is", this.naqId);
      this.$emit("reset");
    },
    onNext() {
      if (this.naqOnly) {
        console.log("this.naq", this.naq.client_name);
        if (!this.naq.client_name) {
          this.$toastr.Add({
            name: "Error", // this is give you ability to use removeByName method
            msg: "Please Enter Client Name!", // Toast Message
            type: "error", // Toast type,
          });
        } else {
          this.$emit("nextStep", {});
          window.scrollTo(0, 0);
        }
      } else {
        this.$emit("nextStep", {});
        window.scrollTo(0, 0);
      }
    },
    onBack() {
      this.$emit("backStep");
      window.scrollTo(0, 0);
    },

    // Update

    async onUpdate() {
      if (!this.editNaqData.client_name) {
        this.$toastr.Add({
          name: "Error", // this is give you ability to use removeByName method
          msg: "Please Enter Client Name!", // Toast Message
          type: "error", // Toast type,
        });
      } else {
        this.$toastr.Add({
          name: "Success", // this is give you ability to use removeByName method
          msg: "NAQ has been updated Successfully!", // Toast Message
          type: "success", // Toast type,
        });
        console.log("updating");
        console.log("naq", this.naq);
        console.log("edit naq", this.editNaqData);
        const dataUpdate = await this.updateNaq({
          id: this.editNaqId,
          payload: this.editNaqData,
        });
        console.log("data has been edited ", dataUpdate);
        this.$router.push(`/client-list`);
      }
    },
  },
  async mounted() {
    if (this.naqOnly) {
      this.buttonNext = "Submit";
    }

    this.editNaqId = this.$route.params.id;
    if (this.editNaqId) {
      console.log(this.editNaqId);
      console.log("id getting");
      //  console.log(this.input)
      await this.getSingleNaq(this.editNaqId);
      this.editNaqData = await this.singleNaq;
      console.log(this.editNaqData);
      // this.naq = this.editNaqData;
      // this.editInputData = this.singleTestData[0];
      // this.editHTMAData = this.singleTestData[1];
    } else {
      await this.getAllNaq();
      this.allNaqData = await this.allNaq;
      console.log("naq data ", this.allNaqData);

      this.clientNameData = this.allNaqData.filter(
        (a, i) =>
          this.allNaqData.findIndex((s) => a.client_name === s.client_name) ===
          i
      );
    }
    // console.log("all naq are ", this.allNaqData);
    // console.log("all client names are ", this.clientNameData);
  },
};
</script>
<style>
.box {
  display: flex;
  justify-content: space-between !important;
  padding-right: 20px;
}
.submit-box {
  display: flex;
  justify-content: flex-end !important;
  padding-right: 20px;
}
.next-button {
  width: 100px;
}
</style>
