<template>
  <div class="card">
    <div class="card__item">
      <table id="comparison_tbl_htma">
        <tr>
          <th colspan="2">HTMA</th>
        </tr>
        <tr>
          <td>Ca/P - Autonomic Balance</td>
          <td class="right_col">
            <input
              type="number"
              :readonly="dataExported"
              min="0"
              onkeypress="return event.charCode >= 48 || event.charCode == 46"
              v-model="htmaData.ca_p_automonic_balance"
            />
          </td>
        </tr>
        <tr>
          <td>Na/K - Vitality</td>
          <td class="right_col">
            <input
              type="number"
              :readonly="dataExported"
              min="0"
              onkeypress="return event.charCode >= 48 || event.charCode == 46"
              v-model="htmaData.na_k_vitality"
            />
          </td>
        </tr>
        <tr>
          <td>Ca/K - Thyroid</td>
          <td class="right_col">
            <input
              type="number"
              :readonly="dataExported"
              min="0"
              onkeypress="return event.charCode >= 48 || event.charCode == 46"
              v-model="htmaData.ca_k_thyroid"
            />
          </td>
        </tr>
        <tr>
          <td>Zn/Cu - Hormonal/Stress</td>
          <td class="right_col">
            <input
              type="number"
              :readonly="dataExported"
              min="0"
              v-model="htmaData.zn_cu_hormonal_stress"
            />
          </td>
        </tr>
        <tr>
          <td>Na/Mg - Adrenal</td>
          <td class="right_col">
            <input
              type="number"
              :readonly="dataExported"
              min="0"
              onkeypress="return event.charCode >= 48 || event.charCode == 46"
              v-model="htmaData.na_mg_adrenal"
            />
          </td>
        </tr>
        <tr>
          <td>Ca/Mg - Blood Sugar</td>
          <td class="right_col">
            <input
              type="number"
              :readonly="dataExported"
              min="0"
              onkeypress="return event.charCode >= 48 || event.charCode == 46"
              v-model="htmaData.ca_mg_blood_sugar"
            />
          </td>
        </tr>
        <tr>
          <td>Fe/Cu - Infection</td>
          <td class="right_col">
            <input
              type="number"
              :readonly="dataExported"
              min="0"
              onkeypress="return event.charCode >= 48 || event.charCode == 46"
              v-model="htmaData.fe_cu_infection"
            />
          </td>
        </tr>
        <tr>
          <td>Cu/Mo - Copper Imbalance</td>
          <td class="right_col">
            <input
              type="number"
              :readonly="dataExported"
              min="0"
              v-model="htmaData.cu_mo_copper_imbalance"
            />
          </td>
        </tr>
        <tr>
          <td>Calcium</td>
          <td class="right_col">
            <input
              type="number"
              :readonly="dataExported"
              min="0"
              onkeypress="return event.charCode >= 48 || event.charCode == 46"
              v-model="htmaData.calcium"
            />
          </td>
        </tr>
        <tr>
          <td>Magnesium</td>
          <td class="right_col">
            <input
              type="number"
              :readonly="dataExported"
              min="0"
              onkeypress="return event.charCode >= 48 || event.charCode == 46"
              v-model="htmaData.magnesium"
            />
          </td>
        </tr>
        <tr>
          <td>Sodium</td>
          <td class="right_col">
            <input
              type="number"
              :readonly="dataExported"
              min="0"
              onkeypress="return event.charCode >= 48 || event.charCode == 46"
              v-model="htmaData.sodium"
            />
          </td>
        </tr>
        <tr>
          <td>Potassium</td>
          <td class="right_col">
            <input
              type="number"
              :readonly="dataExported"
              min="0"
              onkeypress="return event.charCode >= 48 || event.charCode == 46"
              v-model="htmaData.potassium"
            />
          </td>
        </tr>
        <tr>
          <td>Copper</td>
          <td class="right_col">
            <input
              type="number"
              :readonly="dataExported"
              min="0"
              onkeypress="return event.charCode >= 48 || event.charCode == 46"
              v-model="htmaData.copper"
            />
          </td>
        </tr>
        <tr>
          <td>Zinc</td>
          <td class="right_col">
            <input
              type="number"
              :readonly="dataExported"
              min="0"
              onkeypress="return event.charCode >= 48 || event.charCode == 46"
              v-model="htmaData.zinc"
            />
          </td>
        </tr>
        <tr>
          <td>Phosphorus</td>
          <td class="right_col">
            <input
              type="number"
              :readonly="dataExported"
              min="0"
              onkeypress="return event.charCode >= 48 || event.charCode == 46"
              v-model="htmaData.phosphorus"
            />
          </td>
        </tr>
        <tr>
          <td>Iron</td>
          <td class="right_col">
            <input
              type="number"
              :readonly="dataExported"
              min="0"
              onkeypress="return event.charCode >= 48 || event.charCode == 46"
              v-model="htmaData.iron"
            />
          </td>
        </tr>
        <tr>
          <td>Manganese</td>
          <td class="right_col">
            <input
              type="number"
              :readonly="dataExported"
              min="0"
              onkeypress="return event.charCode >= 48 || event.charCode == 46"
              v-model="htmaData.manganese"
            />
          </td>
        </tr>
        <tr>
          <td>Chromium</td>
          <td class="right_col">
            <input
              type="number"
              :readonly="dataExported"
              min="0"
              onkeypress="return event.charCode >= 48 || event.charCode == 46"
              v-model="htmaData.chromium"
            />
          </td>
        </tr>
        <tr>
          <td>Selenium</td>
          <td class="right_col">
            <input
              type="number"
              :readonly="dataExported"
              min="0"
              onkeypress="return event.charCode >= 48 || event.charCode == 46"
              v-model="htmaData.selenium"
            />
          </td>
        </tr>
        <tr>
          <td>Boron</td>
          <td class="right_col">
            <input
              type="number"
              :readonly="dataExported"
              min="0"
              onkeypress="return event.charCode >= 48 || event.charCode == 46"
              v-model="htmaData.boron"
            />
          </td>
        </tr>
        <tr>
          <td>Cobalt</td>
          <td class="right_col">
            <input
              type="number"
              :readonly="dataExported"
              min="0"
              onkeypress="return event.charCode >= 48 || event.charCode == 46"
              v-model="htmaData.cobalt"
            />
          </td>
        </tr>
        <tr>
          <td>Molybdenum</td>
          <td class="right_col">
            <input
              type="number"
              :readonly="dataExported"
              min="0"
              onkeypress="return event.charCode >= 48 || event.charCode == 46"
              v-model="htmaData.molybdenum"
            />
          </td>
        </tr>
        <tr>
          <td>Sulfur</td>
          <td class="right_col">
            <input
              type="number"
              :readonly="dataExported"
              min="0"
              onkeypress="return event.charCode >= 48 || event.charCode == 46"
              v-model="htmaData.sulfur"
            />
          </td>
        </tr>
        <tr>
          <td>Uranium</td>
          <td class="right_col">
            <input
              type="number"
              :readonly="dataExported"
              min="0"
              onkeypress="return event.charCode >= 48 || event.charCode == 46"
              v-model="htmaData.uranium"
            />
          </td>
        </tr>
        <tr>
          <td>Arsenic</td>
          <td class="right_col">
            <input
              type="number"
              :readonly="dataExported"
              min="0"
              onkeypress="return event.charCode >= 48 || event.charCode == 46"
              v-model="htmaData.arsenic"
            />
          </td>
        </tr>
        <tr>
          <td>Beryllium</td>
          <td class="right_col">
            <input
              type="number"
              :readonly="dataExported"
              min="0"
              onkeypress="return event.charCode >= 48 || event.charCode == 46"
              v-model="htmaData.beryllium"
            />
          </td>
        </tr>
        <tr>
          <td>Mercury</td>
          <td class="right_col">
            <input
              type="number"
              :readonly="dataExported"
              min="0"
              onkeypress="return event.charCode >= 48 || event.charCode == 46"
              v-model="htmaData.mercury"
            />
          </td>
        </tr>
        <tr>
          <td>Cadmium</td>
          <td class="right_col">
            <input
              type="number"
              :readonly="dataExported"
              min="0"
              onkeypress="return event.charCode >= 48 || event.charCode == 46"
              v-model="htmaData.cadmium"
            />
          </td>
        </tr>
        <tr>
          <td>Lead</td>
          <td class="right_col">
            <input
              type="number"
              :readonly="dataExported"
              min="0"
              onkeypress="return event.charCode >= 48 || event.charCode == 46"
              v-model="htmaData.lead"
            />
          </td>
        </tr>
        <tr>
          <td>Aluminum</td>
          <td class="right_col">
            <input
              type="number"
              :readonly="dataExported"
              min="0"
              onkeypress="return event.charCode >= 48 || event.charCode == 46"
              v-model="htmaData.aluminum"
            />
          </td>
        </tr>
        <tr>
          <td>Germanium</td>
          <td class="right_col">
            <input
              type="number"
              :readonly="dataExported"
              min="0"
              onkeypress="return event.charCode >= 48 || event.charCode == 46"
              v-model="htmaData.germanium"
            />
          </td>
        </tr>
        <tr>
          <td>Barium</td>
          <td class="right_col">
            <input
              type="number"
              :readonly="dataExported"
              min="0"
              onkeypress="return event.charCode >= 48 || event.charCode == 46"
              v-model="htmaData.barium"
            />
          </td>
        </tr>
        <tr>
          <td>Bismuth</td>
          <td class="right_col">
            <input
              type="number"
              :readonly="dataExported"
              min="0"
              onkeypress="return event.charCode >= 48 || event.charCode == 46"
              v-model="htmaData.bismuth"
            />
          </td>
        </tr>
        <tr>
          <td>Rubidium</td>
          <td class="right_col">
            <input
              type="number"
              :readonly="dataExported"
              min="0"
              onkeypress="return event.charCode >= 48 || event.charCode == 46"
              v-model="htmaData.rubidium"
            />
          </td>
        </tr>
        <tr>
          <td>Lithium</td>
          <td class="right_col">
            <input
              type="number"
              :readonly="dataExported"
              min="0"
              onkeypress="return event.charCode >= 48 || event.charCode == 46"
              v-model="htmaData.lithium"
            />
          </td>
        </tr>
        <tr>
          <td>Nickel</td>
          <td class="right_col">
            <input
              type="number"
              :readonly="dataExported"
              min="0"
              onkeypress="return event.charCode >= 48 || event.charCode == 46"
              v-model="htmaData.nickel"
            />
          </td>
        </tr>
        <tr>
          <td>Platinum</td>
          <td class="right_col">
            <input
              type="number"
              :readonly="dataExported"
              min="0"
              onkeypress="return event.charCode >= 48 || event.charCode == 46"
              v-model="htmaData.platinum"
            />
          </td>
        </tr>
        <tr>
          <td>Thallium</td>
          <td class="right_col">
            <input
              type="number"
              :readonly="dataExported"
              min="0"
              onkeypress="return event.charCode >= 48 || event.charCode == 46"
              v-model="htmaData.thallium"
            />
          </td>
        </tr>
        <tr>
          <td>Vanadium</td>
          <td class="right_col">
            <input
              type="number"
              :readonly="dataExported"
              min="0"
              onkeypress="return event.charCode >= 48 || event.charCode == 46"
              v-model="htmaData.vanadium"
            />
          </td>
        </tr>
        <tr>
          <td>Strontium</td>
          <td class="right_col">
            <input
              type="number"
              :readonly="dataExported"
              min="0"
              onkeypress="return event.charCode >= 48 || event.charCode == 46"
              v-model="htmaData.strontium"
            />
          </td>
        </tr>
        <tr>
          <td>Tin</td>
          <td class="right_col">
            <input
              type="number"
              :readonly="dataExported"
              min="0"
              onkeypress="return event.charCode >= 48 || event.charCode == 46"
              v-model="htmaData.tin"
            />
          </td>
        </tr>
        <tr>
          <td>Titanium</td>
          <td class="right_col">
            <input
              type="number"
              :readonly="dataExported"
              min="0"
              onkeypress="return event.charCode >= 48 || event.charCode == 46"
              v-model="htmaData.titanium"
            />
          </td>
        </tr>
        <tr>
          <td>Tungsten</td>
          <td class="right_col">
            <input
              type="number"
              :readonly="dataExported"
              min="0"
              onkeypress="return event.charCode >= 48 || event.charCode == 46"
              v-model="htmaData.tungsten"
            />
          </td>
        </tr>
        <tr>
          <td>Zirconium</td>
          <td class="right_col">
            <input
              type="number"
              :readonly="dataExported"
              min="0"
              onkeypress="return event.charCode >= 48 || event.charCode == 46"
              v-model="htmaData.zirconium"
            />
          </td>
        </tr>
      </table>
    </div>
    <!-- </div> -->

    <br /><br />
  </div>
</template>

<script>
export default {
  name: "HTMA",
  props: ["htmaData", "dataExported"],

  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
