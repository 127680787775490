<template>
  <div id="">
    <div v-if="loading" id="preloader">
      <div class="loader3">
        <span></span>
        <span></span>
      </div>
    </div>
    <main v-if="comparisonTestAnalysisData">
      <b-container
        fluid
        class="main_content_wrapper pl-4 mb-5 pb-5 main-container"
      >
        <h2 class="mx-2 pt-2 mb-2 text-center">
          Comparison Analysis for
          {{ testClientName }} <br />
        </h2>
        <h3
          v-if="
            comparisonTestAnalysisData.patterns.practitioner_name &&
            comparisonTestAnalysisData.patterns.practitioner_name[0]
          "
          class="mx-2 pt-2 mb-2 text-center"
        >
          Prepared by
          {{ comparisonTestAnalysisData.patterns.practitioner_name[0] }}

          on
          {{ dateValue }}
        </h3>
        <hr />
        <div class="pl-5"></div>
        <b-row>
          <b-col>
            <b-row>
              <b-col cols="12">
                <div class="data-table team-data-table bg-white p-4 rounded">
                  <div class="table_row team_table_row">
                    <div class="text-start"></div>
                    <br />
                    <div class="table-div">
                      <table class="table-hidden table">
                        <tr class="font-weight-bold">
                          <th>Pattern:</th>
                          <td>Positive Re-Test Patterns</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            <ul style="list-style-type: none">
                              <li
                                v-for="text in comparisonTestAnalysisData.patterns.positive_retest_pattern[
                                  index
                                ].filter((test) => test !== '')"
                                :key="text"
                              >
                                {{ text }}
                              </li>
                            </ul>
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Pattern:</th>
                          <td>Metabolic Type</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            <ul style="list-style-type: none">
                              <li
                                v-for="text in comparisonTestAnalysisData.patterns.metabolic_patterns[
                                  index
                                ].filter((test) => test !== '')"
                                :key="text"
                              >
                                {{ text }}
                              </li>
                            </ul>
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Pattern:</th>
                          <td>Mixed Oxidizer</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.patterns
                                .mixed_oxidation[index]
                            }}
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Pattern:</th>
                          <td>Inversion</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.patterns.inversions[
                                index
                              ]
                            }}
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Pattern:</th>
                          <td>Stress Patterns</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            <ul style="list-style-type: none">
                              <li
                                v-for="text in comparisonTestAnalysisData.patterns.stress_patterns[
                                  index
                                ].filter((test) => test !== '')"
                                :key="text"
                              >
                                {{ text }}
                              </li>
                            </ul>
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Pattern:</th>
                          <td>Psychological Patterns</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            <ul style="list-style-type: none">
                              <li
                                v-for="text in comparisonTestAnalysisData.patterns.psychological_patterns[
                                  index
                                ].filter((test) => test !== '')"
                                :key="text"
                              >
                                {{ text }}
                              </li>
                            </ul>
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Pattern:</th>
                          <td>Metabolic Patterns</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            <ul style="list-style-type: none">
                              <li
                                v-for="text in comparisonTestAnalysisData.patterns.metabolic_patterns[
                                  index
                                ].filter((test) => test !== '')"
                                :key="text"
                              >
                                {{ text }}
                              </li>
                            </ul>
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Indicators:</th>
                          <td>Glucose Intolerance</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.indicators
                                .glucose_intolerance.value[index]
                            }}
                          </td>
                        </tr>
                        <tr class="font-weight-bold">
                          <th>Indicators:</th>
                          <td>Glucose Intolerance</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            <ul style="list-style-type: none">
                              <li
                                v-for="text in comparisonTestAnalysisData.indicators.glucose_intolerance.text[
                                  index
                                ].filter((test) => test !== '')"
                                :key="text"
                              >
                                {{ text }}
                              </li>
                            </ul>
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Indicators:</th>
                          <td>Low Stomach Acid</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.indicators
                                .low_stomach_acid.value[index]
                            }}
                          </td>
                        </tr>
                        <tr class="font-weight-bold">
                          <th>Indicators:</th>
                          <td>Low Stomach Acid</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            <ul style="list-style-type: none">
                              <li
                                v-for="text in comparisonTestAnalysisData.indicators.low_stomach_acid.text[
                                  index
                                ].filter((test) => test !== '')"
                                :key="text"
                              >
                                {{ text }}
                              </li>
                            </ul>
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Indicators:</th>
                          <td>Burnout</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.indicators.burnout
                                .value[index]
                            }}
                          </td>
                        </tr>
                        <tr class="font-weight-bold">
                          <th>Indicators:</th>
                          <td>Burnout</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            <ul style="list-style-type: none">
                              <li
                                v-for="text in comparisonTestAnalysisData.indicators.burnout.text[
                                  index
                                ].filter((test) => test !== '')"
                                :key="text"
                              >
                                {{ text }}
                              </li>
                            </ul>
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Indicators:</th>
                          <td>Trauma</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.indicators.trauma
                                .value[index]
                            }}
                          </td>
                        </tr>
                        <tr class="font-weight-bold">
                          <th>Indicators:</th>
                          <td>Trauma</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            <ul style="list-style-type: none">
                              <li
                                v-for="text in comparisonTestAnalysisData.indicators.trauma.text[
                                  index
                                ].filter((test) => test !== '')"
                                :key="text"
                              >
                                {{ text }}
                              </li>
                            </ul>
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Indicators:</th>
                          <td>Impaired Immune</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.indicators
                                .impaired_immune_response.value[index]
                            }}
                          </td>
                        </tr>
                        <tr class="font-weight-bold">
                          <th>Indicators:</th>
                          <td>Impaired Immune</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            <ul style="list-style-type: none">
                              <li
                                v-for="text in comparisonTestAnalysisData.indicators.impaired_immune_response.text[
                                  index
                                ].filter((test) => test !== '')"
                                :key="text"
                              >
                                {{ text }}
                              </li>
                            </ul>
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Indicators:</th>
                          <td>Zinc Deficiency</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.indicators
                                .zinc_deficiency.value[index]
                            }}
                          </td>
                        </tr>
                        <tr class="font-weight-bold">
                          <th>Indicators:</th>
                          <td>Zinc Deficiency</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            <ul style="list-style-type: none">
                              <li
                                v-for="text in comparisonTestAnalysisData.indicators.zinc_deficiency.text[
                                  index
                                ].filter((test) => test !== '')"
                                :key="text"
                              >
                                {{ text }}
                              </li>
                            </ul>
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Indicators:</th>
                          <td>Copper Imbalance</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.indicators
                                .copper_imbalance.value[index]
                            }}
                          </td>
                        </tr>
                        <tr class="font-weight-bold">
                          <th>Indicators:</th>
                          <td>Copper Imbalance</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            <ul style="list-style-type: none">
                              <li
                                v-for="text in comparisonTestAnalysisData.indicators.copper_imbalance.text[
                                  index
                                ].filter((test) => test !== '')"
                                :key="text"
                              >
                                {{ text }}
                              </li>
                            </ul>
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Indicators:</th>
                          <td>Iron Interactions</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.indicators
                                .iron_interactions.value[index]
                            }}
                          </td>
                        </tr>
                        <tr class="font-weight-bold">
                          <th>Indicators:</th>
                          <td>Iron Interactions</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            <ul style="list-style-type: none">
                              <li
                                v-for="text in comparisonTestAnalysisData.indicators.iron_interactions.text[
                                  index
                                ].filter((test) => test !== '')"
                                :key="text"
                              >
                                {{ text }}
                              </li>
                            </ul>
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Indicators:</th>
                          <td>Iodine Deficiency</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.indicators
                                .iodine_deficiency.value[index]
                            }}
                          </td>
                        </tr>
                        <tr class="font-weight-bold">
                          <th>Indicators:</th>
                          <td>Iodine Deficiency</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            <ul style="list-style-type: none">
                              <li
                                v-for="text in comparisonTestAnalysisData.indicators.iodine_deficiency.text[
                                  index
                                ].filter((test) => test !== '')"
                                :key="text"
                              >
                                {{ text }}
                              </li>
                            </ul>
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Indicators:</th>
                          <td>Poor Eliminators</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.indicators
                                .poor_eliminators.value[index]
                            }}
                          </td>
                        </tr>
                        <tr class="font-weight-bold">
                          <th>Indicators:</th>
                          <td>Poor Eliminators</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            <ul style="list-style-type: none">
                              <li
                                v-for="text in comparisonTestAnalysisData.indicators.poor_eliminators.text[
                                  index
                                ].filter((test) => test !== '')"
                                :key="text"
                              >
                                {{ text }}
                              </li>
                            </ul>
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Indicators:</th>
                          <td>Very Poor Eliminators</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.indicators
                                .very_poor_eliminators.value[index]
                            }}
                          </td>
                        </tr>
                        <tr class="font-weight-bold">
                          <th>Indicators:</th>
                          <td>Very Poor Eliminators</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            <ul style="list-style-type: none">
                              <li
                                v-for="text in comparisonTestAnalysisData.indicators.very_poor_eliminators.text[
                                  index
                                ].filter((test) => test !== '')"
                                :key="text"
                              >
                                {{ text }}
                              </li>
                            </ul>
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th colspan="2">Heavy Metals:</th>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            <ul style="list-style-type: none">
                              <li
                                v-for="text in comparisonTestAnalysisData.heavy_metals[
                                  index
                                ].filter((test) => test !== '')"
                                :key="text"
                              >
                                {{ text }}
                              </li>
                            </ul>
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Ratios:</th>
                          <td>Ca/P Autonomic Balance</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.ratio.ca_p[index][0]
                            }}
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Ratios:</th>
                          <td>Na/K Vitality</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.ratio.na_k[index][0]
                            }}
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Ratios:</th>
                          <td>Ca/K Thyroid</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.ratio.ca_k[index][0]
                            }}
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Ratios:</th>
                          <td>Zn/Cu Hormonal/Stress</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.ratio.zn_cu[index][0]
                            }}
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Ratios:</th>
                          <td>Na/Mg Adrenal</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.ratio.na_mg[index][0]
                            }}
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Ratios:</th>
                          <td>Ca/Mg Blood Sugar</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.ratio.ca_mg[index][0]
                            }}
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Ratios:</th>
                          <td>Fe/Cu Infection</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.ratio.fe_cu[index][0]
                            }}
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Ratios:</th>
                          <td>Cu/Mo Copper Imbalance</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.ratio.ca_mo[index][0]
                            }}
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Minerals:</th>
                          <td>Calcium</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.minerals.calcium[index]
                            }}
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Minerals:</th>
                          <td>Magnesium</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.minerals.magnesium[
                                index
                              ]
                            }}
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Minerals:</th>
                          <td>Sodium</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.minerals.sodium[index]
                            }}
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Minerals:</th>
                          <td>Potassium</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.minerals.potassium[
                                index
                              ]
                            }}
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Minerals:</th>
                          <td>Copper</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.minerals.copper[index]
                            }}
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Minerals:</th>
                          <td>Zinc</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.minerals.zinc[index]
                            }}
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Minerals:</th>
                          <td>Phosphorus</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.minerals.phosphorus[
                                index
                              ]
                            }}
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Minerals:</th>
                          <td>Iron</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.minerals.iron[index]
                            }}
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Minerals:</th>
                          <td>Manganese</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.minerals.manganese[
                                index
                              ]
                            }}
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Minerals:</th>
                          <td>Chromium</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.minerals.chromium[
                                index
                              ]
                            }}
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Minerals:</th>
                          <td>Selenium</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.minerals.selenium[
                                index
                              ]
                            }}
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Minerals:</th>
                          <td>Boron</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.minerals.boron[index]
                            }}
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Minerals:</th>
                          <td>Cobalt</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.minerals.cobalt[index]
                            }}
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Minerals:</th>
                          <td>Molybdenum</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.minerals.molybdenum[
                                index
                              ]
                            }}
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Minerals:</th>
                          <td>Uranium</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.minerals.uranium[index]
                            }}
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Minerals:</th>
                          <td>Arsenic</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.minerals.arsenic[index]
                            }}
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Minerals:</th>
                          <td>Beryllium</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.minerals.beryllium[
                                index
                              ]
                            }}
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Minerals:</th>
                          <td>Mercury</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.minerals.mercury[index]
                            }}
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Minerals:</th>
                          <td>Cadmium</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.minerals.cadmium[index]
                            }}
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Minerals:</th>
                          <td>Lead</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.minerals.lead[index]
                            }}
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Minerals:</th>
                          <td>Aluminum</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.minerals.aluminum[
                                index
                              ]
                            }}
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Minerals:</th>
                          <td>Germanium</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.minerals.germanium[
                                index
                              ]
                            }}
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Minerals:</th>
                          <td>Barium</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.minerals.barium[index]
                            }}
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Minerals:</th>
                          <td>Bismuth</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.minerals.bismuth[index]
                            }}
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Minerals:</th>
                          <td>Rubidium</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.minerals.rubidium[
                                index
                              ]
                            }}
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Minerals:</th>
                          <td>Lithium</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.minerals.lithium[index]
                            }}
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Minerals:</th>
                          <td>Nickel</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.minerals.nickel[index]
                            }}
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Minerals:</th>
                          <td>Platinum</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.minerals.platinum[
                                index
                              ]
                            }}
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Minerals:</th>
                          <td>Thallium</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.minerals.thallium[
                                index
                              ]
                            }}
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Minerals:</th>
                          <td>Vanadium</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.minerals.vanadium[
                                index
                              ]
                            }}
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Minerals:</th>
                          <td>Strontium</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.minerals.strontium[
                                index
                              ]
                            }}
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Minerals:</th>
                          <td>Tin</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{ comparisonTestAnalysisData.minerals.tin[index] }}
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Minerals:</th>
                          <td>Titanium</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.minerals.titanium[
                                index
                              ]
                            }}
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Minerals:</th>
                          <td>Tungsten</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.minerals.tungsten[
                                index
                              ]
                            }}
                          </td>
                        </tr>

                        <tr class="font-weight-bold">
                          <th>Minerals:</th>
                          <td>Zirconium</td>
                        </tr>
                        <tr
                          v-for="(date, index) in comparisonTestAnalysisData
                            .patterns.date"
                          :key="date"
                        >
                          <th class="font-weight-bold">
                            {{ getMomentDate(date) }}
                          </th>
                          <td>
                            {{
                              comparisonTestAnalysisData.minerals.zirconium[
                                index
                              ]
                            }}
                          </td>
                        </tr>
                      </table>

                      <!-- 

                       -->

                      <table class="table-comparision table-shown table">
                        <tr>
                          <!-- <td></td> -->
                          <td class="date"></td>
                          <th
                            class="date"
                            v-for="date in comparisonTestAnalysisData.patterns
                              .date"
                            :key="date"
                          >
                            {{ getMomentDate(date) }}
                          </th>
                        </tr>
                        <tr>
                          <th :colspan="totalCount" class="table-heading">
                            Patterns
                          </th>
                        </tr>
                        <tr>
                          <!-- <tr> -->
                          <th>Positive Re-Test Patterns</th>
                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .patterns.positive_retest_pattern"
                            :key="index"
                          >
                            <ul style="list-style-type: none">
                              <li
                                class="mb-2"
                                v-for="text in item.filter(
                                  (test) => test !== ''
                                )"
                                :key="text"
                              >
                                {{ text }}
                              </li>
                            </ul>
                            <!-- {{ item }} -->
                          </td>
                          <!-- </tr> -->
                        </tr>
                        <tr>
                          <th>Metabolic Type</th>
                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .patterns.metabolic_type"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <th>Mixed Oxidizer</th>
                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .patterns.mixed_oxidation"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <th>Inversion</th>
                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .patterns.inversions"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <th>Stress Patterns</th>
                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .patterns.stress_patterns"
                            :key="index"
                          >
                            <ul style="list-style-type: none">
                              <li
                                v-for="text in item.filter(
                                  (test) => test !== ''
                                )"
                                :key="text"
                              >
                                {{ text }}
                              </li>
                            </ul>
                            <!-- {{ item }} -->
                          </td>
                        </tr>
                        <tr>
                          <th>Psychological Patterns</th>
                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .patterns.psychological_patterns"
                            :key="index"
                          >
                            <ul style="list-style-type: none">
                              <li
                                v-for="text in item.filter(
                                  (test) => test !== ''
                                )"
                                :key="text"
                              >
                                {{ text }}
                              </li>
                            </ul>
                            <!-- {{ item }} -->
                          </td>
                        </tr>
                        <tr>
                          <th>Metabolic Patterns</th>
                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .patterns.metabolic_patterns"
                            :key="index"
                          >
                            <ul style="list-style-type: none">
                              <li
                                v-for="text in item.filter(
                                  (test) => test !== ''
                                )"
                                :key="text"
                              >
                                {{ text }}
                              </li>
                            </ul>
                            <!-- {{ item }} -->
                          </td>
                        </tr>
                        <!-- <tr>
                          <td
                            :colspan="
                              totalCount
                            "
                          ></td>
                        </tr> -->
                        <!--  -->
                        <tr>
                          <th :colspan="totalCount" class="table-heading">
                            Indicators
                          </th>
                        </tr>
                        <tr>
                          <th rowspan="2">Glucose Intolerance</th>

                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .indicators.glucose_intolerance.value"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .indicators.glucose_intolerance.text"
                            :key="index"
                          >
                            <ul style="list-style-type: none">
                              <li
                                v-for="text in item.filter(
                                  (test) => test !== ''
                                )"
                                :key="text"
                              >
                                {{ text }}
                              </li>
                            </ul>
                          </td>
                        </tr>
                        <!--  -->
                        <tr>
                          <th rowspan="2">Low Stomach Acid</th>

                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .indicators.low_stomach_acid.value"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <td
                            v-for="(
                              item, index
                            ) in comparisonTestAnalysisData.indicators.low_stomach_acid.text.filter(
                              (test) => test !== ''
                            )"
                            :key="index"
                          >
                            <ul style="list-style-type: none">
                              <li class="mb-2" v-for="text in item" :key="text">
                                {{ text }}
                              </li>
                            </ul>
                          </td>
                        </tr>
                        <!--  -->
                        <tr>
                          <th rowspan="2">Burnout</th>

                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .indicators.burnout.value"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <td
                            v-for="(
                              item, index
                            ) in comparisonTestAnalysisData.indicators.burnout.text.filter(
                              (test) => test !== ''
                            )"
                            :key="index"
                          >
                            <ul style="list-style-type: none">
                              <li v-for="text in item" :key="text" class="mb-2">
                                {{ text }}
                              </li>
                            </ul>
                          </td>
                        </tr>
                        <!--  -->
                        <tr>
                          <th rowspan="2">Trauma</th>

                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .indicators.trauma.value"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <td
                            v-for="(
                              item, index
                            ) in comparisonTestAnalysisData.indicators.trauma.text.filter(
                              (test) => test !== ''
                            )"
                            :key="index"
                          >
                            <ul style="list-style-type: none">
                              <li v-for="text in item" :key="text" class="mb-2">
                                {{ text }}
                              </li>
                            </ul>
                          </td>
                        </tr>
                        <!--  -->
                        <tr>
                          <th rowspan="2">Impaired Immune</th>

                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .indicators.impaired_immune_response.value"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <td
                            v-for="(
                              item, index
                            ) in comparisonTestAnalysisData.indicators.impaired_immune_response.text.filter(
                              (test) => test !== ''
                            )"
                            :key="index"
                          >
                            <ul style="list-style-type: none">
                              <li v-for="text in item" :key="text" class="mb-2">
                                {{ text }}
                              </li>
                            </ul>
                          </td>
                        </tr>
                        <!--  -->
                        <tr>
                          <th rowspan="2">Zinc Deficiency</th>

                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .indicators.zinc_deficiency.value"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <td
                            v-for="(
                              item, index
                            ) in comparisonTestAnalysisData.indicators.zinc_deficiency.text.filter(
                              (test) => test !== ''
                            )"
                            :key="index"
                          >
                            <ul style="list-style-type: none">
                              <li v-for="text in item" :key="text" class="mb-2">
                                {{ text }}
                              </li>
                            </ul>
                          </td>
                        </tr>
                        <!--  -->
                        <tr>
                          <th rowspan="2">Copper Imbalance</th>

                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .indicators.copper_imbalance.value"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <td
                            v-for="(
                              item, index
                            ) in comparisonTestAnalysisData.indicators.copper_imbalance.text.filter(
                              (test) => test !== ''
                            )"
                            :key="index"
                          >
                            <ul style="list-style-type: none">
                              <li v-for="text in item" :key="text" class="mb-2">
                                {{ text }}
                              </li>
                            </ul>
                          </td>
                        </tr>
                        <!--  -->
                        <tr>
                          <th rowspan="2">Iron Interactions</th>

                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .indicators.iron_interactions.value"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <td
                            v-for="(
                              item, index
                            ) in comparisonTestAnalysisData.indicators.iron_interactions.text.filter(
                              (test) => test !== ''
                            )"
                            :key="index"
                          >
                            <ul style="list-style-type: none">
                              <li v-for="text in item" :key="text" class="mb-2">
                                {{ text }}
                              </li>
                            </ul>
                          </td>
                        </tr>
                        <!--  -->
                        <tr>
                          <th rowspan="2">Iodine Deficiency</th>

                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .indicators.iodine_deficiency.value"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <td
                            v-for="(
                              item, index
                            ) in comparisonTestAnalysisData.indicators.iodine_deficiency.text.filter(
                              (test) => test !== ''
                            )"
                            :key="index"
                          >
                            <ul style="list-style-type: none">
                              <li v-for="text in item" :key="text" class="mb-2">
                                {{ text }}
                              </li>
                            </ul>
                          </td>
                        </tr>
                        <!--  -->
                        <tr>
                          <th rowspan="2">Poor Eliminators</th>

                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .indicators.poor_eliminators.value"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <td
                            v-for="(
                              item, index
                            ) in comparisonTestAnalysisData.indicators.poor_eliminators.text.filter(
                              (test) => test !== ''
                            )"
                            :key="index"
                          >
                            <ul style="list-style-type: none">
                              <li v-for="text in item" :key="text" class="mb-2">
                                {{ text }}
                              </li>
                            </ul>
                          </td>
                        </tr>
                        <!--  -->
                        <tr>
                          <th rowspan="2">Very Poor Eliminators</th>

                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .indicators.very_poor_eliminators.value"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <td
                            v-for="(
                              item, index
                            ) in comparisonTestAnalysisData.indicators.very_poor_eliminators.text.filter(
                              (test) => test !== ''
                            )"
                            :key="index"
                          >
                            <ul style="list-style-type: none">
                              <li v-for="text in item" :key="text" class="mb-2">
                                {{ text }}
                              </li>
                            </ul>
                          </td>
                        </tr>
                        <!-- <tr>
                          <td
                            :colspan="
                              totalCount
                            "
                          ></td>
                        </tr> -->
                        <tr>
                          <th :colspan="totalCount" class="table-heading">
                            Heavy Metals
                          </th>
                        </tr>
                        <!--  -->
                        <tr>
                          <th>Heavy Metals</th>

                          <td
                            v-for="(
                              item, index
                            ) in comparisonTestAnalysisData.heavy_metals"
                            :key="index"
                          >
                            <ul style="list-style-type: none">
                              <li v-for="text in item" :key="text" class="mb-2">
                                {{ text }}
                              </li>
                            </ul>
                          </td>
                        </tr>
                        <!--  -->
                        <!-- <tr>
                          <td
                            :colspan="
                             totalCount
                            "
                          ></td>
                        </tr> -->
                        <tr>
                          <th :colspan="totalCount" class="table-heading">
                            Ratios
                          </th>
                        </tr>
                        <!--  -->
                        <tr>
                          <th>Ca/P Autonomic Balance</th>

                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .ratio.ca_p"
                            :key="index"
                          >
                            <span :class="'ratios-' + item[1]">
                              {{ item[0] }}
                            </span>
                          </td>
                        </tr>
                        <!--  -->
                        <tr>
                          <th>Na/K Vitality</th>

                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .ratio.na_k"
                            :key="index"
                          >
                            <span :class="'ratios-' + item[1]">
                              {{ item[0] }}
                            </span>
                          </td>
                        </tr>
                        <!--  -->
                        <tr>
                          <th>Ca/K Thyroid</th>

                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .ratio.ca_k"
                            :key="index"
                          >
                            <span :class="'ratios-' + item[1]">
                              {{ item[0] }}
                            </span>
                          </td>
                        </tr>
                        <!--  -->
                        <tr>
                          <th>Zn/Cu Hormonal/Stress</th>

                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .ratio.zn_cu"
                            :key="index"
                          >
                            <span :class="'ratios-' + item[1]">
                              {{ item[0] }}
                            </span>
                          </td>
                        </tr>
                        <!--  -->
                        <tr>
                          <th>Na/Mg Adrenal</th>

                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .ratio.na_mg"
                            :key="index"
                          >
                            <span :class="'ratios-' + item[1]">
                              {{ item[0] }}
                            </span>
                          </td>
                        </tr>
                        <!--  -->
                        <tr>
                          <th>Ca/Mg Blood Sugar</th>

                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .ratio.ca_mg"
                            :key="index"
                          >
                            <span :class="'ratios-' + item[1]">
                              {{ item[0] }}
                            </span>
                          </td>
                        </tr>
                        <!--  -->
                        <tr>
                          <th>Fe/Cu Infection</th>

                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .ratio.fe_cu"
                            :key="index"
                          >
                            <span :class="'ratios-' + item[1]">
                              {{ item[0] }}
                            </span>
                          </td>
                        </tr>
                        <!--  -->
                        <tr>
                          <th>Cu/Mo Copper Imbalance</th>

                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .ratio.ca_mo"
                            :key="index"
                          >
                            <span :class="'ratios-' + item[1]">
                              {{ item[0] }}
                            </span>
                          </td>
                        </tr>
                        <!--  -->
                        <!--  -->
                        <!-- <tr>
                          <td
                            :colspan="
                              totalCount
                            "
                          ></td>
                        </tr> -->
                        <tr>
                          <th :colspan="totalCount" class="table-heading">
                            Minerals
                          </th>
                        </tr>
                        <tr></tr>
                        <tr>
                          <th>Calcium</th>
                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .minerals.calcium"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <th>Magnesium</th>
                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .minerals.magnesium"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <th>Sodium</th>
                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .minerals.sodium"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <th>Potassium</th>
                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .minerals.potassium"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <th>Copper</th>
                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .minerals.copper"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <th>Zinc</th>
                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .minerals.zinc"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <th>Phosphorus</th>
                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .minerals.phosphorus"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <th>Iron</th>
                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .minerals.iron"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <th>Manganese</th>
                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .minerals.manganese"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <th>Chromium</th>
                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .minerals.chromium"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <th>Selenium</th>
                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .minerals.selenium"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <th>Boron</th>
                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .minerals.boron"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <th>Cobalt</th>
                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .minerals.cobalt"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <th>Molybdenum</th>
                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .minerals.molybdenum"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <th>Sulfur</th>
                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .minerals.sulfur"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <th>Uranium</th>
                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .minerals.uranium"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <th>Arsenic</th>
                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .minerals.arsenic"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <th>Beryllium</th>
                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .minerals.beryllium"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <th>Mercury</th>
                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .minerals.mercury"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <th>Cadmium</th>
                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .minerals.cadmium"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <th>Lead</th>
                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .minerals.lead"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <th>Aluminum</th>
                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .minerals.aluminum"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <th>Germanium</th>
                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .minerals.germanium"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <th>Barium</th>
                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .minerals.barium"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <th>Bismuth</th>
                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .minerals.bismuth"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <th>Rubidium</th>
                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .minerals.rubidium"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <th>Lithium</th>
                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .minerals.lithium"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <th>Nickel</th>
                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .minerals.nickel"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <th>Platinum</th>
                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .minerals.platinum"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <th>Thallium</th>
                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .minerals.thallium"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <th>Vanadium</th>
                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .minerals.vanadium"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <th>Strontium</th>
                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .minerals.strontium"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <th>Tin</th>
                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .minerals.tin"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <th>Titanium</th>
                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .minerals.titanium"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <th>Tungsten</th>
                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .minerals.tungsten"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                        <tr>
                          <th>Zirconium</th>
                          <td
                            v-for="(item, index) in comparisonTestAnalysisData
                              .minerals.zirconium"
                            :key="index"
                          >
                            {{ item }}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </main>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "ComparisonList",
  components: {},
  data() {
    return {
      comparisonTestAnalysisData: null,
      loading: true,
      testClientName: "",
    };
  },
  watch: {},
  computed: {
    ...mapGetters(["comparisonTestAnalysis"]),
    dateValue() {
      var date = new Date();
      return (
        (date.getMonth() > 8
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1)) +
        "/" +
        (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
        "/" +
        date.getFullYear()
      );
    },
    totalCount() {
      console.log(
        "this.comparisonTestAnalysisData.patterns.client_name.length + 2",
        this.comparisonTestAnalysisData.patterns.client_name.length + 1
      );
      return this.comparisonTestAnalysisData.patterns.client_name.length + 1;
    },
    rowSpanCount() {
      // console.log(
      //   "this.comparisonTestAnalysisData.patterns.client_name.length + 2",
      //   this.comparisonTestAnalysisData.patterns.client_name.length + 1
      // );
      return this.comparisonTestAnalysisData.patterns.client_name.length * 2;
    },
    dataCount() {
      return this.comparisonTestAnalysisData.patterns.client_name.length;
    },
  },
  methods: {
    ...mapActions(["getComparisonTestAnalysis"]),
    showLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
    getMomentDate: function (date) {
      return moment(date).format("MM/DD/YYYY");
    },
  },
  async mounted() {
    this.testClientName = this.$route.params.client_name;
    console.log("here is comparions all", this.testClientName);
    await this.getComparisonTestAnalysis(this.testClientName);
    this.comparisonTestAnalysisData = await this.comparisonTestAnalysis;
    localStorage.setItem("instant_clientname", `${this.testClientName}`);
    console.log("comparison test data ", this.comparisonTestAnalysisData);
    console.log(this.comparisonTestAnalysisData.heavy_metals);
    this.showLoading();
  },
};
</script>

<style>
.table-hidden {
  width: 100% !important;
}
.label {
  text-transform: uppercase;
  color: var(--black) !important;
  font-size: var(--extrasmall);
}

.page-item.active .page-link {
  color: white !important;
  background-color: #884c42 !important;
  border-color: #884c42 !important;
}

.show-entries label {
  font-weight: 500;
  margin: auto 10px;
}
.table-hidden {
  display: none;
  /* visibility: hidden; */
}
.table-comparision,
.table-comparision th,
.table-comparision td {
  padding: 1.2rem;
  /* font-size: 18px; */
  font-family: "Inter", sans-serif;
  border: 1px solid #d6d5d5;
  border-collapse: collapse;
  text-align: center;
}
.table-comparision th,
.table-comparision td {
  vertical-align: middle;
}
.table-heading {
  /* transform: rotate(270deg) !important;*/
  text-align: center !important;
  background-color: #f2f2f2;
}
.table-div {
  overflow-x: scroll;
}
/* .blue-color{
  color: #d3e7fa;
}
.red-color{
  color: #fadad7;
} */
/* .heading{
  color: #f2f2f2;
} */
.date {
  color: #fff;
  text-align: center !important;
  background-color: #884c42;
}
.ratios-red {
  font-size: 28px;
  font-weight: bold;
  background-color: #fadad7;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: 0 auto;
}
.ratios-blue {
  font-size: 28px;
  font-weight: bold;
  background-color: #d3e7fa;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: 0 auto;
}
.ratios-black {
  font-size: 28px;
  font-weight: bold;
}
/* .border{
  color: #d6d5d5;
} */
</style>
