<template>
 <Stripe/>
</template>

<script>
import Stripe from "../../components/Stripe/Stripe.vue";
export default {
  name: "Payment",
  components:{Stripe},
  
};

</script>
