<template>
  <div id="">
    <div v-if="loading" id="preloader">
      <div class="loader3">
        <span></span>
        <span></span>
      </div>
    </div>
    <main>
      <div class="main_content_wrapper">
        <br />
        <h2 class="mx-2 pt-2 mb-2 text-center">
          Conditions Results for
          {{ conditionReportData.client_name }}
        </h2>
        <h3 class="mx-2 pt-2 mb-2 text-center">
          Prepared on
          {{ dateValue }}
        </h3>
        <hr />
        <div class="container">
          <div class="row">
            <div class="col-lg-12 pt_40">
              <div class="accorodion_wrapper" id="accordian">
                <conditions
                  :singleTestAnalysisData="
                    conditionReportData.condition_response
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Conditions from "../../components/SingleTestView/Conditions.vue";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "ConditionOutput",
  components: {
    Conditions,
  },
  data() {
    return {
      conditionReportData: {},
      formId: null,
      loading: true,
    };
  },
  watch: {},
  computed: {
    ...mapGetters(["conditionReport"]),
    dateValue() {
      var date = new Date(this.conditionReportData.date);
      return (
        (date.getMonth() > 8
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1)) +
        "/" +
        (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
        "/" +
        date.getFullYear()
      );
    },
  },
  methods: {
    ...mapActions(["getConditionReport","getConditionReportUnsaved"]),
    showLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
  },
  async mounted() {
    this.formId = this.$route.params.id;
    if (this.formId != 0) {
      console.log("condition is", this.formId);
      await this.getConditionReport(this.formId);
    } else {
      const condition = JSON.parse(sessionStorage.getItem("condition"));
      console.log("condition raw data", condition);

      await this.getConditionReportUnsaved(condition);
    }
    // await this.getConditionReport(this.formId);
    this.conditionReportData = await this.conditionReport;
    console.log(this.conditionReportData);
    localStorage.setItem(
      "instant_clientname",
      `${this.conditionReportData.client_name}`
    );
    this.showLoading();
  },
};
</script>

