import { getReq, postReq, deleteReq, putReq } from "../../../services/api";

const state = {
  allNaqHtma:[],
  naqHtmaReport:[],
  singleNaqHtma:[]

};

const getters = {
  allNaqHtma: (state) => state.allNaqHtma,
  naqHtmaReport: (state) => state.naqHtmaReport,
  singleNaqHtma: (state) => state.singleNaqHtma,

};


const actions = {
  // Naq + Htma

  async getAllNaqHtma({ commit },) {
    try {
      let allNaqHtma = await getReq(`naq-htma/`);
      commit("setAllNaqHtma", allNaqHtma);
      
    } catch (error) {
      console.log(error)
      console.log(error.response);
      throw error.response;
    }
  },

  async getSingleNaqHtma({ commit },id) {
    try {
      let singleNaqHtma = await getReq(`naq-htma/${id}/`);
      console.log('sngle naq htma data ', singleNaqHtma)
      commit("setSingleNaqHtma", singleNaqHtma);
      
    } catch (error) {
      console.log(error)
      console.log(error.response);
      throw error.response;
    }
  },

  
   async addNaqHtma(_,payload) {
     
    try {
       const newNaqHtma = await postReq(`naq-htma/`,payload );
       return newNaqHtma
     
    } catch (error) {
      console.log(error.response.data);
      throw error.response.data;
    }
  },
  
  // async updateNaqHtma({ commit }, { id, payload }) {
  //   console.log(commit)
  //   try {
  //     const updatedData = await patchReq("naq-htma/", id, payload);
  //     if (updatedData) {
  //         console.log(updatedData);
  //     }
  //   } catch (e) {
  //       console.log(e)
  //       if(!e.response){
  //           throw "No network connection"
  //       }
  //       const errors = e.response.data;
  //       console.log(errors)
  //       throw errors;
  //   }
  // },
  async updateNaqHtma({ commit }, { id, payload }) {
    console.log(commit)
    try {
      const updatedData = await putReq("naq-htma/", id, payload);
      if (updatedData) {
          console.log(updatedData);
      }
      return updatedData
    } catch (e) {
        console.log(e)
        if(!e.response){
            throw "No network connection"
        }
        const errors = e.response.data;
        console.log(errors)
        throw errors;
    }
  },
  
  async deleteNaqHtma(_, id) {
    try {
        return await deleteReq("naq-htma", id);
    } catch (e) {
        if(!e.response){
            throw "No network connection"
        }
        const errors = e.response.data;
        console.log(errors)
        throw errors;
    }
  },

  // NAQ HTMA Unsaved Api

  async getNaqHtmaReportUnsaved({commit},payload) {
    try {
       const naqHtmaReport = await postReq(`naq-htma-unsaved/`,payload );
      commit("setNaqHtmaReport", naqHtmaReport);
       return naqHtmaReport 
     
    } catch (error) {
      console.log(error.response.data);
      throw error.response.data;
    }
  },

  // naqHtma Report

  async getNaqHtmaReport({ commit },id) {
    try {
      let naqHtmaReport = await getReq(`naqhtma-report/${id}/`);
      commit("setNaqHtmaReport", naqHtmaReport);
      
    } catch (error) {
      console.log(error)
      console.log(error.response);
      throw error.response;
    }
  },

};

const mutations = {
  setAllNaqHtma: (state, allNaqHtma) => (state.allNaqHtma = allNaqHtma),
  setNaqHtmaReport: (state, naqHtmaReport) => (state.naqHtmaReport = naqHtmaReport),

  setSingleNaqHtma: (state, singleNaqHtma) => (state.singleNaqHtma = singleNaqHtma),

};

export default {
  state,
  getters,
  actions,
  mutations,
};
