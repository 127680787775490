<template>
  <div id="">
    <div v-if="loading" id="preloader">
      <div class="loader3">
        <span></span>
        <span></span>
      </div>
    </div>
    <main>
      <div class="main_content_wrapper">
        <h2 class="mx-2 pt-2 mb-2 text-center">
          Comparison Analysis for
          {{ comparisonTestAnalysisData.client_name_1 }} <br />
          HTMA Results from {{ dateValue1 }} and {{ dateValue2 }}
        </h2>
        <h3 class="mx-2 pt-2 mb-2 text-center">
          Prepared by {{ comparisonTestAnalysis.practitioner_name_1 }}
          <!-- Prepared on
          {{ dateValue }} -->
        </h3>
        <hr />
        <div class="container">
          <div class="row">
            <div class="col-lg-12 pt_35">
              <div class="accorodion_wrapper" id="accordian">
                <positive-patterns
                  :comparisonTestAnalysis="comparisonTestAnalysisData"
                />
              </div>
            </div>
            <div class="col-lg-12 pt_35">
              <div class="accorodion_wrapper" id="accordian">
                <anchor-patterns
                  :comparisonTestAnalysis="comparisonTestAnalysisData"
                />
              </div>
            </div>
            <div class="col-lg-4 col-md-6 pt_25">
              <div class="middle_content_box border middle_content_box3_1">
                <div class="heading_brd">
                  <h4 class="title_md text-center" id="RO_E10">
                    Metabolic Type
                  </h4>
                </div>
                <div class="scroll_top_box">
                  <div class="scroll_left_heading">
                    <h4 class="title_md text-center" id="RO_H10">
                      {{
                        comparisonTestAnalysisData.metabolic_type.previous.value
                      }}
                    </h4>
                  </div>
                  <div class="scroll_right_heading">
                    <h4 class="title_md text-center" id="RO_F10">
                      {{
                        comparisonTestAnalysisData.metabolic_type.newest.value
                      }}
                    </h4>
                  </div>
                </div>
                <div class="scroll_box">
                  <div class="scroll_left">
                    <div class="wrap">
                      <div class="mt_15">
                        <p id="RO_I10">
                          {{
                            comparisonTestAnalysisData.metabolic_type.previous
                              .text
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="scroll_right">
                    <div class="wrap">
                      <div class="mt_15">
                        <p id="RO_G10">
                          {{
                            comparisonTestAnalysisData.metabolic_type.newest
                              .text
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 pt_25">
              <div class="middle_content_box border middle_content_box3_1">
                <div class="heading_brd">
                  <h4 class="title_md text-center" id="RO_E10">
                    Mixed Oxidizer
                  </h4>
                </div>
                <div class="scroll_box">
                  <div class="scroll_left">
                    <div class="wrap">
                      <div class="mt_15">
                        <p id="RO_I10">
                          {{
                            comparisonTestAnalysisData.mixed_oxidation.previous
                              .value
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="scroll_right">
                    <div class="wrap">
                      <div class="mt_15">
                        <p id="RO_G10">
                          {{
                            comparisonTestAnalysisData.mixed_oxidation.newest
                              .value
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 pt_25">
              <div class="middle_content_box border middle_content_box3_1">
                <div class="heading_brd">
                  <h4 class="title_md text-center" id="RO_E10">Inversion</h4>
                </div>
                <div class="scroll_box">
                  <div class="scroll_left">
                    <div class="wrap">
                      <div class="mt_15">
                        <p id="RO_I10">
                          {{
                            comparisonTestAnalysisData.inversions.previous.value
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="scroll_right">
                    <div class="wrap">
                      <div class="mt_15">
                        <p id="RO_G10">
                          {{
                            comparisonTestAnalysisData.inversions.newest.value
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 pt_25">
              <div class="middle_content_box border middle_content_box3_1">
                <div class="heading_brd">
                  <h4 class="title_md text-center" id="RO_E10">Diet</h4>
                </div>
                <div class="scroll_box">
                  <div class="scroll_left">
                    <div class="wrap">
                      <div class="mt_15">
                        <!-- <p id="RO_I10" v-for="value in comparisonTestAnalysisData.diet.previous.value" :key="value.index">{{value}}</p> -->
                        <p id="RO_I10">
                          {{ comparisonTestAnalysisData.diet.previous.value[0] }}
                        </p>
                        <a
                          id="RO_I10"
                          style="
                            text-decoration: underline;
                            color: #884c42 !important;
                          "
                          class="text-dark"
                          v-if="
                            comparisonTestAnalysisData.diet.previous.value[0].includes(
                              'Four Lows Diet'
                            )
                          "
                          href="https://docs.google.com/document/d/1ShHJ-hqmdnyFOqMeDaO78UxAu06XLG_ClZPH1digaaU/edit?usp=sharing"
                          >{{
                            comparisonTestAnalysisData.diet.previous.value[0]
                          }}</a
                        >
                        <a
                          id="RO_I10"
                          style="
                            text-decoration: underline;
                            color: #884c42 !important;
                          "
                          class="text-dark"
                          v-if="
                            comparisonTestAnalysisData.diet.previous.value[0].includes(
                              'Slow Oxidation Diet'
                            )
                          "
                          href="https://docs.google.com/document/d/1UHiE1-_MnU6GaOUBcw_-BFUBIusn4fDCiGKV-Sy2irA/edit?usp=sharing"
                          >{{
                            comparisonTestAnalysisData.diet.previous.value[0]
                          }}</a
                        >
                        <a
                          id="RO_I10"
                          style="
                            text-decoration: underline;
                            color: #884c42 !important;
                          "
                          class="text-dark"
                          v-if="
                            comparisonTestAnalysisData.diet.previous.value[0].includes(
                              'Fast Oxidation Diet'
                            )
                          "
                          href="https://docs.google.com/document/d/10DFIOENMlMAaaBB6QNghLIZs0JdWTdsDnwWBdAQawo0/edit?usp=sharing"
                          >{{
                            comparisonTestAnalysisData.diet.previous.value[0]
                          }}</a
                        >
                      </div>
                    </div>
                  </div>
                  <div class="scroll_right">
                    <div class="wrap">
                      <div class="mt_15">
                        <p id="RO_I10">
                          {{
                            comparisonTestAnalysisData.diet.newest.value[0]
                          }}
                        </p>
                        <a
                          id="RO_I10"
                          style="
                            text-decoration: underline;
                            color: #884c42 !important;
                          "
                          class="text-dark"
                          v-if="
                            comparisonTestAnalysisData.diet.newest.value[0].includes(
                              'Four Lows Diet'
                            )
                          "
                          href="https://docs.google.com/document/d/1oDtpzDCgwds0ji3-Y35X_FzQFrsYh4wpZNB4jMNISEs/edit?usp=sharing"
                          >{{
                            comparisonTestAnalysisData.diet.newest.value[0]
                          }}</a
                        >
                        <a
                          id="RO_I10"
                          style="
                            text-decoration: underline;
                            color: #884c42 !important;
                          "
                          class="text-dark"
                          v-if="
                            comparisonTestAnalysisData.diet.newest.value[0].includes(
                              'Slow Oxidation Diet'
                            )
                          "
                          href="https://docs.google.com/document/d/1ac_Vxl3D-sTWEbspoFXqV0nV0RdS9tyQTvRgEDlNWZ4/edit?usp=sharing"
                          >{{
                            comparisonTestAnalysisData.diet.newest.value[0]
                          }}</a
                        >
                        <a
                          id="RO_I10"
                          style="
                            text-decoration: underline;
                            color: #884c42 !important;
                          "
                          class="text-dark"
                          v-if="
                            comparisonTestAnalysisData.diet.newest.value[0].includes(
                              'Fast Oxidation Diet'
                            )
                          "
                          href="https://docs.google.com/document/d/10DFIOENMlMAaaBB6QNghLIZs0JdWTdsDnwWBdAQawo0/edit?usp=sharing"
                          >{{
                            comparisonTestAnalysisData.diet.newest.value[0]
                          }}</a
                        >
                        <!-- <p id="RO_I10" v-for="value in comparisonTestAnalysisData.diet.newest.value" :key="value.index">{{value}}</p> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 pt_25">
              <div class="middle_content_box border middle_content_box3_1">
                <div class="heading_brd">
                  <h4 class="title_md text-center" id="RO_E10">
                    Glucose Intolerance
                  </h4>
                </div>
                <div class="scroll_top_box">
                  <div class="scroll_left_heading">
                    <h4 class="title_md text-center" id="RO_H10">
                      {{
                        comparisonTestAnalysisData.glucose_intolerance.previous
                          .value
                      }}
                    </h4>
                  </div>
                  <div class="scroll_right_heading">
                    <h4 class="title_md text-center" id="RO_F10">
                      {{
                        comparisonTestAnalysisData.glucose_intolerance.newest
                          .value
                      }}
                    </h4>
                  </div>
                </div>
                <div class="scroll_box">
                  <div class="scroll_left">
                    <div class="wrap">
                      <div class="mt_15">
                        <p
                          id="RO_I10"
                          v-for="value in comparisonTestAnalysisData
                            .glucose_intolerance.previous.text"
                          :key="value.index"
                        >
                          {{ value }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="scroll_right">
                    <div class="wrap">
                      <div class="mt_15">
                        <p
                          id="RO_I10"
                          v-for="value in comparisonTestAnalysisData
                            .glucose_intolerance.newest.text"
                          :key="value.index"
                        >
                          {{ value }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 pt_25">
              <div class="middle_content_box border middle_content_box3_1">
                <div class="heading_brd">
                  <h4 class="title_md text-center" id="RO_E10">
                    Low Stomach Acid
                  </h4>
                </div>
                <div class="scroll_top_box">
                  <div class="scroll_left_heading">
                    <h4 class="title_md text-center" id="RO_H10">
                      {{
                        comparisonTestAnalysisData.low_stomach_acid.previous.value
                      }}
                    </h4>
                  </div>
                  <div class="scroll_right_heading">
                    <h4 class="title_md text-center" id="RO_F10">
                      {{
                        comparisonTestAnalysisData.low_stomach_acid.newest
                          .value
                      }}
                    </h4>
                  </div>
                </div>
                <div class="scroll_box">
                  <div class="scroll_left">
                    <div class="wrap">
                      <div class="mt_15">
                        <p
                          id="RO_I10"
                          v-for="value in comparisonTestAnalysisData
                            .low_stomach_acid.previous.text"
                          :key="value.index"
                        >
                          {{ value }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="scroll_right">
                    <div class="wrap">
                      <div class="mt_15">
                        <p
                          id="RO_I10"
                          v-for="value in comparisonTestAnalysisData
                            .low_stomach_acid.newest.text"
                          :key="value.index"
                        >
                          {{ value }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 pt_25">
              <div class="middle_content_box border middle_content_box3_1">
                <div class="heading_brd">
                  <h4 class="title_md text-center" id="RO_E10">Burnout</h4>
                </div>
                <div class="scroll_top_box">
                  <div class="scroll_left_heading">
                    <h4 class="title_md text-center" id="RO_H10">
                      {{
                        parseFloat(
                          comparisonTestAnalysisData.burnout.previous.value
                        ).toFixed(2)
                      }}
                    </h4>
                  </div>
                  <div class="scroll_right_heading">
                    <h4 class="title_md text-center" id="RO_F10">
                      {{
                        parseFloat(
                          comparisonTestAnalysisData.burnout.newest.value
                        ).toFixed(2)
                      }}
                    </h4>
                  </div>
                </div>
                <div class="scroll_box">
                  <div class="scroll_left">
                    <div class="wrap">
                      <div class="mt_15">
                        <p
                          id="RO_I10"
                          v-for="value in comparisonTestAnalysisData.burnout
                            .previous.text"
                          :key="value.index"
                        >
                          {{ value }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="scroll_right">
                    <div class="wrap">
                      <div class="mt_15">
                        <p
                          id="RO_I10"
                          v-for="value in comparisonTestAnalysisData.burnout
                            .newest.text"
                          :key="value.index"
                        >
                          {{ value }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 pt_25">
              <div class="middle_content_box border middle_content_box3_1">
                <div class="heading_brd">
                  <h4 class="title_md text-center" id="RO_E10">Trauma</h4>
                </div>
                <div class="scroll_top_box">
                  <div class="scroll_left_heading">
                    <h4 class="title_md text-center" id="RO_H10">
                      {{ comparisonTestAnalysisData.trauma.previous.value }}
                    </h4>
                  </div>
                  <div class="scroll_right_heading">
                    <h4 class="title_md text-center" id="RO_F10">
                      {{ comparisonTestAnalysisData.trauma.newest.value }}
                    </h4>
                  </div>
                </div>
                <div class="scroll_box">
                  <div class="scroll_left">
                    <div class="wrap">
                      <div class="mt_15">
                        <p
                          id="RO_I10"
                          v-for="value in comparisonTestAnalysisData.trauma
                            .previous.text"
                          :key="value.index"
                        >
                          {{ value }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="scroll_right">
                    <div class="wrap">
                      <div class="mt_15">
                        <p
                          id="RO_I10"
                          v-for="value in comparisonTestAnalysisData.trauma
                            .newest.text"
                          :key="value.index"
                        >
                          {{ value }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 pt_25">
              <div class="middle_content_box border middle_content_box3_1">
                <div class="heading_brd">
                  <h4 class="title_md text-center" id="RO_E10">
                    Impaired Immune
                  </h4>
                </div>
                <div class="scroll_top_box">
                  <div class="scroll_left_heading">
                    <h4 class="title_md text-center" id="RO_H10">
                      {{
                        comparisonTestAnalysisData.impaired_immune_response
                          .previous.value
                      }}
                    </h4>
                  </div>
                  <div class="scroll_right_heading">
                    <h4 class="title_md text-center" id="RO_F10">
                      {{
                        comparisonTestAnalysisData.impaired_immune_response
                          .newest.value
                      }}
                    </h4>
                  </div>
                </div>
                <div class="scroll_box">
                  <div class="scroll_left">
                    <div class="wrap">
                      <div class="mt_15">
                        <p
                          id="RO_I10"
                          v-for="value in comparisonTestAnalysisData
                            .impaired_immune_response.previous.text"
                          :key="value.index"
                        >
                          {{ value }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="scroll_right">
                    <div class="wrap">
                      <div class="mt_15">
                        <p
                          id="RO_I10"
                          v-for="value in comparisonTestAnalysisData
                            .impaired_immune_response.newest.text"
                          :key="value.index"
                        >
                          {{ value }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 pt_25">
              <div class="middle_content_box border middle_content_box3_1">
                <div class="heading_brd">
                  <h4 class="title_md text-center" id="RO_E10">
                    Zinc Deficiency
                  </h4>
                </div>
                <div class="scroll_top_box">
                  <div class="scroll_left_heading">
                    <h4 class="title_md text-center" id="RO_H10">
                      {{
                        comparisonTestAnalysisData.zinc_deficiency.previous.value
                      }}
                    </h4>
                  </div>
                  <div class="scroll_right_heading">
                    <h4 class="title_md text-center" id="RO_F10">
                      {{
                        comparisonTestAnalysisData.zinc_deficiency.newest
                          .value
                      }}
                    </h4>
                  </div>
                </div>
                <div class="scroll_box">
                  <div class="scroll_left">
                    <div class="wrap">
                      <div class="mt_15">
                        <p
                          id="RO_I10"
                          v-for="value in comparisonTestAnalysisData
                            .zinc_deficiency.previous.text"
                          :key="value.index"
                        >
                          {{ value }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="scroll_right">
                    <div class="wrap">
                      <div class="mt_15">
                        <p
                          id="RO_I10"
                          v-for="value in comparisonTestAnalysisData
                            .zinc_deficiency.newest.text"
                          :key="value.index"
                        >
                          {{ value }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 pt_25">
              <div class="middle_content_box border middle_content_box3_1">
                <div class="heading_brd">
                  <h4 class="title_md text-center" id="RO_E10">
                    Copper Imbalance
                  </h4>
                </div>
                <div class="scroll_top_box">
                  <div class="scroll_left_heading">
                    <h4 class="title_md text-center" id="RO_H10">
                      {{
                        comparisonTestAnalysisData.copper_imbalance.previous.value
                      }}
                    </h4>
                  </div>
                  <div class="scroll_right_heading">
                    <h4 class="title_md text-center" id="RO_F10">
                      {{
                        comparisonTestAnalysisData.copper_imbalance.newest
                          .value
                      }}
                    </h4>
                  </div>
                </div>
                <div class="scroll_box">
                  <div class="scroll_left">
                    <div class="wrap">
                      <div class="mt_15">
                        <p
                          id="RO_I10"
                          v-for="value in comparisonTestAnalysisData
                            .copper_imbalance.previous.text"
                          :key="value.index"
                        >
                          {{ value }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="scroll_right">
                    <div class="wrap">
                      <div class="mt_15">
                        <p
                          id="RO_I10"
                          v-for="value in comparisonTestAnalysisData
                            .copper_imbalance.newest.text"
                          :key="value.index"
                        >
                          {{ value }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 pt_25">
              <div class="middle_content_box border middle_content_box3_1">
                <div class="heading_brd">
                  <h4 class="title_md text-center" id="RO_E10">
                    Iron Interactions
                  </h4>
                </div>
                <div class="scroll_top_box">
                  <div class="scroll_left_heading">
                    <h4 class="title_md text-center" id="RO_H10">
                      {{
                        comparisonTestAnalysisData.iron_interactions.previous.value
                      }}
                    </h4>
                  </div>
                  <div class="scroll_right_heading">
                    <h4 class="title_md text-center" id="RO_F10">
                      {{
                        comparisonTestAnalysisData.iron_interactions.newest
                          .value
                      }}
                    </h4>
                  </div>
                </div>
                <div class="scroll_box">
                  <div class="scroll_left">
                    <div class="wrap">
                      <div class="mt_15">
                        <p
                          id="RO_I10"
                          v-for="value in comparisonTestAnalysisData
                            .iron_interactions.previous.text"
                          :key="value.index"
                        >
                          {{ value }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="scroll_right">
                    <div class="wrap">
                      <div class="mt_15">
                        <p
                          id="RO_I10"
                          v-for="value in comparisonTestAnalysisData
                            .iron_interactions.newest.text"
                          :key="value.index"
                        >
                          {{ value }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="scroll_box">
                  <div class="scroll_left">
                    <div class="wrap">
                      <div class="mt_15">
                        <p
                          id="RO_I10"
                          v-for="value in comparisonTestAnalysisData
                            .iron_interactions.previous.value"
                          :key="value.index"
                        >
                          {{ value }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="scroll_right">
                    <div class="wrap">
                      <div class="mt_15">
                        <p
                          id="RO_I10"
                          v-for="value in comparisonTestAnalysisData
                            .iron_interactions.newest.value"
                          :key="value.index"
                        >
                          {{ value }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
            <div class="col-lg-4 col-md-6 pt_25">
              <div class="middle_content_box border middle_content_box3_1">
                <div class="heading_brd">
                  <h4 class="title_md text-center" id="RO_E10">
                    Iodine Deficiency
                  </h4>
                </div>
                <div class="scroll_top_box">
                  <div class="scroll_left_heading">
                    <h4 class="title_md text-center" id="RO_H10">
                      {{
                        comparisonTestAnalysisData.iodine_deficiency.previous
                          .value
                      }}
                    </h4>
                  </div>
                  <div class="scroll_right_heading">
                    <h4 class="title_md text-center" id="RO_F10">
                      {{
                        comparisonTestAnalysisData.iodine_deficiency.newest
                          .value
                      }}
                    </h4>
                  </div>
                </div>
                <div class="scroll_box">
                  <div class="scroll_left">
                    <div class="wrap">
                      <div class="mt_15">
                        <p
                          id="RO_I10"
                          v-for="value in comparisonTestAnalysisData
                            .iodine_deficiency.previous.text"
                          :key="value.index"
                        >
                          {{ value }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="scroll_right">
                    <div class="wrap">
                      <div class="mt_15">
                        <p
                          id="RO_I10"
                          v-for="value in comparisonTestAnalysisData
                            .iodine_deficiency.newest.text"
                          :key="value.index"
                        >
                          {{ value }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 pt_25">
              <div class="middle_content_box border middle_content_box3_1">
                <div class="heading_brd">
                  <h4 class="title_md text-center" id="RO_E10">
                    Poor Eliminators
                  </h4>
                </div>
                <div class="scroll_top_box">
                  <div class="scroll_left_heading">
                    <h4 class="title_md text-center" id="RO_H10">
                      {{
                        comparisonTestAnalysisData.poor_eliminators.previous.value
                      }}
                    </h4>
                  </div>
                  <div class="scroll_right_heading">
                    <h4 class="title_md text-center" id="RO_F10">
                      {{
                        comparisonTestAnalysisData.poor_eliminators.newest
                          .value
                      }}
                    </h4>
                  </div>
                </div>
                <div class="scroll_box">
                  <div class="scroll_left">
                    <div class="wrap">
                      <div class="mt_15">
                        <p
                          id="RO_I10"
                          v-for="value in comparisonTestAnalysisData
                            .poor_eliminators.previous.text"
                          :key="value.index"
                        >
                          {{ value }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="scroll_right">
                    <div class="wrap">
                      <div class="mt_15">
                        <p
                          id="RO_I10"
                          v-for="value in comparisonTestAnalysisData
                            .poor_eliminators.newest.text"
                          :key="value.index"
                        >
                          {{ value }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 13 -->
            <div class="col-lg-4 col-md-6 pt_25">
              <div class="middle_content_box border middle_content_box3_1">
                <div class="heading_brd">
                  <h4 class="title_md text-center" id="RO_E10">
                    Very Poor Eliminators
                  </h4>
                </div>
                <div class="scroll_top_box">
                  <div class="scroll_left_heading">
                    <h4 class="title_md text-center" id="RO_H10">
                      {{
                        comparisonTestAnalysisData.very_poor_eliminators.previous
                          .value
                      }}
                    </h4>
                  </div>
                  <div class="scroll_right_heading">
                    <h4 class="title_md text-center" id="RO_F10">
                      {{
                        comparisonTestAnalysisData.very_poor_eliminators
                          .newest.value
                      }}
                    </h4>
                  </div>
                </div>
                <div class="scroll_box">
                  <div class="scroll_left">
                    <div class="wrap">
                      <div class="mt_15">
                        <p
                          id="RO_I10"
                          v-for="value in comparisonTestAnalysisData
                            .very_poor_eliminators.previous.text"
                          :key="value.index"
                        >
                          {{ value }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="scroll_right">
                    <div class="wrap">
                      <div class="mt_15">
                        <p
                          id="RO_I10"
                          v-for="value in comparisonTestAnalysisData
                            .very_poor_eliminators.newest.text"
                          :key="value.index"
                        >
                          {{ value }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PositivePatterns from "../../components/ComparisionTestView/PositivePatterns.vue";
import AnchorPatterns from "../../components/ComparisionTestView/AnchorPatterns.vue";

export default {
  name: "ComparisonTestAnalysis",

  components: {
    PositivePatterns,
    AnchorPatterns,
  },

  data() {
    return {
      testId1: null,
      testId2: null,
      comparisonTestAnalysisData: {},
      loading: true,
    };
  },
  watch: {},

  computed: {
    ...mapGetters(["comparisonTestAnalysis"]),
    dateValue1() {
      var date = new Date(this.comparisonTestAnalysisData.date_1);
      return (
        (date.getMonth() > 8
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1)) +
        "/" +
        (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
        "/" +
        date.getFullYear()
      );
    },
    dateValue2() {
      var date = new Date(this.comparisonTestAnalysisData.date_2);
      return (
        (date.getMonth() > 8
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1)) +
        "/" +
        (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
        "/" +
        date.getFullYear()
      );
    },
  },
  methods: {
    ...mapActions(["getComparisonTestAnalysis"]),
    showLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
  },
  async mounted() {
    this.testId1 = this.$route.params.id;
    this.testId2 = this.$route.params.id2;
    await this.getComparisonTestAnalysis({
      id1: this.testId1,
      id2: this.testId2,
    });
    this.comparisonTestAnalysisData = await this.comparisonTestAnalysis;
    localStorage.setItem(
      "instant_clientname",
      `${this.comparisonTestAnalysisData.client_name}`
    );
    console.log("comparison test data ", this.comparisonTestAnalysisData);
    this.showLoading();
  },
};
</script>

<style scoped>
.menu_item_new {
  background-color: #884c42;
  padding: -19px 10px !important;
  color: #ffffff !important;
  border-radius: 10px;
  transition: 0.4s;
  margin: 5px;
}
.middle_content_box {
  height: 100%;
}
</style>
