import Vue from 'vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.css'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';

import 'vue-search-select/dist/VueSearchSelect.css'


import router from "./router";
import store from "./store";

import BootstrapVue from "bootstrap-vue/dist/bootstrap-vue.esm";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/css/bootstrap.css";

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

import VueToastr from "vue-toastr";

Vue.use(BootstrapVue);
Vue.use(VueToastr);
Vue.component('vue-typeahead-bootstrap', VueTypeaheadBootstrap)
Vue.config.productionTip = false

new Vue({
  router,
  store,

  render: h => h(App),
}).$mount('#app')
