<template>
  <div id="">
    <main>
      <div class="main_content_wrapper">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 pt_55 col_index4">
              <div class="accorodion_wrapper" id="accordian">
                <div>
                  <ConditionForm
                    :conditionsOnly="true"
                    @backStep="getBackStep()"
                    :condition="condition"
                    :submit="submit"
                    @setConditionId="setConditionId($event)"
                    @reset="resetSearchCondition()"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <!-- Modal -->
    <ConditionsModal
      v-if="showModal"
      :save="save"
      @confirm="confirmSubmit($event)"
    />

    <!-- Modal -->
  </div>
</template>

<script>
import ConditionForm from "../../components/ConditionForm/ConditionForm.vue";
import ConditionsModal from "../../components/SaveModal/ConditionsModal.vue";

import { mapActions, mapGetters } from "vuex";
function getInitConditionData() {
  return {
    client_name: "",
    add_adhd: false,
    allergy: false,
    aneurysms: false,
    anxiety: false,
    arrhythmias: false,
    arteriosclerosis: false,
    arthritis: false,
    bloating: false,
    cancer: false,
    candida: false,
    constipation: false,
    depression: false,
    diabetes: false,
    diarrhea: false,
    edema: false,
    epilepsy: false,
    fractures_not_healing: false,
    gastritis: false,
    headache: false,
    heartattack: false,
    high_blood_pressure: false,
    hypoglycemia: false,
    low_blood_pressure: false,
    mood_swing: false,
    spasms: false,
    neuromuscular_disease: false,
    osteoporosis: false,
    pms_estrogen_dominant: false,
    pms_low_estrogen: false,
    poor_circulation: false,
    kidney_impairment: false,
    scoliosis: false,
    ligament_problem: false,
    plaque: false,
    ulcer: false,
  };
}
export default {
  name: "Conditions",
  components: {
    ConditionForm,
    ConditionsModal,
  },
  data() {
    return {
      step: 1,
      formId: null,
      condition: getInitConditionData(),

      save: {
        data: false,
      },
      showModal: false,
    };
  },
  computed: {
    ...mapGetters(["singleCondition"]),
  },
  methods: {
    ...mapActions(["addCondition", "getSingleCondition"]),
    async setConditionId(value) {
      this.formId = value;
      await this.getSingleCondition(this.formId);
      console.log("select Condition is ", await this.singleCondition);
      this.condition = await this.singleCondition;
    },
    resetSearchCondition() {
      console.log("resetting condition ....");
      this.formId = null;
      Object.assign(this.condition, getInitConditionData());
    },

    async confirmSubmit(toSave) {
      if (toSave) {
        if (!this.condition.client_name) {
          this.$toastr.Add({
            name: "Error", // this is give you ability to use removeByName method
            msg: "Please Enter Client Name!", // Toast Message
            type: "error", // Toast type,
          });
        } else {
          console.log("condition", this.condition);

          this.$toastr.Add({
            name: "Success", // this is give you ability to use removeByName method
            msg: "Conditions has been submitted Successfully!", // Toast Message
            type: "success", // Toast type,
          });

          if (this.formId) {
            console.log("selected condition id ", this.formId);
          } else {
            if (this.save.data) {
              // saving data
              try {
                const condition = await this.addCondition(this.condition);
                console.log("condition response ", condition);
                this.formId = condition.id;
              } catch (error) {
                console.log(error);
              }
            } else {
              // not saving data
              // this.showModal = false;
              // console.log("confirm submit toSave ", toSave, this.save);
              this.condition["created"] = new Date().toISOString();
              sessionStorage.setItem(
                "condition",
                JSON.stringify(this.condition)
              );
              this.formId = 0;
            }
          }

          // Condition

          this.$router.push(`/conditions/${this.formId}`);
        }
      } else {
        this.showModal = false;
        console.log("confirm submit data ", toSave);
      }
    },

    async submit() {
      console.log("submitttting...");

      this.showModal = true;

      // if (!this.condition.client_name) {
      //   this.$toastr.Add({
      //     name: "Error", // this is give you ability to use removeByName method
      //     msg: "Please Enter Client Name!", // Toast Message
      //     type: "error", // Toast type,
      //   });
      // } else {
      // console.log("condition", this.condition);

      // this.$toastr.Add({
      //   name: "Success", // this is give you ability to use removeByName method
      //   msg: "Conditions has been submitted Successfully!", // Toast Message
      //   type: "success", // Toast type,
      // });

      // if (this.formId) {
      //   console.log("selected condition id ", this.formId);
      // } else {
      //   try {
      //     const condition = await this.addCondition(this.condition);
      //     console.log("condition response ", condition);
      //     this.formId = condition.id;
      //   } catch (error) {
      //     console.log(error);
      //   }
      // }

      // // Condition

      // this.$router.push(`/conditions/${this.formId}`);
      // }
    },
  },
  async mounted() {},
};
</script>
<style>
.b-toaster {
  position: absolute;
  max-width: 350px;
  width: 100%;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
}
</style>
