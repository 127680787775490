import { getReq,postReq } from "../../../services/api";

const state = {
  clientReport:[],
  clientReportDocx:[],
};

const getters = {
  clientReport: (state) => state.clientReport,
  clientReportDocx: (state) => state.clientReportDocx,
};


const actions = {
  // clientReport Report

  async getClientReport({ commit },id) {
    try {
      let clientReport = await getReq(`client-report/${id}/`);
      commit("setClientReport", clientReport);
      
    } catch (error) {
      console.log(error)
      console.log(error.response);
      throw error.response;
    }
  },
  async getClientReportDocx({ commit },payload) {
    console.log(payload)
    try {
      let clientReport = await postReq(`google-docx/${payload.id}/`,{access_token:payload.access_token});
      commit("setClientReportDocx", clientReport);
      
    } catch (error) {
      console.log(error)
      console.log(error.response);
      throw error.response;
    }
  },

};

const mutations = {
  setClientReport: (state, clientReport) => (state.clientReport = clientReport),
  setClientReportDocx: (state, clientReportDocx) => (state.clientReportDocx = clientReportDocx),

};

export default {
  state,
  getters,
  actions,
  mutations,
};
