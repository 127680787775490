<template>
  <div id="">
    <main>
      <div class="main_content_wrapper">
        <br />
        <h2
          class="mx-2 pt-2 mb-2 text-center font_head"
          style="4rem !important"
        >
          Privacy Policy
        </h2>
        <hr />
        <div class="container">
          <div class="row">
            <div class="col-lg-12 pt_40">
              <div class="accorodion_wrapper" id="accordian">
                <div class="">
                  <div class="privacy-policy">
                    <p class="font_8">
                      Please read this Privacy Policy carefully before using
                      this website.
                    </p>

                    <p class="font_8">&nbsp;</p>

                    <p class="font_8">Privacy Policy Consent</p>

                    <p class="font_8">
                      The website and its content is owned by [Test Don’t Guess, LLC] (“Company”, “we”, or “us”). The term “you”
                      refers to the user or viewer of [<a
                        data-auto-recognition="true"
                        href="http://www.testdontguess.org"
                        target="_blank"
                        >www.testdontguess.org</a
                      >
                      and
                      <a
                        data-auto-recognition="true"
                        href="http://www.learnhtma.com"
                        target="_blank"
                        >www.learnhtma.com</a
                      >] (“Website”).
                    </p>

                    <p class="font_8">&nbsp;</p>

                    <p class="font_8">
                      This Privacy Policy describes how we collect, use, process
                      and distribute your information, including Personal Data
                      (as defined below) used to access this Website. We will
                      not use or share your information with anyone except as
                      described in this Privacy Policy. The use of information
                      collected through our Sites shall be limited to the
                      purposes under this Privacy Policy and our Terms of
                      Service to customers.
                    </p>

                    <p class="font_8">&nbsp;</p>

                    <p class="font_8">
                      Please read this Privacy Policy carefully. We reserve the
                      right to change this Privacy Policy on the Website at any
                      time without notice. In the event of a material change, we
                      will let you know via email and / or a prominent notice on
                      our Website.
                    </p>

                    <p class="font_8">&nbsp;</p>

                    <p class="font_8">
                      Use of any personal information or contribution that you
                      provide to us, or which is collected by us on or through
                      our Website or its content is governed by this Privacy
                      Policy. &nbsp;By using our Website or its content, you
                      consent to this Privacy Policy, whether or not you have
                      read it.&nbsp;
                    </p>

                    <p class="font_8">&nbsp;</p>

                    <p class="font_8">Information We May Collect</p>

                    <p class="font_8">
                      We collect personal information from you so that we can
                      provide you with a positive experience when utilizing our
                      Website or content. &nbsp;We will only collect the minimum
                      amount of information necessary for us to fulfill our
                      obligation to you.&nbsp;
                    </p>

                    <p class="font_8">&nbsp;</p>

                    <ol class="font_8">
                      We may collect:
                      <li>
                        <p class="font_8">
                          - A name and an email address so we can deliver our
                          newsletter to you - you would be affirmatively
                          consenting to this by providing this to us in our
                          contact forms.
                        </p>
                      </li>
                      <li>
                        <p class="font_8">
                          - Billing information including name, address and credit
                          card information so that we can process payment to
                          deliver our products or services to you under our
                          contractual obligation.
                        </p>
                      </li>
                      <li>
                        <p class="font_8">
                          - A name and an email address if you complete our
                          contact form with a question. We may send you
                          marketing emails with either your consent or if we
                          believe we have a legitimate interest to contact you
                          based on your contact or question.
                        </p>
                      </li>
                      <li>
                        <p class="font_8">
                          - Information from you from a co-branded offer. &nbsp;In
                          this case, we will make clear as to who is collecting
                          the information and whose privacy policy applies. If
                          both / all parties are retaining the information you
                          provide, this will also be made clear as will links to
                          all privacy policies.
                        </p>
                      </li>
                    </ol>

                    <p class="font_8">
                      - Please note that the information above (“Personal Data”)
                      that you are giving to us is voluntarily, and by you
                      providing this information to us you are giving consent
                      for us to use, collect and process this Personal Data. You
                      are welcome to opt-out or request for us to delete your
                      Personal Data at any point by contacting us at
                      <a
                        data-auto-recognition="true"
                        href="mailto:support@testdontguess.org"
                        >support@testdontguess.org</a
                      >
                    </p>

                    <p class="font_8">&nbsp;</p>

                    <p class="font_8">
                      If you choose not to provide us with certain Personal
                      Data, you may not be able to participate in certain
                      aspects of our Website or content.
                    </p>

                    <p class="font_8">&nbsp;</p>

                    <p class="font_8">Other Information We May Collect:</p>

                    <p class="font_8">&nbsp;</p>

                    <p class="font_8">Anonymous Data Collection and Use</p>

                    <p class="font_8">
                      To maintain our Website’s high quality, we may use your IP
                      address to help diagnose problems with our server and to
                      administer the Website by identifying which areas of the
                      Website are most heavily used, and to display content
                      according to your preferences. Your IP address is the
                      number assigned to computers connected to the Internet.
                      This is essentially "traffic data" which cannot personally
                      identify you, but is helpful to us for marketing purposes
                      and for improving our services. Traffic data collection
                      does not follow a user’s activities on any other websites
                      in any way. Anonymous traffic data may also be shared with
                      business partners and advertisers on an aggregate basis.
                    </p>

                    <p class="font_8">&nbsp;</p>

                    <p class="font_8">Use of "Cookies"</p>

                    <p class="font_8">
                      We may use the standard "cookies" feature of major web
                      browsers. We do not set any personally identifiable
                      information in cookies, nor do we employ any data-capture
                      mechanisms on our Website other than cookies. You may
                      choose to disable cookies through your own web browser’s
                      settings. However, disabling this function may diminish
                      your experience on our Website and some features may not
                      work as intended.
                    </p>

                    <p class="font_8">&nbsp;</p>
                    <p class="font_8">Google User Data</p>

                    <p class="font_8">
                      To create custom client documents, the app requests access to Google User Data using the OAuth screen. When the user approves authorization, it gives the application the ability to create a Google Document of the output report and save it to their Google Drive. Each Google Document is only generated and accessible for that user. All generated client reports are stored on the Google Drive for app.InstantHTMA.com. The app does not share Google User Data. app.InstantHTMA.com does not store any Google User Data. 
                    </p>
                    <p class="font_8">&nbsp;</p>

                    <p class="font_8">What We Do With Information We Collect</p>

                    <p class="font_8">&nbsp;</p>

                    <p class="font_8">Contact You</p>

                    <p class="font_8">&nbsp;</p>

                    <p class="font_8">
                      We may contact you with information that you provide to us
                      based on these lawful grounds for processing:
                    </p>

                    <ol style="list-style-type: decimal" class="font_8">
                      <li>
                        <p class="font_8">
                          Consent. We may contact you if you give us your clear,
                          unambiguous, affirmative consent to contact you.
                        </p>
                      </li>
                      <li>
                        <p class="font_8">
                          Contract. &nbsp;We will contact you under our
                          contractual obligation to deliver goods or services
                          you purchase from us.
                        </p>
                      </li>
                      <li>
                        <p class="font_8">
                          Legitimate Interest. &nbsp;We may contact you if we
                          feel you have a legitimate interest in hearing from
                          us. &nbsp;For example, if you sign up for a webinar,
                          we may send you marketing emails based on the content
                          of that webinar. &nbsp;You will always have the option
                          to opt out of any of our emails.
                        </p>
                      </li>
                    </ol>

                    <p class="font_8">&nbsp;</p>

                    <p class="font_8">Process Payments</p>

                    <p class="font_8">
                      We will use the Personal Data you give to us in order to
                      process your payment for the purchase of goods or services
                      under a contract. We only use third party payment
                      processors that take the utmost care in securing data and
                      comply with the GDPR. &nbsp;
                    </p>

                    <p class="font_8">&nbsp;</p>

                    <p class="font_8">Targeted Social Media Advertisements</p>

                    <p class="font_8">
                      We may use the data you provide to us to run social media
                      advertisements and / or create look-alike audiences for
                      advertisements.
                    </p>

                    <p class="font_8">&nbsp;</p>

                    <p class="font_8">Share with Third Parties</p>

                    <p class="font_8">
                      We may share your information with trusted third parties
                      such as our newsletter provider in order to contact you
                      via email, our merchant accounts to process payments, and
                      Google / social media accounts in order to run
                      advertisements and our affiliates.
                    </p>

                    <p class="font_8">&nbsp;</p>

                    <p class="font_8">Viewing by Others</p>

                    <p class="font_8">
                      Note that whenever you voluntarily make your Personal Data
                      available for viewing by others online through this
                      Website or its content, it may be seen, collected and used
                      by others, and therefore, we cannot be responsible for any
                      unauthorized or improper use of the information that you
                      voluntarily share (i.e., sharing a comment on a blog post,
                      posting in a Facebook group that we manage, sharing
                      details on a group coaching call, etc.).
                    </p>

                    <p class="font_8">&nbsp;</p>

                    <p class="font_8">
                      Submission, Storage, Sharing and Transferring of Personal
                      Data
                    </p>

                    <p class="font_8">
                      Personal Data that you provide to us is stored internally
                      or through a data management system. Your Personal Data
                      will only be accessed by those who help to obtain, manage
                      or store that information, or who have a legitimate need
                      to know such Personal Data (i.e., our hosting provider,
                      newsletter provider, payment processors or team members).
                    </p>

                    <p class="font_8">&nbsp;</p>

                    <p class="font_8">
                      It is important to note that we may transfer data
                      internationally. For users in the European Union, please
                      be aware that we transfer Personal Data outside of the
                      European Union. By using our Website and providing us with
                      your Personal Data, you consent to these transfers in
                      accordance with this Privacy Policy.
                    </p>

                    <p class="font_8">Data Retention</p>

                    <p class="font_8">
                      We retain your Personal Data for the minimum amount of
                      time necessary to provide you with the information and /
                      or services that you requested from us. We may include
                      certain Personal Data for longer periods of time if
                      necessary for legal, contractual and accounting
                      obligations.
                    </p>

                    <p class="font_8">&nbsp;</p>

                    <p class="font_8">Confidentiality</p>

                    <p class="font_8">
                      We aim to keep the Personal Data that you share with us
                      confidential. Please note that we may disclose such
                      information if required to do so by law or in the
                      good-faith belief that: (1) such action is necessary to
                      protect and defend our rights or property or those of our
                      users or licensees, (2) to act as immediately necessary in
                      order to protect the personal safety or rights of our
                      users or the public, or (3) to investigate or respond to
                      any real or perceived violation of this Privacy Policy or
                      of our Disclaimer, Terms and Conditions, or any other
                      terms of use or agreement with us.
                    </p>

                    <p class="font_8">&nbsp;</p>

                    <p class="font_8">Passwords</p>

                    <p class="font_8">
                      To use certain features of the Website or its content, you
                      may need a username and password. You are responsible for
                      maintaining the confidentiality of the username and
                      password, and you are responsible for all activities,
                      whether by you or by others, that occur under your
                      username or password and within your account. We cannot
                      and will not be liable for any loss or damage arising from
                      your failure to protect your username, password or account
                      information. If you share your username or password with
                      others, they may be able to obtain access to your Personal
                      Data at your own risk.
                    </p>

                    <p class="font_8">&nbsp;</p>

                    <p class="font_8">
                      You agree to notify us immediately of any unauthorized or
                      improper use of your username or password or any other
                      breach of security. To help protect against unauthorized
                      or improper use, make sure that you log out at the end of
                      each session requiring your username and password.
                    </p>

                    <p class="font_8">&nbsp;</p>

                    <p class="font_8">
                      We will use our best efforts to keep your username and
                      password(s) private and will not otherwise share your
                      password(s) without your consent, except as necessary when
                      the law requires it or in the good faith belief that such
                      action is necessary, particularly when disclosure is
                      necessary to identify, contact or bring legal action
                      against someone who may be causing injury to others or
                      interfering with our rights or property.
                    </p>

                    <p class="font_8">&nbsp;</p>

                    <p class="font_8">
                      How You Can Access, Update or Delete Your Personal Data
                    </p>

                    <p class="font_8">You have the right to:</p>

                    <p class="font_8">&nbsp;</p>

                    <ol style="list-style-type: decimal" class="font_8">
                      <li>
                        <p class="font_8">
                          Request information about how your Personal Data is
                          being used and request a copy of what Personal Data we
                          use.
                        </p>
                      </li>
                      <li>
                        <p class="font_8">
                          Restrict processing if you think the Personal Data is
                          not accurate, unlawful, or no longer needed.
                        </p>
                      </li>
                      <li>
                        <p class="font_8">
                          Rectify or erase Personal Data and receive
                          confirmation of the rectification or erasure. (You
                          have the “right to be forgotten”).
                        </p>
                      </li>
                      <li>
                        <p class="font_8">
                          Withdraw your consent at any time to the processing of
                          your Personal Data.
                        </p>
                      </li>
                      <li>
                        <p class="font_8">
                          Lodge a complaint with a supervisory authority if you
                          feel we are using your Personal Data unlawfully.
                        </p>
                      </li>
                      <li>
                        <p class="font_8">
                          Receive Personal Data portability and transference to
                          another controller without our hinderance.
                        </p>
                      </li>
                      <li>
                        <p class="font_8">
                          Object to our use of your Personal Data.
                        </p>
                      </li>
                      <li>
                        <p class="font_8">
                          Not be subject to an automated decision based solely
                          on automatic processing, including profiling, which
                          legally or significantly affects you.
                        </p>
                      </li>
                    </ol>

                    <p class="font_8">&nbsp;</p>

                    <p class="font_8">
                      You may unsubscribe from our e-newsletters or updates at
                      any time through the unsubscribe link at the footer of all
                      email communications. If you have questions or are
                      experiencing problems unsubscribing, please contact us at
                      <a
                        data-auto-recognition="true"
                        href="mailto:support@testdontguess.org"
                        >support@testdontguess.org</a
                      >
                    </p>

                    <p class="font_8">&nbsp;</p>

                    <p class="font_8">Security</p>

                    <p class="font_8">
                      We take commercially reasonable steps to protect the
                      Personal Data you provide to us from misuse, disclosure or
                      unauthorized access. We only share your Personal Data with
                      trusted third parties who use the same level of care in
                      processing your Personal Data. That being said, we
                      cannot guarantee that your Personal Data will always be
                      secure due to technology or security breaches. Should
                      there be a data breach of which we are aware, we will
                      inform you immediately.
                    </p>

                    <p class="font_8">&nbsp;</p>

                    <p class="font_8">Anti-Spam Policy</p>

                    <p class="font_8">
                      We have a no spam policy and provide you with the ability
                      to opt-out of our communications by selecting the
                      unsubscribe link at the footer of all e-mails. We have
                      taken the necessary steps to ensure that we are compliant
                      with the CAN-SPAM Act of 2003 by never sending out
                      misleading information. We will not sell, rent or share
                      your email address.
                    </p>

                    <p class="font_8">&nbsp;</p>

                    <p class="font_8">Third Party Websites</p>

                    <p class="font_8">
                      We may link to other websites on our Website. We have no
                      responsibility or liability for the content and activities
                      of any other individual, company or entity whose website
                      or materials may be linked to our Website or its content,
                      and thus we cannot be held liable for the privacy of the
                      information on their website or that you voluntarily share
                      with their website. Please review their privacy policies
                      for guidelines as to how they respectively store, use and
                      protect the privacy of your Personal Data.
                    </p>

                    <p class="font_8">&nbsp;</p>

                    <p class="font_8">
                      Children’s Online Privacy Protection Act Compliance
                    </p>

                    <p class="font_8">
                      We do not collect any information from anyone under 18
                      years of age in compliance with COPPA (Children’s Online
                      Privacy Protection Act) and the GDPR (General Data
                      Protection Regulation of the EU). Our Website and its
                      content is directed to individuals who are at least 18
                      years old or older.
                    </p>

                    <p class="font_8">&nbsp;</p>

                    <p class="font_8">Notification of Changes</p>

                    <p class="font_8">
                      We may use your Personal Data, such as your contact
                      information, to inform you of changes to the Website or
                      its content, or, if requested, to send you additional
                      information about us. We reserve the right, at our sole
                      discretion, to change, modify or otherwise alter our
                      Website, its content and this Privacy Policy at any time.
                      Such changes and/or modifications shall become effective
                      immediately upon posting our updated Privacy Policy.
                      &nbsp;Please review this Privacy Policy periodically.
                      Continued use of any of information obtained through or on
                      the Website or its content following the posting of
                      changes and/or modifications constituted acceptance of the
                      revised Privacy Policy. Should there be a material change
                      to our Privacy Policy, we will contact you via email or by
                      a prominent note on our Website.
                    </p>

                    <p class="font_8">&nbsp;</p>

                    <p class="font_8">Data Controller and Processors</p>

                    <p class="font_8">
                      We are the data controllers as we are collecting and using
                      your Personal Data. &nbsp;We use trusted third parties as
                      our data processors for technical and organizational
                      purposes, including for payments and email marketing. We
                      use reasonable efforts to make sure our data processors
                      are GDPR- compliant.
                    </p>

                    <p class="font_8">&nbsp;</p>

                    <p class="font_8">
                      If you have any questions about this Privacy Policy,
                      please contact us at:1624 Laskin Rd., Ste 736 PMB 1014,
                      Virginia Beach, VA 23451,
                      <a
                        data-auto-recognition="true"
                        href="mailto:support@testdontguess.org"
                        >support@testdontguess.org</a
                      >.
                    </p>

                    <p class="font_8">&nbsp;</p>

                    <p class="font_8">Last Updated: October, 2022</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "PrivacyPolicy",
  components: {},
  data() {
    return {
      loading: true,
    };
  },
  watch: {},
  computed: {
    ...mapGetters([]),
  },
  methods: {
    ...mapActions([]),
    showLoading() {
      setTimeout(() => {}, 1000);
    },
  },
  async mounted() {
    console.log("privacypolicy");
  },
};
</script>
<style>
.privacy-policy {
  line-height: 1.6em;
}

.font_8 {
  font-size: 24px;
  line-height: 1.6;
  letter-spacing: normal;
  color: rgb(136, 76, 66);
  /* font-family: Lucida Console, monospace; */
  font-family: "garamond";
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 10 !important;
  /* font-family:'Avenir LT W01 35 Light'; */
}
.font_8 > a {
  color: rgb(136, 76, 66);
  text-decoration: none;
}
.font_head {
  font-size: 58px;
  line-height: 1.6;
  letter-spacing: normal;
  color: rgb(136, 76, 66);
  /* font-family: Lucida Console, monospace; */
  font-family: "garamond";
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 10 !important;
  /* font-family:'Avenir LT W01 35 Light'; */
}
</style>

