<template>
  <div class="bg-white accoridian_bg border">
    <h3 class="mt_20 heading">Considerations</h3>
    <div class="collapse show" id="idcollapseOne">
      <div class="accorodian_content accorodian_content2" id="SO_H2_I">
        <p  style="margin: 0; padding:25px 0px  margin-bottom:5px;"
          v-for="data in singleTestAnalysisData.consideration.filter(
            (test) => test !== ''
          )"
          :key="data.index"
        >
          {{ data }}
          <br />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Consideration",
  components: {},
  props: ["singleTestAnalysisData"],

  data() {
    return {};
  },

  mounted() {},
};
</script>
<style scoped>
.heading {
  font-weight: bold;
  color: #884c42;
  padding: 5px;
  margin-left: 20px;
  padding-top: 20px;
  font-family: "Inter", sans-serif;
}
</style>
