<template>
  <b-modal
    id="HTMAResultsModal"
    header-class="display-none"
    footer-class="border-none justify-content-center"
    no-close-on-backdrop
    centered
  >
    <template #modal-header="{ close }">
      <b-button
        size="sm"
        class="d-none"
        variant="outline-danger"
        @click="close()"
      >
        Close Modal
      </b-button>
    </template>
    <p class="font-weight-bold f-18 text-center" style="font-size: 25px">
      Select Data to Save:
    </p>
    <br />
    <form
      class="saved"
      style="margin-left: 90px !important; font-size: 18px"
      action=""
    >
      <input v-model="save.data" type="checkbox" /> Save NAQ Graph &amp; NAQ
      Symptoms<br />
    </form>
    <br />
    <template #modal-footer="">
      <b-button class="close-btn" @click="cancel()"> Cancel </b-button>
      <b-button class="save-btn" @click="confirm()"> Confirm </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "HTMAResultsModal",
  props: ["save"],
  methods: {
    confirm() {
      this.$bvModal.hide("HTMAResultsModal");
      this.$emit("confirm", this.save);
    },
    cancel() {
      this.$bvModal.hide("HTMAResultsModal");
      this.$emit("confirm", false);
    },
  },
  mounted() {
    this.$bvModal.show("HTMAResultsModal");
  },
};
</script>

<style>
.border-none {
  border: none !important;
}
.display-none {
  display: none;
}

.close-btn {
  width: 100px;
  background-color: #ddd !important;
  color: #000 !important;
  height: 36px;
  border: 1px solid #884c42;
  font-size: 20px;
  padding: 2px;
  /* font-weight: bold; */
}

.save-btn {
  color: #fff;
  font-size: 20px;
  width: 100px;
  height: 36px;
  border: 1px solid #000;
  /* font-weight: bold; */
  background-color: #884c42 !important;
  padding: 2px;
}
.saved > input[type="checkbox"] {
  width: 17px;
  height: 17px;
  padding-right: 10px;
  margin-right: 10px;
}
/* input[type="checkbox"] {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  padding-right: 10px;
  margin-right: 10px;
  background: white;
  border-radius: 5px;
  border: 2px solid #555;
}
input[type="checkbox"]:checked {
  background: #abd;
} */
</style>
