<template>
  <b-modal
    id="deleteModal"
    header-class="display-none"
    footer-class="border-none justify-content-center"
    no-close-on-backdrop
  >
    <template #modal-header="{ close }">
      <b-button
        size="sm"
        class="d-none"
        variant="outline-danger"
        @click="close()"
      >
        Close Modal
      </b-button>
    </template>
    <p class="font-weight-bold f-18 text-center">Do you want to DELETE this?</p>
    <template #modal-footer="">
      <b-button class="close-btn" @click="cancel()"> NO </b-button>
      <b-button class="save-btn" @click="confirm()"> YES </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "DeleteModal",
  methods: {
    confirm() {
      this.$bvModal.hide("deleteModal");
      this.$emit("confirm", true);
    },
    cancel() {
      this.$bvModal.hide("deleteModal");
      this.$emit("confirm", false);
    },
  },
  mounted() {
    this.$bvModal.show("deleteModal");
  },
};
</script>

<style>
.border-none {
  border: none !important;
}
.display-none {
  display: none;
}

.close-btn {
  width: 100px;
  background-color: #ddd !important;
  color: #000 !important;
  height: 36px;
  border: 1px solid #884c42;
  font-size: 20px;
  padding: 2px;
  /* font-weight: bold; */
}

.save-btn {
  color: #fff;
  font-size: 20px;
  width: 100px;
  height: 36px;
  border: 1px solid #000;
  /* font-weight: bold; */
  background-color: #884c42 !important;
  padding: 2px;
}
</style>
