<template>
  <div class="bg-white accoridian_bg border">
    <h3 class="mt_20 heading">Stress Patterns</h3>
    <div class="collapse show" id="idcollapseThree">
      <div class="accorodian_content accorodian_content2" id="SO_K2_L">
        <div v-for="value in singleTestAnalysisData" :key="value.index">
          <h4 class="mb-2">{{ value.heading }}</h4>
          <p
            style="margin: 0; margin-bottom: 5px; padding-top: 0"
            class="pt_15"
          >
            {{ value.text }}
          </p>
          <br />
        </div>
        <!-- <h4 style="font-size: 18px">Very Extreme Four Lows</h4>
        <p class="pt_15 pb-2" style="line-height: 1.8">
          This pattern is associated with fatigue, depression, and a negative
          outlook on life. Compulsive worrying or excessive activity is common.
          Often one feels unloved or unappreciated. The person is often
          "spinning their wheels". It can indicate low nutrient absorption and
          the need for digestive support, but take care to not recommend too
          many supplements at one time. but take care to not recommend too many
          supplements at one time.
        </p>
        <p style="line-height: 1.8">
          Some cases of four lows on a first hair analysis are related to
          lifestyle. These factors include working too much or too hard, being
          much too hard on oneself or others, being in an oppressed or very
          stressful situation of some kind, or compulsive or obsessive
          tendencies that wear out the body. Attitudes that could drive a person
          into a four lows pattern and/or keep one there include anger, intense
          fear, even intense guilt or some other emotion that wears down or
          depletes the body.
        </p> -->
        <!-- <br><br> -->
        <!-- <h4 style="font-size: 18px" v-for="value in singleTestAnalysisData.stress_pattern.heading.filter(
                (test) => test !== ''
              )"
              :key="value.index"
            >
              {{ value }}
          {{ singleTestAnalysisData.stress_pattern.heading }}
        </h4>
        <p class="pt_15"  style="line-height: 1.8" v-for="value in singleTestAnalysisData.stress_pattern.desc.filter(
                (test) => test !== ''
              )"
              :key="value.index"
            >
              {{ value }}
          {{ singleTestAnalysisData.stress_pattern.desc }}
          
        </p> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StressPatterns",
  components: {},
  props: ["singleTestAnalysisData"],

  data() {
    return {};
  },

  mounted() {},
};
</script>
<style scoped>
.heading {
  font-weight: bold;
  color: #884c42;
  padding: 5px;
  margin-left: 20px;
  padding-top: 20px;
  font-family: "Inter", sans-serif;
}
</style>