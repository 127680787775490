<template>
  <div id="">
    <main>
      <div class="main_content_wrapper">
        <br />
        <h2 class="mx-2 pt-2 mb-2 text-center font_head" style="4rem !important">
          Instant HTMA Professional
        </h2>
        <hr />
        <div class="container">
          <div class="row">
            <div class="col-lg-12 pt_40">
              <div class="accorodion_wrapper" id="accordian">
                <div class="">
                  <div class="privacy-policy">
                    <p class="font_8" style="font-size: 30px">
                      This powerful & easy to use Hair Tissue Mineral Analysis App will
                      Decrease your analysis time by 80%+

                    </p>
                    <p class="font_8">&nbsp;</p>
                    <a class="brown-button" href="http://app.instanthtma.com/login">Check it out for FREE!</a>
                    <p class="font_8">&nbsp;</p>
                    <p class="font_8"> Go beyond Analyzing Ratios!<br />
                      Never miss important mineral patterns <br />
                      Compare two HTMA results instantly <br />
                      Find symptoms & conditions in HTMA results <br />
                      Automatically generate detailed reports <br />
                      & so much more
                    </p>

                    <p class="font_8">
                      Introducing the HTMA Analysis Application
                    </p>

                    <p class="font_8">
                      A true game changer when it comes to… <br />
                      Quickly, Accurately, & Confidently Utilizing HTMA

                    </p>
                    <p class="font_8">&nbsp;</p>
                    <p class="font_8">
                      The HTMA Analysis App allows practitioners to easily upload clients raw HTMA lab results and input
                      information from the Nutritional Assessment Questionnaire
                      into one user-friendly app and instantly run a single test analysis or comparison analysis.

                    </p>
                    <p class="font_8">&nbsp;</p>
                    <p class="font_8">
                      This way all the heavy lifting and the long hours typically spent doing this type of manual
                      analysis can be substantially decreased by up to 80%. And that’s just for starters.

                    </p>
                    <p class="font_8">&nbsp;</p>
                    <p class="font_8">
                      The HTMA Analysis App also automatically generates fully customized and editable reports based on
                      your clients’ personal needs.
                    </p>
                    <p class="font_8 mb-0">
                      The report that’s generated instantly provides a detailed (but easy to understand!) breakdown of
                      every mineral pattern and indicator that is present for all kinds of health issues or challenges
                      including…
                    </p>
                    <ul>
                      <li class="font_8">Copper Imbalance</li>
                      <li class="font_8">Impaired Immune Response</li>
                      <li class="font_8">Glucose Intolerance</li>
                      <li class="font_8">Trauma</li>
                      <li class="font_8">Burnout</li>
                      <li class="font_8">Poor Detoxification</li>
                      <li class="font_8">Metabolic Patterns</li>
                      <li class="font_8">Psychological Patterns</li>
                    </ul>
                    <p class="font_8">&nbsp;</p>
                    <p class="font_8">The 15-page custom client report is generated as a Google Doc, which makes it
                      completely editable. <br />
                      It automatically provides detailed information for your client about every mineral, ratio, and
                      heavy metal result.
                    </p>

                    <p class="font_8">&nbsp;</p>

                    <p class="font_8">
                      Thanks to this breakthrough technology, utilizing the HTMA Analysis App in your own practice will
                      not only make your life easier, but it’ll give your clients even better results to improve their
                      overall health, wellness, and vitality.
                    </p>
                    <p class="font_8">&nbsp;</p>


                    <p class="font_8">The app also gives you the unique ability to do a quick side-by-side comparison
                      analysis to see if your client’s data has changed over a span of time.
                    </p>
                    <p class="font_8">&nbsp;</p>

                    <p class="font_8">During this type of side-by-side comparison analysis, the HTMA Analysis App will
                      generate a beautifully formatted Google Doc that’ll compare all patterns and indicators from both
                      tests – and will highlight the most positive patterns where your client is seeing the greatest
                      improvements.</p>
                    <p class="font_8">&nbsp;</p>
                    <p class="font_8">
                      The HTMA Analysis App works on any computer, is super easy to use, and it also comes with training
                      video to help you not only get acclimated to the app, but to also allow you to take full advantage
                      of the biggest features and benefits it has to offer you.
                    </p>
                    <p class="font_8">
                      How does the App work?
                    <ul>
                      <ol>Upload Raw HTMA Data</ol>
                      <ol>Plug in Symptoms and Conditions</ol>
                      <ol>Recieve Results & Indicators</ol>
                      <ol>Auto-Generate Cliet Documents</ol>
                    </ul>
                    </p>
                    <p class="font_8">&nbsp;</p>
                    <a class="brown-button" href="http://app.instanthtma.com/login">Instantly access the HTMA analysis app NOW!</a>
                    <p class="font_8">&nbsp;</p>
                    <p class="font_8">This web app has the power and utility to save you a ton of wasted time, hassles and headaches!</p>
                    <p class="font_8">&nbsp;</p>
                    <p class="font_8">Not only will you get the detailed HTMA analysis and customized reporting you need (with less work and effort on your part) right at your fingertips, but you'll also be able to get more reliable and tangible results for your clients.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "PrivacyPolicy",
  components: {},
  data() {
    return {
      loading: true,
    };
  },
  watch: {},
  computed: {
    ...mapGetters([]),
  },
  methods: {
    ...mapActions([]),
    showLoading() {
      setTimeout(() => { }, 1000);
    },
  },
};
</script>
<style>
.privacy-policy {
  line-height: 1.6em;
}

.font_8 {
  font-size: 24px;
  line-height: 1.6;
  letter-spacing: normal;
  color: rgb(136, 76, 66);
  /* font-family: Lucida Console, monospace; */
  font-family: "garamond";
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 10 !important;
  /* font-family:'Avenir LT W01 35 Light'; */
}

.font_8>a {
  color: rgb(136, 76, 66);
  text-decoration: none;
}

.font_head {
  font-size: 58px;
  line-height: 1.6;
  letter-spacing: normal;
  color: rgb(136, 76, 66);
  /* font-family: Lucida Console, monospace; */
  font-family: "garamond";
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 10 !important;
  /* font-family:'Avenir LT W01 35 Light'; */
}

.brown-button,
.brown-button:hover {
  background-color: rgb(136, 76, 66);
  color: white;
  padding: 1rem;
  font-family: "garamond";
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 10 !important;
  border: none;
  cursor: pointer !important;
}
</style>

