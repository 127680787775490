<template>
  <div class="body">
    <div class="container">
      <div class="row">
        <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
          <div class="card border-0 shadow rounded-3 my-8">
            <div class="card-body p-4 p-sm-5">
              <h5 class="card-title text-center mb-5 fw-light fs-5">Reset Password</h5>
              <form @submit.prevent="onSubmit">
               
                <div class="form-floating mb-3">
                  <label for="floatingPassword">Password</label>
                  <input
                    type="password"
                    class="form-control"
                    id="floatingPassword"
                    placeholder="Password"
                    required
                    v-model="user.password1"
                  />
                </div>

                 <div class="form-floating mb-3">
                  <label for="floatingPassword">Confirm Password</label>
                  <input
                    type="password"
                    class="form-control"
                    id="floatingPassword"
                    placeholder="Password"
                    required
                    v-model="user.password2"
                  />
                </div>

                <div class="form-check mb-3"></div>
                <div class="d-grid text-center">
                  <button
                    class="btn btn-login text-uppercase fw-bold"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
                <br />
                <div class="d-grid text-center">
                  Need an account?
                  <router-link to="/register" class="text-dark form-check-label"
                    >SIGNUP</router-link
                  >
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapActions,
  // mapGetters
} from "vuex";

export default {
  name: "ResetPassword",
  data() {
    return {
      user: {
        // email: null,
        password1: null,
        password2: null,
        uid:null,
        token:null,
      },
    };
  },
  computed: {
    // ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["resetPasswordConfirm"]),

    async onSubmit() {
      try {
        const response = await this.resetPasswordConfirm(this.user);
        // const user = await this.getUser();
        console.log('response ',response)
        // localStorage.setItem("instant_username", user.username);
        // localStorage.setItem("instant_username", `${user.first_name} ${user.last_name}`);
        this.$toastr.Add({
        name: "Success", // this is give you ability to use removeByName method
        msg: response.detail, // Toast Message
        type: "success", // Toast type,
      });
        this.$router.push("/login");
      } catch (error) {
        console.log(error);
        this.$toastr.Add({
          name: "Error", // this is give you ability to use removeByName method
          msg: error, // Toast Message
          type: "error", // Toast type,
        });
      }
    },
  },
  mounted() {
    this.user.uid = this.$route.params.uid
    this.user.token = this.$route.params.token
    console.log('reset pass user',this.user)
    if (localStorage.getItem("instant_token")) {
      this.$toastr.Add({
        name: "Success", // this is give you ability to use removeByName method
        msg: "You have already LoggedIn!", // Toast Message
        type: "success", // Toast type,
      });
      this.$router.push("/htma-results-input");
    }
  },
};
</script>
<style>
body {
  background: #fafafa;
  background: linear-gradient(to right, #f7f0f0, #fafafa);
}

.btn-login {
  font-size: 0.9rem;
  letter-spacing: 0.05rem;
  padding: 0.75rem 1rem;
  color: white !important;
  background-color: #884c42 !important;
  border-radius: 10px;
}
</style>
