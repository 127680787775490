<template>
  <div class="col-lg-12 pt_35">
    <div class="accorodion_wrapper" id="accordian">
      <div class="bg-white accoridian_bg border">
        <h3 class="mt_20 heading">Positive Patterns</h3>
        <div class="collapse show" id="idcollapseOne">
          <div class="accorodian_content accorodian_content2" id="RO_K2_K">
            <p
              class="pt_5"
              v-for="value in comparisonTestAnalysis.positive_pattern"
              :key="value.index"
            >
              {{ value }}
              <br>
              <br>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PositivePatterns",
  components: {},
  props: ["comparisonTestAnalysis"],

  data() {
    return {};
  },

  mounted() {},
};
</script>
<style scoped>
.heading {
  font-weight: bold;
  color: #884c42;
  padding: 5px;
  margin-left: 20px;
  padding-top: 20px;
  font-family: "Inter", sans-serif;
}
</style>
