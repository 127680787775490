import { getReq } from "../../../services/api";

const state = {
  metabolicBlueprint:[],
};

const getters = {
  metabolicBlueprint: (state) => state.metabolicBlueprint,
};


const actions = {
  
  // metabolicBlueprint Report

  async getMetabolicBlueprint({ commit },id) {
    try {
      let metabolicBlueprint = await getReq(`metabolic-report/${id}/`);
      commit("setMetabolicBlueprint", metabolicBlueprint);
      
    } catch (error) {
      console.log(error)
      console.log(error.response);
      throw error.response;
    }
  },

};

const mutations = {
  setMetabolicBlueprint: (state, metabolicBlueprint) => (state.metabolicBlueprint = metabolicBlueprint),

};

export default {
  state,
  getters,
  actions,
  mutations,
};
