import Vuex from "vuex";
import Vue from "vue";

import user from "./modules/user/user";
// import singleTest from "./modules/singleTest/singleTest"
import htmaResultsInput from "./modules/htmaResultsInput/htmaResultsInput"
import naq from "./modules/naq/naq"
import naqHtma from "./modules/naqHtma/naqHtma"
import condition from "./modules/condition/condition"
// import bloodMarker from "./modules/bloodMarker/bloodMarker"
import bloodMarkerIron from "./modules/bloodMarker/bloodMarkerIron"
import bloodMarkerIodine from "./modules/bloodMarker/bloodMarkerIodine"
import bloodMarkerCopper from "./modules/bloodMarker/bloodMarkerCopper"
import metabolicBlueprint from "./modules/metabolicBlueprint/metabolicBlueprint"
import clientReport from "./modules/clientReport/clientReport"
import comparisonTestAnalysis from "./modules/comparisonTestAnalysis/comparisonTestAnalysis"
import errorHandling from "./modules/errorHandling/errorHandling"

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    htmaResultsInput,
    naq,
    naqHtma,
    condition,
    // bloodMarker,
    bloodMarkerIron,
    bloodMarkerIodine,
    bloodMarkerCopper,
    metabolicBlueprint,
    clientReport,
    comparisonTestAnalysis,
    errorHandling,
  },
});
