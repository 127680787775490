var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":""}},[(_vm.loading)?_c('div',{attrs:{"id":"preloader"}},[_vm._m(0)]):_vm._e(),_c('main',[_c('div',{staticClass:"main_content_wrapper"},[_c('br'),_c('h2',{staticClass:"mx-2 pt-2 mb-2 text-center"},[_vm._v(" Results Summary for "+_vm._s(_vm.singleTestAnalysisData.client_name)+" ")]),_c('h3',{staticClass:"mx-2 pt-2 mb-2 text-center"},[_vm._v(" Prepared by "+_vm._s(_vm.singleTestAnalysisData.practitioner_name)+" for results from "+_vm._s(_vm.dateValue)+" ")]),_c('hr'),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 pt_20"},[(_vm.singleTestAnalysisData)?_c('indicators',{attrs:{"singleTestAnalysisData":_vm.singleTestAnalysisData}}):_vm._e()],1)]),_c('div',{staticClass:"pg_break",staticStyle:{"break-after":"always"}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 pt_40"},[_c('div',{staticClass:"accorodion_wrapper",attrs:{"id":"accordian"}},[_c('heavy-metals',{attrs:{"singleTestAnalysisData":_vm.singleTestAnalysisData}}),_c('br'),(
                  _vm.singleTestAnalysisData &&
                  _vm.singleTestAnalysisData.consideration &&
                  _vm.singleTestAnalysisData.consideration.length
                )?_c('consideration',{attrs:{"singleTestAnalysisData":_vm.singleTestAnalysisData}}):_vm._e(),_c('br'),(
                  _vm.singleTestAnalysisData &&
                  _vm.singleTestAnalysisData.metabolic_pattern &&
                  _vm.singleTestAnalysisData.metabolic_pattern.length
                )?_c('metabolic-patterns',{attrs:{"singleTestAnalysisData":_vm.singleTestAnalysisData.metabolic_pattern}}):_vm._e(),_c('br'),(
                  _vm.singleTestAnalysisData &&
                  _vm.singleTestAnalysisData.stress_pattern &&
                  _vm.singleTestAnalysisData.stress_pattern.length
                )?_c('stress-patterns',{attrs:{"singleTestAnalysisData":_vm.singleTestAnalysisData.stress_pattern}}):_vm._e(),_c('br'),(
                  _vm.singleTestAnalysisData &&
                  _vm.singleTestAnalysisData.psychological_patterns &&
                  _vm.singleTestAnalysisData.psychological_patterns.length
                )?_c('psychological-patterns',{attrs:{"singleTestAnalysisData":_vm.singleTestAnalysisData.psychological_patterns}}):_vm._e(),_c('br'),(_vm.naqHtmaShow)?_c('div',{staticClass:"bg-white accoridian_bg border"},[_c('h3',{staticClass:"mt_20 heading"},[_vm._v("NAQ + HTMA")]),_c('div',{staticClass:"collapse show",attrs:{"id":"idcollapseFive"}},[_c('div',{staticClass:"accorodian_content accorodian_content2",attrs:{"id":"SO_Q2_R"}},[(_vm.naqGraphData && _vm.naqGraphData.naq_graph_values)?_c('div',{staticClass:"graph_box"},[_c('h3',[_vm._v("Symptom Burden Graph")]),_c('div',{staticClass:"graph_img"},[(_vm.chartIsLoaded)?_c('bar-chart',{attrs:{"chartdata":_vm.chartdata,"options":_vm.chartOptions}}):_vm._e()],1)]):_vm._e(),(_vm.naqHtmaReportData)?_c('naq-htma',{attrs:{"singleTestAnalysisData":_vm.naqHtmaReportData}}):_vm._e()],1)])]):_vm._e(),_c('br'),(
                  _vm.conditionReportData &&
                  _vm.conditionReportData.condition_response.length
                )?_c('conditions',{attrs:{"singleTestAnalysisData":_vm.conditionReportData.condition_response}}):_vm._e()],1)])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"loader3"},[_c('span'),_c('span')])}]

export { render, staticRenderFns }