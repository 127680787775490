<template>
  <div>
    <div v-if="!conditionsOnly" :class="Conditionbox">
      <button class="menu_item next-button" @click="onBack">Back</button>
      <button class="menu_item button" v-if="!editId" @click="submit">Submit</button>
    </div>
    <div
      v-if="conditionsOnly"
      class="mx-auto w-100 submit-box"
      style="display: flex; justify-content: flex-end !important"
    >
      <button class="menu_item button" @click="submit">Submit</button>
    </div>
    <div class="accoridian_bg graph_box">
      <h2
        v-if="editId"
        class="mx-2 mt-4 pt-2 mb-2"
        style="margin-top: 50px !important"
      >
        Edit Condition
      </h2>
      <h2 v-else class="mx-2 mb-2">Conditions</h2>
      <div>
        <div
          class="submit-box"
          style="display: flex; justify-content: flex-end !important"
          v-if="editId"
        >
          <button class="menu_item button" @click="onUpdate">Update</button>
        </div>
        <br />
        <!-- search -->
        <div class="row mb-5" v-if="!editId">
          <div class="col-lg-5 mx-auto w-100">
            <model-list-select
              :list="clientNameData"
              v-model="clientName"
              option-value="id"
              option-text="client_name"
              placeholder="Search Client Name"
            >
            </model-list-select>
          </div>
          <div class="col-lg-5">
            <model-list-select
              :list="datesData"
              v-model="conditionDate"
              option-value="id"
              option-text="created"
              placeholder="Search Condition Date"
            >
            </model-list-select>
          </div>
          <div class="col-lg-2 submit-box">
            <button
              v-if="conditionId"
              @click="onReset()"
              class="menu_item submit-button"
            >
              Reset
            </button>
            <button v-else @click="search()" class="menu_item submit-button">
              Search
            </button>
          </div>
        </div>
        <!-- search -->

        <div class="card">
          <div class="card__item">
            <div style="margin-left: 5%" v-if="conditionsOnly || editId">
              <label for="">Client Name *</label>
              <input
                style="margin-left: 5%"
                type="text"
                min="0"
                class="w-75"
                v-model="compVal.client_name"
              />
            </div>
            <!-- <br> -->
            <table id="tbl_conditions">
              <tr>
                <td class="table_section" rowspan="69">Conditions</td>
                <td><input type="checkbox" v-model="compVal.add_adhd" /></td>
                <td>ADD / ADHD</td>
              </tr>
              <tr>
                <td><input type="checkbox" v-model="compVal.allergy" /></td>
                <td>Allergies</td>
              </tr>
              <tr>
                <td><input type="checkbox" v-model="compVal.aneurysms" /></td>
                <td>Aneurysms</td>
              </tr>
              <tr>
                <td><input type="checkbox" v-model="compVal.anxiety" /></td>
                <td>Anxiety</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" v-model="compVal.arrhythmias" />
                </td>
                <td>Arrhythmias (Fast Ox)</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" v-model="compVal.arteriosclerosis" />
                </td>
                <td>Arteriosclerosis (Arterial Disease)</td>
              </tr>
              <tr>
                <td><input type="checkbox" v-model="compVal.arthritis" /></td>
                <td>Arthritis</td>
              </tr>
              <tr>
                <td><input type="checkbox" v-model="compVal.bloating" /></td>
                <td>Bloating</td>
              </tr>
              <tr>
                <td><input type="checkbox" v-model="compVal.cancer" /></td>
                <td>Cancer</td>
              </tr>
              <tr>
                <td><input type="checkbox" v-model="compVal.candida" /></td>
                <td>Candida</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" v-model="compVal.constipation" />
                </td>
                <td>Constipation</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" v-model="compVal.depression" />
                </td>
                <td>Depression</td>
              </tr>
              <tr>
                <td><input type="checkbox" v-model="compVal.diabetes" /></td>
                <td>Diabetes</td>
              </tr>
              <tr>
                <td><input type="checkbox" v-model="compVal.diarrhea" /></td>
                <td>Diarrhea</td>
              </tr>
              <tr>
                <td><input type="checkbox" v-model="compVal.edema" /></td>
                <td>Edema</td>
              </tr>
              <tr>
                <td><input type="checkbox" v-model="compVal.epilepsy" /></td>
                <td>Epilepsy</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.fractures_not_healing"
                  />
                </td>
                <td>Fractures Not Healing or Slow Healing</td>
              </tr>
              <tr>
                <td><input type="checkbox" v-model="compVal.gastritis" /></td>
                <td>Gastritis, Colitis, IBS</td>
              </tr>
              <tr>
                <td><input type="checkbox" v-model="compVal.headache" /></td>
                <td>Headaches</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" v-model="compVal.heartattack" />
                </td>
                <td>Heart Attack</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.high_blood_pressure"
                  />
                </td>
                <td>High Blood Pressure (Hypertension)</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" v-model="compVal.hypoglycemia" />
                </td>
                <td>Hypoglycemia</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" v-model="compVal.low_blood_pressure" />
                </td>
                <td>Low Blood Pressure (Hypotension)</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" v-model="compVal.mood_swing" />
                </td>
                <td>Mood Swings</td>
              </tr>
              <tr>
                <td><input type="checkbox" v-model="compVal.spasms" /></td>
                <td>Muscle cramps or spasms</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.neuromuscular_disease"
                  />
                </td>
                <td>
                  Neuromuscular Disease (Parkinson&#39;s, MS, ALS, Myasthenia
                  Gravis, and Muscular Dystrophy)
                </td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" v-model="compVal.osteoporosis" />
                </td>
                <td>Osteoporosis</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="compVal.pms_estrogen_dominant"
                  />
                </td>
                <td>PMS - Estrogen Dominant</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" v-model="compVal.pms_low_estrogen" />
                </td>
                <td>PMS - Low Estrogen</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" v-model="compVal.poor_circulation" />
                </td>
                <td>Poor Circulation</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" v-model="compVal.kidney_impairment" />
                </td>
                <td>Renal Impairment (Kidney Impairment)</td>
              </tr>
              <tr>
                <td><input type="checkbox" v-model="compVal.scoliosis" /></td>
                <td>Scoliosis</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" v-model="compVal.ligament_problem" />
                </td>
                <td>Tendon or Ligament Problems</td>
              </tr>
              <tr>
                <td><input type="checkbox" v-model="compVal.plaque" /></td>
                <td>Tooth Decay and Plaque</td>
              </tr>
              <tr>
                <td><input type="checkbox" v-model="compVal.ulcer" /></td>
                <td>Ulcers</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div
        class="submit-box"
        style="
          display: flex;
          justify-content: flex-end !important;
          margin-top: 30px;
        "
        v-if="editId"
      >
        <button class="menu_item button" @click="onUpdate">Update</button>
      </div>
      <br />
    </div>
    <div v-if="!conditionsOnly" :class="Conditionbox">
      <button
        v-if="!editId"
        class="menu_item next-button"
        @click="onBack"
      >
        Back
      </button>
      <button v-if="!editId" class="menu_item button" @click="submit">
        Submit
      </button>
    </div>
    <div
      v-if="conditionsOnly"
      class="mx-auto w-100 submit-box"
      style="display: flex; justify-content: flex-end !important"
    >
      <button class="menu_item button" @click="submit">Submit</button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import { ModelListSelect } from "vue-search-select";
export default {
  name: "ConditionForm",
  components: {
    ModelListSelect,
  },
  props: ["condition", "submit", "conditionsOnly"],
  data() {
    return {
      clientName: "",
      conditionDate: "",
      conditionId: null,
      allConditionData: [],
      clientNameData: [],
      datesData: [],

      editId: null,
      editData: [],
    };
  },
  watch: {
    clientName(value) {
      if (value) {
        console.log("value", value);
        this.datesData = this.allConditionData.filter(
          (data) =>
            data.client_name ===
            this.allConditionData.find((x) => x.id === value).client_name
        );
        console.log("data.created");
        this.datesData.map((data) => {
          data.created = moment(data.created).format("MM/DD/YYYY");
        });
        console.log("conditionDatesData", this.datesData);
      }
    },
  },
  methods: {
    ...mapActions(["getAllCondition", "getSingleCondition", "updateCondition"]),

    async search() {
      console.log("submit");
      console.log("condition data id ", this.conditionDate);
      console.log("condition id searched is ", this.conditionDate);
      this.conditionId = this.conditionDate;
      this.$emit("setConditionId", this.conditionId);
    },
    onReset() {
      this.conditionId = null;
      this.clientName = null;
      this.conditionDate = null;
      console.log("condition id is", this.conditionId);
      this.$emit("reset");
    },

    onBack() {
      this.$emit("backStep");
      window.scrollTo(0, 0);
    },

    // Update

    async onUpdate() {
      if (!this.editData.client_name) {
        this.$toastr.Add({
          name: "Error", // this is give you ability to use removeByName method
          msg: "Please Enter Client Name!", // Toast Message
          type: "error", // Toast type,
        });
      } else {
        this.$toastr.Add({
          name: "Success", // this is give you ability to use removeByName method
          msg: "Condition has been updated Successfully!", // Toast Message
          type: "success", // Toast type,
        });
        console.log("updating");
        console.log("condition", this.condition);
        console.log("edit condition", this.editData);
        const dataUpdate = await this.updateCondition({
          id: this.editId,
          payload: this.editData,
        });
        console.log("data has been edited ", dataUpdate);
        this.$router.push(`/client-list`);
      }
    },
  },
  computed: {
    ...mapGetters(["allConditions", "singleCondition"]),
    Conditionbox: function () {
      return this.conditionsOnly ? "submit-box" : "box";
    },
    compVal: {
      get() {
        if (this.editId) {
          return this.editData;
        } else {
          return this.condition;
        }
      },
      // set (val) {
      //   if (this.editId) {
      //     this.editData = val
      //   } else {
      //     this.condition = val
      //   }
      // }
    },
  },
  async mounted() {
    this.editId = this.$route.params.id;
    if (this.editId) {
      console.log(this.editId);
      console.log("id getting");
      //  console.log(this.input)
      await this.getSingleCondition(this.editId);
      this.editData = await this.singleCondition;
      console.log(this.editData);
    } else {
      await this.getAllCondition();
      this.allConditionData = await this.allConditions;
      console.log("naq data ", this.allConditionData);

      this.clientNameData = this.allConditionData.filter(
        (a, i) =>
          this.allConditionData.findIndex(
            (s) => a.client_name === s.client_name
          ) === i
      );
      console.log("allConditionData are ", this.allConditionData);
      // console.log("all client names are ", this.clientNameData);
    }
  },
};
</script>
<style>
.box {
  display: flex;
  justify-content: space-between !important;
  padding-right: 20px;
}
.submit-box {
  display: flex;
  justify-content: flex-end !important;
  padding-right: 20px;
}
.next-button {
  width: 100px;
}
.back-button {
  width: 100px;
}
</style>
