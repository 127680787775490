<template>
<div id="app">
    <!-- <div class="banner">
        App is undergoing maintenance
    </div> -->
    <div id="preloader">
      <div class="loader3">
        <span></span>
        <span></span>
      </div>
    </div>
    <Header v-if="$route.name === 'Login' || $route.name === 'Register' || $route.name === 'ForgotPassword' || $route.name === 'PrivacyPolicy' || $route.name === 'Home' || $route.name === 'ResetPassword'"/>
    <router-view/>
</div>
</template>

<script>
import "jquery"
import $ from "jquery"
import Header from "./components/header/Header.vue";

import {
    mapGetters,
    mapActions
} from "vuex";
export default {
    name: 'App',
     components: {
        Header
    },
    mounted() {
        $('#preloader').hide()
        this.sticky_header();
        $(window).on('scroll', function () {
            if ($(this).scrollTop() > 600) {
                $('.back-to-top').fadeIn(200)
            } else {
                $('.back-to-top').fadeOut(200)
            }
        });

        //Animate the scroll to yop
        $('.back-to-top').on('click', function (event) {
            event.preventDefault();

            $('html, body').animate({
                scrollTop: 0,
            }, 1500);
        });

        // Hamburger-menu
        $('.hamburger-menu').on('click', function () {
            $('.hamburger-menu .line-top, .ofcavas-menu').toggleClass('current');
            $('.hamburger-menu .line-center').toggleClass('current');
            $('.hamburger-menu .line-bottom').toggleClass('current');
        });

    },
    computed: mapGetters(["token", "notification"]),

    methods: {
        ...mapActions(["getUser", "setToken"]),

        sticky_header() {
            var wind = $(window);
            var sticky = $('header');
            wind.on('scroll', function () {
                var scroll = wind.scrollTop();
                if (scroll < 100) {
                    sticky.removeClass('sticky');
                } else {
                    sticky.addClass('sticky');
                }
            });
        }
    }
}
</script>

<style>
:root {

    /* bg-color */
    --btn-bg-color: #5E72E4;
    --btn-color: #5E72E4;
    --black: #00000;
    --bg-grey: rgba(219, 219, 219, 1);
    --bg-green: rgba(44, 206, 137, 1);
    --bg-dark: #171F47;
    --red: #884C42;
    /* Font size */
    --extrasmall: 12px;
    --small: 14px;
}

/*======================
   01. Google fonts
========================*/
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
/*======================
   02. Basic css
========================*/
html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
strong,
i,
ol,
ul,
li,
form,
label,
footer,
header,
menu,
nav,
section {
    margin: 0;
    padding: 0;
}

h4 {
    margin-bottom: 0 !important;
}

:root {
    --black: #093014;
    --red: #884C42;
}

.text_red {
    color: #884C42;
}

body {
    /* font-family: 'Inter', sans-serif; */
    font-family: 'Roboto', sans-serif !important;
    color: #093014;
    font-size: 1rem;
    line-height: 18px;
    font-weight: 400;
}

ol,
ul {
    list-style: none;
}

a:hover {
    text-decoration: none;
}

button:focus,
a:focus,
input:focus,
textarea:focus {
    outline: none;
}

/*Proloader START CSS*/

#preloader {
    position: fixed;
    background: #fff;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    height: 100vh;
    width: 100vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.loader3 {
    width: 50px;
    height: 50px;
    display: inline-block;
    padding: 0px;
    text-align: left;
}

.loader3 span {
    position: absolute;
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background: #884C42;
    -webkit-animation: loader3 1.5s linear infinite;
    animation: loader3 1.5s linear infinite;
}

.loader3 span:last-child {
    animation-delay: -0.9s;
    -webkit-animation-delay: -0.9s;
}

@keyframes loader3 {
    0% {
        -webkit-transform: scale(0, 0);
        transform: scale(0, 0);
        opacity: 0.8;
    }

    100% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
        opacity: 0;
    }
}

@-webkit-keyframes loader3 {
    0% {
        -webkit-transform: scale(0, 0);
        opacity: 0.8;
    }

    100% {
        -webkit-transform: scale(1, 1);
        opacity: 0;
    }
}

/*Hamburger-menu START CSS*/
.hamburger-menu {
    cursor: pointer;
    position: absolute;
    right: 0;
    display: none;
    z-index: 999;
    top: 26px;
}

.hamburger-menu span {
    background: var(--black);
    width: 30px;
    height: 3px;
    display: block;
    margin: 5px 0;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.hamburger-menu:hover .line-top {
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
}

.hamburger-menu:hover .line-bottom {
    -webkit-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%);
}

.hamburger-menu .line-top.current {
    -webkit-transform: translateY(200%) rotate(135deg);
    -ms-transform: translateY(200%) rotate(135deg);
    transform: translateY(200%) rotate(135deg);
}

.hamburger-menu .line-center.current {
    opacity: 0;
}

.hamburger-menu .line-bottom.current {
    -webkit-transform: translateY(-325%) rotate(-135deg);
    -ms-transform: translateY(-325%) rotate(-135deg);
    transform: translateY(-325%) rotate(-135deg);
}

/*ofcanvas-menu*/
.ofcavas-menu {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: -1;
    top: 0;
    display: none;
    left: -100%;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    text-align: center;
}

.ofcavas-menu.current {
    left: 0;
}
.banner{
    background: #884C42;
    text-align: center;
    color: white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10000;
}
.ofcavas-menu a {
    color: #884C42;
    margin-top: 20px;
}

.ofcavas-menu ul {
    margin-top: 70px;
    line-height: 22px;
}

header {
    position: fixed;
    top: 0;
    left: 0;
    height: 80px;
    line-height: 80px;
    z-index: 1024;
    width: 100%;
    -webkit-transition: background 0.3s;
    -o-transition: background 0.3s;
    transition: background 0.3s;
    background-color: #ffffff;

}

.accoridian_bg {
    background: #FAFAFA !important;
}

/*sticky START CSS*/
header.sticky {
    -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    -webkit-box-shadow: 0 8px 20px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, .1);
}

.header_main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.logo_text {
    font-size: 22px;
    line-height: 1.2;
    font-weight: 600;
    color: var(--black);
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

.logo_text:hover {
    color: #884C42;
}

#menu {
    text-align: right;
}

#menu li {
    display: inline-block;
    padding: 0 5px;
}

#menu li a {
    display: inline-block;
    font-size: 14px;
    color: #884C42;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    font-weight: 500;
    line-height: 1.2;
}

.menu_item {
    background-color: #884C42;
    padding: 12px 15px;
    color: #ffffff !important;
    border-radius: 10px;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    border: none !important;
}

.menu_item:hover {
    background-color: var(--black);
}

#menu li:nth-child(3) {
    padding-right: 10px;
}

#menu li:nth-child(4) {
    padding-left: 10px;
}

#menu li:last-child {
    padding-right: 0;
}

#menu>li>a.active,
#menu>li>a:hover {
    color: var(--black);
}

/* main area start */
.main_content_wrapper {
    padding: 80px 15px 130px;
    background-color: #FAFAFA;
}

.side_content {
    background-color: #ffffff;
    padding: 20px 20px 13px 20px;
    border-radius: 10px;
}

.side_content_box h3 {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
}

.side_content_box .content {
    padding-top: 15px;
}

.circale {
    position: relative;
}

.circale::before {
    position: absolute;
    content: '';
    height: 12px;
    width: 12px;
    border-radius: 50px;
    background-color: #B58676;
    top: 3px;
    left: 0;
}

.content p {
    padding-left: 22px;
    padding-top: 10px;
    line-height: 20px;
}

.content p:first-of-type {
    padding-top: 0;
}

.content {
    padding-bottom: 25px;
    border-bottom: 1px solid rgba(9, 48, 20, 0.1);
}

.font_weight {
    font-weight: 500;
}

/* graph area */
.graph_box {
    padding: 20px 20px 35px 20px;
    background-color: #ffffff;
    border-radius: 10px;
}

.graph_box h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 20px;
}

.graph_box h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    padding-bottom: 20px;
}

.graph_img img {
    mix-blend-mode: multiply;
}

/* indactor area */
.title_bg {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-transform: uppercase;
}

.title_md {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
}

.middle_content_box {
    padding: 15px;
    background-color: #ffffff;
    border-radius: 10px;
    min-height: 180px;
}

.middle_content_box h3 span {
    color: #884C42 !important;
    padding-left: 10px;
}

.middle_content_box h3 {
    border-bottom: 1px solid rgba(9, 48, 20, 0.1);
    padding-bottom: 15px;
    margin-bottom: 15px;
}

.middle_content_list li:first-of-type {
    padding-top: 0;
}

.middle_content_list li {
    padding-top: 8px;
}

.scroll {
    overflow: auto;
    height: 94px;
}

.nicescroll-rails.nicescroll-rails-vr {
    width: 2px !important;
    background-color: rgba(9, 48, 20, 0.1);
}

/* accorodian */
.accoridian_bg {
    border-radius: 15px;
}

.accordion_btn {
    background-color: #ffffff;
    display: block;
    width: 100%;
    border: none;
    outline: none;
    border-radius: 15px;
    padding: 12px 30px;
    text-align: left;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: var(--black);
    position: relative;
    text-transform: capitalize;
    -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

.accordion_btn::before {
    position: absolute;
    content: '';
    background-image: url('../public/assets/images/uparrow.svg');
    background-repeat: no-repeat;
    background-size: contain;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 33px;
    height: 13px;
    width: 22px;
    background-position: center center;
}

.accordion_btn[aria-expanded="true"]::before {
    content: '';
    background-image: url('../public/assets/images/down-arrow.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
}

.accordion_btn[aria-expanded="true"] {
    background-color: rgba(181, 134, 118, 0.1);
    -webkit-box-shadow: none;
    box-shadow: none;
}

.accorodian_content {
    padding: 20px 32px 45px 32px;
    background-color: #ffffff;
    border-radius: 0 0 15px 15px;
}

.accorodian_content h4 {
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    color: #884C42;
}

.accorodian_content p {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
}

/* index 2  area */
.content1 {
    min-height: 135px;
}

.content2 {
    min-height: 200px;
}

.content3 {
    min-height: 270px;
}

.content4 {
    min-height: 285px;
}

.middle_content_box1 h3 span {
    padding-left: 152px;
}

.middle_content_box2 h3 span {
    padding-left: 52px;
}

.middle_content_box3 h3 span {
    padding-left: 53px;
}

.middle_content_box4 h3 span {
    padding-left: 142px;
}

.middle_content_box5 h3 span {
    padding-left: 219px;
}

.middle_content_box6 h3 span {
    padding-left: 111px;
}

.middle_content_box7 h3 span {
    padding-left: 121px;
}

.middle_content_box8 h3 span {
    padding-left: 162px;
}

.accorodian_content2 {
    min-height: 250px;
}

/* index 3 area */
.middle_content_box3 {
    min-height: 160px !important;
}

.middle_content3 {
    width: 100%;
    display: flex;
}

.middle_left_content3 {
    width: 54%;
}

.middle_right_content3 {
    width: 36%;
}

.middle_left_content3 p,
.middle_right_content3 p {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    padding-top: 15px;
}

.middle_left_content3 p {
    color: #9B8A79;
}

.middle_right_content3 p {
    color: #884C42;
}

.middle_left_content3 p:first-of-type,
.middle_right_content3 p:first-of-type {
    padding-top: 0;
}

.middle_content_box3_1 {
    min-height: 320px;
}

.heading_brd {
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(9, 48, 20, 0.1);
    margin-bottom: 25px;
}

.heading_brd .h1 {
    padding-left: 30px;
}

.heading_brd .h2 {
    padding-right: 15px;
    color: #884C42;
}

.middle_content_box3_1 p {
    font-family: 'Inter', sans-serif;
}

.scroll_top_box {
    width: 100%;
    display: flex;
}

.scroll_left_heading {
    width: 38%;
}

.scroll_right_heading {
    width: 62%;
}

.scroll_left_heading h4 {
    color: #9B8A79;
}

.scroll_right_heading h4 {
    color: #884C42;
}

.scroll_box {
    width: 100%;
    display: flex;
}

.scroll_left {
    padding: 0 15px 15px 10px;
    border-right: 1px solid rgba(9, 48, 20, 0.1);
    width: 50%;
}

.scroll_right {
    width: 50%;
    padding: 0 10px 15px 20px;
}

.scroll_left p {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
}

.scroll_item {
    overflow: auto;
    height: 149px !important;
}

/* index 4 area */
.accorodian_content4 {
    width: 100%;
    min-height: 350px;
    display: flex;
}

.accorodian_content4 .accorodian_left_content {
    width: 65%;
    padding: 50px 30px;
}

.accorodian_content4 .accorodian_right_content {
    width: 35%;
    padding: 50px 30px;
}

.accorodian_content_box {
    display: flex;
    align-items: center;
}

.accorodian_content_box .p1 {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #884C42;
}

.accorodian_content_box .p2 {
    font-size: 12px;
    line-height: 20px;
    font-family: 'Inter', sans-serif;
    padding-left: 20px;
}

.accorodian_full_content {
    padding: 50px 30px;
    min-height: 280px;
}

/*return-to-top START CSS*/

.back-to-top {
    font-size: 24px;
    width: 45px;
    height: 45px;
    line-height: 42px;
    text-align: center;
    display: none;
    position: fixed;
    bottom: 30px;
    right: 20px;
    border-radius: 50%;
    background: #884C42;
    z-index: 1000;
}

.back-to-top img {
    width: 15px;
}

.mt_5 {
    margin-top: 5px
}

.mt_10 {
    margin-top: 10px
}

.mt_15 {
    margin-top: 15px
}

.mt_20 {
    margin-top: 20px
}

.mt_25 {
    margin-top: 25px
}

.mt_30 {
    margin-top: 30px
}

.mt_35 {
    margin-top: 35px
}

.mt_40 {
    margin-top: 40px
}

.mt_45 {
    margin-top: 45px
}

.mt_50 {
    margin-top: 50px
}

.mt_55 {
    margin-top: 55px
}

.mt_60 {
    margin-top: 60px
}

.mt_65 {
    margin-top: 65px
}

.mt_70 {
    margin-top: 70px
}

.mt_75 {
    margin-top: 75px
}

.mt_80 {
    margin-top: 80px
}

.mt_85 {
    margin-top: 85px
}

.mt_90 {
    margin-top: 90px
}

.mt_95 {
    margin-top: 95px
}

.mt_100 {
    margin-top: 100px
}

.mb_5 {
    margin-bottom: 5px
}

.mb_10 {
    margin-bottom: 10px
}

.mb_15 {
    margin-bottom: 15px
}

.mb_20 {
    margin-bottom: 20px
}

.mb_25 {
    margin-bottom: 25px
}

.mb_30 {
    margin-bottom: 30px
}

.mb_35 {
    margin-bottom: 35px
}

.mb_40 {
    margin-bottom: 40px
}

.mb_45 {
    margin-bottom: 45px
}

.mb_50 {
    margin-bottom: 50px
}

.mb_55 {
    margin-bottom: 55px
}

.mb_60 {
    margin-bottom: 60px
}

.mb_65 {
    margin-bottom: 65px
}

.mb_70 {
    margin-bottom: 70px
}

.mb_75 {
    margin-bottom: 75px
}

.mb_80 {
    margin-bottom: 80px
}

.mb_85 {
    margin-bottom: 85px
}

.mb_90 {
    margin-bottom: 90px
}

.mb_95 {
    margin-bottom: 95px
}

.mb_100 {
    margin-bottom: 100px
}

.mr_5 {
    margin-right: 5px
}

.mr_10 {
    margin-right: 10px
}

.mr_15 {
    margin-right: 15px
}

.mr_20 {
    margin-right: 20px
}

.mr_25 {
    margin-right: 25px
}

.mr_30 {
    margin-right: 30px
}

.mr_35 {
    margin-right: 35px
}

.mr_40 {
    margin-right: 40px
}

.mr_45 {
    margin-right: 45px
}

.mr_50 {
    margin-right: 50px
}

.mr_55 {
    margin-right: 55px
}

.mr_60 {
    margin-right: 60px
}

.mr_65 {
    margin-right: 65px
}

.mr_70 {
    margin-right: 70px
}

.mr_75 {
    margin-right: 75px
}

.mr_80 {
    margin-right: 80px
}

.mr_85 {
    margin-right: 85px
}

.mr_90 {
    margin-right: 90px
}

.mr_95 {
    margin-right: 95px
}

.mr_100 {
    margin-right: 100px
}

.ml_5 {
    margin-left: 5px
}

.ml_10 {
    margin-left: 10px
}

.ml_15 {
    margin-left: 15px
}

.ml_20 {
    margin-left: 20px
}

.ml_25 {
    margin-left: 25px
}

.ml_30 {
    margin-left: 30px
}

.ml_35 {
    margin-left: 35px
}

.ml_40 {
    margin-left: 40px
}

.ml_45 {
    margin-left: 45px
}

.ml_50 {
    margin-left: 50px
}

.ml_55 {
    margin-left: 55px
}

.ml_60 {
    margin-left: 60px
}

.ml_65 {
    margin-left: 65px
}

.ml_70 {
    margin-left: 70px
}

.ml_75 {
    margin-left: 75px
}

.ml_80 {
    margin-left: 80px
}

.ml_85 {
    margin-left: 85px
}

.ml_90 {
    margin-left: 90px
}

.ml_95 {
    margin-left: 95px
}

.ml_100 {
    margin-left: 100px
}

.pt_5 {
    padding-top: 5px
}

.pt_10 {
    padding-top: 10px
}

.pt_15 {
    padding-top: 15px
}

.pt_20 {
    padding-top: 20px
}

.pt_25 {
    padding-top: 25px
}

.pt_30 {
    padding-top: 30px
}

.pt_35 {
    padding-top: 35px
}

.pt_40 {
    padding-top: 40px
}

.pt_45 {
    padding-top: 45px
}

.pt_50 {
    padding-top: 50px
}

.pt_55 {
    padding-top: 55px
}

.pt_60 {
    padding-top: 60px
}

.pt_65 {
    padding-top: 65px
}

.pt_70 {
    padding-top: 70px
}

.pt_75 {
    padding-top: 75px
}

.pt_80 {
    padding-top: 80px
}

.pt_85 {
    padding-top: 85px
}

.pt_90 {
    padding-top: 90px
}

.pt_95 {
    padding-top: 95px
}

.pt_100 {
    padding-top: 100px
}

.pb_5 {
    padding-bottom: 5px
}

.pb_10 {
    padding-bottom: 10px
}

.pb_15 {
    padding-bottom: 15px
}

.pb_20 {
    padding-bottom: 20px
}

.pb_25 {
    padding-bottom: 25px
}

.pb_30 {
    padding-bottom: 30px
}

.pb_35 {
    padding-bottom: 35px
}

.pb_40 {
    padding-bottom: 40px
}

.pb_45 {
    padding-bottom: 45px
}

.pb_50 {
    padding-bottom: 50px
}

.pb_55 {
    padding-bottom: 55px
}

.pb_60 {
    padding-bottom: 60px
}

.pb_65 {
    padding-bottom: 65px
}

.pb_70 {
    padding-bottom: 70px
}

.pb_75 {
    padding-bottom: 75px
}

.pb_80 {
    padding-bottom: 80px
}

.pb_85 {
    padding-bottom: 85px
}

.pb_90 {
    padding-bottom: 90px
}

.pb_95 {
    padding-bottom: 95px
}

.pb_100 {
    padding-bottom: 100px
}

.pr_5 {
    padding-right: 5px
}

.pr_10 {
    padding-right: 10px
}

.pr_15 {
    padding-right: 15px
}

.pr_20 {
    padding-right: 20px
}

.pr_25 {
    padding-right: 25px
}

.pr_30 {
    padding-right: 30px
}

.pr_35 {
    padding-right: 35px
}

.pr_40 {
    padding-right: 40px
}

.pr_45 {
    padding-right: 45px
}

.pr_50 {
    padding-right: 50px
}

.pr_55 {
    padding-right: 55px
}

.pr_60 {
    padding-right: 60px
}

.pr_65 {
    padding-right: 65px
}

.pr_70 {
    padding-right: 70px
}

.pr_75 {
    padding-right: 75px
}

.pr_80 {
    padding-right: 80px
}

.pr_85 {
    padding-right: 85px
}

.pr_90 {
    padding-right: 90px
}

.pr_95 {
    padding-right: 95px
}

.pr_100 {
    padding-right: 100px
}

.pl_5 {
    padding-left: 5px
}

.pl_10 {
    padding-left: 10px
}

.pl_15 {
    padding-left: 15px
}

.pl_20 {
    padding-left: 20px
}

.pl_25 {
    padding-left: 25px
}

.pl_30 {
    padding-left: 30px
}

.pl_35 {
    padding-left: 35px
}

.pl_40 {
    padding-left: 40px
}

.pl_45 {
    padding-left: 45px
}

.pl_50 {
    padding-left: 50px
}

.pl_55 {
    padding-left: 55px
}

.pl_60 {
    padding-left: 60px
}

.pl_65 {
    padding-left: 65px
}

.pl_70 {
    padding-left: 70px
}

.pl_75 {
    padding-left: 75px
}

.pl_80 {
    padding-left: 80px
}

.pl_85 {
    padding-left: 85px
}

.pl_90 {
    padding-left: 90px
}

.pl_95 {
    padding-left: 95px
}

.pl_100 {
    padding-left: 100px
}

table {
    width: 100%;
    /* border: solid black 1px; */
    border-collapse: collapse;
}

td,
th {
    /* border: solid black 1px; */
    min-width: 3rem;
    padding: 0.25rem;
}

th {
    text-align: center;
    font-size: large;
}

td>input,
td>select {
    width: 100%;
}

.table_section {
    font-size: large;
    font-weight: bold;
    text-align: center;
}

.collapse {
    padding: 1rem;
}

.card {
    padding: 1rem;
}

/*Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
    .container {
        max-width: 1170px;
    }
}

@media (max-width: 1199.98px) {
    .logo_text {
        font-size: 20px;
    }

    #menu li {
        padding: 0 5px;
    }

    #menu li a {
        font-size: 12px;
    }

    #menu li:nth-child(3) {
        padding-right: 5px;
    }

    #menu li:nth-child(4) {
        padding-left: 5px;
    }

    .side_content_box h3 {
        font-size: 13px;
    }

    .side_content {
        padding: 13px;
    }

    .content3 {
        min-height: 249px;
    }

    .content4 {
        min-height: 270px;
    }

    .scroll_left_heading {
        width: 48%;
    }

    .scroll_right_heading {
        width: 57%;
    }
}

@media (max-width: 991.98px) {
    #menu {
        display: none;
    }

    .hamburger-menu {
        display: block;
    }

    .ofcavas-menu {
        display: block;
    }

    .ofcavas-menu a {
        display: inline-block;
    }

    a.menu_item.last_menu {
        margin-top: 28px;
    }

    .right_clear {
        padding-right: calc((100% - 720px) / 2 + 15px);
    }

    .main_content_wrapper {
        padding: 80px 0 85px;
    }
}

/*Medium devices (tablets, 767px and down)*/
@media (max-width: 767.98px) {
    .right_clear {
        padding-right: calc((100% - 540px) / 2 + 15px);
    }

    .accordion_btn {
        padding: 12px 15px;
    }

    .accordion_btn::before {
        right: 16px;
    }

    .accorodian_content {
        padding: 20px 15px 45px 15px;
    }

    .accorodian_content4 .accorodian_left_content {
        width: 50%;
        padding: 25px 15px;
    }

    .accorodian_content4 .accorodian_right_content {
        width: 50%;
        padding: 25px 15px;
    }

    .accorodian_full_content {
        padding: 25px 15px;
        min-height: 280px;
    }

    .accorodian_content_box .p2 {
        padding-left: 15px;
    }

    .col_index4 {
        padding-top: 30px;
    }
}

/*Small devices (landscape phones, 575px and down)*/
@media (max-width: 575px) {
    header {
        height: 70px;
    }

    .hamburger-menu {
        top: 21px;
    }

    .right_clear {
        padding-right: 15px;
    }

    .accordion_btn {
        font-size: 16px;
        line-height: 25px;
    }

    .accorodian_content_box .p1 {
        font-size: 13px;
    }

    .accorodian_content_box .p2 {
        font-size: 11px;
        padding-left: 6px;
    }

    .logo_text4 {
        font-size: 17px !important;
    }

    .inde3_pt {
        padding-top: 30px;
    }
}

@media (max-width: 450px) {
    .graph_box {
        padding: 0px
    }

    body,
    .form-control {
        font-size: 10px !important;
    }

    .card {
        padding: 0.5rem !important;
    }

    .back-button,
    .next-button {
        width: auto !important;
        padding:12px 25px; 
    }
}

th {
    text-align: left !important;
}
@media print {
  #sidebar-wrapper * {
  visibility:hidden;
  }
 /* body * {
  visibility:hidden;
  } */
  /* #print, #print * {
    visibility:visible;
  }
  #print {
    position:absolute;
    left:0;
    top:0;
  } */
}
.my-8{
    margin-top: 8rem;
}
</style>
