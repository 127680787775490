<template>
  <div class="bg-white accoridian_bg border">
    <h3 class="mt_20 heading">Specific Heavy Metals</h3>
    <div class="collapse show" id="idcollapseOne">
      <div class="accorodian_content accorodian_content2" id="SO_H2_I">
        <div
          v-for="value in singleTestAnalysisData.specific_heavy_metal"
          :key="value.index"
        >
          <h4 class="mb-2">{{ value.heading.toUpperCase() }}</h4>
          <p
            style="margin: 0; padding-top: 0 margin-bottom:5px;"
            class="pt_15"
            v-for="desc in value.desc"
            :key="desc.index"
          >
            {{ desc }}
          </p>
          <br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeavyMetals",
  components: {},
  props: ["singleTestAnalysisData"],
  data() {
    return {};
  },
  mounted() {},
};
</script>
<style scoped>
.heading {
  font-weight: bold;
  color: #884c42;
  padding: 5px;
  margin-left: 20px;
  padding-top: 20px;
  font-family: "Inter", sans-serif;
}
</style>
