<template>
  <div class="bg-white accoridian_bg border">
    <h3 class="mt_20 heading">Conditions</h3>
    <div class="collapse show" id="idcollapseSix">
      <div class="accorodian_content accorodian_content2" id="SO_T2_U">
        <div v-for="value in singleTestAnalysisData" :key="value.index">
          <h4 class="mb-2">{{ value.heading }}</h4>
          <p
            style="margin: 0; margin-bottom: 5px; padding-top: 0"
            class="pt_15"
            v-for="desc in value.desc"
            :key="desc.index"
          >
            {{ desc }}
          </p>
          <br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Conditions",
  components: {},
  props: ["singleTestAnalysisData"],

  data() {
    return {};
  },

  mounted() {},
};
</script>
<style scoped>
.heading {
  font-weight: bold;
  color: #884c42;
  padding: 5px;
  margin-left: 20px;
  padding-top: 20px;
  font-family: "Inter", sans-serif;
}
</style>
