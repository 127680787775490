<template>
<section class="pricing py-5">
    <div class="container mt-3">
        <div class="row">

            <!-- Plus Tier -->
            <div class="col-lg-6">
                <div class="card mb-5 mb-lg-0">
                    <div class="card-body">
                        <!-- <h5 class="card-title text-muted text-uppercase text-center">Plus</h5> -->
                        <h6 class="card-price text-center">$29<span class="period">/month</span></h6>
                        <hr>
                        <ul class="fa-ul">
                            <li><span class="fa-li"><i class="fas fa-check"></i></span>1 user</li>
                            <li><span class="fa-li"><i class="fas fa-check"></i></span>Includes 14 day free trial</li>
                            <li><span class="fa-li"><i class="fas fa-check"></i></span>Unlimited access for one month</li>
                            <li><span class="fa-li"><i class="fas fa-check"></i></span>Unlimited report downloads</li>
                            <li><span class="fa-li"><i class="fas fa-check"></i></span>Automatically bills each month</li>
                            <li><span class="fa-li"><i class="fas fa-check"></i></span>Cancel anytime</li>
                        </ul>
                        <div class="d-grid text-center mt-5">
                            <button class="btn btn-login text-uppercase fw-bold" v-if="currentPlan && currentPlan == 4" :disabled="currentPlan && currentPlan == 4">Already Subscribed</button>
                            <button @click="changePlan(4)" class="btn btn-login text-uppercase fw-bold" v-else-if="currentPlan">Change Plan</button>
                            <a target="_blank" class="btn btn-login text-uppercase fw-bold" :href="'https://api.instanthtma.com/subscription/stripe-checkout-session?payment_id=4&user='+user.pk">Select Plan</a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Pro Tier -->
            <div class="col-lg-6">
                <div class="card">
                    <div class="card-body">
                        <!-- <h5 class="card-title text-muted text-uppercase text-center">Pro</h5> -->
                        <h6 class="card-price text-center">$319<span class="period">/yearly</span></h6>
                        <hr>
                        <ul class="fa-ul">
                            <li><span class="fa-li"><i class="fas fa-check"></i></span>1 user</li>
                            <li><span class="fa-li"><i class="fas fa-check"></i></span>Includes 14 day free trial</li>
                            <li><span class="fa-li"><i class="fas fa-check"></i></span>Unlimited access for one year</li>
                            <li><span class="fa-li"><i class="fas fa-check"></i></span>Unlimited report downloads</li>
                            <li><span class="fa-li"><i class="fas fa-check"></i></span>Automatically bills each year</li>
                            <li><span class="fa-li"><i class="fas fa-check"></i></span>Cancel anytime</li>
                        </ul>
                        <div class="d-grid text-center mt-5">
                            <button class="btn btn-login text-uppercase fw-bold" v-if="currentPlan && currentPlan == 5" :disabled="currentPlan && currentPlan == 5">Already Subscribed</button>
                            <button @click="changePlan(5)" class="btn btn-login text-uppercase fw-bold" v-else-if="currentPlan">Change Plan</button>
                            <a target="_blank" class="btn btn-login text-uppercase fw-bold"  :href="'https://api.instanthtma.com/subscription/stripe-checkout-session?payment_id=5&user='+user.pk">Select Plan</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import {mapActions,mapGetters} from "vuex"
export default {
    name: "PaymentPricing",
    data() {
    return {
      currentPlan:null
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods:{
    ...mapActions(["changeUserSubscription"]),
    async changePlan(id){
        try{
            const status = await this.changeUserSubscription({
                email:this.user.email,
                subscriptions:[{
                    name: id === 4 ? "Monthly" : "Yearly",
                    quantity:1
                }]
            })
            if(status){
                this.$toastr.Add({
                    name: "Success", // this is give you ability to use removeByName method
                    msg: "You have Changed Plan Successfully!", // Toast Message
                    type: "success", // Toast type,
                  });
                this.$router.push("/")
            }
        }
        catch(e){
            console.log(e)
        }
    }
  },
  mounted(){
    if(this.$route.params.id){
        this.currentPlan = this.$route.params.id
    }
  }
}
</script>

<style scoped>
.pricing .card {
    border: none;
    border-radius: 1rem;
    transition: all 0.2s;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}

.pricing hr {
    margin: 1.5rem 0;
}

.pricing .card-title {
    margin: 0.5rem 0;
    font-size: 0.9rem;
    letter-spacing: .1rem;
    font-weight: bold;
}

.pricing .card-price {
    font-size: 3rem;
    margin: 0;
}

.pricing .card-price .period {
    font-size: 0.8rem;
}

.pricing ul li {
    margin-bottom: 1rem;
}

.pricing .text-muted {
    opacity: 0.7;
}

.pricing .btn {
    letter-spacing: .1rem;
    font-weight: bold;
    padding: 1rem;
    opacity: 0.7;
    transition: all 0.2s;
}

/* Hover Effects on Card */

@media (min-width: 992px) {
    .pricing .card:hover {
        margin-top: -.25rem;
        margin-bottom: .25rem;
        box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
    }

    .pricing .card:hover .btn {
        opacity: 1;
    }
}
</style>
