<template>
  <main>
    <b-container
      fluid
      class="main_content_wrapper pl-4 mb-5 pb-5 main-container"
    >
      <div class="pl-5">
        <h2 class="mt-5 pl-2 pt-2 mb-2">Client List</h2>
        <div class="row mb-5">
          <div class="col-lg-6 pl-4 pb-2 pt-3 font-weight-bold">
            Find Client Name
          </div>
          <div class="pl-2 col-lg-12" style="display: flex">
            <div class="col-lg-6">
              <model-list-select
                :list="clientNameData"
                v-model="clientName"
                option-value="client_name"
                option-text="client_name"
                placeholder="Search Client Name"
              >
              </model-list-select>
            </div>
            <!-- <div class="col-lg-6">
            <button @click="search()" class="menu_item submit-button">
              Search
            </button>
          </div> -->
            <div class="col-lg-3 submit-box">
              <button
                v-if="searched"
                @click="onReset()"
                class="menu_item submit-button"
              >
                Reset
              </button>
              <button v-else @click="search()" class="menu_item submit-button">
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Input Htma -->
      <InputHtma
        :inputHtmaTableData="inputHtmaTableData"
        @setInputHtmaData="setInputHtmaData($event)"
      />

      <!-- NAQ -->
      <NAQ :naqTableData="naqTableData" @setNaqData="setNaqData($event)" />

      <!-- NAQ Htma  -->
      <NaqHtma
        :naqHtmaTableData="naqHtmaTableData"
        @setNaqHtmaData="setNaqHtmaData($event)"
      />

      <!-- Conditions  -->
      <Condition
        :conditionTableData="conditionTableData"
        @setConditionData="setConditionData($event)"
      />
      <!-- Blood Markers -->
      <!-- <BloodMarker
        :bloodMarkerTableData="bloodMarkerTableData"
        @setBloodMarkerData="setBloodMarkerData($event)"
      /> -->
      <!-- Blood Markers -->
      <IronBloodMarker
        :IronBloodMarkerTableData="IronBloodMarkerTableData"
        @setIronBloodMarkerData="setIronBloodMarkerData($event)"
      />
      <!-- Blood Markers -->
      <IodineBloodMarker
        :IodineBloodMarkerTableData="IodineBloodMarkerTableData"
        @setIodineBloodMarkerData="setIodineBloodMarkerData($event)"
      />
      <!-- Blood Markers -->
      <CopperBloodMarker
        :CopperBloodMarkerTableData="CopperBloodMarkerTableData"
        @setCopperBloodMarkerData="setCopperBloodMarkerData($event)"
      />
    </b-container>
  </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ModelListSelect } from "vue-search-select";
import moment from "moment";

import InputHtma from "../../components/ClientList/InputHtma.vue";
import NAQ from "../../components/ClientList/NAQ.vue";
import NaqHtma from "../../components/ClientList/NaqHtma.vue";
import Condition from "../../components/ClientList/Condition.vue";
// import BloodMarker from "../../components/ClientList/BloodMarker.vue";
import IronBloodMarker from "../../components/ClientList/BloodMarkerIron.vue";
import IodineBloodMarker from "../../components/ClientList/BloodMarkerIodine.vue";
import CopperBloodMarker from "../../components/ClientList/BloodMarkerCopper.vue";

export default {
  name: "ClientList",
  components: {
    ModelListSelect,
    InputHtma,
    NAQ,
    NaqHtma,
    Condition,
    // BloodMarker,
    IronBloodMarker,
    IodineBloodMarker,
    CopperBloodMarker
  },
  data() {
    return {
      clientName: "",
      clientNameData: [],
      inputData: [],

      statusOptions: [
        {
          value: null,
          text: "",
        },
        {
          value: "Active",
          text: "Active",
        },
        {
          value: "Inactive",
          text: "Inactive",
        },
      ],
      perPage: 10,
      currentPage: 1,

      inputHtmaTableData: [],
      naqTableData: [],
      naqHtmaTableData: [],
      conditionTableData: [],
      // bloodMarkerTableData: [],
      IronBloodMarkerTableData: [],
      IodineBloodMarkerTableData: [],
      CopperBloodMarkerTableData: [],

      inputHtmaConstData: [],
      naqConstData: [],
      naqHtmaConstData: [],
      conditionConstData: [],
      // bloodMarkerConstData: [],
      IronBloodMarkerConstData: [],
      IodineBloodMarkerConstData: [],
      CopperBloodMarkerConstData: [],

      errors: {},
      searchClientName: null,
      pageOptions: [
        {
          value: 10,
          text: "10",
        },
        {
          value: 25,
          text: "25",
        },
        {
          value: 50,
          text: "50",
        },
        {
          value: 100,
          text: "100",
        },
      ],
      searched: false,
    };
  },
  watch: {
    clientName(value) {
      console.log("searched client name ", value);
      //   this.htmaDatesData = this.inputData.filter(
      //     (data) =>
      //       data.client_name ===
      //       this.inputData.find((x) => x.id === value).client_name
      //   );
      //   console.log("data.htma_test_date");
      //   this.htmaDatesData.map((data) => {
      //     data.htma_test_date = moment(data.htma_test_date).format("MM/DD/YYYY");
      //   });
      //   console.log("htmaDatesData", this.htmaDatesData);
    },
  },
  computed: {
    ...mapGetters([
      // "allInput",
      "allInputData",
      "allInputHtma",
      "allNaq",
      "allNaqHtma",
      // "bloodMarkers",
      "ironBloodMarkers",
      "iodineBloodMarkers",
      "copperBloodMarkers",
      "allConditions",
      // "clientReport",
    ]),
    rows() {
      return this.inputHtmaTableData.length;
      // return this.items.length;
    },
    positionText() {
      let endIndex = this.currentPage * this.perPage,
        startIndex =
          this.rows > 0 ? (this.currentPage - 1) * this.perPage + 1 : 0;

      return (
        "Showing " +
        startIndex +
        " to " +
        (endIndex > this.rows ? this.rows : endIndex) +
        " of " +
        this.rows +
        " entries"
      );
    },
  },
  methods: {
    ...mapActions([
      // "getAllInput",
      "getInputData",
      "getAllNaq",
      "getAllNaqHtma",
      // "getBloodMarkers",
      "getIronBloodMarkers",
      "getIodineBloodMarkers",
      "getCopperBloodMarkers",
      "getAllCondition",
      // "getAllInputHtma",
      // "getClientReport",
    ]),
    getMomentDate: function (date) {
      return moment(date).format("MM/DD/YYYY");
    },
    setInputHtmaData(data) {
      this.inputHtmaTableData = data;
    },
    setNaqData(data) {
      this.naqTableData = data;
    },
    setNaqHtmaData(data) {
      this.naqHtmaTableData = data;
    },
    setConditionData(data) {
      this.conditionTableData = data;
    },
    // setBloodMarkerData(data) {
    //   this.bloodMarkerTableData = data;
    // },
    setIronBloodMarkerData(data) {
      console.log(data,'setting iron...')
      this.IronBloodMarkerTableData = data;
    },
    setIodineBloodMarkerData(data) {
      console.log(data,'setting iodine...')
      this.IodineBloodMarkerTableData = data;
    },
    setCopperBloodMarkerData(data) {
      console.log(data,'setting Copper...')
      this.CopperBloodMarkerTableData = data;
    },
    search() {
      this.searched = true;
      console.log("client name searched ", this.clientName);
      this.inputHtmaTableData = this.inputHtmaConstData.filter(
        (a) => a.client_name === this.clientName
      );
      this.naqTableData = this.naqConstData.filter(
        (a) => a.client_name === this.clientName
      );
      this.naqHtmaTableData = this.naqHtmaConstData.filter(
        (a) => a.client_name === this.clientName
      );
      this.conditionTableData = this.conditionConstData.filter(
        (a) => a.client_name === this.clientName
      );
      // this.bloodMarkerTableData = this.bloodMarkerConstData.filter(
      //   (a) => a.client_name === this.clientName
      // );
      this.IronBloodMarkerTableData = this.IronBloodMarkerConstData.filter(
        (a) => a.client_name === this.clientName
      );
      this.IodineBloodMarkerTableData = this.IodineBloodMarkerConstData.filter(
        (a) => a.client_name === this.clientName
      );
      this.CopperBloodMarkerTableData = this.CopperBloodMarkerConstData.filter(
        (a) => a.client_name === this.clientName
      );
    },
    onReset() {
      this.searched = false;
      this.clientName = "";
      this.getClientNames();
      this.inputHtmaTableData = this.inputHtmaConstData;
      this.naqTableData = this.naqConstData;
      this.naqHtmaTableData = this.naqHtmaConstData;
      this.conditionTableData = this.conditionConstData;
      // this.bloodMarkerTableData = this.bloodMarkerConstData;
      this.IronBloodMarkerTableData = this.IronBloodMarkerConstData;
      this.IodineBloodMarkerTableData = this.IodineBloodMarkerConstData;
      this.CopperBloodMarkerTableData = this.CopperBloodMarkerConstData;
    },
    getClientNames() {
      this.clientName = "";
      // this.clientNameData = this.inputHtmaTableData.filter(
      const inputHtmaClientNames = this.inputHtmaConstData.filter(
        (a, i) =>
          this.inputHtmaConstData.findIndex(
            (s) => a.client_name === s.client_name
          ) === i
      );

      const naqClientNames = this.naqConstData.filter(
        (a, i) =>
          this.naqConstData.findIndex(
            (s) => a.client_name === s.client_name
          ) === i
      );

      const naqHtmaClientNames = this.naqHtmaConstData.filter(
        (a, i) =>
          this.naqHtmaConstData.findIndex(
            (s) => a.client_name === s.client_name
          ) === i
      );

      const conditionClientNames = this.conditionConstData.filter(
        (a, i) =>
          this.conditionConstData.findIndex(
            (s) => a.client_name === s.client_name
          ) === i
      );

      // const bloodMarkerClientNames = this.bloodMarkerConstData.filter(
      //   (a, i) =>
      //     this.bloodMarkerConstData.findIndex(
      //       (s) => a.client_name === s.client_name
      //     ) === i
      // );

      const IronBloodMarkerClientNames = this.IronBloodMarkerConstData.filter(
        (a, i) =>
          this.IronBloodMarkerConstData.findIndex(
            (s) => a.client_name === s.client_name
          ) === i
      );

      const IodineBloodMarkerClientNames = this.IodineBloodMarkerConstData.filter(
        (a, i) =>
          this.IodineBloodMarkerConstData.findIndex(
            (s) => a.client_name === s.client_name
          ) === i
      );
      const CopperBloodMarkerClientNames = this.CopperBloodMarkerConstData.filter(
        (a, i) =>
          this.CopperBloodMarkerConstData.findIndex(
            (s) => a.client_name === s.client_name
          ) === i
      );

      this.clientNameData = [
        ...inputHtmaClientNames,
        ...naqClientNames,
        ...naqHtmaClientNames,
        ...conditionClientNames,
        // ...bloodMarkerClientNames,
        ...IronBloodMarkerClientNames,
        ...IodineBloodMarkerClientNames,
        ...CopperBloodMarkerClientNames,
      ];

      this.clientNameData = this.clientNameData.filter(
        (a, i) =>
          this.clientNameData.findIndex(
            (s) => a.client_name === s.client_name
          ) === i
      );
    },
  },
  async mounted() {
    await this.getInputData();
    this.inputHtmaConstData = await this.allInputData;
    this.inputHtmaTableData = this.inputHtmaConstData;

    await this.getAllNaq();
    this.naqConstData = await this.allNaq;
    this.naqTableData = this.naqConstData;

    await this.getAllNaqHtma();
    this.naqHtmaConstData = await this.allNaqHtma;
    this.naqHtmaTableData = this.naqHtmaConstData;

    await this.getAllCondition();
    this.conditionConstData = await this.allConditions;
    this.conditionTableData = this.conditionConstData;

    // await this.getBloodMarkers();
    // this.bloodMarkerConstData = await this.bloodMarkers;
    // this.bloodMarkerTableData = this.bloodMarkerConstData;

    await this.getIronBloodMarkers();
    this.IronBloodMarkerConstData = await this.ironBloodMarkers;
    this.IronBloodMarkerTableData = this.IronBloodMarkerConstData;

    await this.getIodineBloodMarkers();
    this.IodineBloodMarkerConstData = await this.iodineBloodMarkers;
    this.IodineBloodMarkerTableData = this.IodineBloodMarkerConstData;

    await this.getCopperBloodMarkers();
    this.CopperBloodMarkerConstData = await this.copperBloodMarkers;
    this.CopperBloodMarkerTableData = this.CopperBloodMarkerConstData;

    this.getClientNames();

    console.log("all inputs are ", this.inputHtmaTableData);
    console.log("all naq are ", this.naqTableData);
    console.log("all naq htma are ", this.naqHtmaTableData);
    console.log("all condition are ", this.conditionTableData);
    // console.log("all blood marker are ", this.bloodMarkerTableData);
    console.log("all iron blood marker are ", this.IronBloodMarkerTableData);
    console.log("all iodine blood marker are ", this.IodineBloodMarkerTableData);
    console.log("all copper blood marker are ", this.CopperBloodMarkerTableData);

    console.log("all client names are ", this.clientNameData);
  },
};
</script>

<style>
.label {
  text-transform: uppercase;
  color: var(--black) !important;
  font-size: var(--extrasmall);
}

.page-item.active .page-link {
  color: white !important;
  background-color: #884c42 !important;
  border-color: #884c42 !important;
}

.show-entries label {
  font-weight: 500;
  margin: auto 10px;
}
</style>
