<template>
  <div class="card">
    <div v-if="editId">
      <br />
      <br />
      <br />
    </div>
    <div :class="BloodMarkerbox">
      <div v-if="editId">
            <h2 class="mx-2 pt-2 mt-5 mb-5">Edit Iron Blood Markers</h2>
      </div>
      <table id="comparison_tbl_naq">
        <tr>
          <!-- <th v-if="editId" colspan="2"><h3>Edit Blood Markers for Iron</h3> </th> -->
          <th colspan="2">Blood Markers for Iron</th>
        </tr>
        <tr>
          <td>Client Name *</td>
          <td class="right_col">
            <input type="text" v-model="compVal.client_name" />
          </td>
        </tr>
        <tr>
          <td>Serum Iron ug/dL</td>
          <td class="right_col">
            <input type="number" v-model="compVal.serum_iron" />
          </td>
        </tr>
        <tr>
          <td>TIBC ug/dL</td>
          <td class="right_col">
            <input type="number" v-model="compVal.tibc" />
          </td>
        </tr>
        <tr>
          <td>Iron Saturation %</td>
          <td class="right_col">
            <input type="number" v-model="compVal.iron_saturation" />
          </td>
        </tr>
        <tr>
          <td>Ferritin (Female) ug/dL</td>
          <td class="right_col">
            <input type="number" v-model="compVal.ferritin_female" />
          </td>
        </tr>
        <tr>
          <td>Ferritin (Male) ug/dL</td>
          <td class="right_col">
            <input type="number" v-model="compVal.ferritin_male" />
          </td>
        </tr>
        <tr>
          <td>HTMA Iron</td>
          <td class="right_col">
            <input type="number" v-model="compVal.htma_iron" />
          </td>
        </tr>
      </table>
    </div>
    <div v-if="editId" class="row">
      <div class="mt-5 mr-3 w-100 submit-box" style="display:flex;justify-content:flex-end!important">
        <button @click="onUpdate()" class="menu_item next-button">
          Update
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "BloodMarkerIron",
  props: ["data"],
  data() {
    return {
      editId: null,
      editData: [],
    };
  },
  computed: {
    ...mapGetters(["singleIronBloodMarker"]),
    BloodMarkerbox: function () {
      return this.editId ? "update-box" : "card__item";
    },
    compVal: {
      get() {
        if (this.editId) {
          return this.editData;
        } else {
          return this.data;
        }
      },
    },
  },
  methods: {
    ...mapActions(["getSingleIronBloodMarker", "updateIronBloodMarkers"]),
    async onUpdate(){
      if (!this.editData.client_name) {
        this.$toastr.Add({
          name: "Error", // this is give you ability to use removeByName method
          msg: "Please Enter Client Name!", // Toast Message
          type: "error", // Toast type,
        });
      } else {
      this.$toastr.Add({
        name: "Success", // this is give you ability to use removeByName method
        msg: "Blood Markers Iron has been updated Successfully!", // Toast Message
        type: "success", // Toast type,
      });
      //  Blood Markers
      console.log("updating..");
      console.log("edit BloodMarkers", this.editData);
      const dataUpdate = await this.updateIronBloodMarkers({
        id: this.editId,
        payload: this.editData,
      });
      console.log("data has been edited ", dataUpdate);

      this.$router.push(`/client-list`);
    }
    }
  },

  async mounted() {
    this.editId = this.$route.params.id;
    if (this.editId) {
      console.log(this.editId);
      console.log("id getting");
      await this.getSingleIronBloodMarker(this.editId);
      this.editData = await this.singleIronBloodMarker;
      console.log(this.editData);
    }
  },
};
</script>

<style>
.update-box{
  margin: 5px 10px;
  padding: 0px 15px;
}
</style>
