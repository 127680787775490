<template>
  <div>
    <div v-if="this.singleTestAnalysisData.naqhtma_diet.length">
      <div
        v-for="value in singleTestAnalysisData.naqhtma_diet"
        :key="value.index"
      >
        <h4 class="pt_15 mb-2">{{ value.heading }}</h4>
        <p
          style="margin: 0;  margin-bottom:5px;"
          class="pt_15"
          v-for="desc in value.desc"
          :key="desc.index"
        >
          {{ desc }}
        </p>
      </div>
      <br />
    </div>
    <!-- Upper Gi -->
    <div v-if="this.singleTestAnalysisData.naqhtma_response_uppergi.length">
      <!-- <h2>Upper Gi</h2> -->
      <div
        v-for="value in singleTestAnalysisData.naqhtma_response_uppergi"
        :key="value.index"
      >
        <h4 class="pt_15 mb-2">{{ value.heading }}</h4>
        <p
          style="margin: 0; "
          class="pt_15"
          v-for="desc in value.desc"
          :key="desc.index"
        >
          {{ desc }}
        </p>
      </div>
      <br />
    </div>
    <!-- Liver -->
    <div v-if="this.singleTestAnalysisData.naqhtma_liver.length">
      <!-- <h2>Liver</h2> -->
      <div
        v-for="value in singleTestAnalysisData.naqhtma_liver"
        :key="value.index"
      >
        <h4 class="pt_15 mb-2">{{ value.heading }}</h4>
        <p
          style="margin: 0; "
          class="pt_15"
          v-for="desc in value.desc"
          :key="desc.index"
        >
          {{ desc }}
        </p>
      </div>
      <br />
    </div>
    <!-- Small Intestine -->
    <div v-if="this.singleTestAnalysisData.naqhtma_small_intestine.length">
      <!-- <h2>Small Intestine</h2> -->
      <div
        v-for="value in singleTestAnalysisData.naqhtma_small_intestine"
        :key="value.index"
      >
        <h4 class="pt_15 mb-2">{{ value.heading }}</h4>
        <p
          style="margin: 0; "
          class="pt_15"
          v-for="desc in value.desc"
          :key="desc.index"
        >
          {{ desc }}
        </p>
      </div>
      <br />
    </div>
    <!-- Large Intestine -->
    <div v-if="this.singleTestAnalysisData.naqhtma_large_intestine.length">
      <!-- <h2>Large Intestine</h2> -->
      <div
        v-for="value in singleTestAnalysisData.naqhtma_large_intestine"
        :key="value.index"
      >
        <h4 class="pt_15 mb-2">{{ value.heading }}</h4>
        <p
          style="margin: 0; "
          class="pt_15"
          v-for="desc in value.desc"
          :key="desc.index"
        >
          {{ desc }}
        </p>
      </div>
      <br />
    </div>
    <!-- Mineral Need -->
    <div v-if="this.singleTestAnalysisData.naqhtma_mineral_need.length">
      <!-- <h2>Mineral Need</h2> -->
      <div
        v-for="value in singleTestAnalysisData.naqhtma_mineral_need"
        :key="value.index"
      >
        <h4 class="pt_15 mb-2">{{ value.heading }}</h4>
        <p
          style="margin: 0; "
          class="pt_15"
          v-for="desc in value.desc"
          :key="desc.index"
        >
          {{ desc }}
        </p>
      </div>
      <br />
    </div>
    <!-- Essential Fatty Acid -->
    <div v-if="this.singleTestAnalysisData.naqhtma_essential_fatty_acid.length">
      <!-- <h2>Essential Fatty Acid</h2> -->
      <div
        v-for="value in singleTestAnalysisData.naqhtma_essential_fatty_acid"
        :key="value.index"
      >
        <h4 class="pt_15 mb-2">{{ value.heading }}</h4>
        <p
          style="margin: 0; "
          class="pt_15"
          v-for="desc in value.desc"
          :key="desc.index"
        >
          {{ desc }}
        </p>
      </div>
      <br />
    </div>
    <!-- Sugar Handling -->
    <div v-if="this.singleTestAnalysisData.naqhtma_sugar_handling.length">
      <!-- <h2>Sugar Handling</h2> -->
      <div
        v-for="value in singleTestAnalysisData.naqhtma_sugar_handling"
        :key="value.index"
      >
        <h4 class="pt_15 mb-2">{{ value.heading }}</h4>
        <p
          style="margin: 0; "
          class="pt_15"
          v-for="desc in value.desc"
          :key="desc.index"
        >
          {{ desc }}
        </p>
      </div>
      <br />
    </div>
    <!-- Vitamin Need -->
    <div v-if="this.singleTestAnalysisData.naqhtma_vitamin_need.length">
      <!-- <h2>Vitamin Need</h2> -->
      <div
        v-for="value in singleTestAnalysisData.naqhtma_vitamin_need"
        :key="value.index"
      >
        <h4 class="pt_15 mb-2">{{ value.heading }}</h4>
        <p
          style="margin: 0; "
          class="pt_15"
          v-for="desc in value.desc"
          :key="desc.index"
        >
          {{ desc }}
        </p>
      </div>
      <br />
    </div>
    <!-- Ardenal -->
    <div v-if="this.singleTestAnalysisData.naqhtma_ardenal.length">
      <!-- <h2>Ardenal</h2> -->
      <div
        v-for="value in singleTestAnalysisData.naqhtma_ardenal"
        :key="value.index"
      >
        <h4 class="pt_15 mb-2">{{ value.heading }}</h4>
        <p
          style="margin: 0; "
          class="pt_15"
          v-for="desc in value.desc"
          :key="desc.index"
        >
          {{ desc }}
        </p>
      </div>
      <br />
    </div>
    <!-- Pituitary -->
    <div v-if="this.singleTestAnalysisData.naqhtma_pituitary.length">
      <!-- <h2>Pituitary</h2> -->
      <div
        v-for="value in singleTestAnalysisData.naqhtma_pituitary"
        :key="value.index"
      >
        <h4 class="pt_15 mb-2">{{ value.heading }}</h4>
        <p
          style="margin: 0; "
          class="pt_15"
          v-for="desc in value.desc"
          :key="desc.index"
        >
          {{ desc }}
        </p>
      </div>
      <br />
    </div>
    <!-- Thyroid -->
    <div v-if="this.singleTestAnalysisData.naqhtma_thyroid.length">
      <!-- <h2>Thyroid</h2> -->
      <div
        v-for="value in singleTestAnalysisData.naqhtma_thyroid"
        :key="value.index"
      >
        <h4 class="pt_15 mb-2">{{ value.heading }}</h4>
        <p
          style="margin: 0; "
          class="pt_15"
          v-for="desc in value.desc"
          :key="desc.index"
        >
          {{ desc }}
        </p>
      </div>
      <br />
    </div>
    <!-- Cardio -->
    <div v-if="this.singleTestAnalysisData.naqhtma_cardio.length">
      <!-- <h2>Cardio</h2> -->
      <div
        v-for="value in singleTestAnalysisData.naqhtma_cardio"
        :key="value.index"
      >
        <h4 class="pt_15 mb-2">{{ value.heading }}</h4>
        <p
          style="margin: 0; "
          class="pt_15"
          v-for="desc in value.desc"
          :key="desc.index"
        >
          {{ desc }}
        </p>
      </div>
      <br />
    </div>
    <!-- Kidney Bladder -->
    <div v-if="this.singleTestAnalysisData.naqhtma_kidney_bladder.length">
      <!-- <h2>Kidney Bladder</h2> -->
      <div
        v-for="value in singleTestAnalysisData.naqhtma_kidney_bladder"
        :key="value.index"
      >
        <h4 class="pt_15 mb-2">{{ value.heading }}</h4>
        <p
          style="margin: 0; "
          class="pt_15"
          v-for="desc in value.desc"
          :key="desc.index"
        >
          {{ desc }}
        </p>
      </div>
      <br />
    </div>
    <!-- Immune System -->
    <div v-if="this.singleTestAnalysisData.naqhtma_immune_system.length">
      <!-- <h2>Immune System</h2> -->
      <div
        v-for="value in singleTestAnalysisData.naqhtma_immune_system"
        :key="value.index"
      >
        <h4 class="pt_15 mb-2">{{ value.heading }}</h4>
        <p
          style="margin: 0; "
          class="pt_15"
          v-for="desc in value.desc"
          :key="desc.index"
        >
          {{ desc }}
        </p>
      </div>
      <br />
    </div>
    <!-- Women Only -->
    <div v-if="this.singleTestAnalysisData.naqhtma_women_only.length">
      <!-- <h2>Women Only</h2> -->
      <div
        v-for="value in singleTestAnalysisData.naqhtma_women_only"
        :key="value.index"
      >
        <h4 class="pt_15 mb-2">{{ value.heading }}</h4>
        <p
          style="margin: 0; "
          class="pt_15"
          v-for="desc in value.desc"
          :key="desc.index"
        >
          {{ desc }}
        </p>
      </div>
      <br />
    </div>
    <!-- Men Only -->
    <div v-if="this.singleTestAnalysisData.naqhtma_men_only.length">
      <!-- <h2>Men Only</h2> -->
      <div
        v-for="value in singleTestAnalysisData.naqhtma_men_only"
        :key="value.index"
      >
        <h4 class="pt_15 mb-2">{{ value.heading }}</h4>
        <p
          style="margin: 0;  margin-bottom:5px;"
          class="pt_15"
          v-for="desc in value.desc"
          :key="desc.index"
        >
          {{ desc }}
        </p>
      </div>
      <br />
    </div>
  </div>
</template>

<script>
export default {
  name: "NaqHtma",
  components: {},
  props: ["singleTestAnalysisData"],

  data() {
    return {};
  },

  mounted() {
    console.log(this.singleTestAnalysisData);
  },
};
</script>

<style scoped>
.heading {
  font-weight: bold;
  color: #884c42;
  padding: 5px;
  margin-left: 20px;
  padding-top: 20px;
  font-family: "Inter", sans-serif;
}
</style>