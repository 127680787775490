<template>
  <div>
    <div class="row">
      <div class="col-lg-4 pt_20">
        <div class="middle_content_box middle_content_box-2 border" style="height: 100% !important">
          <table>
            <tr>
              <td>
                <h3 class="title_md" id="SO_D2">
                  Metabolic Type:
                  {{ singleTestAnalysisData.metabolic_type.value }}
                </h3>
              </td>
            </tr>
          </table>
          <ul class="middle_content_list">
            <li id="SO_F2">
              <!-- <span class="title_md">
                A metabolic type typically indicates:
                
              </span>
              <hr /> -->
              <span
                id="SO_F7"
                v-for="(
                  value, index
                ) in singleTestAnalysisData.metabolic_type.text.filter(
                  (test) => test !== ''
                )"
                :key="index"
              >
                {{ value }} <br />
                <br v-if="index == 0" />
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-4 pt_20">
        <div class="middle_content_box middle_content_box-2 border" style="height: 100% !important">
          <table>
            <tr>
              <td><h3 class="title_md" id="SO_D4">Mixed Oxidizer</h3></td>
            </tr>
          </table>
          <div class="boxscroll do-nicescrol4">
            <div class="wrap">
              <ul class="middle_content_list">
                <li class="">
                  {{ singleTestAnalysisData.mixed_oxidizer.heading_bullet }}
                </li>
                <li id="SO_F4">
                  {{ singleTestAnalysisData.mixed_oxidizer.text }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 pt_20">
        <div class="middle_content_box middle_content_box-2 border" style="height: 100% !important">
          <table>
            <tr>
              <td>
                <h3 class="title_md" id="SO_D3">Inversion</h3>
              </td>
            </tr>
          </table>
          <ul class="middle_content_list">
            <li class="" style="text-align: left">
              {{ singleTestAnalysisData.inversions.heading_bullet }}
            </li>
            <li style="line-height: 2">
              {{ singleTestAnalysisData.inversions.text }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 pt_20">
        <div class="middle_content_box middle_content_box-2 border" style="height: 100% !important">
          <table>
            <tr>
              <td><h3 class="title_md" id="SO_D7">Diet</h3></td>
            </tr>
          </table>
          <div class="boxscroll do-nicescrol4">
            <div class="wrap">
              <ul class="middle_content_list">
                <h3 class="title_md" style="text-align: left">
                  {{ singleTestAnalysisData.diet.heading }}
                </h3>
                <li
                  id="SO_F7"
                  v-for="value in singleTestAnalysisData.diet.text.filter(
                    (test) => test !== ''
                  )"
                  :key="value.index"
                >
                  <a
                    id="RO_I10"
                    style="
                      text-decoration: underline;
                      color: #884c42 !important;
                    "
                    class="text-dark"
                    v-if="value.includes('Four Lows Diet')"
                    href="https://docs.google.com/document/d/1oDtpzDCgwds0ji3-Y35X_FzQFrsYh4wpZNB4jMNISEs/edit?usp=sharing"
                    >{{ value }}</a
                  >
                </li>
                <li
                  id="SO_F7"
                  v-for="value in singleTestAnalysisData.diet.text.filter(
                    (test) => test !== ''
                  )"
                  :key="value.index"
                >
                  <a
                    id="RO_I10"
                    style="
                      text-decoration: underline;
                      color: #884c42 !important;
                    "
                    class="text-dark"
                    v-if="value.includes('Slow Oxidation Diet')"
                    href="https://docs.google.com/document/d/1ac_Vxl3D-sTWEbspoFXqV0nV0RdS9tyQTvRgEDlNWZ4/edit?usp=sharing"
                    >{{ value }}</a
                  >
                </li>
                <li
                  id="SO_F7"
                  v-for="value in singleTestAnalysisData.diet.text.filter(
                    (test) => test !== ''
                  )"
                  :key="value.index"
                >
                  <a
                    id="RO_I10"
                    style="
                      text-decoration: underline;
                      color: #884c42 !important;
                    "
                    class="text-dark"
                    v-if="value.includes('Fast Oxidation Diet')"
                    href="https://docs.google.com/document/d/10DFIOENMlMAaaBB6QNghLIZs0JdWTdsDnwWBdAQawo0/edit?usp=sharing"
                    >{{ value }}</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 pt_20">
        <div class="middle_content_box middle_content_box-2 border" style="height: 100% !important">
          <table>
            <tr>
              <td><h3 class="title_md" id="SO_D5">Glucose Intolerance</h3></td>
              <td>
                <h3 class="title_md" style="text-align: right">
                  <span id="SO_E5">{{
                    singleTestAnalysisData.glucose_intolerance.value
                  }}</span>
                </h3>
              </td>
            </tr>
          </table>
          <ul class="middle_content_list">
            <li
              id="SO_F5"
              v-for="value in singleTestAnalysisData.glucose_intolerance.text.filter(
                (test) => test !== ''
              )"
              :key="value.index"
            >
              {{ value }}
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-4 pt_20">
        <div class="middle_content_box middle_content_box-2 border" style="height: 100% !important">
          <table>
            <tr>
              <td><h3 class="title_md" id="SO_D6">Low Stomach Acid</h3></td>
              <td>
                <h3 class="title_md" style="text-align: right">
                  <span id="SO_E6">{{
                    singleTestAnalysisData.low_stomach_acid.value
                  }}</span>
                </h3>
              </td>
            </tr>
          </table>
          <ul class="middle_content_list">
            <li
              id="SO_F6"
              v-for="value in singleTestAnalysisData.low_stomach_acid.text.filter(
                (test) => test !== ''
              )"
              :key="value.index"
            >
              {{ value }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- <br class="pg_break" />
    <br class="pg_break" />
    <br class="pg_break" />
    <br class="pg_break" />
    <br class="pg_break" />
    <br class="pg_break" /> -->
    <div class="row">
      <div class="col-lg-4 pt_20">
        <div class="middle_content_box middle_content_box-2 border" style="height: 100% !important">
          <table>
            <tr>
              <td><h3 class="title_md" id="SO_D8">Burnout</h3></td>
              <td>
                <h3 class="title_md" style="text-align: right">
                  <span id="SO_E8">{{
                    singleTestAnalysisData.burnout.value
                  }}</span>
                </h3>
              </td>
            </tr>
          </table>
          <ul class="middle_content_list">
            <li
              id="SO_F8"
              v-for="value in singleTestAnalysisData.burnout.text.filter(
                (test) => test !== ''
              )"
              :key="value.index"
            >
              {{ value }}
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-4 pt_20">
        <div class="middle_content_box middle_content_box-2 border" style="height: 100% !important">
          <table>
            <tr>
              <td><h3 class="title_md" id="SO_D9">Trauma</h3></td>
              <td>
                <h3 class="title_md" style="text-align: right">
                  <span id="SO_E9">{{
                    singleTestAnalysisData.trauma.value
                  }}</span>
                </h3>
              </td>
            </tr>
          </table>
          <div class="boxscroll do-nicescrol4">
            <div class="wrap">
              <ul class="middle_content_list">
                <li
                  id="SO_F9"
                  v-for="value in singleTestAnalysisData.trauma.text.filter(
                    (test) => test !== ''
                  )"
                  :key="value.index"
                >
                  {{ value }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 pt_20">
        <div class="middle_content_box middle_content_box-2 border" style="height: 100% !important">
          <table>
            <tr>
              <td>
                <h3 class="title_md" id="SO_D8">Impaired Immune</h3>
              </td>
              <td>
                <h3 class="title_md" style="text-align: right">
                  <span id="SO_E9">{{
                    singleTestAnalysisData.impaired_immune_response.value
                  }}</span>
                </h3>
              </td>
            </tr>
          </table>
          <ul class="middle_content_list">
            <li
              id="SO_F8"
              v-for="value in singleTestAnalysisData.impaired_immune_response.text.filter(
                (test) => test !== ''
              )"
              :key="value.index"
            >
              {{ value }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 pt_20">
        <div class="middle_content_box middle_content_box-2 border" style="height: 100% !important">
          <table>
            <tr>
              <td>
                <h3 class="title_md" id="SO_D9">Zinc Deficiency</h3>
              </td>
              <td>
                <h3 class="title_md" style="text-align: right">
                  <span id="SO_E9">{{
                    singleTestAnalysisData.zinc_deficiency.value
                  }}</span>
                </h3>
              </td>
            </tr>
          </table>
          <div class="boxscroll do-nicescrol4">
            <div class="wrap">
              <ul class="middle_content_list">
                <li
                  id="SO_F9"
                  v-for="value in singleTestAnalysisData.zinc_deficiency.text.filter(
                    (test) => test !== ''
                  )"
                  :key="value.index"
                >
                  {{ value }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 pt_20">
        <div class="middle_content_box middle_content_box-2 border" style="height: 100% !important">
          <table>
            <tr>
              <td>
                <h3 class="title_md" id="SO_D9">Copper Imbalance</h3>
              </td>
              <td>
                <h3 class="title_md" style="text-align: right">
                  <span id="SO_E9">{{
                    singleTestAnalysisData.copper_imbalance.value
                  }}</span>
                </h3>
              </td>
            </tr>
          </table>
          <div class="boxscroll do-nicescrol4">
            <div class="wrap">
              <ul class="middle_content_list">
                <li
                  id="SO_F9"
                  v-for="value in singleTestAnalysisData.copper_imbalance.text.filter(
                    (test) => test !== ''
                  )"
                  :key="value.index"
                >
                  {{ value }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 pt_20">
        <div class="middle_content_box middle_content_box-2 border" style="height: 100% !important">
          <table>
            <tr>
              <td>
                <h3 class="title_md" id="SO_D9">Iron Interactions</h3>
              </td>
              <td>
                <h3 class="title_md" style="text-align: right">
                  <span id="SO_E9">{{
                    singleTestAnalysisData.iron_interaction.value
                  }}</span>
                </h3>
              </td>
            </tr>
          </table>
          <div class="boxscroll do-nicescrol4">
            <div class="wrap">
              <ul class="middle_content_list">
                <li
                  id="SO_F9"
                  v-for="value in singleTestAnalysisData.iron_interaction.text.filter(
                    (test) => test !== ''
                  )"
                  :key="value.index"
                >
                  {{ value }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <br class="pg_break" />
    <br class="pg_break" />
    <br class="pg_break" /> -->
    <div class="row">
      <div class="col-lg-4 pt_20">
        <div class="middle_content_box middle_content_box-2 border" style="height: 100% !important">
          <table>
            <tr>
              <td>
                <h3 class="title_md" id="SO_D9">Iodine Deficiency</h3>
              </td>
              <td>
                <h3 class="title_md" style="text-align: right">
                  <span id="SO_E9">{{
                    singleTestAnalysisData.iodine_deficiency.value
                  }}</span>
                </h3>
              </td>
            </tr>
          </table>
          <div class="boxscroll do-nicescrol4">
            <div class="wrap">
              <ul class="middle_content_list">
                <li
                  id="SO_F9"
                  v-for="value in singleTestAnalysisData.iodine_deficiency.text.filter(
                    (test) => test !== ''
                  )"
                  :key="value.index"
                >
                  {{ value }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 pt_20">
        <div class="middle_content_box middle_content_box-2 border" style="height: 100% !important">
          <table>
            <tr>
              <td>
                <h3 class="title_md" id="SO_D9">Poor Eliminators</h3>
              </td>
              <td>
                <h3 class="title_md" style="text-align: right">
                  <span id="SO_E9">{{
                    singleTestAnalysisData.poor_eliminators.value
                  }}</span>
                </h3>
              </td>
            </tr>
          </table>
          <div class="boxscroll do-nicescrol4">
            <div class="wrap">
              <ul class="middle_content_list">
                <li
                  id="SO_F9"
                  v-for="value in singleTestAnalysisData.poor_eliminators.text.filter(
                    (test) => test !== ''
                  )"
                  :key="value.index"
                >
                  {{ value }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 pt_20">
        <div class="middle_content_box middle_content_box-2 border" style="height: 100% !important">
          <table>
            <tr>
              <td>
                <h3 class="title_md" id="SO_D9">Very Poor Eliminators</h3>
              </td>
              <td>
                <h3 class="title_md" style="text-align: right">
                  <span id="SO_E9">{{
                    singleTestAnalysisData.very_poor_eliminators.value
                  }}</span>
                </h3>
              </td>
            </tr>
          </table>
          <div class="boxscroll do-nicescrol4">
            <div class="wrap">
              <ul class="middle_content_list">
                <li
                  id="SO_F9"
                  v-for="value in singleTestAnalysisData.very_poor_eliminators.text.filter(
                    (test) => test !== ''
                  )"
                  :key="value.index"
                >
                  {{ value }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Indicators",
  components: {},
  props: ["singleTestAnalysisData"],

  data() {
    return {};
  },

  mounted() {},
};
</script>
<style scoped>
.pg_break {
  display: none;
}
</style>
