<template>
  <div>
    <div v-if="loading && !singleTestId" id="preloader">
      <div class="loader3">
        <span></span>
        <span></span>
      </div>
    </div>
    <div class="box" v-else>
      <button
        class="menu_item"
        v-bind:class="label === 'Previous Test' ? 'back-button' : 'no-button'"
        @click="onBack"
      >
        Back
      </button>
      <button v-if="upload" class="menu_item next-button" @click="onSubmit">
        Submit
      </button>
      <button v-else class="menu_item next-button" @click="onNext">Next</button>
    </div>
    <div class="accoridian_bg graph_box">
      <h2 class="mx-2 pt-2 mb-2" v-if="!singleTestId">
        {{ label }}
        <label v-if="upload" class="ml-2 col-lg-2 file-upload">
          <input
            type="file"
            v-on:change="onFileUpload"
            accept="application/pdf"
            ref="file"
          />
          Upload PDF
        </label>
      </h2>
      <div class="">
        <div v-if="!singleTestId" class="row mb-5 px-3">
          <div class="col-lg-5 mx-auto w-100">
            <model-list-select
              :list="clientNameData"
              v-model="clientName"
              option-value="id"
              option-text="client_name"
              placeholder="Search Client Name"
            >
            </model-list-select>
            <!-- <vue-typeahead-bootstrap
              :data="inputData"
              :serializer="(item) => item.client_name"
              v-model="clientName"
              placeholder="Search Client Name"
            /> -->
          </div>
          <div class="col-lg-5">
            <model-list-select
              :list="htmaDatesData"
              v-model="htmaDate"
              option-value="id"
              option-text="htma_test_date"
              placeholder="Search HTMA Date"
            >
            </model-list-select>
            <!-- <vue-typeahead-bootstrap
              :data="htmaDatesData"
              :serializer="(item) => item.htma_test_date"
              v-model="htmaDate"
              placeholder="Search HTMA Date"
            /> -->
          </div>
          <div class="col-lg-2 submit-box">
            <button
              v-if="inputId"
              @click="onReset()"
              class="menu_item submit-button"
            >
              Reset
            </button>
            <button v-else @click="search()" class="menu_item submit-button">
              Search
            </button>
          </div>
        </div>
        <div style="margin-top: 100px" v-if="singleTestId">
          <h2 class="ml-3">Edit HTMA Results Input</h2>
        </div>

        <div class="box" style="margin-bottom: 50px" v-if="singleTestId">
          <button
            class="menu_item"
            v-bind:class="
              label === 'Previous Test' ? 'back-button' : 'no-button'
            "
            @click="onBack"
          >
            Back
          </button>
          <button class="menu_item next-button" @click="onUpdate">
            Update
          </button>
        </div>

        <div class="row mx-auto w-100">
          <div class="col-lg-12 mb-12 mb-lg-0">
            <InputForm
              :input="input ? input : editInputData"
              :inputId="inputId"
              :clientNameError="clientNameError"
              :metTypeError="metTypeError"
              :htmaDateError="htmaDateError"
              :dataExported="dataExported"
            />
            <br /><br />
            <HTMA
              :htmaData="htma ? htma : editHTMAData"
              :dataExported="dataExported"
            />
          </div>
        </div>
      </div>
      <br />
      <div class="box" style="margin-bottom: 50px" v-if="singleTestId">
        <button
          class="menu_item"
          v-bind:class="label === 'Previous Test' ? 'back-button' : 'no-button'"
          @click="onBack"
        >
          Back
        </button>
        <button class="menu_item next-button" @click="onUpdate">Update</button>
      </div>
      <div class="box" v-else>
        <button
          class="menu_item"
          v-bind:class="label === 'Previous Test' ? 'back-button' : 'no-button'"
          @click="onBack"
        >
          Back
        </button>
        <button v-if="upload" class="menu_item next-button" @click="onSubmit">Submit</button>
        <button v-else class="menu_item next-button" @click="onNext">Next</button>
      </div>
    </div>
  </div>
</template>

<script>
import InputForm from "../InputForm/InputForm.vue";
import HTMA from "../HTMA/HTMA.vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import { ModelListSelect } from "vue-search-select";

export default {
  components: {
    InputForm,
    HTMA,
    ModelListSelect,
  },
  name: "HTMAInput",
  props: ["label", "input", "htma", "upload"],
  data() {
    return {
      clientName: "",
      htmaDate: "",
      inputId: null,
      inputData: [],
      clientNameData: [],
      htmaDatesData: [],
      // searchData: [],
      // singleInputHtmaData: [],

      allInputHtmaData: [],

      clientNameError: false,
      metTypeError: false,
      htmaDateError: false,

      uploadedFile: null,

      singleTestId: null,
      singleTestData: [],

      editInputData: [],
      editHTMAData: [],
      dataExported: false,
      loading: false,

      dataOfFile: {},
    };
  },
  computed: {
    ...mapGetters([
      // "allInput",
      "allInputData",
      "singleInputHtma",
      "allInputHtma",
      "fileData",
    ]),
  },
  watch: {
    clientName(value) {
      if (value) {
        console.log("value", value);
        this.htmaDatesData = this.inputData.filter(
          (data) =>
            data.client_name ===
            this.inputData.find((x) => x.id === value).client_name
        );
        console.log("data.htma_test_date");
        this.htmaDatesData.map((data) => {
          data.htma_test_date = moment(data.htma_test_date).format(
            "MM/DD/YYYY"
          );
        });
        console.log("htmaDatesData", this.htmaDatesData);
      }
    },
  },
  async mounted() {
    this.singleTestId = this.$route.params.id;
    if (this.singleTestId) {
      console.log(this.singleTestId);
      console.log("id getting");
      //  console.log(this.input)
      await this.getSingleInputHtma(this.singleTestId);
      this.singleTestData = await this.singleInputHtma;
      this.editInputData = this.singleTestData[0];
      this.editHTMAData = this.singleTestData[1];
      //  console.log(await this.singleInputHtma)
      //  this.input = await this.singleInputHtma[0]
      //  this.$emit("setInput", await this.singleInputHtma[0]);
    }
    // await this.getAllInput();
    // this.inputData = await this.allInput;
    else {
      await this.getInputData();
      this.inputData = await this.allInputData;

      this.clientNameData = this.inputData.filter(
        (a, i) =>
          this.inputData.findIndex((s) => a.client_name === s.client_name) === i
      );
      console.log("all inputs are ", this.inputData);
      console.log("all client names are ", this.clientNameData);

      await this.getAllInputHtma();
      this.allInputHtmaData = await this.allInputHtma;
      console.log("input-htma", this.allInputHtmaData);
    }
  },
  methods: {
    ...mapActions([
      // "getAllInput",
      "getInputData",
      "getSingleInputHtma",
      "getAllInputHtma",
      "uploadFile",
      "updateInputHtma",
    ]),
    showLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 12000);
    },

    async onFileUpload(e) {
      this.$toastr.Add({
        name: "Success", // this is give you ability to use removeByName method
        msg: "File has been uploaded! Please wait for extracting data.", // Toast Message
        type: "success", // Toast type,
      });
      this.loading = true;
      // this.showLoading();

      this.uploadedFile = e.target.files[0];
      // this.uploadedFile = this.$refs.file.files[0]
      console.log("file is ", this.uploadedFile);
      const formData = new FormData();
      formData.append("file", this.uploadedFile, this.uploadedFile.name);
      this.dataOfFile = await this.uploadFile(formData);

      if (this.dataOfFile) {
        console.log("data came");
        this.loading = false;
      }
      // const dataOfFile = await this.uploadFile({file:this.uploadedFile})
      console.log("data of file upload exported is ", this.dataOfFile);
      this.$emit("setInput", this.dataOfFile.input);
      this.$emit("setHTMA", this.dataOfFile.htma);
      this.dataExported = true;
    },

    async search() {
      console.log("submit");
      console.log("htmadata id ", this.htmaDate);
      this.inputId = this.allInputHtmaData.find((data) => {
        return data.input == this.htmaDate;
      });
      console.log("input id searched is ", this.inputId.id);
      // this.inputId = this.htmaDate;
      this.$emit("setInputId", this.inputId.id);
    },
    onReset() {
      this.inputId = null;
      this.clientName = null;
      this.htmaDate = null;
      console.log("inputid is", this.inputId);
      this.$emit("reset");
    },
    onNext() {
      if (!this.input.client_name) {
        this.clientNameError = true;
        window.scrollTo(0, 0);
        // console.log('not entered client name')
      } else if (!this.input.metabolic_type) {
        this.metTypeError = true;
        window.scrollTo(0, 0);
        // console.log('not entered metabolic_type')
      } else if (!this.input.htma_test_date) {
        this.htmaDateError = true;
        window.scrollTo(0, 0);
        // console.log('not entered htma_test_date')
      } else if (!this.inputId) {
        let exist = this.inputData.filter((a) => {
          let d1 = moment(this.input.htma_test_date).format("MM/DD/YYYY");
          let d2 = moment(a.htma_test_date).format("MM/DD/YYYY");
          return this.input.client_name == a.client_name && d1 == d2;
        });
        // console.log("data after next", this.input.client_name, exist);
        if (exist.length) {
          this.$toastr.Add({
            name: "Error", // this is give you ability to use removeByName method
            msg: "Please Enter different HTMA Test Date!</br>HTMA Results Input Data is already exists with this Client Name and HTMA Test Date.", // Toast Message
            type: "error", // Toast type,
          });
          // console.log("exists");
        } else {
          this.$emit("nextStep");
          window.scrollTo(0, 0);
        }

        // console.log(
        //   this.input.client_name,
        //   moment(this.input.htma_test_date).format("MM/DD/YYYY")
        // );
        // console.log('not exists')
      } else {
        this.$emit("nextStep");
        window.scrollTo(0, 0);
      }
    },
    onBack() {
      this.$emit("backStep");
      window.scrollTo(0, 0);
    },
    onSubmit() {
      console.log("submitting");
      if (!this.input.client_name) {
        this.clientNameError = true;
        window.scrollTo(0, 0);
        // console.log('not entered client name')
      } else {
        // if (exist.length) {
        //   this.$toastr.Add({
        //     name: "Error", // this is give you ability to use removeByName method
        //     msg: "Please Enter different HTMA Test Date!</br>HTMA Results Input Data is already exists with this Client Name and HTMA Test Date.", // Toast Message
        //     type: "error", // Toast type,
        //   });
        //   // console.log("exists");
        // } else {
          this.$emit("nextStep");
          window.scrollTo(0, 0);
        // }
      }
    },

    // Update

    async onUpdate() {
      this.$toastr.Add({
        name: "Success", // this is give you ability to use removeByName method
        msg: "Input HTMA has been updated Successfully!", // Toast Message
        type: "success", // Toast type,
      });
      console.log("updating");
      console.log("input", this.editInputData);
      console.log("htma", this.editHTMAData);
      const dataUpdate = await this.updateInputHtma({
        id: this.singleTestId,
        payload: {
          input: this.editInputData,
          htma: this.editHTMAData,
        },
      });
      console.log("data has been edited ", dataUpdate);
      this.$router.push(`/client-list`);
    },
  },
};
</script>

<style>
.box {
  display: flex;
  justify-content: space-between !important;
  padding-right: 20px;
}

.next-button {
  width: 150px;
}
.submit-box {
  display: flex;
  justify-content: flex-end !important;
  padding-right: 20px;
}

.submit-button {
  height: 40px;
  width: 250px;
  padding: 0 !important;
}

input[type="file"] {
  display: none;
}

.file-upload {
  cursor: pointer;
  height: 35px;
  width: 200px !important;
  background-color: #884c42;
  padding: 5px 5px;
  color: #ffffff !important;
  border-radius: 10px;
  transition: 0.4s;
  font-size: 15px;
  text-align: center;
  /* border: 1px solid black; */
}
.file-upload:hover {
  background-color: var(--black);
}

.no-button {
  color: transparent !important;
  background-color: transparent !important;
  border: transparent !important;
  cursor: auto !important;
}
</style>