import {
    Document,
    HeadingLevel,
    AlignmentType,
    Packer,
    Paragraph,
    TextRun,
} from "docx";
import { saveAs } from "file-saver";

function paragraphBuild(array) {
    let temp = []
    array.map((heavy_metal) => {
        temp.push(
            new TextRun({
                text: heavy_metal.metal_name,
                bold: true,
                color: "990000",
                break: 2,
            })
        )
        heavy_metal.metal_desc.map((desc) => {
            temp.push(
                new TextRun({
                    text: desc,
                    color: "990000",
                    break: 1,
                })
            )
        })
    })
    console.log('temp', temp)
    return temp
}

const generateDOC = async (data) => {
    var date = new Date();
    let formatedDate = (date.getMonth() > 8
        ? date.getMonth() + 1
        : "0" + (date.getMonth() + 1)) +
        "/" +
        (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
        "/" +
        date.getFullYear()
        ;
    const doc = new Document({
        creator: "Practiotioner Name",
        title: "Client Report",
        description: "A brief example of using docx",
        styles: {
            default: {
                heading1: {
                    run: {
                        size: 30,
                        bold: true,
                        color: "000000",
                    },
                    paragraph: {
                        spacing: {
                            after: 220,
                        },
                    },
                },
                heading2: {
                    run: {
                        size: 26,
                        bold: true,
                    },
                    paragraph: {
                        spacing: {
                            before: 240,
                            after: 120,
                        },
                    },
                },
            },
            paragraphStyles: [
                {
                    name: 'Normal',
                    run: {
                        font: 'arial',
                    },
                    paragraph: {
                        alignment: AlignmentType.JUSTIFIED,
                        spacing: {
                            after: 150,
                            line: 280,
                        },
                    },
                },
                {
                    id: "leftAligned",
                    name: "LeftAnligned",
                    basedOn: "Normal",
                    next: "Normal",
                    run: {
                        color: "000000",
                    },
                    paragraph: {
                        alignment: AlignmentType.LEFT,
                        spacing: {
                            line: 276,
                        },
                    },
                },
            ],
        },
        sections: [
            {
                children: [
                    new Paragraph({
                        text: "Hair\n Tissue Mineral Analysis Summary for " + data.client_name,
                        heading: HeadingLevel.HEADING_1,
                        alignment: AlignmentType.CENTER,
                    }),
                    new Paragraph({
                        text: "Prepared by " + data.practitioner_name + " on " + formatedDate,
                        alignment: AlignmentType.CENTER,
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                center: true,
                                text: "_________________________________________________________________________________________________________________________",
                                bold: true,
                                font: {
                                    name: "adam",
                                },
                            }),
                        ],
                    }),
                    new Paragraph({
                        text: "Hair is used for mineral testing because of its very nature. Hair is formed from clusters of specialized cells that make up the hair follicle. During the growth phase, the hair is exposed to the internal environment such as blood, lymph, and extracellular fluids. As the hair continues to grow and reach the surface of the skin, the outer layers harden, locking in the metabolic activity that has occurred during this time.",
                        break: 2,
                    }),
                    new Paragraph({
                        text: "The precise analytical method of determining the levels of minerals in the hair is a highly sophisticated process using spectroscopy. This method was developed ~75 years ago and continues to be the standard way to test for minerals in geology, agriculture, plant, animal, and human tissue studies. When performed to the exact standards and interpreted correctly, it may be used as a screening and aid for determining mineral deficiencies, excesses, and/or imbalances. HTMA provides you and your health care professional with an economical and sensitive indicator of the long-term effects of diet, stress, toxic metal exposure and their effects on your mineral balance that is difficult to obtain through other clinical tests.",
                        break: 2,
                    }),
                    new Paragraph({
                        text: "It is important to determine your mineral status because minerals are absolutely critical for life and abundant health. They are involved in and are necessary for cellular metabolism, structural support, nerve conduction, muscular activity, immune functions, antioxidant and endocrine activity, enzyme functions, water and acid/alkaline balance and even DNA function.",
                        break: 2,
                    }),
                    new Paragraph({
                        text: "Many factors can affect mineral nutrition, such as; food preparation, dietary habits, genetic and metabolic disorders, disease, medications, stress, environmental factors, as well as exposure to heavy metals. Rarely does a single nutrient deficiency exist in a person today. Multiple nutritional imbalances however are quite common, contributing to an increased incidence of adverse health conditions. In fact, it is estimated that mild and sub-clinical nutritional imbalances are up to ten times more common than nutritional deficiency alone. ~ Trace Elements Inc.",
                        break: 2,
                    }),
                    new Paragraph({
                        text: "The laboratory test results and the comprehensive report that follows should not be construed as diagnostic medical advice.",
                        break: 2,
                        bold: true,
                    }),
                    // Metabolic Type
                    new Paragraph({
                        text: "Metabolic Type",
                        heading: HeadingLevel.HEADING_2,
                    }),
                    new Paragraph({
                        text: 'The interpretation of your hair analysis depends upon developing a "metabolic blueprint" of how the body is responding to stress. The ability to determine the stage of stress and the oxidation rate from a hair analysis makes it possible to assess the likelihood of many conditions and guide correction based upon metabolic imbalances. A thorough interpretation of the test results also requires identification of mineral levels, ratios, and metabolic patterns.',
                    }),
                    new Paragraph({
                        text: "The adrenal and thyroid glands are the main energy-producing organs in the body and they determine the rate of metabolism or oxidation rate. They work together to release simple sugars from the liver and then process them into energy. These glands need to be functioning at optimal levels to have the maximum amount of energy possible. The adrenal glands are also responsible for providing extra energy when needed. In an emergency situation, it is the adrenal glands that release the hormone adrenaline, which generates a sudden increase in energy. They are also required for maintaining normal blood pressure and blood sugar, combating inflammation, carbohydrate metabolism, and to activate the body's response to stress.",
                    }),
                    new Paragraph({
                        text: "The goal with the oxidation rate is always balance. Someone who is excessively Fast will have just as many health issues as someone who is excessively Slow. When you’re too Fast, you’re basically burning through your minerals too quickly. When you’re Slow, everything can get clogged up because your system is moving too slowly. We are looking to be somewhere in the middle. We want to achieve balance.",
                    }),
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: "Your metabolic type is " + data.metabolic_type + ":",
                                bold: true,
                                color: "990000",
                            }),
                            new Paragraph({
                                style: "leftAligned",
                                children: data.metabolic_1.map((v) => (
                                    new TextRun({
                                        text: v,
                                        color: "990000",
                                        break: 2,
                                    })
                                ))
                            }),
                        ]
                    }),
                    // Significant Ratios
                    new Paragraph({
                        text: "Significant Ratios",
                        heading: HeadingLevel.HEADING_2,
                        pageBreakBefore: true,
                    }),
                    new Paragraph({
                        text: "Mineral Ratios provide the most useful information in determining nutritional deficiencies and excesses. Analyzing the ratios provides significantly more information about the body than looking at each mineral level alone. The ratios represent homeostatic balances and can be indicative of disease trends (not diagnostic, but associated based on research). Ratios can also be predictive of future metabolic dysfunctions or hidden metabolic dysfunctions. They are also used to chart progress on re-test reports.",
                        break: 2,
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                center: true,
                                text: "_________________________________________________________________________________________________________________________",
                                bold: true,
                                font: {
                                    name: "adam",
                                },
                            }),
                        ],
                    }),
                    // Calcium to Phosphorus (Ca/P)
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: "Calcium to Phosphorus (Ca/P) ",
                                bold: true,
                            }),
                            new TextRun({
                                text: "“Autonomic Balance Ratio”",
                                break: 1,

                            }),
                            new TextRun({
                                text: "Range: 1.6 - 3.6",
                                break: 1,

                            }),
                            new TextRun({
                                text: "Optimal: 2.6",
                                break: 1,

                            }),
                        ],
                    }),
                    new Paragraph({
                        text: "The calcium to phosphorus ratio indicates the autonomic state (sympathetic or parasympathetic) and the metabolic state of the body. Calcium is controlled by the parasympathetic branch and phosphorus is controlled by the sympathetic branch.",
                        break: 2,
                    }),
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: "Sympathetic: “Fight or Flight”",
                            }),
                        ],
                    }),
                    new Paragraph({
                        text: 'This side of the nervous system activates the brain and muscles and is the "speed-up" system. It causes expenditure of energy and is catabolic. The sympathetic nervous system becomes activated when you’re having a stress response, which can be anything from being in a heated meeting to seeing a bear!',
                        break: 2,
                    }),
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: "Parasympathetic: “Rest and Digest”",
                            }),
                        ],
                    }),
                    new Paragraph({
                        text: 'This side of the nervous system is more relaxed and calm. It is conserving, nourishing, restful, and nurturing. It is anabolic and regenerates the body. Ideally, we want to be spending most of our time in this state, unless the body has defaulted to being in an unhealthy parasympathetic state after being exposed to prolonged chronic stress and has trouble “speeding up” when needed.',
                        break: 2,
                    }),
                    new Paragraph({
                        text: 'The body balances these two systems and the minerals associated with them. Animals are quite good at this. Healthy cats or dogs run fast and fight hard but are equally comfortable spending the afternoon sleeping. Humans, however, have more difficulty balancing the autonomic system. Many people compulsively fight or run all the time, others are in a give-up mode where they are unable to keep fighting. They are often depressed and feel like victims.',
                        break: 2,
                    }),
                    new Paragraph({
                        text: 'The autonomic state is important as it is closely related to the activity of the adrenal and thyroid glands. We fluctuate between being in a sympathetic or parasympathetic state, but genetically we tend to be more dominant on one side or the other. Causes of autonomic imbalance include chemical imbalances one is born with, poor diet, use of stimulants, negative thinking, structural imbalances, physical or emotional traumas, and exposure to toxic metals and chemicals that affect the hypothalamus and pituitary gland.',
                        break: 2,
                    }),
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: data.client_name + " - " + data.ca_p,
                                bold: true,
                                color: "990000",
                            }),
                            new Paragraph({
                                style: "leftAligned",
                                children: data.cap_result.map((v) => (
                                    new TextRun({
                                        text: v,
                                        color: "990000",
                                        break: 2,
                                    })
                                ))
                            }),
                        ]
                    }),
                    // Sodium to Potassium (Na/K)
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: "Sodium to Potassium (Na/K)",
                                bold: true,
                                break: 2,
                            }),
                            new TextRun({
                                text: "“Stress Ratio” ",
                                break: 1,
                            }),
                            new TextRun({
                                text: "Range: 1.4 - 3.4 ",
                                break: 1,
                            }),
                            new TextRun({
                                text: "Optimal: 2.6",
                                break: 1,
                            }),
                        ],
                    }),
                    new Paragraph({
                        text: 'The sodium to potassium ratio is one of the most important ratios to evaluate because there is a sodium/potassium pump that controls movement in and out of the cell on every single cell membrane in the body. Nothing exits or gets into the cell without the use of the sodium/ potassium pump.',
                        break: 2,
                    }),
                    new Paragraph({
                        text: 'This ratio tells us about the electrical potential of the cells, kidney, liver, and adrenal function. Sodium is linked to aldosterone and potassium is linked to cortisone.',
                        break: 2,
                    }),
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: data.client_name + " - " + data.na_k,
                                bold: true,
                                color: "990000",
                            }),
                            new Paragraph({
                                style: "leftAligned",
                                children: data.nak_result.map((v) => (
                                    new TextRun({
                                        text: v,
                                        color: "990000",
                                        break: 2,
                                    })
                                ))
                            }),
                        ]
                    }),
                    // Calcium to Potassium (Ca/K)
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: "Calcium to Potassium (Ca/K)",
                                bold: true,
                                break: 2,
                            }),
                            new TextRun({
                                text: "“Thyroid Ratio”",
                                break: 1,
                            }),
                            new TextRun({
                                text: "Range: 2.2 - 6.2",
                                break: 1,
                            }),
                            new TextRun({
                                text: "Optimal: 4.2",
                                break: 1,
                            }),
                        ],
                    }),
                    new Paragraph({
                        text: 'The thyroid gland is one of the major glands that regulates metabolic rate in the body. Thyroid hormone controls calcium in the body via the production of calcitonin. The higher the calcium, the lower the thyroid gland activity. Potassium is associated with sensitivity of the tissues to thyroid hormone. When potassium is low, thyroid hormone is poorly utilized.',
                        break: 2,
                    }),
                    new Paragraph({
                        text: 'When the thyroid ratios are abnormal, the efficiency of energy production in the body decreases. It is like an engine that is turning too slow or too fast - power output declines.',
                        break: 2,
                    }),
                    new Paragraph({
                        text: 'HTMA results do not always correlate with blood thyroid tests because HTMA is a tissue test. Often blood tests will be normal, but hair analysis will indicate impaired thyroid function. For nutritional correction, it is prudent to follow the hair analysis indication.',
                        break: 2,
                    }),
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: data.client_name + " - " + data.cak,
                                bold: true,
                                color: "990000",
                            }),
                            new Paragraph({
                                style: "leftAligned",
                                children: data.cak_result.map((v) => (
                                    new TextRun({
                                        text: v,
                                        color: "990000",
                                        break: 2,
                                    })
                                ))
                            }),
                        ]
                    }),
                    // Zinc to Copper (Zn/Cu)
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: "Zinc to Copper (Zn/Cu)",
                                bold: true,
                                break: 2,
                            }),
                            new TextRun({
                                text: "“Hormone and Energy Regulation Ratio”",
                                break: 1,
                            }),
                            new TextRun({
                                text: "Range: 4.0 - 12.0",
                                break: 1,
                            }),
                            new TextRun({
                                text: "Optimal: 8",
                                break: 1,
                            }),
                        ],
                    }),
                    new Paragraph({
                        text: 'The zinc to copper ratio is a much more effective method of evaluating zinc and copper levels than considering either copper or zinc levels alone.',
                        break: 2,
                    }),
                    new Paragraph({
                        text: 'This ratio can be indicative of copper deficiency or copper unavailability.',
                        break: 2,
                    }),
                    new Paragraph({
                        text: 'Zinc roughly correlates with progesterone in women and testosterone in men, while copper roughly correlates with estrogen in men and women.',
                        break: 2,
                    }),
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: data.client_name + " - " + data.zn_cu,
                                bold: true,
                                color: "990000",
                            }),
                            new Paragraph({
                                style: "leftAligned",
                                children: data.zn_cu_result.map((v) => (
                                    new TextRun({
                                        text: v,
                                        color: "990000",
                                        break: 2,
                                    })
                                ))
                            }),
                        ]
                    }),
                    // Sodium to Magnesium (Na/Mg)
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: "Sodium to Magnesium (Na/Mg)",
                                bold: true,
                                break: 2,
                            }),
                            new TextRun({
                                text: "“Adrenal Health Ratio”",
                                break: 1,
                            }),
                            new TextRun({
                                text: "Range: 2.0 - 6.0",
                                break: 1,
                            }),
                            new TextRun({
                                text: "Optimal: 4.0",
                                break: 1,
                            }),
                        ],
                    }),
                    new Paragraph({
                        text: 'Aldosterone, a mineralocorticoid adrenal hormone, regulates retention of sodium in the body. In general, the higher the sodium level, the higher the aldosterone level.',
                        break: 2,
                    }),
                    new Paragraph({
                        text: 'This ratio is also a measure of energy output, because the adrenal glands are a major regulator (along with the thyroid gland) of the rate of metabolism.',
                        break: 2,
                    }),
                    new Paragraph({
                        text: 'This ratio is a tissue reading and will often not match blood tests for adrenal hormones. Usually the blood tests will be normal, but the tissue mineral test will show abnormal adrenal function. Symptoms, however, tend to correlate well with the hair analysis.',
                        break: 2,
                    }),
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: data.client_name + " - " + data.na_mg,
                                bold: true,
                                color: "990000",
                            }),
                            new Paragraph({
                                style: "leftAligned",
                                children: data.na_mg_result.map((v) => (
                                    new TextRun({
                                        text: v,
                                        color: "990000",
                                        break: 2,
                                    })
                                ))
                            }),
                        ]
                    }),

                    // Calcium to Magnesium (Ca/Mg)
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: "Calcium to Magnesium (Ca/Mg)",
                                bold: true,
                                break: 2,
                            }),
                            new TextRun({
                                text: "“Blood Sugar Ratio”",
                                break: 1,
                            }),
                            new TextRun({
                                text: "Range: 3.0 - 11.0",
                                break: 1,
                            }),
                            new TextRun({
                                text: "Optimal: 7.0",
                                break: 1,
                            }),
                        ],
                    }),
                    new Paragraph({
                        text: 'Calcium is required for the release of insulin from the pancreas. Magnesium is necessary for both the action of insulin and the manufacture of insulin.',
                        break: 2,
                    }),
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: data.client_name + " - " + data.ca_mg,
                                bold: true,
                                color: "990000",
                            }),
                            new Paragraph({
                                style: "leftAligned",
                                children: data.ca_mg_result.map((v) => (
                                    new TextRun({
                                        text: v,
                                        color: "990000",
                                        break: 2,
                                    })
                                ))
                            }),
                        ]
                    }),

                    // Iron to Copper (Fe/Cu)
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: "Iron to Copper (Fe/Cu)",
                                bold: true,
                                break: 2,
                            }),
                            new TextRun({
                                text: "“Infection Indication Ratio”",
                                break: 1,
                            }),
                            new TextRun({
                                text: "Range: .2 - 1.6",
                                break: 1,
                            }),
                            new TextRun({
                                text: "Optimal: .9",
                                break: 1,
                            }),
                        ],
                    }),
                    new Paragraph({
                        text: 'The iron to copper ratio indicates if your body tends toward viral infections or bacterial infections',
                        break: 2,
                    }),
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: data.client_name + " - " + data.fe_cu,
                                bold: true,
                                color: "990000",
                            }),
                            new Paragraph({
                                style: "leftAligned",
                                children: data.fe_cu_result.map((v) => (
                                    new TextRun({
                                        text: v,
                                        color: "990000",
                                        break: 2,
                                    })
                                ))
                            }),
                        ]
                    }),
                    // Copper to Molybdenum (Cu/Mo)
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: "Copper to Molybdenum (Cu/Mo)",
                                bold: true,
                                break: 2,
                            }),
                            new TextRun({
                                text: "“Copper Imbalance Ratio”",
                                break: 1,
                            }),
                            new TextRun({
                                text: "Range: 400 - 850",
                                break: 1,
                            }),
                            new TextRun({
                                text: "Optimal: 625",
                                break: 1,
                            }),
                        ],
                    }),
                    new Paragraph({
                        text: 'The copper to molybdenum ratio is an important marker for copper imbalance.',
                        break: 2,
                    }),
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: data.client_name + " - " + data.cu_mo,
                                bold: true,
                                color: "990000",
                            }),
                            new Paragraph({
                                style: "leftAligned",
                                children: data.cu_mo_result.map((v) => (
                                    new TextRun({
                                        text: v,
                                        color: "990000",
                                        break: 2,
                                    })
                                ))
                            }),
                        ]
                    }),

                    // Heavy Metals
                    new Paragraph({
                        text: "Heavy Metals",
                        heading: HeadingLevel.HEADING_2,
                        pageBreakBefore: true,
                    }),
                    new Paragraph({
                        text: "Heavy metals are a group of minerals with no known physiological or biological function in the human body and are known to be harmful. They are naturally occurring, but the industrial revolution has exposed us to increasing amounts of heavy metals. They are a major cause of illness, aging, and genetic defects.",
                        break: 2,
                    }),
                    new Paragraph({
                        text: "The body uses what it can when it needs minerals. Heavy metals can be used in place of mineral deficiencies and displace the trace elements. This can potentially skew the readings of the minerals until the toxic metals are removed, which can take months or even years in some cases.",
                        break: 2,
                    }),
                    new Paragraph({
                        text: "Low levels of metals on HTMA only tells us that the amount of toxic metal deposited in the hair was low. Low levels do not indicate that the body doesn’t have any metals in their body. It should be assumed that toxic metals are present - however, most are hidden deep in the body organs and tissues.",
                        break: 2,
                    }),
                    new Paragraph({
                        text: "This report only focuses on the heavy metals that are present in your hair currently. Please review the potential sources for each heavy metal exposure and try to limit them.",
                        break: 2,
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                center: true,
                                text: "_________________________________________________________________________________________________________________________",
                                bold: true,
                                font: {
                                    name: "adam",
                                },
                            }),
                        ],
                    }),

                    new Paragraph({
                        style: "leftAligned",
                        children: paragraphBuild(data.heavy_metallist)
                        // data.heavy_metallist.map((v) => (
                        //     new TextRun({
                        //         text: `${v.metal_name}\n<br>mlml`,
                        //         bold: true,
                        //         color: "990000",
                        //         break: 2,
                        //     })
                        //     // ,
                        //     // new TextRun({
                        //     //     text: v.metal_desc,
                        //     //     bold: true,
                        //     //     color: "990000",
                        //     //     break: 2,
                        //     // })
                        // ))
                    }),

                    // Single Minerals
                    new Paragraph({
                        text: "Single Minerals",
                        heading: HeadingLevel.HEADING_2,
                        pageBreakBefore: true,
                    }),
                    new Paragraph({
                        text: "A very high or a very low hair level may indicate bio-unavailability of the mineral.",
                        break: 2,
                    }),
                    new Paragraph({
                        text: "This simply refers to the ability of the body to utilize a mineral or incorporate it into an enzyme or other binding site. Bio-unavailability is important to understand so you don’t simply take each mineral reading at face value.",
                        break: 2,
                    }),
                    new Paragraph({
                        text: "Additionally, it is important to understand that a hair mineral level may be elevated because it is being displaced by another mineral or heavy metal. For example, arsenic can displace magnesium, calcium, and other minerals. Often, arsenic will also raise the sodium level which in turn, affects the sodium/potassium ratio.",
                        break: 2,
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                center: true,
                                text: "_________________________________________________________________________________________________________________________",
                                bold: true,
                                font: {
                                    name: "adam",
                                },
                            }),
                        ],
                    }),
                    // Calcium
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: "Calcium",
                                bold: true,
                                break: 2,
                            }),
                            new TextRun({
                                text: "Range: 22 - 97",
                                break: 1,
                            }),
                            new TextRun({
                                text: "Optimal: 42",
                                break: 1,
                            }),
                        ],
                    }),
                    new Paragraph({
                        style: "leftAligned",
                        text: 'Functions of Calcium:',
                        children: [
                            new TextRun({
                                text: "-The primary structural element",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-90%+ of calcium is in the bone and teeth, which act as reservoirs that calcium can be withdrawn as required for extra-skeletal functions",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Regulated in tissues and serum at the expense of skeletal structures",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Controls the nervous system",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Responsible for muscle contractions",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Maintains the pH balance of the body",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Reduces lactic acid build up",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Regulated by the parathyroid and kidneys",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Inhibits thyroid hormone",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Insulin release is dependent on calcium being available",
                                break: 1,
                            }),
                        ],
                        break: 2,
                    }),
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: data.client_name + " - " + data.calcium,
                                bold: true,
                                color: "990000",
                            }),
                            new Paragraph({
                                style: "leftAligned",
                                children: data.calcium_result.map((v) => (
                                    new TextRun({
                                        text: v,
                                        color: "990000",
                                        break: 2,
                                    })
                                ))
                            })
                        ]
                    }),
                    // Magnesium
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: "Magnesium",
                                bold: true,
                                break: 2,
                            }),
                            new TextRun({
                                text: "Range: 2.0 - 11.0",
                                break: 1,
                            }),
                            new TextRun({
                                text: "Optimal: 6",
                                break: 1,
                            }),
                        ],
                    }),
                    new Paragraph({
                        style: "leftAligned",
                        text: 'Functions of Magnesium:',
                        children: [
                            new TextRun({
                                text: "-Intracellular mineral & key element in cellular metabolism",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Needed for both Glycolysis & the Citric Acid Cycle",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Needed for 500 different critical enzyme reactions",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Regulates sugar metabolism & involved in energy production",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Highest concentration are found in muscles, liver, heart and pancreas",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Cell membrane permeability",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Relaxation of the muscle & reduces inflammation",
                                break: 1,
                            }),
                            new TextRun({
                                text: '-The "heart mineral"',
                                break: 1,
                            }),
                        ],
                        break: 2,
                    }),
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: data.client_name + " - " + data.magnesium,
                                bold: true,
                                color: "990000",
                            }),
                            new Paragraph({
                                style: "leftAligned",
                                children: data.magnesium_result.map((v) => (
                                    new TextRun({
                                        text: v,
                                        color: "990000",
                                        break: 2,
                                    })
                                ))
                            })
                        ]
                    }),
                    // Sodium
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: "Sodium",
                                bold: true,
                                break: 2,
                            }),
                            new TextRun({
                                text: "Range: 4 - 36",
                                break: 1,
                            }),
                            new TextRun({
                                text: "Optimal: 25",
                                break: 1,
                            }),
                        ],
                    }),
                    new Paragraph({
                        style: "leftAligned",
                        text: 'Functions of Sodium:',
                        children: [
                            new TextRun({
                                text: "-Major regulator of the fluid balance, pH and other fluid-related aspects of the body; the body’s great solvent.",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-A primary alkalizer",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Regulated by the adrenal glands (aldosterone)",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Regulates the blood pressure & increased heart rate",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Influences stomach acid levels ",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Influences cell membrane permeability",
                                break: 1,
                            }),
                        ],
                        break: 2,
                    }),
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: data.client_name + " - " + data.sodium,
                                bold: true,
                                color: "990000",
                            }),
                            new Paragraph({
                                style: "leftAligned",
                                children: data.sodium_result.map((v) => (
                                    new TextRun({
                                        text: v,
                                        color: "990000",
                                        break: 2,
                                    })
                                ))
                            })
                        ]
                    }),
                    // Potassium
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: "Potassium",
                                bold: true,
                                break: 2,
                            }),
                            new TextRun({
                                text: "Range: 2 - 24",
                                break: 1,
                            }),
                            new TextRun({
                                text: "Optimal: 10",
                                break: 1,
                            }),
                        ],
                    }),
                    new Paragraph({
                        style: "leftAligned",
                        text: 'Functions of Potassium:',
                        children: [
                            new TextRun({
                                text: "-A primary solvent mineral",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Intracellular mineral",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Regulates the blood pressure with sodium",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Maintains fluid balance & pH level of the body",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Influences cell membrane permeability",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Sensitizes the cell to thyroid hormone",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Nerve conduction of the heart",
                                break: 1,
                            }),
                            new TextRun({
                                text: '-Lowers heart rate & dilates arteries',
                                break: 1,
                            }),
                            new TextRun({
                                text: '-Regulates the cell membrane potential, cell permeability, sodium pump action, muscle contraction and relaxation, and nerve impulse conduction',
                                break: 1,
                            }),
                        ],
                        break: 2,
                    }),
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: data.client_name + " - " + data.potassium,
                                bold: true,
                                color: "990000",
                            }),
                            new Paragraph({
                                style: "leftAligned",
                                children: data.potassium_result.map((v) => (
                                    new TextRun({
                                        text: v,
                                        color: "990000",
                                        break: 2,
                                    })
                                ))
                            })
                        ]
                    }),
                    // Copper
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: "Copper",
                                bold: true,
                                break: 2,
                            }),
                            new TextRun({
                                text: "Range: .9 - 3.9",
                                break: 1,
                            }),
                            new TextRun({
                                text: "Optimal: 2.2",
                                break: 1,
                            }),
                        ],
                    }),
                    new Paragraph({
                        style: "leftAligned",
                        text: 'Functions of Copper:',
                        children: [
                            new TextRun({
                                text: "-Electron transport chain",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Hemoglobin synthesis",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Neurotransmitter activity",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Immune system health",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Cardiovascular health",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Synthesis of collagen and elastin",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Iron availability",
                                break: 1,
                            }),
                            new TextRun({
                                text: '-Anti-fungal, anti-mold, anti-bacterial',
                                break: 1,
                            }),
                            new TextRun({
                                text: 'The highest level of copper is found in the liver and brain. Copper levels must always be assessed with other mineral relationships and history of exposure. It is a very problematic mineral today. Copper transport requires strong adrenal function and removal from the body requires strong bile function.',
                                break: 2,
                            }),
                        ],
                        break: 2,
                    }),
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: data.client_name + " - " + data.copper,
                                bold: true,
                                color: "990000",
                            }),
                            new Paragraph({
                                style: "leftAligned",
                                children: data.copper_result.map((v) => (
                                    new TextRun({
                                        text: v,
                                        color: "990000",
                                        break: 2,
                                    })
                                ))
                            })
                        ]
                    }),
                    // Zinc
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: "Zinc",
                                bold: true,
                                break: 2,
                            }),
                            new TextRun({
                                text: "Range: 10 - 21",
                                break: 1,
                            }),
                            new TextRun({
                                text: "Optimal: 18",
                                break: 1,
                            }),
                        ],
                    }),
                    new Paragraph({
                        style: "leftAligned",
                        text: 'Functions of Zinc:',
                        children: [
                            new TextRun({
                                text: "-Protein synthesis",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Male reproductive health",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-HCL, digestive enzyme, & bile production",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Insulin production & secretion",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Immune system health",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Brain development",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Hair, skin, nail, & eye health",
                                break: 1,
                            }),
                            new TextRun({
                                text: 'Zinc is the balancing stabilizing mineral and it is anti-viral and immune supportive.',
                                break: 2,
                            }),
                        ],
                        break: 2,
                    }),
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: data.client_name + " - " + data.zinc,
                                bold: true,
                                color: "990000",
                            }),
                            new Paragraph({
                                style: "leftAligned",
                                children: data.zinc_result.map((v) => (
                                    new TextRun({
                                        text: v,
                                        color: "990000",
                                        break: 2,
                                    })
                                ))
                            })
                        ]
                    }),
                    // Phosphorus
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: "Phosphorus",
                                bold: true,
                                break: 2,
                            }),
                            new TextRun({
                                text: "Range: 11 - 20",
                                break: 1,
                            }),
                            new TextRun({
                                text: "Optimal: 16",
                                break: 1,
                            }),
                        ],
                    }),
                    new Paragraph({
                        style: "leftAligned",
                        text: 'Functions of Phosphorus:',
                        children: [
                            new TextRun({
                                text: "-Stimulatory mineral that makes up ATP",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Important for growth & development, sympathetic nervous system -Cell membrane function, protein synthesis, bone formation",
                                break: 1,
                            }),
                            new TextRun({
                                text: "This is the 'power' mineral or digestive fire mineral.",
                                break: 2,
                            }),
                            new TextRun({
                                text: "Phosphorus is an essential mineral that is involved in protein synthesis and energy production within the cells. All proteins contain phosphorus and thus are a significant source of organic phosphorus. The hair tissue mineral level of phosphorus is often associated with the adequacy of protein synthesis in the body. This depends on the diet, lifestyle, condition of the intestinal tract and liver and the levels of other nutritional minerals such as zinc and copper.",
                                break: 2,
                            }),
                        ],
                        break: 2,
                    }),
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: data.client_name + " - " + data.phosphorus,
                                bold: true,
                                color: "990000",
                            }),
                            new Paragraph({
                                style: "leftAligned",
                                children: data.phosphorus_result.map((v) => (
                                    new TextRun({
                                        text: v,
                                        color: "990000",
                                        break: 2,
                                    })
                                ))
                            })
                        ]
                    }),
                    // Iron
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: "Iron",
                                bold: true,
                                break: 2,
                            }),
                            new TextRun({
                                text: "Range: .5 - 1.6",
                                break: 1,
                            }),
                            new TextRun({
                                text: "Optimal: 1.1",
                                break: 1,
                            }),
                        ],
                    }),
                    new Paragraph({
                        style: "leftAligned",
                        text: 'Functions of Iron:',
                        children: [
                            new TextRun({
                                text: "-Required for production of hemoglobin ",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Energy production",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Immune system efficiency",
                                break: 1,
                            }),
                            new TextRun({
                                text: "We do not interpret hair iron levels alone; it is best to correlate with blood chemistry markers.",
                                break: 2,
                            }),
                        ],
                        break: 2,
                    }),
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: data.client_name + " - " + data.iron,
                                bold: true,
                                color: "990000",
                            }),
                            new Paragraph({
                                style: "leftAligned",
                                children: data.iron_result.map((v) => (
                                    new TextRun({
                                        text: v,
                                        color: "990000",
                                        break: 2,
                                    })
                                ))
                            })
                        ]
                    }),
                    // Manganese
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: "Manganese",
                                bold: true,
                                break: 2,
                            }),
                            new TextRun({
                                text: "Range: .010 - .13",
                                break: 1,
                            }),
                            new TextRun({
                                text: "Optimal: .07",
                                break: 1,
                            }),
                        ],
                    }),
                    new Paragraph({
                        style: "leftAligned",
                        text: 'Functions of Manganese:',
                        children: [
                            new TextRun({
                                text: "-Helps the mitochondria of the cells metabolize glucose.",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Required for tendon and ligament function, energy production, and thyroid activity.",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Necessary for the bacteria lactobacillus to thrive in the microbiome.",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Involved in the formation of T4 thyroid hormone and necessary for proper thyroid function",
                                break: 1,
                            }),
                            new TextRun({
                                text: "Manganese is an alkaline-forming, regulatory mineral. It is located in the mitochondria of the cells, so the structure and function of mitochondria are particularly affected by manganese status. It is found in the liver, thyroid, pituitary, pancreas, kidneys, and bones.",
                                break: 2,
                            }),
                        ],
                        break: 2,
                    }),
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: data.client_name + " - " + data.manganese,
                                bold: true,
                                color: "990000",
                            }),
                            new Paragraph({
                                style: "leftAligned",
                                children: data.manganese_result.map((v) => (
                                    new TextRun({
                                        text: v,
                                        color: "990000",
                                        break: 2,
                                    })
                                ))
                            })
                        ]
                    }),
                    // Chromium
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: "Chromium",
                                bold: true,
                                break: 2,
                            }),
                            new TextRun({
                                text: "Range: .02 - .08",
                                break: 1,
                            }),
                            new TextRun({
                                text: "Optimal: .05",
                                break: 1,
                            }),
                        ],
                    }),
                    new Paragraph({
                        style: "leftAligned",
                        text: 'Functions of Chromium:',
                        children: [
                            new TextRun({
                                text: "-Along with zinc, manganese, copper, and a few other trace minerals, chromium is needed for blood sugar regulation, processing carbohydrates and fats, and helping cells respond properly to insulin.",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Helps transport insulin and attach it to cell walls.",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Important for the structure and metabolism of nucleic acids.",
                                break: 1,
                            }),
                        ],
                        break: 2,
                    }),
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: data.client_name + " - " + data.chromium,
                                bold: true,
                                color: "990000",
                            }),
                            new Paragraph({
                                style: "leftAligned",
                                children: data.chromium_result.map((v) => (
                                    new TextRun({
                                        text: v,
                                        color: "990000",
                                        break: 2,
                                    })
                                ))
                            })
                        ]
                    }),
                    // Selenium
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: "Selenium",
                                bold: true,
                                break: 2,
                            }),
                            new TextRun({
                                text: "Range: .03 - .18",
                                break: 1,
                            }),
                            new TextRun({
                                text: "Optimal: .12",
                                break: 1,
                            }),
                        ],
                    }),
                    new Paragraph({
                        style: "leftAligned",
                        text: 'Functions of Selenium:',
                        children: [
                            new TextRun({
                                text: "-Aids in liver detoxification processes, as it is a precursor to glutathione",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Needed for thyroid hormone conversion (T4 to T3)",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Works with vitamin E as an antioxidant",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-May help prevent heart disease and cancer through its roles as an antioxidant and in DNA repair.",
                                break: 1,
                            }),
                        ],
                        break: 2,
                    }),
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: data.client_name + " - " + data.selenium,
                                bold: true,
                                color: "990000",
                            }),
                            new Paragraph({
                                style: "leftAligned",
                                children: data.selenium_result.map((v) => (
                                    new TextRun({
                                        text: v,
                                        color: "990000",
                                        break: 2,
                                    })
                                ))
                            })
                        ]
                    }),
                    // Boron
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: "Boron",
                                bold: true,
                                break: 2,
                            }),
                            new TextRun({
                                text: "Range: .02 - .91",
                                break: 1,
                            }),
                            new TextRun({
                                text: "Optimal: .46",
                                break: 1,
                            }),
                        ],
                    }),
                    new Paragraph({
                        style: "leftAligned",
                        text: 'Functions of Boron:',
                        children: [
                            new TextRun({
                                text: "-Supports adrenal gland activity ",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Helps the body respond to hormone activity.",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Increases absorption of and regulates calcium, magnesium, copper and phosphorus.",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Cofactor in enzymatic reactions throughout the body.",
                                break: 1,
                            }),
                        ],
                        break: 2,
                    }),
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: data.client_name + " - " + data.boron,
                                bold: true,
                                color: "990000",
                            }),
                            new Paragraph({
                                style: "leftAligned",
                                children: data.boron_result.map((v) => (
                                    new TextRun({
                                        text: v,
                                        color: "990000",
                                        break: 2,
                                    })
                                ))
                            })
                        ]
                    }),
                    // Cobalt
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: "Cobalt",
                                bold: true,
                                break: 2,
                            }),
                            new TextRun({
                                text: "Range: .001 - .003",
                                break: 1,
                            }),
                            new TextRun({
                                text: "Optimal: .002",
                                break: 1,
                            }),
                        ],
                    }),
                    new Paragraph({
                        style: "leftAligned",
                        text: 'Functions of Cobalt:',
                        children: [
                            new TextRun({
                                text: "-Required for normal function of the pancreas",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Helps take in and process Vitamin B12",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Helps with iron absorption",
                                break: 1,
                            }),
                        ],
                        break: 2,
                    }),
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: data.client_name + " - " + data.cobalt,
                                bold: true,
                                color: "990000",
                            }),
                            new Paragraph({
                                style: "leftAligned",
                                children: data.cobalt_result.map((v) => (
                                    new TextRun({
                                        text: v,
                                        color: "990000",
                                        break: 2,
                                    })
                                ))
                            })
                        ]
                    }),
                    // Molybdenum
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: "Molybdenum",
                                bold: true,
                                break: 2,
                            }),
                            new TextRun({
                                text: "Range: .003 - .008",
                                break: 1,
                            }),
                            new TextRun({
                                text: "Optimal: .055",
                                break: 1,
                            }),
                        ],
                    }),
                    new Paragraph({
                        style: "leftAligned",
                        text: 'Functions of Molybdenum:',
                        children: [
                            new TextRun({
                                text: "-Helps the body utilize stored iron",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Prevents copper levels from getting too high",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Assists in alcohol detoxification",
                                break: 1,
                            }),
                        ],
                        break: 2,
                    }),
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: data.client_name + " - " + data.molybdenum,
                                bold: true,
                                color: "990000",
                            }),
                            new Paragraph({
                                style: "leftAligned",
                                children: data.molybdenum_result.map((v) => (
                                    new TextRun({
                                        text: v,
                                        color: "990000",
                                        break: 2,
                                    })
                                ))
                            })
                        ]
                    }),
                    // Sulfur
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: "Sulfur",
                                bold: true,
                                break: 2,
                            }),
                            new TextRun({
                                text: "Range: 3546 - 5336",
                                break: 1,
                            }),
                            new TextRun({
                                text: "Optimal: 4441",
                                break: 1,
                            }),
                        ],
                    }),
                    new Paragraph({
                        style: "leftAligned",
                        text: 'Functions of Sulfur:',
                        children: [
                            new TextRun({
                                text: "-Aids in liver detoxification and the metabolism of fats and carbohydrates.",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Promotes formation of collagen, which helps strengthen hair and nails",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-Precursor for utilization of amino acids",
                                break: 1,
                            }),
                        ],
                        break: 2,
                    }),
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: data.client_name + " - " + data.sulfur,
                                bold: true,
                                color: "990000",
                            }),
                            new Paragraph({
                                style: "leftAligned",
                                children: data.sulfur_result.map((v) => (
                                    new TextRun({
                                        text: v,
                                        color: "990000",
                                        break: 2,
                                    })
                                ))
                            })
                        ]
                    }),
                    // Lithium
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: "Lithium",
                                bold: true,
                                break: 2,
                            }),
                            new TextRun({
                                text: "Range: 0-.007",
                                break: 1,
                            }),
                            new TextRun({
                                text: "Optimal: .035",
                                break: 1,
                            }),
                        ],
                    }),
                    new Paragraph({
                        style: "leftAligned",
                        text: 'Functions of Lithium:',
                        children: [
                            new TextRun({
                                text: "-Important for detoxification and mental health",
                                break: 1,
                            }),
                            new TextRun({
                                text: "-May support serotonin activity",
                                break: 1,
                            }),
                        ],
                        break: 2,
                    }),
                    new Paragraph({
                        style: "leftAligned",
                        children: [
                            new TextRun({
                                text: data.client_name + " - " + data.lithium,
                                bold: true,
                                color: "990000",
                            }),
                            new Paragraph({
                                style: "leftAligned",
                                children: data.lithium_result.map((v) => (
                                    new TextRun({
                                        text: v,
                                        color: "990000",
                                        break: 2,
                                    })
                                ))
                            })
                        ]
                    }),
                ],
            },
        ],
    });

    Packer.toBlob(doc).then((blob) => {
        console.log(blob);
        saveAs(blob, "Client Report - " + data.client_name + " - " + formatedDate);
        console.log("Document created successfully");
    });

};



export default generateDOC;
