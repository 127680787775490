var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":""}},[(_vm.loading)?_c('div',{attrs:{"id":"preloader"}},[_vm._m(0)]):_vm._e(),(_vm.comparisonTestAnalysisData)?_c('main',[_c('b-container',{staticClass:"main_content_wrapper pl-4 mb-5 pb-5 main-container",attrs:{"fluid":""}},[_c('h2',{staticClass:"mx-2 pt-2 mb-2 text-center"},[_vm._v(" Comparison Analysis for "+_vm._s(_vm.testClientName)+" "),_c('br')]),(
          _vm.comparisonTestAnalysisData.patterns.practitioner_name &&
          _vm.comparisonTestAnalysisData.patterns.practitioner_name[0]
        )?_c('h3',{staticClass:"mx-2 pt-2 mb-2 text-center"},[_vm._v(" Prepared by "+_vm._s(_vm.comparisonTestAnalysisData.patterns.practitioner_name[0])+" on "+_vm._s(_vm.dateValue)+" ")]):_vm._e(),_c('hr'),_c('div',{staticClass:"pl-5"}),_c('b-row',[_c('b-col',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"data-table team-data-table bg-white p-4 rounded"},[_c('div',{staticClass:"table_row team_table_row"},[_c('div',{staticClass:"text-start"}),_c('br'),_c('div',{staticClass:"table-div"},[_c('table',{staticClass:"table-hidden table"},[_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Pattern:")]),_c('td',[_vm._v("Positive Re-Test Patterns")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_c('ul',{staticStyle:{"list-style-type":"none"}},_vm._l((_vm.comparisonTestAnalysisData.patterns.positive_retest_pattern[
                                index
                              ].filter(function (test) { return test !== ''; })),function(text){return _c('li',{key:text},[_vm._v(" "+_vm._s(text)+" ")])}),0)])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Pattern:")]),_c('td',[_vm._v("Metabolic Type")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_c('ul',{staticStyle:{"list-style-type":"none"}},_vm._l((_vm.comparisonTestAnalysisData.patterns.metabolic_patterns[
                                index
                              ].filter(function (test) { return test !== ''; })),function(text){return _c('li',{key:text},[_vm._v(" "+_vm._s(text)+" ")])}),0)])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Pattern:")]),_c('td',[_vm._v("Mixed Oxidizer")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.patterns .mixed_oxidation[index])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Pattern:")]),_c('td',[_vm._v("Inversion")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.patterns.inversions[ index ])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Pattern:")]),_c('td',[_vm._v("Stress Patterns")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_c('ul',{staticStyle:{"list-style-type":"none"}},_vm._l((_vm.comparisonTestAnalysisData.patterns.stress_patterns[
                                index
                              ].filter(function (test) { return test !== ''; })),function(text){return _c('li',{key:text},[_vm._v(" "+_vm._s(text)+" ")])}),0)])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Pattern:")]),_c('td',[_vm._v("Psychological Patterns")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_c('ul',{staticStyle:{"list-style-type":"none"}},_vm._l((_vm.comparisonTestAnalysisData.patterns.psychological_patterns[
                                index
                              ].filter(function (test) { return test !== ''; })),function(text){return _c('li',{key:text},[_vm._v(" "+_vm._s(text)+" ")])}),0)])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Pattern:")]),_c('td',[_vm._v("Metabolic Patterns")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_c('ul',{staticStyle:{"list-style-type":"none"}},_vm._l((_vm.comparisonTestAnalysisData.patterns.metabolic_patterns[
                                index
                              ].filter(function (test) { return test !== ''; })),function(text){return _c('li',{key:text},[_vm._v(" "+_vm._s(text)+" ")])}),0)])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Indicators:")]),_c('td',[_vm._v("Glucose Intolerance")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.indicators .glucose_intolerance.value[index])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Indicators:")]),_c('td',[_vm._v("Glucose Intolerance")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_c('ul',{staticStyle:{"list-style-type":"none"}},_vm._l((_vm.comparisonTestAnalysisData.indicators.glucose_intolerance.text[
                                index
                              ].filter(function (test) { return test !== ''; })),function(text){return _c('li',{key:text},[_vm._v(" "+_vm._s(text)+" ")])}),0)])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Indicators:")]),_c('td',[_vm._v("Low Stomach Acid")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.indicators .low_stomach_acid.value[index])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Indicators:")]),_c('td',[_vm._v("Low Stomach Acid")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_c('ul',{staticStyle:{"list-style-type":"none"}},_vm._l((_vm.comparisonTestAnalysisData.indicators.low_stomach_acid.text[
                                index
                              ].filter(function (test) { return test !== ''; })),function(text){return _c('li',{key:text},[_vm._v(" "+_vm._s(text)+" ")])}),0)])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Indicators:")]),_c('td',[_vm._v("Burnout")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.indicators.burnout .value[index])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Indicators:")]),_c('td',[_vm._v("Burnout")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_c('ul',{staticStyle:{"list-style-type":"none"}},_vm._l((_vm.comparisonTestAnalysisData.indicators.burnout.text[
                                index
                              ].filter(function (test) { return test !== ''; })),function(text){return _c('li',{key:text},[_vm._v(" "+_vm._s(text)+" ")])}),0)])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Indicators:")]),_c('td',[_vm._v("Trauma")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.indicators.trauma .value[index])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Indicators:")]),_c('td',[_vm._v("Trauma")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_c('ul',{staticStyle:{"list-style-type":"none"}},_vm._l((_vm.comparisonTestAnalysisData.indicators.trauma.text[
                                index
                              ].filter(function (test) { return test !== ''; })),function(text){return _c('li',{key:text},[_vm._v(" "+_vm._s(text)+" ")])}),0)])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Indicators:")]),_c('td',[_vm._v("Impaired Immune")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.indicators .impaired_immune_response.value[index])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Indicators:")]),_c('td',[_vm._v("Impaired Immune")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_c('ul',{staticStyle:{"list-style-type":"none"}},_vm._l((_vm.comparisonTestAnalysisData.indicators.impaired_immune_response.text[
                                index
                              ].filter(function (test) { return test !== ''; })),function(text){return _c('li',{key:text},[_vm._v(" "+_vm._s(text)+" ")])}),0)])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Indicators:")]),_c('td',[_vm._v("Zinc Deficiency")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.indicators .zinc_deficiency.value[index])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Indicators:")]),_c('td',[_vm._v("Zinc Deficiency")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_c('ul',{staticStyle:{"list-style-type":"none"}},_vm._l((_vm.comparisonTestAnalysisData.indicators.zinc_deficiency.text[
                                index
                              ].filter(function (test) { return test !== ''; })),function(text){return _c('li',{key:text},[_vm._v(" "+_vm._s(text)+" ")])}),0)])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Indicators:")]),_c('td',[_vm._v("Copper Imbalance")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.indicators .copper_imbalance.value[index])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Indicators:")]),_c('td',[_vm._v("Copper Imbalance")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_c('ul',{staticStyle:{"list-style-type":"none"}},_vm._l((_vm.comparisonTestAnalysisData.indicators.copper_imbalance.text[
                                index
                              ].filter(function (test) { return test !== ''; })),function(text){return _c('li',{key:text},[_vm._v(" "+_vm._s(text)+" ")])}),0)])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Indicators:")]),_c('td',[_vm._v("Iron Interactions")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.indicators .iron_interactions.value[index])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Indicators:")]),_c('td',[_vm._v("Iron Interactions")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_c('ul',{staticStyle:{"list-style-type":"none"}},_vm._l((_vm.comparisonTestAnalysisData.indicators.iron_interactions.text[
                                index
                              ].filter(function (test) { return test !== ''; })),function(text){return _c('li',{key:text},[_vm._v(" "+_vm._s(text)+" ")])}),0)])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Indicators:")]),_c('td',[_vm._v("Iodine Deficiency")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.indicators .iodine_deficiency.value[index])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Indicators:")]),_c('td',[_vm._v("Iodine Deficiency")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_c('ul',{staticStyle:{"list-style-type":"none"}},_vm._l((_vm.comparisonTestAnalysisData.indicators.iodine_deficiency.text[
                                index
                              ].filter(function (test) { return test !== ''; })),function(text){return _c('li',{key:text},[_vm._v(" "+_vm._s(text)+" ")])}),0)])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Indicators:")]),_c('td',[_vm._v("Poor Eliminators")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.indicators .poor_eliminators.value[index])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Indicators:")]),_c('td',[_vm._v("Poor Eliminators")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_c('ul',{staticStyle:{"list-style-type":"none"}},_vm._l((_vm.comparisonTestAnalysisData.indicators.poor_eliminators.text[
                                index
                              ].filter(function (test) { return test !== ''; })),function(text){return _c('li',{key:text},[_vm._v(" "+_vm._s(text)+" ")])}),0)])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Indicators:")]),_c('td',[_vm._v("Very Poor Eliminators")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.indicators .very_poor_eliminators.value[index])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Indicators:")]),_c('td',[_vm._v("Very Poor Eliminators")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_c('ul',{staticStyle:{"list-style-type":"none"}},_vm._l((_vm.comparisonTestAnalysisData.indicators.very_poor_eliminators.text[
                                index
                              ].filter(function (test) { return test !== ''; })),function(text){return _c('li',{key:text},[_vm._v(" "+_vm._s(text)+" ")])}),0)])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',{attrs:{"colspan":"2"}},[_vm._v("Heavy Metals:")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_c('ul',{staticStyle:{"list-style-type":"none"}},_vm._l((_vm.comparisonTestAnalysisData.heavy_metals[
                                index
                              ].filter(function (test) { return test !== ''; })),function(text){return _c('li',{key:text},[_vm._v(" "+_vm._s(text)+" ")])}),0)])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Ratios:")]),_c('td',[_vm._v("Ca/P Autonomic Balance")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.ratio.ca_p[index][0])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Ratios:")]),_c('td',[_vm._v("Na/K Vitality")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.ratio.na_k[index][0])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Ratios:")]),_c('td',[_vm._v("Ca/K Thyroid")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.ratio.ca_k[index][0])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Ratios:")]),_c('td',[_vm._v("Zn/Cu Hormonal/Stress")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.ratio.zn_cu[index][0])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Ratios:")]),_c('td',[_vm._v("Na/Mg Adrenal")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.ratio.na_mg[index][0])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Ratios:")]),_c('td',[_vm._v("Ca/Mg Blood Sugar")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.ratio.ca_mg[index][0])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Ratios:")]),_c('td',[_vm._v("Fe/Cu Infection")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.ratio.fe_cu[index][0])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Ratios:")]),_c('td',[_vm._v("Cu/Mo Copper Imbalance")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.ratio.ca_mo[index][0])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Minerals:")]),_c('td',[_vm._v("Calcium")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.minerals.calcium[index])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Minerals:")]),_c('td',[_vm._v("Magnesium")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.minerals.magnesium[ index ])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Minerals:")]),_c('td',[_vm._v("Sodium")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.minerals.sodium[index])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Minerals:")]),_c('td',[_vm._v("Potassium")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.minerals.potassium[ index ])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Minerals:")]),_c('td',[_vm._v("Copper")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.minerals.copper[index])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Minerals:")]),_c('td',[_vm._v("Zinc")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.minerals.zinc[index])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Minerals:")]),_c('td',[_vm._v("Phosphorus")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.minerals.phosphorus[ index ])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Minerals:")]),_c('td',[_vm._v("Iron")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.minerals.iron[index])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Minerals:")]),_c('td',[_vm._v("Manganese")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.minerals.manganese[ index ])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Minerals:")]),_c('td',[_vm._v("Chromium")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.minerals.chromium[ index ])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Minerals:")]),_c('td',[_vm._v("Selenium")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.minerals.selenium[ index ])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Minerals:")]),_c('td',[_vm._v("Boron")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.minerals.boron[index])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Minerals:")]),_c('td',[_vm._v("Cobalt")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.minerals.cobalt[index])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Minerals:")]),_c('td',[_vm._v("Molybdenum")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.minerals.molybdenum[ index ])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Minerals:")]),_c('td',[_vm._v("Uranium")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.minerals.uranium[index])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Minerals:")]),_c('td',[_vm._v("Arsenic")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.minerals.arsenic[index])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Minerals:")]),_c('td',[_vm._v("Beryllium")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.minerals.beryllium[ index ])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Minerals:")]),_c('td',[_vm._v("Mercury")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.minerals.mercury[index])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Minerals:")]),_c('td',[_vm._v("Cadmium")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.minerals.cadmium[index])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Minerals:")]),_c('td',[_vm._v("Lead")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.minerals.lead[index])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Minerals:")]),_c('td',[_vm._v("Aluminum")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.minerals.aluminum[ index ])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Minerals:")]),_c('td',[_vm._v("Germanium")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.minerals.germanium[ index ])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Minerals:")]),_c('td',[_vm._v("Barium")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.minerals.barium[index])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Minerals:")]),_c('td',[_vm._v("Bismuth")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.minerals.bismuth[index])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Minerals:")]),_c('td',[_vm._v("Rubidium")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.minerals.rubidium[ index ])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Minerals:")]),_c('td',[_vm._v("Lithium")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.minerals.lithium[index])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Minerals:")]),_c('td',[_vm._v("Nickel")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.minerals.nickel[index])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Minerals:")]),_c('td',[_vm._v("Platinum")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.minerals.platinum[ index ])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Minerals:")]),_c('td',[_vm._v("Thallium")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.minerals.thallium[ index ])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Minerals:")]),_c('td',[_vm._v("Vanadium")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.minerals.vanadium[ index ])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Minerals:")]),_c('td',[_vm._v("Strontium")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.minerals.strontium[ index ])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Minerals:")]),_c('td',[_vm._v("Tin")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.minerals.tin[index])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Minerals:")]),_c('td',[_vm._v("Titanium")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.minerals.titanium[ index ])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Minerals:")]),_c('td',[_vm._v("Tungsten")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.minerals.tungsten[ index ])+" ")])])}),_c('tr',{staticClass:"font-weight-bold"},[_c('th',[_vm._v("Minerals:")]),_c('td',[_vm._v("Zirconium")])]),_vm._l((_vm.comparisonTestAnalysisData
                          .patterns.date),function(date,index){return _c('tr',{key:date},[_c('th',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.comparisonTestAnalysisData.minerals.zirconium[ index ])+" ")])])})],2),_c('table',{staticClass:"table-comparision table-shown table"},[_c('tr',[_c('td',{staticClass:"date"}),_vm._l((_vm.comparisonTestAnalysisData.patterns
                            .date),function(date){return _c('th',{key:date,staticClass:"date"},[_vm._v(" "+_vm._s(_vm.getMomentDate(date))+" ")])})],2),_c('tr',[_c('th',{staticClass:"table-heading",attrs:{"colspan":_vm.totalCount}},[_vm._v(" Patterns ")])]),_c('tr',[_c('th',[_vm._v("Positive Re-Test Patterns")]),_vm._l((_vm.comparisonTestAnalysisData
                            .patterns.positive_retest_pattern),function(item,index){return _c('td',{key:index},[_c('ul',{staticStyle:{"list-style-type":"none"}},_vm._l((item.filter(
                                function (test) { return test !== ''; }
                              )),function(text){return _c('li',{key:text,staticClass:"mb-2"},[_vm._v(" "+_vm._s(text)+" ")])}),0)])})],2),_c('tr',[_c('th',[_vm._v("Metabolic Type")]),_vm._l((_vm.comparisonTestAnalysisData
                            .patterns.metabolic_type),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',[_c('th',[_vm._v("Mixed Oxidizer")]),_vm._l((_vm.comparisonTestAnalysisData
                            .patterns.mixed_oxidation),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',[_c('th',[_vm._v("Inversion")]),_vm._l((_vm.comparisonTestAnalysisData
                            .patterns.inversions),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',[_c('th',[_vm._v("Stress Patterns")]),_vm._l((_vm.comparisonTestAnalysisData
                            .patterns.stress_patterns),function(item,index){return _c('td',{key:index},[_c('ul',{staticStyle:{"list-style-type":"none"}},_vm._l((item.filter(
                                function (test) { return test !== ''; }
                              )),function(text){return _c('li',{key:text},[_vm._v(" "+_vm._s(text)+" ")])}),0)])})],2),_c('tr',[_c('th',[_vm._v("Psychological Patterns")]),_vm._l((_vm.comparisonTestAnalysisData
                            .patterns.psychological_patterns),function(item,index){return _c('td',{key:index},[_c('ul',{staticStyle:{"list-style-type":"none"}},_vm._l((item.filter(
                                function (test) { return test !== ''; }
                              )),function(text){return _c('li',{key:text},[_vm._v(" "+_vm._s(text)+" ")])}),0)])})],2),_c('tr',[_c('th',[_vm._v("Metabolic Patterns")]),_vm._l((_vm.comparisonTestAnalysisData
                            .patterns.metabolic_patterns),function(item,index){return _c('td',{key:index},[_c('ul',{staticStyle:{"list-style-type":"none"}},_vm._l((item.filter(
                                function (test) { return test !== ''; }
                              )),function(text){return _c('li',{key:text},[_vm._v(" "+_vm._s(text)+" ")])}),0)])})],2),_c('tr',[_c('th',{staticClass:"table-heading",attrs:{"colspan":_vm.totalCount}},[_vm._v(" Indicators ")])]),_c('tr',[_c('th',{attrs:{"rowspan":"2"}},[_vm._v("Glucose Intolerance")]),_vm._l((_vm.comparisonTestAnalysisData
                            .indicators.glucose_intolerance.value),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',_vm._l((_vm.comparisonTestAnalysisData
                            .indicators.glucose_intolerance.text),function(item,index){return _c('td',{key:index},[_c('ul',{staticStyle:{"list-style-type":"none"}},_vm._l((item.filter(
                                function (test) { return test !== ''; }
                              )),function(text){return _c('li',{key:text},[_vm._v(" "+_vm._s(text)+" ")])}),0)])}),0),_c('tr',[_c('th',{attrs:{"rowspan":"2"}},[_vm._v("Low Stomach Acid")]),_vm._l((_vm.comparisonTestAnalysisData
                            .indicators.low_stomach_acid.value),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',_vm._l((_vm.comparisonTestAnalysisData.indicators.low_stomach_acid.text.filter(
                            function (test) { return test !== ''; }
                          )),function(item,index){return _c('td',{key:index},[_c('ul',{staticStyle:{"list-style-type":"none"}},_vm._l((item),function(text){return _c('li',{key:text,staticClass:"mb-2"},[_vm._v(" "+_vm._s(text)+" ")])}),0)])}),0),_c('tr',[_c('th',{attrs:{"rowspan":"2"}},[_vm._v("Burnout")]),_vm._l((_vm.comparisonTestAnalysisData
                            .indicators.burnout.value),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',_vm._l((_vm.comparisonTestAnalysisData.indicators.burnout.text.filter(
                            function (test) { return test !== ''; }
                          )),function(item,index){return _c('td',{key:index},[_c('ul',{staticStyle:{"list-style-type":"none"}},_vm._l((item),function(text){return _c('li',{key:text,staticClass:"mb-2"},[_vm._v(" "+_vm._s(text)+" ")])}),0)])}),0),_c('tr',[_c('th',{attrs:{"rowspan":"2"}},[_vm._v("Trauma")]),_vm._l((_vm.comparisonTestAnalysisData
                            .indicators.trauma.value),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',_vm._l((_vm.comparisonTestAnalysisData.indicators.trauma.text.filter(
                            function (test) { return test !== ''; }
                          )),function(item,index){return _c('td',{key:index},[_c('ul',{staticStyle:{"list-style-type":"none"}},_vm._l((item),function(text){return _c('li',{key:text,staticClass:"mb-2"},[_vm._v(" "+_vm._s(text)+" ")])}),0)])}),0),_c('tr',[_c('th',{attrs:{"rowspan":"2"}},[_vm._v("Impaired Immune")]),_vm._l((_vm.comparisonTestAnalysisData
                            .indicators.impaired_immune_response.value),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',_vm._l((_vm.comparisonTestAnalysisData.indicators.impaired_immune_response.text.filter(
                            function (test) { return test !== ''; }
                          )),function(item,index){return _c('td',{key:index},[_c('ul',{staticStyle:{"list-style-type":"none"}},_vm._l((item),function(text){return _c('li',{key:text,staticClass:"mb-2"},[_vm._v(" "+_vm._s(text)+" ")])}),0)])}),0),_c('tr',[_c('th',{attrs:{"rowspan":"2"}},[_vm._v("Zinc Deficiency")]),_vm._l((_vm.comparisonTestAnalysisData
                            .indicators.zinc_deficiency.value),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',_vm._l((_vm.comparisonTestAnalysisData.indicators.zinc_deficiency.text.filter(
                            function (test) { return test !== ''; }
                          )),function(item,index){return _c('td',{key:index},[_c('ul',{staticStyle:{"list-style-type":"none"}},_vm._l((item),function(text){return _c('li',{key:text,staticClass:"mb-2"},[_vm._v(" "+_vm._s(text)+" ")])}),0)])}),0),_c('tr',[_c('th',{attrs:{"rowspan":"2"}},[_vm._v("Copper Imbalance")]),_vm._l((_vm.comparisonTestAnalysisData
                            .indicators.copper_imbalance.value),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',_vm._l((_vm.comparisonTestAnalysisData.indicators.copper_imbalance.text.filter(
                            function (test) { return test !== ''; }
                          )),function(item,index){return _c('td',{key:index},[_c('ul',{staticStyle:{"list-style-type":"none"}},_vm._l((item),function(text){return _c('li',{key:text,staticClass:"mb-2"},[_vm._v(" "+_vm._s(text)+" ")])}),0)])}),0),_c('tr',[_c('th',{attrs:{"rowspan":"2"}},[_vm._v("Iron Interactions")]),_vm._l((_vm.comparisonTestAnalysisData
                            .indicators.iron_interactions.value),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',_vm._l((_vm.comparisonTestAnalysisData.indicators.iron_interactions.text.filter(
                            function (test) { return test !== ''; }
                          )),function(item,index){return _c('td',{key:index},[_c('ul',{staticStyle:{"list-style-type":"none"}},_vm._l((item),function(text){return _c('li',{key:text,staticClass:"mb-2"},[_vm._v(" "+_vm._s(text)+" ")])}),0)])}),0),_c('tr',[_c('th',{attrs:{"rowspan":"2"}},[_vm._v("Iodine Deficiency")]),_vm._l((_vm.comparisonTestAnalysisData
                            .indicators.iodine_deficiency.value),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',_vm._l((_vm.comparisonTestAnalysisData.indicators.iodine_deficiency.text.filter(
                            function (test) { return test !== ''; }
                          )),function(item,index){return _c('td',{key:index},[_c('ul',{staticStyle:{"list-style-type":"none"}},_vm._l((item),function(text){return _c('li',{key:text,staticClass:"mb-2"},[_vm._v(" "+_vm._s(text)+" ")])}),0)])}),0),_c('tr',[_c('th',{attrs:{"rowspan":"2"}},[_vm._v("Poor Eliminators")]),_vm._l((_vm.comparisonTestAnalysisData
                            .indicators.poor_eliminators.value),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',_vm._l((_vm.comparisonTestAnalysisData.indicators.poor_eliminators.text.filter(
                            function (test) { return test !== ''; }
                          )),function(item,index){return _c('td',{key:index},[_c('ul',{staticStyle:{"list-style-type":"none"}},_vm._l((item),function(text){return _c('li',{key:text,staticClass:"mb-2"},[_vm._v(" "+_vm._s(text)+" ")])}),0)])}),0),_c('tr',[_c('th',{attrs:{"rowspan":"2"}},[_vm._v("Very Poor Eliminators")]),_vm._l((_vm.comparisonTestAnalysisData
                            .indicators.very_poor_eliminators.value),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',_vm._l((_vm.comparisonTestAnalysisData.indicators.very_poor_eliminators.text.filter(
                            function (test) { return test !== ''; }
                          )),function(item,index){return _c('td',{key:index},[_c('ul',{staticStyle:{"list-style-type":"none"}},_vm._l((item),function(text){return _c('li',{key:text,staticClass:"mb-2"},[_vm._v(" "+_vm._s(text)+" ")])}),0)])}),0),_c('tr',[_c('th',{staticClass:"table-heading",attrs:{"colspan":_vm.totalCount}},[_vm._v(" Heavy Metals ")])]),_c('tr',[_c('th',[_vm._v("Heavy Metals")]),_vm._l((_vm.comparisonTestAnalysisData.heavy_metals),function(item,index){return _c('td',{key:index},[_c('ul',{staticStyle:{"list-style-type":"none"}},_vm._l((item),function(text){return _c('li',{key:text,staticClass:"mb-2"},[_vm._v(" "+_vm._s(text)+" ")])}),0)])})],2),_c('tr',[_c('th',{staticClass:"table-heading",attrs:{"colspan":_vm.totalCount}},[_vm._v(" Ratios ")])]),_c('tr',[_c('th',[_vm._v("Ca/P Autonomic Balance")]),_vm._l((_vm.comparisonTestAnalysisData
                            .ratio.ca_p),function(item,index){return _c('td',{key:index},[_c('span',{class:'ratios-' + item[1]},[_vm._v(" "+_vm._s(item[0])+" ")])])})],2),_c('tr',[_c('th',[_vm._v("Na/K Vitality")]),_vm._l((_vm.comparisonTestAnalysisData
                            .ratio.na_k),function(item,index){return _c('td',{key:index},[_c('span',{class:'ratios-' + item[1]},[_vm._v(" "+_vm._s(item[0])+" ")])])})],2),_c('tr',[_c('th',[_vm._v("Ca/K Thyroid")]),_vm._l((_vm.comparisonTestAnalysisData
                            .ratio.ca_k),function(item,index){return _c('td',{key:index},[_c('span',{class:'ratios-' + item[1]},[_vm._v(" "+_vm._s(item[0])+" ")])])})],2),_c('tr',[_c('th',[_vm._v("Zn/Cu Hormonal/Stress")]),_vm._l((_vm.comparisonTestAnalysisData
                            .ratio.zn_cu),function(item,index){return _c('td',{key:index},[_c('span',{class:'ratios-' + item[1]},[_vm._v(" "+_vm._s(item[0])+" ")])])})],2),_c('tr',[_c('th',[_vm._v("Na/Mg Adrenal")]),_vm._l((_vm.comparisonTestAnalysisData
                            .ratio.na_mg),function(item,index){return _c('td',{key:index},[_c('span',{class:'ratios-' + item[1]},[_vm._v(" "+_vm._s(item[0])+" ")])])})],2),_c('tr',[_c('th',[_vm._v("Ca/Mg Blood Sugar")]),_vm._l((_vm.comparisonTestAnalysisData
                            .ratio.ca_mg),function(item,index){return _c('td',{key:index},[_c('span',{class:'ratios-' + item[1]},[_vm._v(" "+_vm._s(item[0])+" ")])])})],2),_c('tr',[_c('th',[_vm._v("Fe/Cu Infection")]),_vm._l((_vm.comparisonTestAnalysisData
                            .ratio.fe_cu),function(item,index){return _c('td',{key:index},[_c('span',{class:'ratios-' + item[1]},[_vm._v(" "+_vm._s(item[0])+" ")])])})],2),_c('tr',[_c('th',[_vm._v("Cu/Mo Copper Imbalance")]),_vm._l((_vm.comparisonTestAnalysisData
                            .ratio.ca_mo),function(item,index){return _c('td',{key:index},[_c('span',{class:'ratios-' + item[1]},[_vm._v(" "+_vm._s(item[0])+" ")])])})],2),_c('tr',[_c('th',{staticClass:"table-heading",attrs:{"colspan":_vm.totalCount}},[_vm._v(" Minerals ")])]),_c('tr'),_c('tr',[_c('th',[_vm._v("Calcium")]),_vm._l((_vm.comparisonTestAnalysisData
                            .minerals.calcium),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',[_c('th',[_vm._v("Magnesium")]),_vm._l((_vm.comparisonTestAnalysisData
                            .minerals.magnesium),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',[_c('th',[_vm._v("Sodium")]),_vm._l((_vm.comparisonTestAnalysisData
                            .minerals.sodium),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',[_c('th',[_vm._v("Potassium")]),_vm._l((_vm.comparisonTestAnalysisData
                            .minerals.potassium),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',[_c('th',[_vm._v("Copper")]),_vm._l((_vm.comparisonTestAnalysisData
                            .minerals.copper),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',[_c('th',[_vm._v("Zinc")]),_vm._l((_vm.comparisonTestAnalysisData
                            .minerals.zinc),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',[_c('th',[_vm._v("Phosphorus")]),_vm._l((_vm.comparisonTestAnalysisData
                            .minerals.phosphorus),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',[_c('th',[_vm._v("Iron")]),_vm._l((_vm.comparisonTestAnalysisData
                            .minerals.iron),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',[_c('th',[_vm._v("Manganese")]),_vm._l((_vm.comparisonTestAnalysisData
                            .minerals.manganese),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',[_c('th',[_vm._v("Chromium")]),_vm._l((_vm.comparisonTestAnalysisData
                            .minerals.chromium),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',[_c('th',[_vm._v("Selenium")]),_vm._l((_vm.comparisonTestAnalysisData
                            .minerals.selenium),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',[_c('th',[_vm._v("Boron")]),_vm._l((_vm.comparisonTestAnalysisData
                            .minerals.boron),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',[_c('th',[_vm._v("Cobalt")]),_vm._l((_vm.comparisonTestAnalysisData
                            .minerals.cobalt),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',[_c('th',[_vm._v("Molybdenum")]),_vm._l((_vm.comparisonTestAnalysisData
                            .minerals.molybdenum),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',[_c('th',[_vm._v("Sulfur")]),_vm._l((_vm.comparisonTestAnalysisData
                            .minerals.sulfur),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',[_c('th',[_vm._v("Uranium")]),_vm._l((_vm.comparisonTestAnalysisData
                            .minerals.uranium),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',[_c('th',[_vm._v("Arsenic")]),_vm._l((_vm.comparisonTestAnalysisData
                            .minerals.arsenic),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',[_c('th',[_vm._v("Beryllium")]),_vm._l((_vm.comparisonTestAnalysisData
                            .minerals.beryllium),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',[_c('th',[_vm._v("Mercury")]),_vm._l((_vm.comparisonTestAnalysisData
                            .minerals.mercury),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',[_c('th',[_vm._v("Cadmium")]),_vm._l((_vm.comparisonTestAnalysisData
                            .minerals.cadmium),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',[_c('th',[_vm._v("Lead")]),_vm._l((_vm.comparisonTestAnalysisData
                            .minerals.lead),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',[_c('th',[_vm._v("Aluminum")]),_vm._l((_vm.comparisonTestAnalysisData
                            .minerals.aluminum),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',[_c('th',[_vm._v("Germanium")]),_vm._l((_vm.comparisonTestAnalysisData
                            .minerals.germanium),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',[_c('th',[_vm._v("Barium")]),_vm._l((_vm.comparisonTestAnalysisData
                            .minerals.barium),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',[_c('th',[_vm._v("Bismuth")]),_vm._l((_vm.comparisonTestAnalysisData
                            .minerals.bismuth),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',[_c('th',[_vm._v("Rubidium")]),_vm._l((_vm.comparisonTestAnalysisData
                            .minerals.rubidium),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',[_c('th',[_vm._v("Lithium")]),_vm._l((_vm.comparisonTestAnalysisData
                            .minerals.lithium),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',[_c('th',[_vm._v("Nickel")]),_vm._l((_vm.comparisonTestAnalysisData
                            .minerals.nickel),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',[_c('th',[_vm._v("Platinum")]),_vm._l((_vm.comparisonTestAnalysisData
                            .minerals.platinum),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',[_c('th',[_vm._v("Thallium")]),_vm._l((_vm.comparisonTestAnalysisData
                            .minerals.thallium),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',[_c('th',[_vm._v("Vanadium")]),_vm._l((_vm.comparisonTestAnalysisData
                            .minerals.vanadium),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',[_c('th',[_vm._v("Strontium")]),_vm._l((_vm.comparisonTestAnalysisData
                            .minerals.strontium),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',[_c('th',[_vm._v("Tin")]),_vm._l((_vm.comparisonTestAnalysisData
                            .minerals.tin),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',[_c('th',[_vm._v("Titanium")]),_vm._l((_vm.comparisonTestAnalysisData
                            .minerals.titanium),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',[_c('th',[_vm._v("Tungsten")]),_vm._l((_vm.comparisonTestAnalysisData
                            .minerals.tungsten),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('tr',[_c('th',[_vm._v("Zirconium")]),_vm._l((_vm.comparisonTestAnalysisData
                            .minerals.zirconium),function(item,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item)+" ")])})],2)])])])])])],1)],1)],1)],1)],1):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"loader3"},[_c('span'),_c('span')])}]

export { render, staticRenderFns }