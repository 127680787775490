<template>
  <div class="col-lg-12 pt_35">
    <div class="accorodion_wrapper" id="accordian">
      <!-- accordian 1 -->
      <div class="bg-white accoridian_bg border">
        <h3 class="mt_20 heading">Anchors or “No change” Patterns</h3>
        <div class="collapse show" id="idcollapseOne">
          <div class="accorodian_content accorodian_content2" id="RO_K2_K">
            <!-- <span style="display:flex;"> <h4>Anchor Number: </h4> &nbsp; <p>{{comparisonTestAnalysis.anchor_or_no_change[0]}}</p> </span> -->
            <ul>
              <h4 style="font-size: 20px">
                Anchor Indicators:
                {{ comparisonTestAnalysis.anchor_or_no_change[0] }}
              </h4>
              <li
                class="pt_5"
                v-for="value in comparisonTestAnalysis.anchor_or_no_change[1]"
                :key="value.index"
              >
                {{ value }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AnchorPatterns",
  components: {},
  props: ["comparisonTestAnalysis"],

  data() {
    return {};
  },

  mounted() {},
};
</script>
<style scoped>
.heading {
  font-weight: bold;
  color: #884c42;
  padding: 5px;
  margin-left: 20px;
  padding-top: 20px;
  font-family: "Inter", sans-serif;
}
</style>
