<template>
  <div>
    <br />
    <div class="accoridian_bg mt_35 graph_box">
      <h2 class="mt-5 mx-2 pt-2 mb-2">Client Report Generator</h2>
      <div class="">
        <div class="row mb-4">
          <div class="col-lg-8 mx-auto w-100">
            <div class="pl-1 pb-2 pt-3 font-weight-bold">Find Client Name</div>
            <model-list-select
              :list="clientNameData"
              v-model="clientName"
              option-value="id"
              option-text="client_name"
              placeholder="Search Client Name"
            >
            </model-list-select>
            <div class="pl-1 pb-2 pt-3 font-weight-bold">Find Date</div>
            <model-list-select
              :list="htmaDatesData"
              v-model="htmaDate"
              option-value="id"
              option-text="htma_test_date"
              placeholder="Search HTMA Date"
            >
            </model-list-select>
          </div>
        </div>

        <div class="row">
          <div
            class="col-lg-8 mx-auto"
            v-if="gisInited && gapiInited"
          >
          <p class="mb-2">To generate the editable client report, you must sign in with Google below.</p>
            <button @click="generateClientReportDocx()" :disabled="loading" class="btn d-flex align-items-center mb-3" style="height:46px;padding: 0px;">
               <img  :src="require('@/assets/images/btn-google.png')" height="46px"/>
            </button>
            <!-- <button @click="generateClientReportWordDocx()" class="menu_item submit-button">
              Generate Client Report Docx
            </button> -->
            <!-- <button
              @click="generateClientReport()"
              class="menu_item submit-button h-100 mb-3"
              style="height:46px !important"
              :disabled="loading"
            >
              Generate Client Report
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import { ModelListSelect } from "vue-search-select";

import generateDOC from "../../services/generateDOC";

export default {
  components: {
    ModelListSelect,
  },
  name: "ClientReportGenerator",
  data() {
    return {
      clientName: "",
      htmaDate: "",
      inputId: null,
      inputData: [],
      clientNameData: [],
      htmaDatesData: [],
      searchData: [],

      allInputHtmaData: [],
      clientReportData: [],

      googleDocsLink:'',
      loading:false,
      tokenClient:null,
      gisInited: false,
      gapiInited:false
    };
  },
  computed: {
    ...mapGetters([
      // "allInput",
      "allInputData",
      "allInputHtma",
      "clientReport",
      "clientReportDocx",
    ]),
  },
  watch: {
    clientName(value) {
      console.log("value", value);
      this.htmaDatesData = this.inputData.filter(
        (data) =>
          data.client_name ===
          this.inputData.find((x) => x.id === value).client_name
      );
      console.log("data.htma_test_date");
      this.htmaDatesData.map((data) => {
        data.htma_test_date = moment(data.htma_test_date).format("MM/DD/YYYY");
      });
      console.log("htmaDatesData", this.htmaDatesData);
    },
  },
  async mounted() {
    // await this.getAllInput();
    // this.inputData = await this.allInput;
    await this.getInputData();
    this.$nextTick(() => {
        window.gapi.load('client', ()=>{
          window.gapi.client.init({
          apiKey: 'AIzaSyBK8SaJ2IyQcXtExKOcqUQFrIdXuJFVB0M',
          discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'],
        }).then(()=>{
          this.gapiInited = true;
        })
        });
        this.tokenClient = window.google.accounts.oauth2.initTokenClient({
          client_id: '862241483599-o9el4g83m01dutkef9kecf0ka5mos8b4.apps.googleusercontent.com',
          scope: 'https://www.googleapis.com/auth/drive.file',
          callback: async (resp)=>{
            if(resp?.access_token){
            this.$toastr.Add({
          name: "Success", // this is give you ability to use removeByName method
          msg: "Google Doc is being generated in a new tab. Please ensure you do not have a pop-up blocker!", // Toast Message
          type: "success", // Toast type,
        });
        this.loading = true;
      this.inputId = this.allInputHtmaData.find((data) => {
        return data.input == this.htmaDate;
      });
      console.log("input id searched is ", this.inputId.id);
      console.log({access_token:resp.access_token})
      await this.getClientReportDocx({id:this.inputId.id,access_token:resp.access_token});
      this.googleDocsLink = await this.clientReportDocx;

      console.log("this.clientReportDataDocx", this.googleDocsLink);
      if(this.googleDocsLink){
        this.loading = false;
      }
      console.log('link is', this.googleDocsLink.link.webViewLink)
      setTimeout(() => {
      window.open(this.googleDocsLink.link.webViewLink,'_blank');
      },1)
    }
          }, // defined later
        });
        this.gisInited = true;
      })
    this.inputData = await this.allInputData;

    this.clientNameData = this.inputData.filter(
      (a, i) =>
        this.inputData.findIndex((s) => a.client_name === s.client_name) === i
    );
    console.log("all inputs are ", this.inputData);
    console.log("all client names are ", this.clientNameData);

    await this.getAllInputHtma();
    this.allInputHtmaData = await this.allInputHtma;
    console.log("input-htma", this.allInputHtmaData);
  },
  methods: {
    ...mapActions([
      // "getAllInput",
      "getInputData",
      "getAllInputHtma",
      "getClientReport",
      "getClientReportDocx",
    ]),
    reset() {
      this.item = {};
    },

    generateClientReport() {
      console.log("submit");
      console.log("htmadata id ", this.htmaDate);
      this.inputId = this.allInputHtmaData.find((data) => {
        return data.input == this.htmaDate;
      });
      console.log("input id searched is ", this.inputId.id);

      this.$router.push(`/client-report/${this.inputId.id}`);
    },

    async generateClientReportDocx() {
      // need to make a condition as client name and date selected
    if (!this.clientName || !this.htmaDate) {
        this.$toastr.Add({
          name: "Error", // this is give you ability to use removeByName method
          msg: "Please Select Client Name and HTMA Data first!", // Toast Message
          type: "error", // Toast type,
        });
      } else {
        let testingVar = window.gapi.client.getToken()
           if (testingVar === null) {
          // Prompt the user to select a Google Account and ask for consent to share their data
          // when establishing a new session.
          this.tokenClient.requestAccessToken({prompt: 'consent'})
        } else {
          // Skip display of account chooser and consent dialog for an existing session.
          this.tokenClient.requestAccessToken({prompt: ''})
        }
      console.log("htmadata id ", this.htmaDate);
     
  }
    },

    async generateClientReportWordDocx() {
      if (!this.clientName || !this.htmaDate) {
        this.$toastr.Add({
          name: "Error", // this is give you ability to use removeByName method
          msg: "Please Select Client Name and HTMA Data first!", // Toast Message
          type: "error", // Toast type,
        });
        console.log("enter client name");
      } else {
        console.log("clientname", this.clientName);
        console.log("date", this.htmaDate);
        console.log("htmadata id ", this.htmaDate);
        this.inputId = this.allInputHtmaData.find((data) => {
          return data.input == this.htmaDate;
        });
        console.log("input id searched is ", this.inputId.id);
        await this.getClientReport(this.inputId.id);
        this.clientReportData = await this.clientReport;
        console.log("this.clientReportData", this.clientReportData);
        console.log("clientReportDocx");
        generateDOC(this.clientReportData);
      }
    },

    onNext() {
      this.$emit("nextStep");
      window.scrollTo(0, 0);
    },
    onBack() {
      this.$emit("backStep");
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style>
.submit-box {
  display: flex;
  /* justify-content: flex-end !important; */
  justify-content: space-between !important;
  padding-right: 20px;
}

.submit-button {
  width: 250px;
}

.no-button {
  color: transparent !important;
  background-color: transparent !important;
  border: transparent !important;
  cursor: auto !important;
}
.google-btn{
  background: #4285F4;
    padding: 0px 16px 0px 0px !important;
    color:white !important;
    font-weight: 700;
}
</style>