<template>
  <div id="">
    <div v-if="loading" id="preloader">
      <div class="loader3">
        <span></span>
        <span></span>
      </div>
    </div>
    <main>
      <div class="main_content_wrapper">
        <br />
        <h2 class="mx-2 pt-2 mb-2 text-center">
          HTMA Analysis for
          {{ singleTestAnalysisData.client_name }}
        </h2>
        <h3 class="mx-2 pt-2 mb-2 text-center">
          Prepared by {{ singleTestAnalysisData.practitioner_name }} for results
          from
          {{ dateValue }}
        </h3>
        <hr />
        <div class="container">
          <div class="row">
            <div class="col-lg-12 pt_20">
              <indicators
                v-if="singleTestAnalysisData"
                :singleTestAnalysisData="singleTestAnalysisData"
              />
            </div>
          </div>
          <div class="pg_break" style="break-after: always"></div>
          <div class="row">
            <div class="col-lg-12 pt_40">
              <div class="accorodion_wrapper" id="accordian">
                <heavy-metals
                  :singleTestAnalysisData="singleTestAnalysisData"
                />
                <br />
                <consideration
                  v-if="
                    singleTestAnalysisData &&
                    singleTestAnalysisData.consideration &&
                    singleTestAnalysisData.consideration.length
                  "
                  :singleTestAnalysisData="singleTestAnalysisData"
                />
                <br />
                <metabolic-patterns
                  v-if="
                    singleTestAnalysisData &&
                    singleTestAnalysisData.metabolic_pattern &&
                    singleTestAnalysisData.metabolic_pattern.length
                  "
                  :singleTestAnalysisData="
                    singleTestAnalysisData.metabolic_pattern
                  "
                />
                <br />
                <stress-patterns
                  v-if="
                    singleTestAnalysisData &&
                    singleTestAnalysisData.stress_pattern &&
                    singleTestAnalysisData.stress_pattern.length
                  "
                  :singleTestAnalysisData="
                    singleTestAnalysisData.stress_pattern
                  "
                />
                <br />
                <psychological-patterns
                  v-if="
                    singleTestAnalysisData &&
                    singleTestAnalysisData.psychological_patterns &&
                    singleTestAnalysisData.psychological_patterns.length
                  "
                  :singleTestAnalysisData="
                    singleTestAnalysisData.psychological_patterns
                  "
                />
                <!-- <br />
                <div class="bg-white accoridian_bg border" v-if="naqHtmaShow">
                  <h3 class="mt_20 heading">NAQ + HTMA</h3>
                  <div class="collapse show" id="idcollapseFive">
                    <div
                      class="accorodian_content accorodian_content2"
                      id="SO_Q2_R"
                    >
                      <div
                        class="graph_box"
                        v-if="naqGraphData && naqGraphData.naq_graph_values"
                      >
                        <h3>Symptom Burden Graph</h3>
                        <div class="graph_img">
                          <bar-chart
                            v-if="chartIsLoaded"
                            :chartdata="chartdata"
                            :options="chartOptions"
                          />
                        </div>
                      </div>
                      <naq-htma
                        v-if="naqHtmaReportData"
                        :singleTestAnalysisData="naqHtmaReportData"
                      />
                    </div>
                  </div>
                </div>
                <br />
                <conditions
                  v-if="
                    conditionReportData &&
                    conditionReportData.condition_response.length
                  "
                  :singleTestAnalysisData="
                    conditionReportData.condition_response
                  "
                />-->
              </div> 
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
// import BarChart from "../../components/Chart/BarChart";
import Indicators from "../../components/SingleTestView/Indicators.vue";
import Consideration from "../../components/SingleTestView/Consideration.vue";
import MetabolicPatterns from "../../components/SingleTestView/MetabolicPatterns.vue";
// import NaqHtma from "../../components/SingleTestView/NaqHtma.vue";
import PsychologicalPatterns from "../../components/SingleTestView/PsychologicalPatterns.vue";
import StressPatterns from "../../components/SingleTestView/StressPatterns.vue";
// import Conditions from "../../components/SingleTestView/Conditions.vue";
import HeavyMetals from "../../components/SingleTestView/HeavyMetals.vue";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "SingleTest",
  components: {
    // BarChart,
    Indicators,
    MetabolicPatterns,
    Consideration,
    // NaqHtma,
    PsychologicalPatterns,
    StressPatterns,
    // Conditions,
    HeavyMetals,
  },

  data() {
    return {
      loading: true,
      // naqGraphLabel: [],
      // naqGraphValues: [],
      // chartIsLoaded: false,
      // chartdata: {
      //   labels: [],
      //   datasets: [
      //     {
      //       backgroundColor: "#884C42",
      //       borderWidth: 2,
      //       borderRadius: 5,
      //       data: [],
      //     },
      //   ],
      // },

      // sysBurdenLabels: [
      //   // "",
      //   "High priority",
      //   "Moderate Priority",
      //   "Low Priority",
      //   "",
      // ],
      // chartOptions: {
      //   scales: {
      //     yAxes: [
      //       {
      //         ticks: {
      //           callback: (value, index) => {
      //             return this.sysBurdenLabels[index];
      //           },
      //           min: 0,
      //           max: 0.4,
      //           stepSize: 0.166667,
      //         },
      //         // ticks: {
      //         //   beginAtZero: true,
      //         // },
      //         gridLines: {
      //           display: true,
      //         },
      //       },
      //     ],
      //     xAxes: [
      //       {
      //         ticks: {
      //           beginAtZero: true,
      //         },
      //         gridLines: {
      //           display: false,
      //         },
      //       },
      //     ],
      //   },
      //   legend: {
      //     display: false,
      //   },
      //   tooltips: {
      //     enabled: true,
      //     mode: "single",
      //     callbacks: {
      //       label: function (tooltipItems) {
      //         return tooltipItems.yLabel;
      //       },
      //     },
      //   },
      //   responsive: true,
      //   maintainAspectRatio: false,
      //   height: 200,
      // },
      singleTestAnalysisData: {},
      // naqHtmaReportData: {},
      // conditionReportData: {},
      // naqGraphData: {},
      testId: null,
      // naqId: null,
      // naqHtmaId: null,
      // conditionId: null,
    };
  },
  watch: {},

  computed: {
    ...mapGetters([
      "singleTestAnalysis",
      // "naqHtmaReport",
      // "conditionReport",
      // "naqGraph",
    ]),
    dateValue() {
      var date = new Date(this.singleTestAnalysisData.date);
      return (
        (date.getMonth() > 8
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1)) +
        "/" +
        (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
        "/" +
        date.getFullYear()
      );
    },
    // naqHtmaShow() {
    //   const show = !!(
    //     this.naqHtmaReportData.naqhtma_response_uppergi.length ||
    //     this.naqHtmaReportData.naqhtma_diet.length ||
    //     this.naqHtmaReportData.naqhtma_liver.length ||
    //     this.naqHtmaReportData.naqhtma_small_intestine.length ||
    //     this.naqHtmaReportData.naqhtma_large_intestine.length ||
    //     this.naqHtmaReportData.naqhtma_mineral_need.length ||
    //     this.naqHtmaReportData.naqhtma_essential_fatty_acid.length ||
    //     this.naqHtmaReportData.naqhtma_sugar_handling.length ||
    //     this.naqHtmaReportData.naqhtma_vitamin_need.length ||
    //     this.naqHtmaReportData.naqhtma_ardenal.length ||
    //     this.naqHtmaReportData.naqhtma_pituitary.length ||
    //     this.naqHtmaReportData.naqhtma_thyroid.length ||
    //     this.naqHtmaReportData.naqhtma_cardio.length ||
    //     this.naqHtmaReportData.naqhtma_kidney_bladder.length ||
    //     this.naqHtmaReportData.naqhtma_immune_system.length ||
    //     this.naqHtmaReportData.naqhtma_women_only.length ||
    //     this.naqHtmaReportData.naqhtma_men_only.length ||
    //     this.naqGraphData.naq_graph_values
    //   );

    //   console.log("show", show);
    //   return show;
    // },
  },
  methods: {
    ...mapActions([
      "getSingleTestAnalysis",
      // "getNaqHtmaReport",
      // "getConditionReport",
      // "getNaqGraph",

      "getSingleTestAnalysisUnsaved",
      // "getNaqGraphUnsaved",
      // "getNaqHtmaReportUnsaved",
      // "getConditionReportUnsaved",
    ]),
    showLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
  },

  async mounted() {
    console.log("singletest anal page");
    this.testId = this.$route.query.test;
    console.log(this.$route.query.test);
    // this.naqId = this.$route.query.naq;
    // this.naqHtmaId = this.$route.query.naqhtma;
    // this.conditionId = this.$route.query.condition;

    if (this.testId != 0) {
      await this.getSingleTestAnalysis(this.testId);
    } else {
      const input = JSON.parse(sessionStorage.getItem("input"));
      const htma = JSON.parse(sessionStorage.getItem("htma"));
      Object.keys(htma).map(function (key) {
        htma[key] = parseFloat(htma[key]);
      });

      // let newHtma = htma.map((v)=>{
      //   return v = parseFloat(v)
      // })
      // console.log('newhtma',newHtma)
      console.log("input raw data", input);
      console.log("htma raw data", htma);
      await this.getSingleTestAnalysisUnsaved({
        input: input,
        htma: htma,
      });
    }
    // if (this.naqHtmaId != 0) {
    //   await this.getNaqHtmaReport(this.naqHtmaId);
    // } else {
    //   const naqHtma = JSON.parse(sessionStorage.getItem("naqHtma"));
    //   console.log("naqhtma raw data", naqHtma);
    //   await this.getNaqHtmaReportUnsaved(naqHtma);
    // }
    // if (this.conditionId != 0) {
    //   console.log("condition is", this.conditionId);
    //   await this.getConditionReport(this.conditionId);
    // } else {
    //   const condition = JSON.parse(sessionStorage.getItem("condition"));
    //   console.log("condition raw data", condition);

    //   await this.getConditionReportUnsaved(condition);
    // }
    // if (this.naqId != 0) {
    //   await this.getNaqGraph(this.naqId);
    // } else {
    //   const naq = JSON.parse(sessionStorage.getItem("naq"));
    //   console.log("naq raw data", naq);
    //   await this.getNaqGraphUnsaved(naq);
    // }
this.singleTestAnalysisData = await this.singleTestAnalysis;
    // [
    //   this.singleTestAnalysisData,
    //   this.naqHtmaReportData,
    //   this.conditionReportData,
    //   this.naqGraphData,
    // ] = await Promise.all([
    //   this.singleTestAnalysis,
    //   this.naqHtmaReport,
    //   this.conditionReport,
    //   this.naqGraph,
    // ]);
    console.log("single test analysis data", this.singleTestAnalysisData);
    console.log("naqhtma data", this.naqHtmaReportData);
    // console.log("condition analysis data", this.conditionReportData);
    // console.log("naq graph analysis data", this.naqGraphData);
    // console.log("naq graph analysis data", this.naqGraphData.naq_graph_labels);
    localStorage.setItem(
      "instant_clientname",
      `${this.singleTestAnalysisData.client_name}`
    );
    // this.chartdata.labels = this.naqGraphData.naq_graph_labels;
    // this.chartdata.datasets[0].data = this.naqGraphData.naq_graph_values;
    // this.chartIsLoaded = true;
    this.showLoading();
  },
};
</script>

<style scoped>
.box {
  display: flex;
  justify-content: flex-end !important;
}
.menu_item_new {
  background-color: #884c42;
  padding: -19px 10px !important;
  color: #ffffff !important;
  border-radius: 10px;
  transition: 0.4s;
  margin: 5px;
}
.middle_content_box {
  height: 100%;
}
.heading {
  font-weight: bold;
  color: #884c42;
  padding: 5px;
  margin-left: 20px;
  padding-top: 20px;
  font-family: "Inter", sans-serif;
}
.pg_break {
  display: none;
}
</style>
