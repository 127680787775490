<template>
  <div class="body">
    <div class="container">
      <div class="row">
        <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
          <div class="card border-0 shadow rounded-3 my-8">
            <div class="card-body p-4 p-sm-5">
              <h5 class="card-title text-center mb-5 fw-light fs-5">Sign Up</h5>
              <form @submit.prevent="onRegister">
                <div class="form-floating mb-3">
                  <label for="floatingInput">First name</label>
                  <input
                    type="text"
                    class="form-control"
                    id="floatingInput"
                    placeholder="First name"
                    v-model="user.first_name"
                    required
                  />
                </div>
                <div class="form-floating mb-3">
                  <label for="floatingInput">Last name</label>
                  <input
                    type="text"
                    class="form-control"
                    id="floatingInput"
                    placeholder="Last name"
                    v-model="user.last_name"
                    required
                  />
                </div>
                <div class="form-floating mb-3">
                  <label for="floatingInput">Email address</label>
                  <input
                    type="email"
                    class="form-control"
                    id="floatingInput"
                    placeholder="name@example.com"
                    v-model="user.email"
                    required
                  />
                </div>
                <div class="form-floating mb-3">
                  <label for="floatingPassword">Password</label>
                  <input
                    type="password"
                    class="form-control"
                    id="floatingPassword"
                    placeholder="Password"
                    v-model="user.password1"
                    required
                  />
                </div>
                <div class="form-floating mb-3">
                  <label for="floatingPassword2">Confirm password</label>
                  <input
                    type="password"
                    class="form-control"
                    id="floatingPassword2"
                    placeholder="Password"
                    v-model="user.password2"
                    required
                  />
                </div>
                <div class="d-grid text-center">
                  <button
                    class="btn btn-login text-uppercase fw-bold"
                    type="submit"
                  >
                    Sign Up
                  </button>
                </div>
                <br />
                <div class="d-grid text-center">
                  Already have account?
                  <router-link to="/login" class="text-dark form-check-label"
                    >Login</router-link
                  >
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapActions,
  // mapGetters
} from "vuex";

export default {
  name: "Register",
  data() {
    return {
      user: {
        first_name: null,
        last_name: null,
        email: null,
        password1: null,
        password2: null,
      },
    };
  },
  computed: {
    // ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["registerUser","setLifeTimeSubscribed"]),

    async onRegister() {
      try {
        const user = await this.registerUser(this.user);
        this.setLifeTimeSubscribed(user.lifetime_subscribed)
        this.$toastr.Add({
          name: "Success", // this is give you ability to use removeByName method
          msg: "You have Registered Successfully!", // Toast Message
          type: "success", // Toast type,
        });
        this.$router.push("/htma-results-input");
      } catch (error) {
        console.log(error);
        this.$toastr.Add({
          name: "Error", // this is give you ability to use removeByName method
          msg: error, // Toast Message
          type: "error", // Toast type,
        });
      }
    },
  },
  mounted() {
    if (localStorage.getItem("instant_token")) {
      this.$router.push("/htma-results-input");
    }
  },
};
</script>
<style>
body {
  background: #fafafa;
  background: linear-gradient(to right, #f7f0f0, #fafafa);
}

.btn-login {
  font-size: 0.9rem;
  letter-spacing: 0.05rem;
  padding: 0.75rem 1rem;
  color: white !important;
  background-color: #884c42 !important;
  border-radius: 10px;
}
</style>
