import { getReq, postReq, deleteReq, putReq } from "../../../services/api";

const state = {
  allNaq:[],
  naqGraph:{},
  singleNaq:[],
};

const getters = {
  allNaq: (state) => state.allNaq,
  naqGraph: (state) => state.naqGraph,
  singleNaq: (state) => state.singleNaq,
};


const actions = {
  
  // Naq 

  async getAllNaq({ commit },) {
    try {
      let allNaq = await getReq(`naq/`);
      commit("setAllNaq", allNaq);
      
    } catch (error) {
      console.log(error)
      console.log(error.response);
      throw error.response;
    }
  },

  async getSingleNaq({ commit },id) {
    try {
      let singleNaq = await getReq(`naq/${id}/`);
      commit("setSingleNaq", singleNaq);
      
    } catch (error) {
      console.log(error)
      console.log(error.response);
      throw error.response;
    }
  },

  
   async addNaq(_,payload) {
     
    try {
       const newNaq = await postReq(`naq/`,payload );
       return newNaq
     
    } catch (error) {
      console.log(error.response.data);
      throw error.response.data;
    }
  },
  
  // async updateNaq({ commit }, { id, payload }) {
  //   console.log(commit)
  //   try {
  //     const updatedData = await patchReq("naq/", id, payload);
  //     if (updatedData) {
  //         console.log(updatedData);
  //     }
  //   } catch (e) {
  //       console.log(e)
  //       if(!e.response){
  //           throw "No network connection"
  //       }
  //       const errors = e.response.data;
  //       console.log(errors)
  //       throw errors;
  //   }
  // },

  async updateNaq({ commit }, { id, payload }) {
    console.log(commit)
    try {
      const updatedData = await putReq("naq/", id, payload);
      if (updatedData) {
          console.log(updatedData);
      }
      return updatedData
    } catch (e) {
        console.log(e)
        if(!e.response){
            throw "No network connection"
        }
        const errors = e.response.data;
        console.log(errors)
        throw errors;
    }
  },
  
  async deleteNaq(_, id) {
    try {
        return await deleteReq("naq", id);
    } catch (e) {
        if(!e.response){
            throw "No network connection"
        }
        const errors = e.response.data;
        console.log(errors)
        throw errors;
    }
  },

  // NAQ Graph Unsaved Api

  async getNaqGraphUnsaved({commit},payload) {
    try {
       const naqGraph = await postReq(`naq-unsaved/`,payload );
      commit("setNaqGraph", naqGraph);
       return naqGraph 
     
    } catch (error) {
      console.log(error.response.data);
      throw error.response.data;
    }
  },

  // Naq Graph

  async getNaqGraph({ commit }, id) {
    try {
      let naqGraph = await getReq(`naq-graph/${id}/`);
      commit("setNaqGraph", naqGraph);

    } catch (error) {
      console.log(error)
      console.log(error.response);
      throw error.response;
    }
  },

};

const mutations = {
  setAllNaq: (state, allNaq) => (state.allNaq = allNaq),
  setNaqGraph: (state, naqGraph) => (state.naqGraph = naqGraph),

  setSingleNaq: (state, singleNaq) => (state.singleNaq = singleNaq),

};

export default {
  state,
  getters,
  actions,
  mutations,
};
