import axios from "axios";
import router from "../router";
// axios.defaults.baseURL = "http://127.0.0.1:8000/";
axios.defaults.baseURL = "https://api.instanthtma.com/";
const setHeaders = () => {
  axios.defaults.headers.post["Content-Type"] =
    "multipart/form-data";
    // "application/json;charset=utf-8";
  if (localStorage.getItem("instant_token")) {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Token ${localStorage.getItem("instant_token")}`;

  }
};

export const getReq = async (enpoint) => {
  setHeaders();
  return await axios
    .get(`${enpoint}`)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      console.log(error)
      throw error;
    });
};

export const getById = async (enpoint, id) => {
  setHeaders();
  return await axios
    .get(`${enpoint}${id}/`)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      if (error.response.status==401){
        localStorage.removeItem('instant_token')
        router.push('/login')
        throw 'Your License expired'
      }
      throw error;
    });
};

export const postReq = async (enpoint, payload) => {
  setHeaders();
  return await axios
    .post(`${enpoint}`, payload)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      if (error.response.status==401){
        localStorage.removeItem('instant_token')
        router.push('/login')
        throw 'Your License expired'
      }
      throw error;
    });
};

export const putReq = async (enpoint, id, payload) => {
  setHeaders();
  return await axios
    .put(`${enpoint}${id}/`, payload)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      if (error.response.status==401){
        localStorage.removeItem('instant_token')
        router.push('/login')
        throw 'Your License expired'
      }
      throw error;
    });
};

export const patchReq = async (enpoint, id, payload) => {
  setHeaders();
  return await axios
    .patch(`${enpoint}/${id}/`, payload)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      if (error.response.status==401){
        localStorage.removeItem('instant_token')
        router.push('/login')
        throw 'Your License expired'
      }
      throw error;
    });
};
export const patchStripeReq = async (enpoint, payload) => {
  setHeaders();
  return await axios
    .patch(`${enpoint}`, payload)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      if (error.response.status==401){
        localStorage.removeItem('instant_token')
        router.push('/login')
        throw 'Your License expired'
      }
      throw error;
    });
};
export const putWithoutIdReq = async (enpoint, payload) => {
  setHeaders();
  return await axios
    .put(`${enpoint}`, payload)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      if (error.response.status==401){
        localStorage.removeItem('instant_token')
        router.push('/login')
        throw 'Your License expired'
      }
      throw error;
    });
};
export const postFormData = async (enpoint, formData) => {
  setHeaders();
  axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
  return await axios
    .post(`${enpoint}/`, formData)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      if (error.response.status==401){
        localStorage.removeItem('instant_token')
        router.push('/login')
        throw 'Your License expired'
      }
      throw error;
    });
};

export const deleteReq = async (enpoint, id) => {
  setHeaders();
  return await axios
    .delete(`${enpoint}/${id}/`)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      if (error.response.status==401){
        localStorage.removeItem('instant_token')
        router.push('/login')
        throw 'Your License expired'
      }
      console.log(error);
      throw error;
    });
};
