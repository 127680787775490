var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"d-flex align-items-center",attrs:{"id":"header-print"}},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"header_main"},[_c('router-link',{staticClass:"logo_text logo_text4",attrs:{"to":"/"}},[_vm._v("Instant HTMA Professional")]),_c('ul',{attrs:{"id":"menu"}},[(
            _vm.$route.name === 'SingleTestAnalysis' ||
            _vm.$route.name === 'HTMAAnalysis' ||
            _vm.$route.name === 'ComparisonTestAnalysis' ||
            _vm.$route.name === 'MetabolicBlueprintResult' ||
            _vm.$route.name === 'BloodMarkersIron' ||
            _vm.$route.name === 'BloodMarkersIodine' ||
            _vm.$route.name === 'BloodMarkersCopper' ||
            _vm.$route.name === 'NAQSymptomsOutput' ||
            _vm.$route.name === 'ConditionOutput' ||
            _vm.$route.name === 'ComparisonReport' ||
            _vm.$route.name === 'ClientReport' ||
            _vm.$route.name === 'ComparisonList'
          )?_c('li',{attrs:{"id":"print-pdf"}},[_c('a',{staticClass:"menu_item",on:{"click":function($event){return _vm.print()}}},[_vm._v("Print to PDF")])]):_vm._e(),(_vm.$route.name !== 'Login' && _vm.$route.name !== 'Register' && _vm.$route.name !== 'ForgotPassword' && _vm.$route.name !== 'PrivacyPolicy' && _vm.$route.name !== 'Home' && _vm.$route.name !== 'ResetPassword')?_c('li',[_c('a',{staticClass:"menu_item",attrs:{"target":"_blank","href":"https://www.learnhtma.com/apptroubleshooting/"}},[_vm._v("Help")])]):_vm._e(),(_vm.$route.name !== 'Login' && _vm.$route.name !== 'Register' && _vm.$route.name !== 'ForgotPassword' && _vm.$route.name !== 'PrivacyPolicy' && _vm.$route.name !== 'Home' && _vm.$route.name !== 'ResetPassword')?_c('li',[_c('a',{staticClass:"menu_item",on:{"click":function($event){return _vm.onLogout()}}},[_vm._v("Logout")])]):_vm._e(),((_vm.$route.name === 'PrivacyPolicy' && !_vm.userLogin) || _vm.$route.name === 'Home')?_c('li',[_c('router-link',{staticClass:"menu_item",attrs:{"tag":"a","to":"/login"}},[_vm._v("Login")])],1):_vm._e(),(_vm.$route.name === 'PrivacyPolicy' && _vm.userLogin)?_c('li',[_c('router-link',{staticClass:"menu_item",attrs:{"tag":"a","to":"/"}},[_vm._v("Go to App")])],1):_vm._e(),(_vm.$route.name === 'Login' || _vm.$route.name === 'Register' || _vm.$route.name === 'ForgotPassword' || _vm.$route.name === 'PrivacyPolicy' || _vm.$route.name === 'Home' || _vm.$route.name === 'ResetPassword')?_c('li',[_c('router-link',{staticClass:"menu_item",attrs:{"tag":"a","to":"/privacy-policy"}},[_vm._v("Privacy Policy")])],1):_vm._e()]),_vm._m(0),_c('div',{staticClass:"ofcavas-menu"},[_c('ul',[(
             _vm.$route.name === 'SingleTestAnalysis' ||
            _vm.$route.name === 'HTMAAnalysis' ||
            _vm.$route.name === 'ComparisonTestAnalysis' ||
            _vm.$route.name === 'MetabolicBlueprintResult' ||
            _vm.$route.name === 'BloodMarkersIron' ||
            _vm.$route.name === 'BloodMarkersIodine' ||
            _vm.$route.name === 'BloodMarkersCopper' ||
            _vm.$route.name === 'NAQSymptomsOutput' ||
            _vm.$route.name === 'ConditionOutput' ||
            _vm.$route.name === 'ComparisonReport' ||
            _vm.$route.name === 'ClientReport' ||
            _vm.$route.name === 'ComparisonList'
            )?_c('li',[_c('a',{staticClass:"menu_item",on:{"click":function($event){return _vm.print()}}},[_vm._v("Print to PDF")])]):_vm._e(),_vm._m(1),(_vm.$route.name !== 'Login' && _vm.$route.name !== 'Register' && _vm.$route.name !== 'ForgotPassword' && _vm.$route.name !== 'PrivacyPolicy' && _vm.$route.name !== 'Home' && _vm.$route.name !== 'ResetPassword')?_c('li',[_c('a',{staticClass:"menu_item",on:{"click":function($event){return _vm.onLogout()}}},[_vm._v("Logout")])]):_vm._e(),(_vm.$route.name === 'PrivacyPolicy' || _vm.$route.name === 'Home')?_c('li',[_c('router-link',{staticClass:"menu_item",attrs:{"tag":"a","to":"/login"}},[_vm._v("Login")])],1):_vm._e(),(_vm.$route.name === 'Login' || _vm.$route.name === 'Register' || _vm.$route.name === 'ForgotPassword' || _vm.$route.name === 'PrivacyPolicy' || _vm.$route.name === 'Home' || _vm.$route.name === 'ResetPassword')?_c('li',[_c('router-link',{staticClass:"menu_item",attrs:{"tag":"a","to":"/privacy-policy"}},[_vm._v("Privacy Policy")])],1):_vm._e()])])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hamburger-menu right_clear"},[_c('span',{staticClass:"line-top"}),_c('span',{staticClass:"line-center"}),_c('span',{staticClass:"line-bottom"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_c('a',{staticClass:"menu_item",attrs:{"target":"_blank","href":"https://www.learnhtma.com/apptroubleshooting/"}},[_vm._v("Help")])])}]

export { render, staticRenderFns }