import { getReq, postReq, patchReq,patchStripeReq, putWithoutIdReq } from "../../../services/api";

const state = {
  user: null,
  currentUserSubscription: null,
  lifeTimeSubscribed: localStorage.getItem("lifetime_subscribed") && localStorage.getItem("lifetime_subscribed") === "true" ? true : false || false
};

const getters = {
  user: (state) => state.user,
  currentUserSubscription:(state)=>state.currentUserSubscription,
  lifeTimeSubscribed: (state)=>state.lifeTimeSubscribed
};

const actions = {
  async registerUser(_, user) {
    try {
      const response = await postReq("rest-auth/registration/", user);
      if (response.key) {
        localStorage.setItem("instant_token", response.key);
        // localStorage.setItem("has_subscribed",response.has_subscribed)
        localStorage.setItem("lifetime_subscribed",response.lifetime_subscribed)
        return response;
      }
    } catch (e) {
      console.log(e)
      if (!e.response) {
        throw "No network connection"
      }
      if (e.response.status == 500) {

        throw "Internal server error"
      }
      const errors = e.response.data;
      const error = [];
      for (const key in errors) {
        if (Object.hasOwnProperty.call(errors, key)) {
          error.push(errors[key]);
        }
      }

      throw error[0][0];
    }
  },
  async loginUser(_, user) {

    try {
      const response = await postReq("rest-auth/login/", user);
      if (response.key) {
        localStorage.setItem("instant_token", response.key);
        // localStorage.setItem("has_subscribed",response.has_subscribed)
        localStorage.setItem("lifetime_subscribed",response.lifetime_subscribed)
        return response;
      }
    } catch (e) {
      const errors = e.response.data;

      const error = [];
      for (const key in errors) {
        if (Object.hasOwnProperty.call(errors, key)) {
          error.push(errors[key]);
        }
      }

      throw error[0][0];
    }
  },
  async resetPassword(_, email) {
    try {
      const form = new FormData();
      form.append("email", email)
      const response = await postReq("rest-auth/password/reset/", form);
      console.log(response);
      return response;
    } catch (e) {
      const errors = e.response.data;
      const error = [];
      for (const key in errors) {
        if (Object.hasOwnProperty.call(errors, key)) {
          error.push(errors[key]);
        }
      }
      throw error[0][0];
    }
  },
  async resetPasswordConfirm(_, user) {
    try {
      console.log('reset user',this.user)
      const form = new FormData();
      form.append("token", user.token),
        form.append("uid", user.uid),
        form.append("new_password1", user.password1),
        form.append("new_password2", user.password2)
      const response = await postReq(`password/reset/confirm/${user.uid}/${user.token}/`, form);
      console.log(response);
      return response;
    } catch (e) {
      const errors = e.response.data;
      const error = [];
      for (const key in errors) {
        if (Object.hasOwnProperty.call(errors, key)) {
          error.push(errors[key]);
        }
      }
      throw error[0][0];
    }
  },
  async changePassword(_, user) {

    try {
      const response = await postReq("rest-auth/password/change/", user);
      if (response.key) {
        // this.logoutUser()
        return response
        // localStorage.setItem("instant_token", response.key);
        // localStorage.setItem("has_subscribed",response.has_subscribed)
        // localStorage.setItem("lifetime_subscribed",response.lifetime_subscribed)
        // return response;
      }
    } catch (e) {
      const errors = e.response.data;
      console.log('errors',errors)
      const error = [];
      for (const key in errors) {
        if (Object.hasOwnProperty.call(errors, key)) {
          error.push(errors[key]);
        }
      }
      
        // this.$toastr.Add({
        //   name: "Error", // this is give you ability to use removeByName method
        //   msg: error[0][0], // Toast Message
        //   type: "error", // Toast type,
        // });
      throw error[0][0];
    }
  },
  async logoutUser() {
    localStorage.removeItem("instant_token");
    localStorage.removeItem("instant_username");
    localStorage.removeItem("instant_clientname");
    localStorage.removeItem("has_subscribed");

  },
  async getUser({ commit }) {
    try {
      const user = await getReq("rest-auth/user/");
      console.log('user', user)
      commit("setUser", user);
      return user
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  async getUserSubscriptionStatus(_,id) {
    try {
      const {has_subscribed,lifetime_subscribed} = await postReq("subscription/subscription-status/",{id});
      localStorage.setItem("has_subscribed",has_subscribed)
      localStorage.setItem("lifetime_subscribed",lifetime_subscribed)
      return {has_subscribed, lifetime_subscribed}
    } catch (error) {
      console.log(error);
    }
  },
  
  async getUserByID({ commit },) {
    try {
      let user = await getReq(`rest-auth/user/`);
      if (user && user.pk) {
        user = await getReq(`rest-auth/user/${user.pk}`);
      }
      commit("setUser", user);
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async updateUserName({ commit },user) {
    console.log('change name',user)
    try {
      let updateUser = await putWithoutIdReq(`rest-auth/user/`,user);
      // if (updateUser && updateUser.pk) {
      //   updateUser = await getReq(`rest-auth/user/${updateUser.pk}`);
      // }
      commit("setUser", updateUser);
      return updateUser
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async setUserSubscription(_,id){
    try {
      await postReq(`subscription/user-subscriptions/`,{subscriptions:[id]});
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async setCardDetails(_,cardDetails){
    try {
      await postReq(`subscription/stripe-customer/`,cardDetails);
      localStorage.setItem('has_subscribed',"true")
      return true

    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getUserSubscriptions(){
    try {
      const subscriptions = await getReq(`subscription/user-subscriptions/`);
      return subscriptions
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getCurrentUserSubscription({ commit }){
    try {
      const subscription = await getReq(`subscription/stripe-customer/`);
      commit("setCurrentUserSubscription",subscription[0].id)
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async changeUserSubscription(_,subscription){
    try {
    await postReq(`subscription/checkout/`,subscription);
    return true
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async updateCardDetails(_, { id, payload }){
    try {
    await patchReq(`subscription/stripe-customer`,id,payload);
    return true
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async cancelUserSubscription({commit}, {id,payload} ){
    try {
    await patchStripeReq(`subscription/user-subscriptions/${id}/cancel_subscription/`,payload);
    commit("setCurrentUserSubscription",null)
    return true
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  setLifeTimeSubscribed({commit},data){
    commit("setLifeTimeSubscribed", data)
  }
};

const mutations = {
  setUser: (state, user) => (state.user = user),
  setCurrentUserSubscription:(state, currentUserSubscription) => (state.currentUserSubscription = currentUserSubscription),
  setLifeTimeSubscribed:(state,lifeTimeSubscribed) => (state.lifeTimeSubscribed = lifeTimeSubscribed)
};

export default {
  state,
  getters,
  actions,
  mutations,
};
