<template>
  <div class="card">
    <div v-if="editId">
      <br />
      <br />
      <br />
    </div>
    <div :class="BloodMarkerbox">
      <div v-if="editId">
        <h2 class="mx-2 pt-2 mt-5 mb-5">Edit Copper Blood Markers</h2>
      </div>
      <table id="comparison_tbl_naq">
        <tr>
          <!-- <th v-if="editId" colspan="2"><h3>Edit Blood Markers for Copper</h3> </th> -->
          <th colspan="2">Blood Markers for Copper</th>
        </tr>
        <tr>
          <td>Client Name *</td>
          <td class="right_col">
            <input type="text" v-model="compVal.client_name" />
          </td>
        </tr>
        <tr>
          <td>Zinc</td>
          <td class="right_col d-flex justify-content-between">
            <select class="col-3" v-model="compVal.zinc_choice">
              <option value="1">umol/L</option>
              <option value="2">ug/dL</option>
            </select>
            <input class="col-8" type="number" v-model="compVal.zinc" />
          </td>
        </tr>
        <tr>
          <td>Copper</td>
          <td class="right_col d-flex justify-content-between">
            <select class="col-3" v-model="compVal.copper_choice">
              <option value="1">umol/L</option>
              <option value="2">ug/dL</option>
            </select>
            <input class="col-8" type="number" v-model="compVal.copper" />
          </td>
        </tr>
        <tr>
          <td>Ceruloplasmin</td>
          <td class="right_col d-flex justify-content-between">
            <select class="col-3" v-model="compVal.cp_choice">
              <option value="1">g/L</option>
              <option value="2">mg/dL</option>
              <option value="3">mg/L</option>
              <option value="4">ug/L</option>
              <option value="5">ng/mL</option>
              <option value="6">ug/dL</option>
            </select>
            <input class="col-8" type="number" v-model="compVal.ceruloplasmin" />
          </td>
        </tr>
      </table>
    </div>
    <div v-if="editId" class="row">
      <div
        class="mt-5 mr-3 w-100 submit-box"
        style="display: flex; justify-content: flex-end !important"
      >
        <button @click="onUpdate()" class="menu_item next-button">
          Update
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "BloodMarkerCopper",
  props: ["data"],
  data() {
    return {
      editId: null,
      editData: [],
    };
  },
  computed: {
    ...mapGetters(["singleCopperBloodMarker"]),
    BloodMarkerbox: function () {
      return this.editId ? "update-box" : "card__item";
    },
    compVal: {
      get() {
        if (this.editId) {
          return this.editData;
        } else {
          return this.data;
        }
      },
    },
  },
  methods: {
    ...mapActions(["getSingleCopperBloodMarker", "updateCopperBloodMarkers"]),
    async onUpdate() {
      if (!this.editData.client_name) {
        this.$toastr.Add({
          name: "Error", // this is give you ability to use removeByName method
          msg: "Please Enter Client Name!", // Toast Message
          type: "error", // Toast type,
        });
      } else {
        this.$toastr.Add({
          name: "Success", // this is give you ability to use removeByName method
          msg: "Blood Markers Copper has been updated Successfully!", // Toast Message
          type: "success", // Toast type,
        });
        //  Blood Markers
        console.log("updating..");
        console.log("edit BloodMarkers", this.editData);
        const dataUpdate = await this.updateCopperBloodMarkers({
          id: this.editId,
          payload: this.editData,
        });
        console.log("data has been edited ", dataUpdate);

        this.$router.push(`/client-list`);
      }
    },
  },

  async mounted() {
    this.editId = this.$route.params.id;
    if (this.editId) {
      console.log(this.editId);
      console.log("id getting");
      await this.getSingleCopperBloodMarker(this.editId);
      this.editData = await this.singleCopperBloodMarker;
      console.log(this.editData);
    }
  },
};
</script>

<style>
.update-box {
  margin: 5px 10px;
  padding: 0px 15px;
}
</style>
