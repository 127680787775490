<template>
  <div id="">
    <div v-if="loading" id="preloader">
      <div class="loader3">
        <span></span>
        <span></span>
      </div>
    </div>
    <main>
      <div class="main_content_wrapper">
        <br />
        <h2 class="mx-2 pt-2 mb-2 text-center">
          Metabolic Blueprint for {{ metabolicBlueprintData.client_name }}
        </h2>
        <h3 class="mx-2 pt-2 mb-2 text-center">
          Prepared by {{ metabolicBlueprintData.practitioner_name }} on
          {{ dateValue }}
        </h3>
        <hr />
        <div class="container">
          <div class="row accorodian_content accorodian_content2">
            <div class="col-lg-12 col-md-12 col-sm-12 pt_20">
              <div class="middle_content_box" style="height: 100% !important">
                <table>
                  <tr>
                    <h3 class="title_md mhd" id="SO_D2">Stress:</h3>
                    <td>
                      <h3 class="title_md" style="text-align: right">
                        <span id="SO_E2"></span>
                      </h3>
                    </td>
                  </tr>
                </table>
                <ul class="middle_content_list" style="line-height: 2">
                  <li style=" display: inline-block; !important">
                    <span class="hd">Stage of Stress: </span>
                    &nbsp;{{ metabolicBlueprintData.sos }}
                  </li>
                  <li>
                    <span class="hd">Metabolic Type: </span> &nbsp;
                    {{ metabolicBlueprintData.mt }},
                    {{ metabolicBlueprintData.mme }}
                  </li>
                  <li>
                    <span class="hd">Na/K: </span>&nbsp;
                    {{ metabolicBlueprintData.na_k }}
                  </li>
                  <li>
                    <span class="hd">Burnout Indicators: </span>&nbsp;
                    {{ parseFloat(metabolicBlueprintData.b_number).toFixed(2) }}
                    <br />
                    <!-- <span
                      id="SO_F3"
                      v-for="value in metabolicBlueprintData.burnout_indicator.filter(
                        (test) => test !== ''
                      )"
                      :key="value.index"
                    >
                      {{ value }}
                      <br />
                    </span> -->
                  </li>
                  <li>
                    <span class="hd">Impaired Immune System Indicators:</span>
                    &nbsp;
                    {{ metabolicBlueprintData.i_number }}
                    <br />
                    <!-- <span
                      id="SO_F3"
                      v-for="value in metabolicBlueprintData.immune_indicator.filter(
                        (test) => test !== ''
                      )"
                      :key="value.index"
                    >
                      {{ value }}
                      <br />
                    </span> -->
                  </li>
                  <li>
                    <span class="hd">Zinc Deficiency Indicators:</span> &nbsp;
                    {{ metabolicBlueprintData.z_number }}
                    <br />
                    <!-- <span
                      id="SO_F3"
                      v-for="value in metabolicBlueprintData.z_indicators.filter(
                        (test) => test !== ''
                      )"
                      :key="value.index"
                    >
                      {{ value }}
                      <br />
                    </span> -->
                  </li>
                  <li>
                    <span class="hd">Low Stomach Acid Indicators:</span> &nbsp;
                    {{ metabolicBlueprintData.low_stomach_number }}
                    <br />
                    <!-- <span
                      id="SO_F3"
                      v-for="value in metabolicBlueprintData.z_indicators.filter(
                        (test) => test !== ''
                      )"
                      :key="value.index"
                    >
                      {{ value }}
                      <br />
                    </span> -->
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 pt_20">
              <div class="middle_content_box" style="height: 100% !important">
                <table>
                  <tr>
                    <h3 class="title_md mhd" id="SO_D2">Diet:</h3>
                    <td>
                      <h3 class="title_md" style="text-align: right">
                        <span id="SO_E2"></span>
                      </h3>
                    </td>
                  </tr>
                </table>
                <ul class="middle_content_list" style="line-height: 2">
                  <li style="line-height: 1.8">
                    <span class="hd">Diet Notes</span>
                    <!-- (This is the same
                    information from the information on the single output page) -->
                    <br />
                    {{ metabolicBlueprintData.carb_protein }}
                  </li>
                  <li>
                    <span class="hd">Glucose Intolerance Indicators:</span>
                    {{ metabolicBlueprintData.gi_number }}
                    <br />
                    <!-- <span
                      id="SO_F3"
                      v-for="value in metabolicBlueprintData.gi_indicator.filter(
                        (test) => test !== ''
                      )"
                      :key="value.index"
                    >
                      {{ value }}
                      <br />
                    </span> -->
                  </li>
                  <li
                  id="SO_F7"
                  v-for="value in metabolicBlueprintData.diet_f.filter(
                    (test) => test !== ''
                  )"
                  :key="value.index"
                >
                  <a
                    id="RO_I10"
                    style="
                      text-decoration: underline;
                      color: #884c42 !important;
                    "
                    class="text-dark"
                    v-if="value.includes('Four Lows Diet')"
                    href="https://docs.google.com/document/d/1oDtpzDCgwds0ji3-Y35X_FzQFrsYh4wpZNB4jMNISEs/edit?usp=sharing"
                    >{{ value }}</a
                  >
                </li>
                <li
                  id="SO_F7"
                  v-for="value in metabolicBlueprintData.diet_f.filter(
                    (test) => test !== ''
                  )"
                  :key="value.index"
                >
                  <a
                    id="RO_I10"
                    style="
                      text-decoration: underline;
                      color: #884c42 !important;
                    "
                    class="text-dark"
                    v-if="value.includes('Slow Oxidation Diet')"
                    href="https://docs.google.com/document/d/1ac_Vxl3D-sTWEbspoFXqV0nV0RdS9tyQTvRgEDlNWZ4/edit?usp=sharing"
                    >{{ value }}</a
                  >
                </li>
                <li
                  id="SO_F7"
                  v-for="value in metabolicBlueprintData.diet_f.filter(
                    (test) => test !== ''
                  )"
                  :key="value.index"
                >
                  <a
                    id="RO_I10"
                    style="
                      text-decoration: underline;
                      color: #884c42 !important;
                    "
                    class="text-dark"
                    v-if="value.includes('Fast Oxidation Diet')"
                    href="https://docs.google.com/document/d/10DFIOENMlMAaaBB6QNghLIZs0JdWTdsDnwWBdAQawo0/edit?usp=sharing"
                    >{{ value }}</a
                  >
                </li>
                  <!-- <li>
                    <span class="hd"> Low Stomach Acid Indicators: </span>
                    ??????
                    {{ Number }} {{ SAIndicators }}
                  </li> -->
                </ul>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 pt_20">
              <div class="middle_content_box" style="height: 100% !important">
                <table>
                  <tr>
                    <h3 class="title_md mhd" id="SO_D2">Priorities:</h3>
                    <td>
                      <h3 class="title_md" style="text-align: right">
                        <span id="SO_E2"></span>
                      </h3>
                    </td>
                  </tr>
                </table>
                <ul class="" style="line-height: 2">
                  <li>
                    <span class="hd"> Patterns: </span>
                    <br />
                    <span
                      id="SO_F3 mb-4"
                      v-for="value in metabolicBlueprintData.stress_patterns.filter(
                        (test) => test !== ''
                      )"
                      :key="value.index"
                    >
                      {{ value }}
                      <br />
                    </span>
                  </li>
                  <ul>
                    <li>
                      <span class="hd">Copper Imbalance Indicators: </span>
                      {{ metabolicBlueprintData.ci_number }}
                      <br />
                      <!-- <span
                        id="SO_F3"
                        style="line-height: 2"
                        v-for="value in metabolicBlueprintData.ci_indicator.filter(
                          (test) => test !== ''
                        )"
                        :key="value.index"
                      >
                        {{ value }}
                        <br />
                      </span> -->
                    </li>
                  </ul>

                  <li>
                    <span class="hd">Inversion: </span>
                    {{ metabolicBlueprintData.inversions }},
                    {{ metabolicBlueprintData.double_inv }}
                  </li>
                  <li>
                    <span class="hd">Ca/Mg (Blood Sugar Ratio): </span
                    >{{ metabolicBlueprintData.ca_mg }}
                  </li>
                  <li>
                    <span class="hd">Ca/K (Thyroid Ratio):</span>
                    {{ metabolicBlueprintData.ca_k }}
                  </li>
                  <li>
                    <span class="hd">Na/Mg (Adrenal Ratio):</span>
                    {{ metabolicBlueprintData.na_mg }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 pt_20">
              <div class="middle_content_box" style="height: 100% !important">
                <table>
                  <tr>
                    <h3 class="title_md mhd" id="SO_D2">Detoxification:</h3>
                    <td>
                      <h3 class="title_md" style="text-align: right">
                        <span id="SO_E2"></span>
                      </h3>
                    </td>
                  </tr>
                </table>
                <ul class="middle_content_list" style="line-height: 2">
                  <li>
                    <span class="hd">Reduce Exposure:</span>
                    {{ metabolicBlueprintData.red_exp }}
                  </li>
                  <li>
                    <span class="hd">Poor Eliminators:</span>
                    {{ metabolicBlueprintData.pe_number }}
                    <br />
                    <!-- <span
                      id="SO_F3"
                      v-for="value in metabolicBlueprintData.pe_indicator.filter(
                        (test) => test !== ''
                      )"
                      :key="value.index"
                    >
                      {{ value }}
                      <br />
                    </span> -->
                  </li>
                  <li>
                    <span class="hd">Very Poor Eliminators:</span>
                    {{ metabolicBlueprintData.vpe_number }}
                    <br />
                    <!-- <span
                      id="SO_F3"
                      v-for="value in metabolicBlueprintData.vpe_indicators.filter(
                        (test) => test !== ''
                      )"
                      :key="value.index"
                    >
                      {{ value }}
                      <br />
                    </span> -->
                  </li>
                  <li>
                    <span class="hd">Total Poor Eliminators:</span>
                    {{ metabolicBlueprintData.tpe }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 pt_20">
              <div class="middle_content_box" style="height: 100% !important">
                <table>
                  <tr>
                    <h3 class="title_md mhd" id="SO_D2">Psychological:</h3>
                    <td>
                      <h3 class="title_md" style="text-align: right">
                        <span id="SO_E2"></span>
                      </h3>
                    </td>
                  </tr>
                </table>
                <ul class="middle_content_list" style="line-height: 2">
                  <li>
                    <span class="hd">Patterns: </span>
                    {{ metabolicBlueprintData.psych }}
                  </li>
                  <li>
                    <span class="hd">Trauma Indicators:</span>
                    {{ metabolicBlueprintData.trauma_number }}
                    <br />
                    <!-- <span
                      id="SO_F3"
                      v-for="value in metabolicBlueprintData.trauma_indicators.filter(
                        (test) => test !== ''
                      )"
                      :key="value.index"
                    >
                      {{ value }}
                      <br />
                    </span> -->
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import $ from "jquery"

export default {
  name: "MetabolicBlueprintResult",
  components: {},

  data() {
    return {
      formId: null,
      loading: true,
      metabolicBlueprintData: {},
      singleInputData: {},
    };
  },
  watch: {},

  computed: {
    ...mapGetters(["metabolicBlueprint", "singleInput"]),
    dateValue() {
      var date = new Date(this.metabolicBlueprintData.date);
      return (
        (date.getMonth() > 8
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1)) +
        "/" +
        (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
        "/" +
        date.getFullYear()
      );
    },
  },
  methods: {
    ...mapActions([
      "getMetabolicBlueprint",
      //  "getSingleInput"
    ]),
    showLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
  },
  async mounted() {
    console.log("loading is ", this.loading);
    // window.onload = async function () {
    // $('#preloader').delay(1000).fadeOut(500);
    // }

    this.formId = this.$route.params.id;

    await this.getMetabolicBlueprint(this.formId);
    // await this.getSingleInput(this.formId);

    [
      this.metabolicBlueprintData,
      // this.singleInputData
    ] = await Promise.all([
      this.metabolicBlueprint,
      // this.singleInput,
    ]);

    localStorage.setItem(
      "instant_clientname",
      `${this.metabolicBlueprintData.client_name}`
    );

    console.log("this.meta bolic blueprint", this.metabolicBlueprintData);
    console.log("this.input", this.singleInputData);
    this.showLoading();
    // this.loading = false;
    // console.log('loading is ', this.loading)
  },
};
</script>

<style scoped>
.menu_item_new {
  background-color: #884c42;
  padding: -19px 10px !important;
  color: #ffffff !important;
  border-radius: 10px;
  transition: 0.4s;
  margin: 5px;
}
.middle_content_box {
  height: 100%;
}
.hd {
  color: #884c42;
  font-size: 18px;
  font-weight: bold;
}
.mhd {
  /* color: #884c42; */
  font-size: 20px;
  font-weight: bold;
}
</style>
