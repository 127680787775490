<template>
  <div class="bg-white accoridian_bg border">
    <h3 class="mt_20 heading">Metabolic Patterns</h3>
    <div class="collapse show" id="idcollapseTwo">
      <div class="accorodian_content accorodian_content2" id="SO_H2_I">
        <div v-for="value in singleTestAnalysisData" :key="value.index">
          <h4 class="mb-2">{{ value.heading }}</h4>
          <p
            style="margin: 0; margin-bottom: 5px; padding-top: 0"
            class="pt_15"
          >
            {{ value.text }}
          </p>
          <br />
        </div>
        <!-- <h4>{{singleTestAnalysisData.metabolic_pattern.heading}}</h4>
        <p class="pt_15">{{singleTestAnalysisData.metabolic_pattern.desc}}</p> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MetabolicPatterns",
  components: {},
  props: ["singleTestAnalysisData"],
  data() {
    return {};
  },
  mounted() {},
};
</script>
<style scoped>
.heading {
  font-weight: bold;
  color: #884c42;
  padding: 5px;
  margin-left: 20px;
  padding-top: 20px;
  font-family: "Inter", sans-serif;
}
</style>