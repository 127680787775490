<template>
  <main>
    <b-container
      fluid
      class="main_content_wrapper mt-5 mb-5 pb-5 main-container"
    >
      <b-row>
        <b-col cols="" class="">
          <b-row>
            <b-col cols="12">
              <div class="data-table team-data-table bg-white p-4 rounded">
                <div class="table_row team_table_row">
                  <div class="text-start">
                    <h4>All Reports</h4>
                  </div>

                  <form class="mt-4">
                    <div class="d-flex">
                      <b-form-group
                        label="Client Name"
                        class="text-start label"
                      >
                        <b-form-input
                          type="text"
                          class="mb-4 input"
                          required
                          v-model="searchClientName"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                  </form>

                  <b-table
                    responsive
                    :fields="fields"
                    :items="data"
                    striped
                    hover
                    :per-page="perPage"
                    :current-page="currentPage"
                  >
                    <template #cell(status)="data">
                      <b-button
                        class="
                          export
                          table_btn
                          d-flex
                          justify-content-center
                          align-items-center
                          my-1
                        "
                        :class="
                          data.item.Status === 'Active'
                            ? 'equipment'
                            : 'inactive-equipment'
                        "
                      >
                        <p class="mb-0">{{ data.value }}</p>
                      </b-button>
                    </template>
                  </b-table>
                </div>
              </div>
            </b-col>
          </b-row>
          <div class="entries-pagination mt-4">
            <div
              class="
                d-flex
                align-items-center
                justify-content-between
                flex-wrap
              "
            >
              <div class="text-left show-entries d-flex">
                <label for="" class="ms-0">Show</label>
                <b-form-select v-model="perPage" :options="pageOptions" />
                <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                </svg> -->
                <label for="" class="me-0">entries</label>
              </div>
              <div class="pagination">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="my-table"
                ></b-pagination>
              </div>
            </div>
            <p class="mt-2">{{ positionText }}</p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </main>
</template>

<script>
// import { mapActions, mapGetters } from "vuex";

export default {
  name: "AllReports",
  data() {
    return {
      statusOptions: [
        {
          value: null,
          text: "",
        },
        {
          value: "Active",
          text: "Active",
        },
        {
          value: "Inactive",
          text: "Inactive",
        },
      ],
      perPage: 10,
      currentPage: 1,
      items: [],
      errors: {},
      searchClientName: null,
      fields: [
        {
          key: "FirstName",
          sortable: true,
          tdClass: "align-middle",
        },
        {
          key: "LastName",
          sortable: true,
          tdClass: "align-middle",
        },
        {
          key: "Address",
          sortable: true,
          tdClass: "align-middle",
        },
        {
          key: "City",
          sortable: true,
          tdClass: "align-middle",
        },
      ],
      data: [
        {
          FirstName: "abc",
          LastName: "abc",
          Address: "abc",
          City: "abc",
        },
        {
          FirstName: "abc",
          LastName: "abc",
          Address: "abc",
          City: "abc",
        },
      ],
      pageOptions: [
        {
          value: 10,
          text: "10",
        },
        {
          value: 25,
          text: "25",
        },
        {
          value: 50,
          text: "50",
        },
        {
          value: 100,
          text: "100",
        },
      ],
    };
  },

  computed: {
    rows() {
      return this.items.length;
    },
    positionText() {
      let endIndex = this.currentPage * this.perPage,
        startIndex =
          this.rows > 0 ? (this.currentPage - 1) * this.perPage + 1 : 0;

      return (
        "Showing " +
        startIndex +
        " to " +
        (endIndex > this.rows ? this.rows : endIndex) +
        " of " +
        this.rows +
        " entries"
      );
    },
  },
  methods: {},
  mounted() {},
};
</script>

<style>
.label {
  text-transform: uppercase;
  color: var(--black) !important;
  font-size: var(--extrasmall);
}

.page-item.active .page-link {
  color: white !important;
  background-color: #884c42 !important;
  border-color: #884c42 !important;
}

.show-entries label {
  font-weight: 500;
  margin: auto 10px;
}
</style>
